import { CALL_API } from '../../middleware';
import { Schemas } from '../../schemas';
import { sample } from 'lodash';

export const SCANS_REQUEST = 'SCANS_REQUEST';
export const SCANS_SUCCESS = 'SCANS_SUCCESS';
export const SCANS_FAILURE = 'SCANS_FAILURE';

function fetchScans(
  sortColumns,
  sortOrders,
  offset = null,
  limit = null,
  join,
  filter,
) {
  return {
    [CALL_API]: {
      types: [SCANS_REQUEST, SCANS_SUCCESS, SCANS_FAILURE],
      endpoint: Routing.generate(
        'ccsscan_index',
        {
          sortColumns: sortColumns,
          sortOrders: sortOrders,
          offset: offset,
          limit: limit,
          join: join,
          filter: filter,
        },
        true,
      ),
      schema: Schemas.CCSSCANS,
    },
  };
}

export function loadScans(
  requiredFields = [],
  sortColumns = [],
  sortOrders = [],
  join = [],
  filter = [],
) {
  return (dispatch, getState) => {
    const scan = getState().entities.ccsScan;
    const resultset = getState().resultsets.ccsScan;
    const outdated = getState().outdated.ccsScan;

    let offset = null;
    let limit = null;

    if (
      !outdated &&
      resultset &&
      resultset.length &&
      scan &&
      requiredFields.every((key) => sample(scan).hasOwnProperty(key))
    ) {
      return null;
    }

    return dispatch(
      fetchScans(sortColumns, sortOrders, offset, limit, join, filter),
    );
  };
}

export const CCSSCAN_DOWNLOAD_REQUEST = 'CCSSCAN_DOWNLOAD_REQUEST';
export const CCSSCAN_DOWNLOAD_SUCCESS = 'CCSSCAN_DOWNLOAD_SUCCESS';
export const CCSSCAN_DOWNLOAD_FAILURE = 'CCSSCAN_DOWNLOAD_FAILURE';

function newExport(form) {
  return {
    [CALL_API]: {
      types: [
        CCSSCAN_DOWNLOAD_REQUEST,
        CCSSCAN_DOWNLOAD_SUCCESS,
        CCSSCAN_DOWNLOAD_FAILURE,
      ],
      endpoint: Routing.generate('ccsscan_create', null, true),
      method: 'POST',
      data: form,
      schema: Schemas.CCSSCANS,
    },
  };
}

export function createExport(form) {
  // eslint-disable-next-line no-unused-vars
  return (dispatch, getState) => {
    return dispatch(newExport(form));
  };
}

export const CCSSCAN_IMPORT_REQUEST = 'CCSSCAN_IMPORT_REQUEST';
export const CCSSCAN_IMPORT_SUCCESS = 'CCSSCAN_IMPORT_SUCCESS';
export const CCSSCAN_IMPORT_FAILURE = 'CCSSCAN_IMPORT_FAILURE';

function writeImport(form) {
  return {
    [CALL_API]: {
      types: [
        CCSSCAN_IMPORT_REQUEST,
        CCSSCAN_IMPORT_SUCCESS,
        CCSSCAN_IMPORT_FAILURE,
      ],
      endpoint: Routing.generate('ccsscan_import', null, true),
      method: 'POST',
      data: form,
      schema: Schemas.FORM,
    },
  };
}

export function updateImport(form) {
  // eslint-disable-next-line no-unused-vars
  return (dispatch, getState) => {
    return dispatch(writeImport(form));
  };
}

function fetchForm() {
  return {
    [CALL_API]: {
      types: [CCS_FORM_REQUEST, CCS_FORM_SUCCESS, CCS_FORM_FAILURE],
      endpoint: Routing.generate('ccsscan_token', {}, true),
      schema: Schemas.FORM,
    },
  };
}

export const CCS_FORM_REQUEST = 'CCS_FORM_REQUEST';
export const CCS_FORM_SUCCESS = 'CCS_FORM_SUCCESS';
export const CCS_FORM_FAILURE = 'CCS_FORM_FAILURE';

export function initForm() {
  return (dispatch, getState) => {
    const updateToken = getState().forms.updateToken;

    if (updateToken) {
      return null;
    }

    return dispatch(fetchForm());
  };
}

export const CCS_PAGED_REQUEST = 'CCS_PAGED_REQUEST';
export const CCS_PAGED_SUCCESS = 'CCS_PAGED_SUCCESS';
export const CCS_PAGED_FAILURE = 'CCS_PAGED_FAILURE';

function fetchCcsScanPaged(
  sortColumns,
  sortOrders,
  offset = null,
  limit = null,
  join,
  filter,
) {
  return {
    [CALL_API]: {
      types: [CCS_PAGED_REQUEST, CCS_PAGED_SUCCESS, CCS_PAGED_FAILURE],
      endpoint: Routing.generate(
        'ccsscan_index',
        {
          sortColumns: sortColumns,
          sortOrders: sortOrders,
          offset: offset,
          limit: limit,
          join: join,
          filter,
        },
        true,
      ),
      schema: Schemas.CCSSCANS,
    },
  };
}

// eslint-disable-next-line no-unused-vars
export function loadNext(
  sortColumns = [],
  sortOrders = [],
  offset = null,
  limit = null,
  join = [],
  filter = [],
) {
  return (dispatch, getState) => {
    const ccsScan = getState().entities.ccsScan;
    const pagination =
      ccsScan && getState().pagination.ccsScan
        ? getState().pagination.ccsScan
        : false;

    if (!ccsScan || !pagination) {
      return null;
    }

    return dispatch(
      fetchCcsScanPaged(sortColumns, sortOrders, offset, limit, join, filter),
    );
  };
}

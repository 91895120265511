import { CALL_API } from '../../middleware';
import { Schemas } from '../../schemas';
import { sample } from 'lodash';

export const SEASONS_REQUEST = 'SEASONS_REQUEST';
export const SEASONS_SUCCESS = 'SEASONS_SUCCESS';
export const SEASONS_FAILURE = 'SEASONS_FAILURE';

function fetchSeasons(sortColumns, sortOrders) {
  return {
    [CALL_API]: {
      types: [SEASONS_REQUEST, SEASONS_SUCCESS, SEASONS_FAILURE],
      endpoint: Routing.generate(
        'season_index',
        { sortColumns: sortColumns, sortOrders: sortOrders },
        true,
      ),
      schema: Schemas.SEASONS,
    },
  };
}

export function loadSeasons(
  sortColumns = [],
  sortOrders = [],
  requiredFields = [],
) {
  return (dispatch, getState) => {
    const season = getState().entities.season;
    const resultset = getState().resultsets.season;
    const outdated = getState().outdated.season;

    if (
      !outdated &&
      resultset &&
      resultset.length &&
      season &&
      requiredFields.every((key) => sample(season).hasOwnProperty(key))
    ) {
      return null;
    }

    return dispatch(fetchSeasons(sortColumns, sortOrders));
  };
}

export const SEASON_REQUEST = 'SEASON_REQUEST';
export const SEASON_SUCCESS = 'SEASON_SUCCESS';
export const SEASON_FAILURE = 'SEASON_FAILURE';

function fetchSeason(id) {
  return {
    [CALL_API]: {
      types: [SEASON_REQUEST, SEASON_SUCCESS, SEASON_FAILURE],
      endpoint: Routing.generate('season_show', { id: id }, true),
      schema: Schemas.SEASON,
    },
  };
}

export function loadSeason(id, requiredFields = []) {
  return (dispatch, getState) => {
    const seasons = getState().entities.season;
    const outdated = getState().outdated.season;

    if (
      !outdated &&
      seasons &&
      seasons[id] &&
      requiredFields.every((key) => seasons[id].hasOwnProperty(key))
    ) {
      return null;
    }

    return dispatch(fetchSeason(id));
  };
}

/*UPDATE EMAIL SEASON ACTIONS*/
export const EMAIL_UPDATE_REQUEST = 'EMAIL_UPDATE_REQUEST';
export const EMAIL_UPDATE_SUCCESS = 'EMAIL_UPDATE_SUCCESS';
export const EMAIL_UPDATE_FAILURE = 'EMAIL_UPDATE_FAILURE';

function writeEmailSeason(form, emailId = null) {
  return {
    [CALL_API]: {
      types: [EMAIL_UPDATE_REQUEST, EMAIL_UPDATE_SUCCESS, EMAIL_UPDATE_FAILURE],
      endpoint: Routing.generate('email_update', { id: emailId }, true),
      method: 'POST',
      data: form,
      schema: Schemas.SEASON,
    },
  };
}

export function updateEmailSeason(form, emailId = null) {
  return (dispatch) => {
    return dispatch(writeEmailSeason(form, emailId));
  };
}

export const SEASON_TAGS_REQUEST = 'SEASON_TAGS_REQUEST';
export const SEASON_TAGS_SUCCESS = 'SEASON_TAGS_SUCCESS';
export const SEASON_TAGS_FAILURE = 'SEASON_TAGS_FAILURE';

function fetchEmailTags() {
  return {
    [CALL_API]: {
      types: [SEASON_TAGS_REQUEST, SEASON_TAGS_SUCCESS, SEASON_TAGS_FAILURE],
      endpoint: Routing.generate('tag_email', {}, true),
      schema: Schemas.TAGS,
    },
  };
}

function fetchTagsBySeason(seasonId) {
  return {
    [CALL_API]: {
      types: [SEASON_TAGS_REQUEST, SEASON_TAGS_SUCCESS, SEASON_TAGS_FAILURE],
      endpoint: Routing.generate('tag_season', { id: seasonId }, true),
      schema: Schemas.TAGS,
    },
  };
}

export function loadTagsBySeason(seasonId) {
  return (dispatch) => {
    return dispatch(fetchTagsBySeason(seasonId));
  };
}

export function loadEmailTags() {
  return (dispatch) => {
    return dispatch(fetchEmailTags());
  };
}

export const SEASON_ENABLE_REQUEST = 'SEASON_ENABLE_REQUEST';
export const SEASON_ENABLE_SUCCESS = 'SEASON_ENABLE_SUCCESS';
export const SEASON_ENABLE_FAILURE = 'SEASON_ENABLE_FAILURE';

function writeEnableSeason(form) {
  return {
    [CALL_API]: {
      types: [
        SEASON_ENABLE_REQUEST,
        SEASON_ENABLE_SUCCESS,
        SEASON_ENABLE_FAILURE,
      ],
      endpoint: Routing.generate('season_enable', {}, true),
      schema: Schemas.CAMPAIGN,
      method: 'POST',
      data: form,
    },
  };
}

export function enableSeason(form) {
  return (dispatch) => {
    return dispatch(writeEnableSeason(form));
  };
}

export const SEASON_DISABLE_REQUEST = 'SEASON_DISABLE_REQUEST';
export const SEASON_DISABLE_SUCCESS = 'SEASON_DISABLE_SUCCESS';
export const SEASON_DISABLE_FAILURE = 'SEASON_DISABLE_FAILURE';

function writeDisableSeason(form) {
  return {
    [CALL_API]: {
      types: [
        SEASON_DISABLE_REQUEST,
        SEASON_DISABLE_SUCCESS,
        SEASON_DISABLE_FAILURE,
      ],
      endpoint: Routing.generate('season_disable', { mode: 'v3' }, true),
      schema: Schemas.CAMPAIGN,
      method: 'POST',
      data: form,
    },
  };
}

export function disableSeason(form) {
  return (dispatch) => {
    return dispatch(writeDisableSeason(form));
  };
}

export const EMAIL_FORM_REQUEST = 'EMAIL_FORM_REQUEST';
export const EMAIL_FORM_SUCCESS = 'EMAIL_FORM_SUCCESS';
export const EMAIL_FORM_FAILURE = 'EMAIL_FORM_FAILURE';

function fetchFormTokens() {
  return {
    [CALL_API]: {
      types: [EMAIL_FORM_REQUEST, EMAIL_FORM_SUCCESS, EMAIL_FORM_FAILURE],
      endpoint: Routing.generate('email_token', {}, true),
      schema: Schemas.FORM,
    },
  };
}

export function initEmailForm() {
  return (dispatch, getState) => {
    const updateToken = getState().forms.updateToken;

    if (updateToken) {
      return null;
    }

    return dispatch(fetchFormTokens());
  };
}

/*UPDATE SMS SEASON ACTIONS*/
export const SMS_UPDATE_REQUEST = 'SMS_UPDATE_REQUEST';
export const SMS_UPDATE_SUCCESS = 'SMS_UPDATE_SUCCESS';
export const SMS_UPDATE_FAILURE = 'SMS_UPDATE_FAILURE';

function writeSmsSeason(form, SMSId = null) {
  return {
    [CALL_API]: {
      types: [SMS_UPDATE_REQUEST, SMS_UPDATE_SUCCESS, SMS_UPDATE_FAILURE],
      endpoint: Routing.generate('sms_update', { id: SMSId }, true),
      method: 'POST',
      data: form,
      schema: Schemas.SEASON,
    },
  };
}

export function updateSmsSeason(form, SMSId = null) {
  return (dispatch) => {
    return dispatch(writeSmsSeason(form, SMSId));
  };
}

export const SMS_FORM_REQUEST = 'SMS_FORM_REQUEST';
export const SMS_FORM_SUCCESS = 'SMS_FORM_SUCCESS';
export const SMS_FORM_FAILURE = 'SMS_FORM_FAILURE';

/*FETCH SMS TOKEN ACTIONS*/
function fetchSmsFormTokens() {
  return {
    [CALL_API]: {
      types: [SMS_FORM_REQUEST, SMS_FORM_SUCCESS, SMS_FORM_FAILURE],
      endpoint: Routing.generate('sms_token', {}, true),
      schema: Schemas.FORM,
    },
  };
}

export function initSmsForm() {
  return (dispatch, getState) => {
    const updateToken = getState().forms.updateToken;

    if (updateToken) {
      return null;
    }

    return dispatch(fetchSmsFormTokens());
  };
}

import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';
import axios from 'axios';
import { camelizeKeys } from 'humps';
import { normalize, schema } from 'normalizr';
import { Location } from './LocationType';
import type { RootState } from '../../reducers';
import { Schemas } from '../../schemas';

const locationAdapter = createEntityAdapter<Location>({
  sortComparer: (a, b) => a.id - b.id,
});

export const fetchAllLocations = createAsyncThunk(
  'location/fetchAll',
  async (paginationObj?: {
    columns?: string[];
    sort?: string[];
    join?: object;
    offset?: number | null;
    limit?: number | null;
    filter?: object;
  }) => {
    const response = await axios.get(
      Routing.generate('location_index', null, true),
      {
        withCredentials: true,
        params: { ...paginationObj },
      },
    );

    const normalized = normalize<
      schema.Entity<Location>,
      {
        location: Record<string, Location>;
      }
    >(camelizeKeys(response.data.results), Schemas.LOCATIONS);

    return {
      entities: normalized.entities,
      pagination: response.data.pagination,
      resultset: normalized.result,
    };
  },
);

const locationSlice = createSlice({
  name: 'location',
  initialState: locationAdapter.getInitialState({
    updateToken: null,
    pagination: {},
    resultset: [],
  }),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAllLocations.fulfilled, (state, action) => {
      const { location } = action.payload.entities;

      if (location) {
        locationAdapter.upsertMany(state, location);
      }

      state.pagination = action.payload.pagination;
      state.resultset = action.payload.resultset;
    });
  },
});

export const {
  selectAll: selectAllLocations,
  selectById: selectLocationById,
  selectIds: selectLocationsByIds,
} = locationAdapter.getSelectors<RootState>((state) => state.location);

export default locationSlice.reducer;

import selectors from '../../selectors';

// eslint-disable-next-line no-unused-vars
const getQuestionEntities = (state, props) => state.entities.question;
// eslint-disable-next-line no-unused-vars
const getAnswerEntities = (state, props) => state.entities.answer;
// eslint-disable-next-line no-unused-vars
const getAnswerOptionEntities = (state, props) => state.entities.answerOption;
// eslint-disable-next-line no-unused-vars
const getQuestionnaireEntities = (state, props) => state.entities.questionnaire;

// const getQuestionnaireFilters = (state, props) => {
//     return {callingId: parseInt(props.match.params.callingId) || null};
// };

const getQuestionsSelector = () => {
  return selectors(
    [getQuestionEntities, getAnswerEntities, getAnswerOptionEntities],
    (questions, answers, answerOptions) => {
      if (questions === undefined) {
        return [];
      } else if (answers === undefined || answerOptions === undefined) {
        return Object.values(questions);
      } else {
        return Object.values(questions).map((question) => {
          question = { ...question }; //Sonst wird der State verändert...
          if (question.answers === undefined) {
            return question;
          }
          question.answers = question.answers.map((answerId) => {
            let answer = Object.values(answers).find(
              (answer) => answer.id === answerId,
            );
            answer = { ...answer }; //Sonst wird der State verändert...
            if (answer !== undefined && answer.answerOption !== undefined) {
              answer.answerOption = Object.values(answerOptions).find(
                (answerOption) => answerOption.id === answer.answerOption,
              );
            }
            return answer;
          });
          return question;
        });
      }
    },
  );
};

const getAnswerOptionsSelector = () => {
  return selectors([getAnswerOptionEntities], (answerOptions) => {
    if (answerOptions === undefined) {
      return [];
    } else {
      return Object.values(answerOptions);
    }
  });
};

const getQuestions = getQuestionsSelector();
const getQuestionnairesSelector = () => {
  return selectors(
    [getQuestionnaireEntities, getQuestions],
    (questionnaires, questions) => {
      if (questionnaires === undefined) {
        return [];
      } else {
        return Object.values(questionnaires).map((questionnaire) => {
          questionnaire = { ...questionnaire };
          if (questionnaire.questions !== undefined) {
            questionnaire.questions = questionnaire.questions.map(
              (questionId) => {
                return {
                  ...Object.values(questions).find(
                    (question) => question.id === questionId,
                  ),
                };
              },
            );
          }
          // eslint-disable-next-line no-undef
          return questionaire;
        });
      }
    },
  );
};

export {
  getAnswerOptionsSelector,
  getQuestionsSelector,
  getQuestionnairesSelector,
};

import React, { Component } from 'react';
import { Box, Paper } from '@mui/material';
import Translate from '../../components/service/Translate';

class E403 extends Component {
  render() {
    return (
      <div>
        <Paper>
          <Box
            sx={{
              lineHeight: '40px',
              mb: (theme) => theme.spacingConstants.desktopGutter + 'px',
            }}
          >
            <Translate>No authorisation</Translate>
          </Box>
          <Translate raw={true}>e403</Translate>
        </Paper>
      </div>
    );
  }
}

export default E403;

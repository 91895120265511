import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  LinearProgress,
  CircularProgress,
  linearProgressClasses,
} from '@mui/material';

class Loading extends Component {
  static propTypes = {
    small: PropTypes.bool,
    style: PropTypes.object,
    color: PropTypes.string,
    size: PropTypes.number,
    thickness: PropTypes.number,
  };

  static defaultProps = {
    small: false,
    size: 29.75,
    thickness: 1.75,
  };

  render() {
    const { small, size, thickness } = this.props;

    if (small) {
      return (
        <CircularProgress
          sx={{
            color: 'accent2Color',
            animationDuration: '550ms',
          }}
          size={size}
          thickness={thickness}
          variant="indeterminate"
        />
      );
    }
    return (
      <LinearProgress
        sx={{
          flexGrow: 1,
          position: 'absolute',
          top: (theme) => theme.spacingConstants.appBarHeight + 'px',
          left: 0,
          right: 0,
          [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: 'primary1Color',
          },
          [`& .${linearProgressClasses.bar}`]: {
            backgroundColor: 'accent1Color',
          },
        }}
        variant="indeterminate"
      />
    );
  }
}

export default Loading;

import { createSelector, EntityId } from '@reduxjs/toolkit';
import { omitBy } from 'lodash';
import { selectAllCampaigns } from './CampaignSlice';
import { RootState } from '../../reducers';

const selectCampaignIdByCampaignConfigParam = (
  _state: RootState,
  id: EntityId,
) => id;

export const makeCampaignsBySingularSeason = () =>
  createSelector([selectAllCampaigns], (campaigns) =>
    campaigns.filter((campaign) => campaign.isSingularCampaign),
  );

const selectCampaignsBySingularSeason = makeCampaignsBySingularSeason();
export const makeCampaignsBySingularSeasonForDuplicate = () => {
  createSelector(
    [selectCampaignsBySingularSeason, selectCampaignIdByCampaignConfigParam],
    (campaigns, id) => omitBy(campaigns, { id }),
  );
};

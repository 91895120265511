import React, { memo, useCallback } from 'react';
import { Box, Tooltip, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import ContentAdd from '@mui/icons-material/Add';
import EditorModeEdit from '@mui/icons-material/Edit';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import DeleteIcon from '@mui/icons-material/Delete';
import { GridRowId, GridRowSelectionModel } from '@mui/x-data-grid';
import Translate from '../service/Translate';
import { ToolbarRoot } from '../StyledElements/StyledElements';

interface EeloyDataGridToolbarProps {
  onEdit?: (selected: GridRowId) => void;
  onAdd?: () => void;
  onDelete?: (selected: GridRowId) => void;
  onDuplicate?: (selected: GridRowId | GridRowSelectionModel) => void;
  onReset?: (selected: GridRowId) => void;
  onExport?: () => void;
  embedded?: boolean;
  title?: string;
  disableAdd?: boolean;
  disableEdit?: boolean;
  disableDuplicate?: boolean;
  disableReset?: boolean;
  disableExport?: boolean;
  disableDelete?: boolean;
  deleteTooltipText?: React.ReactNode;
  selected?: GridRowId;
  checkboxSelectionModel?: GridRowSelectionModel;
  useCheckboxSelectionForDuplicate?: boolean;
}
function EeloyDataGridToolbar(props: EeloyDataGridToolbarProps): JSX.Element {
  const {
    embedded,
    title,
    selected,
    disableAdd,
    disableEdit,
    onEdit,
    onAdd,
    onDuplicate,
    onReset,
    onExport,
    onDelete,
    disableDuplicate,
    disableReset,
    disableExport,
    disableDelete,
    deleteTooltipText,
    checkboxSelectionModel,
    useCheckboxSelectionForDuplicate,
  } = props;

  const handleAdd = useCallback((): void => {
    if (onAdd) {
      onAdd();
    }
  }, [onAdd]);

  const handleEdit = useCallback((): void => {
    if (selected && onEdit) {
      onEdit(selected);
    }
  }, [selected, onEdit]);

  const handleDelete = useCallback((): void => {
    if (selected && onDelete) {
      onDelete(selected);
    }
  }, [selected, onDelete]);

  const handleDuplicate = useCallback((): void => {
    if (
      useCheckboxSelectionForDuplicate &&
      checkboxSelectionModel &&
      onDuplicate
    ) {
      onDuplicate(checkboxSelectionModel);
    } else if (selected && onDuplicate) {
      onDuplicate(selected);
    }
  }, [
    selected,
    onDuplicate,
    useCheckboxSelectionForDuplicate,
    checkboxSelectionModel,
  ]);

  const handleReset = useCallback((): void => {
    if (selected && onReset) {
      onReset(selected);
    }
  }, [selected, onReset]);

  const handleExport = useCallback((): void => {
    if (onExport) {
      onExport();
    }
  }, [onExport]);

  return (
    <ToolbarRoot>
      <Box
        sx={{
          flex: '0 0 auto',
        }}
      >
        <Typography variant="h5">{embedded ? '' : title}</Typography>
      </Box>
      <Box sx={{ flex: '1' }} />
      <Box sx={{ color: 'shadowColor' }}>
        {onAdd && (
          <Tooltip title={<Translate>Add</Translate>}>
            <Box component="span">
              <IconButton
                aria-label="Add"
                onClick={handleAdd}
                disabled={disableAdd}
                size="large"
              >
                <ContentAdd />
              </IconButton>
            </Box>
          </Tooltip>
        )}
        {onEdit && (
          <Tooltip title={<Translate>Edit</Translate>}>
            <span>
              <IconButton
                aria-label="Edit"
                onClick={handleEdit}
                disabled={disableEdit || !selected}
                size="large"
              >
                <EditorModeEdit />
              </IconButton>
            </span>
          </Tooltip>
        )}
        {onDuplicate ? (
          <Tooltip title={<Translate>Duplicate</Translate>}>
            <span>
              <IconButton
                aria-label="Duplicate"
                onClick={handleDuplicate}
                disabled={disableDuplicate}
                size="large"
              >
                <FileCopyIcon />
              </IconButton>
            </span>
          </Tooltip>
        ) : null}
        {onReset ? (
          <Tooltip title={<Translate>Reset</Translate>}>
            <span>
              <IconButton
                aria-label="Reset"
                onClick={handleReset}
                disabled={disableReset}
                size="large"
              >
                <SettingsBackupRestoreIcon />
              </IconButton>
            </span>
          </Tooltip>
        ) : null}
        {onExport ? (
          <Tooltip title={<Translate>Export</Translate>}>
            <span>
              <IconButton
                aria-label="Export"
                onClick={handleExport}
                disabled={disableExport}
                size="large"
              >
                <FileDownloadIcon />
              </IconButton>
            </span>
          </Tooltip>
        ) : null}
        {onDelete && (
          <Tooltip
            title={
              disableDelete ? (
                (deleteTooltipText ?? <Translate>Delete</Translate>)
              ) : (
                <Translate>Delete</Translate>
              )
            }
          >
            <span>
              <IconButton
                aria-label="Delete"
                onClick={handleDelete}
                disabled={disableDelete || !selected}
                size="large"
              >
                <DeleteIcon />
              </IconButton>
            </span>
          </Tooltip>
        )}
      </Box>
    </ToolbarRoot>
  );
}

export default memo(EeloyDataGridToolbar);

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ACL from '../../components/ACL';
import { withRouter } from '../../withRouter';
import SmsQueue from './SmsQueue';

class SmsQueueIndex extends Component {
  static propTypes = {
    requiredRoles: PropTypes.array,
    seasonJobId: PropTypes.string,
  };

  static defaultProps = {
    requiredRoles: ['ROLE_ADMIN'],
    resultset: [],
  };

  render() {
    return (
      <ACL authorized={this.props.requiredRoles}>
        <SmsQueue seasonType={'sms'} />
      </ACL>
    );
  }
}

export default withRouter(SmsQueueIndex);

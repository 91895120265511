import { CALL_API } from '../../middleware';
import { Schemas } from '../../schemas';

export const EMAILQUEUE_REQUEST = 'EMAILQUEUE_REQUEST';
export const EMAILQUEUE_SUCCESS = 'EMAILQUEUE_SUCCESS';
export const EMAILQUEUE_FAILURE = 'EMAILQUEUE_FAILURE';

function fetchEmailQueues(sortColumns, sortOrders) {
  return {
    [CALL_API]: {
      types: [EMAILQUEUE_REQUEST, EMAILQUEUE_SUCCESS, EMAILQUEUE_FAILURE],
      endpoint: Routing.generate(
        'emailqueue_index',
        { sortColumns: sortColumns, sortOrders: sortOrders },
        true,
      ),
      schema: Schemas.EMAILS,
    },
  };
}

// eslint-disable-next-line no-unused-vars
export function loadEmailQueues(
  sortColumns = [],
  sortOrders = [],
  requiredFields = [],
) {
  return (dispatch) => {
    return dispatch(fetchEmailQueues(sortColumns, sortOrders));
  };
}

export const CUSTOMER_INFINITESCROLL_REQUEST =
  'CUSTOMER_INFINITESCROLL_REQUEST';
export const CUSTOMER_INFINITESCROLL_SUCCESS =
  'CUSTOMER_INFINITESCROLL_SUCCESS';
export const CUSTOMER_INFINITESCROLL_FAILURE =
  'CUSTOMER_INFINITESCROLL_FAILURE';

function fetchCustomersPaged(
  jobId,
  filter,
  path,
  sortColumns,
  sortOrders,
  customerListCustomerId = null,
  offset = null,
  limit = null,
) {
  if (customerListCustomerId) {
    return {
      [CALL_API]: {
        types: [
          CUSTOMER_INFINITESCROLL_REQUEST,
          CUSTOMER_INFINITESCROLL_SUCCESS,
          CUSTOMER_INFINITESCROLL_FAILURE,
        ],
        endpoint: Routing.generate(
          'emailqueue_detail',
          {
            seasonJob: jobId,
            filter: filter,
            sortColumns: sortColumns,
            sortOrders: sortOrders,
            offset: offset,
            limit: limit,
            customerListCustomerId: customerListCustomerId,
          },
          true,
        ),
        schema: Schemas.CUSTOMERLIST,
        path,
      },
    };
  } else {
    return {
      [CALL_API]: {
        types: [
          CUSTOMER_INFINITESCROLL_REQUEST,
          CUSTOMER_INFINITESCROLL_SUCCESS,
          CUSTOMER_INFINITESCROLL_FAILURE,
        ],
        endpoint: Routing.generate(
          'emailqueue_customer',
          {
            seasonJob: jobId,
            filter: filter,
            sortColumns: sortColumns,
            sortOrders: sortOrders,
            offset: offset,
            limit: limit,
          },
          true,
        ),
        schema: Schemas.CUSTOMERLIST,
        path,
      },
    };
  }
}

export function loadEmailQueueCustomersPaged(
  jobId,
  filter = [],
  sortColumns = [],
  sortOrders = [],
  customerListCustomerId = null,
) {
  return (dispatch, getState) => {
    const emailJob = getState().entities.emailJob
      ? getState().entities.emailJob[jobId]
      : false;
    const path = emailJob
      ? 'customerList[' + emailJob.customerList + '].customerListCustomers'
      : '';

    let offset = 0;
    let limit = 50;

    return dispatch(
      fetchCustomersPaged(
        jobId,
        filter,
        path,
        sortColumns,
        sortOrders,
        customerListCustomerId,
        offset,
        limit,
      ),
    );
  };
}

// eslint-disable-next-line no-unused-vars
export function loadNext(
  jobId,
  filter = [],
  sortColumns = [],
  sortOrders = [],
  requiredFields = [],
  customerListCustomerId = null,
) {
  return (dispatch, getState) => {
    const emailJob = getState().entities.emailJob
      ? getState().entities.emailJob[jobId]
      : false;
    const pagination =
      emailJob && getState().pagination.customerList
        ? getState().pagination.customerList[emailJob.customerList]
        : false;

    if (!emailJob || !pagination) {
      return null;
    }

    const path =
      'customerList[' + emailJob.customerList + '].customerListCustomers';

    return dispatch(
      fetchCustomersPaged(
        jobId,
        filter,
        path,
        sortColumns,
        sortOrders,
        customerListCustomerId,
        pagination.current + pagination.limit,
        pagination.limit,
      ),
    );
  };
}

export const EMAILBODY_REQUEST = 'EMAILBODY_REQUEST';
export const EMAILBODY_SUCCESS = 'EMAILBODY_SUCCESS';
export const EMAILBODY_FAILURE = 'EMAILBODY_FAILURE';

function fetchEmail(emailQueue, sortColumns, sortOrders) {
  return {
    [CALL_API]: {
      types: [EMAILBODY_REQUEST, EMAILBODY_SUCCESS, EMAILBODY_FAILURE],
      endpoint: Routing.generate(
        'emailqueue_show',
        {
          emailQueue: emailQueue,
          sortColumns: sortColumns,
          sortOrders: sortOrders,
        },
        true,
      ),
      schema: Schemas.EMAILQUEUE,
    },
  };
}

// eslint-disable-next-line no-unused-vars
export function loadEmailQueueEmail(
  jobId,
  sortColumns = [],
  sortOrders = [],
  requiredFields = [],
) {
  return (dispatch) => {
    return dispatch(fetchEmail(jobId, sortColumns, sortOrders));
  };
}

export const SEND_EMAIL_REQUEST = 'SEND_EMAIL_REQUEST';
export const SEND_EMAIL_SUCCESS = 'SEND_EMAIL_SUCCESS';
export const SEND_EMAIL_FAILURE = 'SEND_EMAIL_FAILURE';

function sendEmail(emailInfo) {
  return {
    [CALL_API]: {
      types: [SEND_EMAIL_REQUEST, SEND_EMAIL_SUCCESS, SEND_EMAIL_FAILURE],
      endpoint: Routing.generate('emailqueue_sendemail', {}, true),
      method: 'POST',
      data: emailInfo,
      schema: Schemas.FORM,
    },
  };
}

export function dispatchEmail(emailInfo) {
  return (dispatch) => {
    return dispatch(sendEmail(emailInfo));
  };
}

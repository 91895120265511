import React, { JSX } from 'react';
import { Box, useTheme } from '@mui/material';
import Scene from '../../components/Scene';
import ErrorBoundary from '../../components/ErrorBoundary';

function Home(): JSX.Element {
  const theme = useTheme();

  return (
    <ErrorBoundary>
      <Scene>
        <Box
          sx={{
            m: `${theme.spacingConstants.desktopGutter}px`,
            height: 'calc(100vh - (2*2*3*8px) - (2*3*8px) - 16px)',
            boxShadow:
              '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
          }}
        >
          <iframe
            src="https://seeds-static.eeloy.com/aktuell/"
            frameBorder="0"
            width="100%"
            height="100%"
            title="aktuell"
          />
        </Box>
      </Scene>
    </ErrorBoundary>
  );
}

export default Home;

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Paper } from '@mui/material';
import BreadcrumbsNav from '../../components/BreadcrumbNavigation';
import SeasonCausalityForm from './components/Edit';
import { fetchCampaign } from '../Campaign/actions';
import { updateCausality, removeCausality, loadCausalities } from './actions';
import { resetEntity, outdateEntity } from '../../actions';
import { loadSeasons } from '../Season/actions';
import { isEmpty, sortBy } from 'lodash';
import ErrorBoundary from '../../components/ErrorBoundary';
import { withRouter } from '../../withRouter';
import { createBrowserHistory } from 'history';
import {
  makeCausalityBySeasonId,
  makeCauseSeasons,
} from '../Campaign/selectors_deprecated';
import Translate from '../../components/service/Translate';
import {
  ToolbarRoot,
  Spacer,
  Title,
} from '../../components/StyledElements/StyledElements';
import { compose } from 'redux';

const browserHistory = createBrowserHistory();

class SeasonCausality extends Component {
  static propTypes = {
    initForm: PropTypes.func,
    postCausalityForm: PropTypes.func,
    campaignId: PropTypes.number,
    seasonId: PropTypes.number,
    fetchCampaign: PropTypes.func,
    seasonList: PropTypes.object,
    campaign: PropTypes.object,
    token: PropTypes.string,
  };

  static defaultProps = {};

  state = {};

  componentDidMount = () => {
    const { campaign, fetchCampaign, campaignId } = this.props;

    if (!campaign) {
      fetchCampaign(campaignId, []);
    }
    this.props.loadCausalities();
    this.props.loadSeasons();
  };

  handleSubmit = (form) => {
    const {
      updateCausality,
      outdateEntity,
      resetEntity,
      loadCausalities,
      seasonCausality,
    } = this.props;
    let causalityId = seasonCausality ? seasonCausality.id : null;

    return Promise.all([updateCausality(form, causalityId)]).then((values) => {
      if (!(values && values[0] && values[0].error)) {
        resetEntity('seasonCausality', causalityId);
        outdateEntity('seasonCausality');
        outdateEntity('season');
        loadCausalities();
        this.props.navigate(
          Routing.generate('campaign_show', { id: this.props.campaignId }),
        );
      }
    });
  };

  handleDelete = () => {
    const {
      removeCausality,
      outdateEntity,
      resetEntity,
      loadCausalities,
      seasonCausality,
    } = this.props;

    Promise.all([
      removeCausality(seasonCausality.id, seasonCausality.deleteToken),
    ]).then((values) => {
      if (!(values && values[0] && values[0].error)) {
        outdateEntity('seasonCausality');
        outdateEntity('season');
        resetEntity('seasonCausality', seasonCausality.id);
        resetEntity('season', seasonCausality.cause);
        resetEntity('season', seasonCausality.season);
        loadCausalities();
        this.props.navigate(
          Routing.generate('campaign_show', { id: this.props.campaignId }),
        );
      }
    });
  };

  handleCancel = () => {
    browserHistory.back();
  };

  handleFormMount = (form) => {
    this.form = form;
  };

  submit = () => {
    this.form.submit();
  };

  renderHeader = () => {
    const { campaign, campaignId, seasonCausality } = this.props;
    if (isEmpty(campaign)) {
      return null;
    }

    return (
      <div>
        <ToolbarRoot>
          <Title>
            <BreadcrumbsNav
              items={[
                { link: '/#/campaign', text: 'Kampagnen' },
                {
                  link: '/#/campaign/' + campaignId + '/show',
                  text: campaign ? campaign[campaignId].title : '',
                },
                seasonCausality ? 'Abhängigkeit' : 'Neue Abhängigkeit',
              ]}
            />
          </Title>
          <Spacer />
        </ToolbarRoot>
      </div>
    );
  };

  render() {
    const { seasons, currentSeason, seasonCausality, loading, success } =
      this.props;

    if (!isEmpty(loading) && !success.includes('SEASON_CAUSALITY')) {
      return <Translate>Data loading...</Translate>;
    } else {
      return (
        <>
          {this.renderHeader()}
          <Paper sx={{ m: 0 }}>
            <ErrorBoundary>
              <SeasonCausalityForm
                onMount={this.handleFormMount}
                onCancel={this.handleCancel}
                causality={seasonCausality}
                currentSeason={currentSeason}
                seasons={seasons}
                onSubmit={this.handleSubmit}
                onDelete={this.handleDelete}
              />
            </ErrorBoundary>
          </Paper>
        </>
      );
    }
  }
}

function mapStateToProps(state, props) {
  const getCauseSeasons = makeCauseSeasons();
  const getCausalityBySeasonId = makeCausalityBySeasonId();

  const {
    entities: { campaign, season },
    loading: loading,
    success: successActions,
  } = state;

  return {
    campaignId: parseInt(props.campaignId),
    seasonId: parseInt(props.seasonId),
    campaign: campaign,
    seasonCausality: getCausalityBySeasonId(state, props),
    seasons: sortBy(getCauseSeasons(state, props), ['configKey']),
    currentSeason: props.seasonId && season ? season[props.seasonId] : {},
    loading: loading,
    success: successActions,
  };
}

const enhance = compose(
  withRouter,
  connect(mapStateToProps, {
    fetchCampaign,
    updateCausality,
    removeCausality,
    resetEntity,
    outdateEntity,
    loadSeasons,
    loadCausalities,
  }),
);

export default enhance(SeasonCausality);

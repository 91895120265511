import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import RoleAwareComponent from './RoleAwareComponent';
import CommunicationCall from '@mui/icons-material/Call';
import ContentClear from '@mui/icons-material/Clear';
import ActionDone from '@mui/icons-material/Done';
import CommunicationMessage from '@mui/icons-material/Message';
import SocialNotificationsActive from '@mui/icons-material/NotificationsActive';
import { Badge, Avatar } from '@mui/material';
import { red } from '@mui/material/colors';
const red600 = red['600'];
import { isEqual } from 'lodash';
import { withRouter } from '../withRouter';
import { compose } from 'redux';

class CustomerListCustomerAvatar extends RoleAwareComponent {
  static propTypes = {
    customerListCustomerId: PropTypes.number.isRequired,
    customerListCustomer: PropTypes.object,
    customer: PropTypes.object,
    calls: PropTypes.object,
    completedCallId: PropTypes.number,
    complaintId: PropTypes.string,
    complaints: PropTypes.object,
    size: PropTypes.number,
    noWrap: PropTypes.bool,
    docked: PropTypes.bool,
  };

  static defaultProps = {
    docked: false,
    size: 30,
    noWrap: true,
    complaintId: null,
    completedCallId: null,
  };

  // eslint-disable-next-line no-unused-vars
  shouldComponentUpdate = (nextProps, nextState) => {
    return (
      !isEqual(
        this.props.customerListCustomerId,
        nextProps.customerListCustomerId,
      ) ||
      !isEqual(
        this.props.customerListCustomer,
        nextProps.customerListCustomer,
      ) ||
      !isEqual(this.props.complaints, nextProps.complaints) ||
      !isEqual(nextProps.calls, this.props.calls)
    );
  };

  wrapCallStatus = () => {
    const { customerListCustomer, attempts } = this.props;
    let avatar = this.renderAvatar({
      p: 0,
      position: 'absolute',
      top: '8px',
      left: '26px',
    });

    switch (customerListCustomer.status) {
      case 'ongoing':
        avatar = (
          <Badge
            badgeContent={
              <CommunicationCall
                sx={{
                  top: -8,
                  right: -8,
                  width: 16,
                  height: 16,
                  borderRadius: '50%',
                  backgroundColor:
                    customerListCustomer &&
                    customerListCustomer.calls &&
                    customerListCustomer.calls.length >= attempts
                      ? 'errorColor'
                      : 'alternateTextColor',
                  color:
                    customerListCustomer &&
                    customerListCustomer.calls &&
                    customerListCustomer.calls.length >= attempts
                      ? 'alternateTextColor'
                      : 'primary',
                }}
              />
            }
            sx={{
              p: 0,
              position: 'absolute',
              top: '8px',
              left: '26px',
            }}
          >
            {this.renderAvatar()}
          </Badge>
        );
        break;
      case 'completed':
        avatar = (
          <Badge
            badgeContent={
              <ActionDone
                sx={{
                  top: -8,
                  right: -8,
                  width: 16,
                  height: 16,
                  borderRadius: '50%',
                  backgroundColor:
                    customerListCustomer &&
                    customerListCustomer.calls &&
                    customerListCustomer.calls.length >= attempts
                      ? 'errorColor'
                      : 'alternateTextColor',
                  color:
                    customerListCustomer &&
                    customerListCustomer.calls &&
                    customerListCustomer.calls.length >= attempts
                      ? 'alternateTextColor'
                      : 'primary',
                }}
              />
            }
            sx={{
              p: 0,
              position: 'absolute',
              top: '8px',
              left: '26px',
            }}
          >
            {this.renderAvatar()}
          </Badge>
        );
        break;
      case 'unsolicited':
        avatar = (
          <Badge
            badgeContent={
              <ContentClear
                sx={{
                  top: -8,
                  right: -8,
                  width: 16,
                  height: 16,
                  borderRadius: '50%',
                  backgroundColor:
                    customerListCustomer &&
                    customerListCustomer.calls &&
                    customerListCustomer.calls.length >= attempts
                      ? 'errorColor'
                      : 'alternateTextColor',
                  color:
                    customerListCustomer &&
                    customerListCustomer.calls &&
                    customerListCustomer.calls.length >= attempts
                      ? 'alternateTextColor'
                      : 'primary',
                }}
              />
            }
            sx={{
              p: 0,
              position: 'absolute',
              top: '8px',
              left: '26px',
            }}
          >
            {this.renderAvatar()}
          </Badge>
        );
        break;
      default:
        if (customerListCustomer.calls.length) {
          avatar = (
            <Badge
              badgeContent={customerListCustomer.calls.length}
              sx={{
                p: 0,
                position: 'absolute',
                top: 1,
                left: '26px',
              }}
              color={
                customerListCustomer.calls.length > 3 ? 'error' : 'default'
              }
            >
              {this.renderAvatar()}
            </Badge>
          );
        }
        break;
    }

    return avatar;
  };

  wrapCompletedStatus = () => {
    const {
      calls,
      complaints,
      completedCallId,
      complaintId,
      customerListCustomer,
      attempts,
    } = this.props;
    let avatar = this.renderAvatar({
      p: 0,
      position: 'absolute',
      top: '8px',
      left: '26px',
    });

    if (calls && (completedCallId || customerListCustomer.completedCall)) {
      let call =
        calls[
          completedCallId ? completedCallId : customerListCustomer.completedCall
        ];
      let complaint = complaintId
        ? complaints[complaintId]
        : call.complaint
          ? complaints[call.complaint]
          : null;

      if (complaint) {
        let badge = (
          <SocialNotificationsActive
            color={red600}
            sx={{
              top: -8,
              right: -8,
              width: 16,
              height: 16,
              borderRadius: '50%',
              backgroundColor:
                customerListCustomer &&
                customerListCustomer.calls &&
                customerListCustomer.calls.length >= attempts
                  ? 'errorColor'
                  : 'alternateTextColor',
              color:
                customerListCustomer &&
                customerListCustomer.calls &&
                customerListCustomer.calls.length >= attempts
                  ? 'alternateTextColor'
                  : 'primary',
            }}
          />
        );
        switch (complaint.status) {
          case 'completed':
            badge = <ActionDone />;
            break;
          case 'ongoing':
            badge = <SocialNotificationsActive />;
            break;
        }

        avatar = (
          <Badge
            badgeContent={badge}
            sx={{
              p: 0,
              position: 'absolute',
              top: '8px',
              left: '26px',
            }}
          >
            {this.renderAvatar()}
          </Badge>
        );
      } else if (call.note) {
        avatar = (
          <Badge
            badgeContent={
              <CommunicationMessage
                sx={{
                  top: -8,
                  right: -8,
                  width: 16,
                  height: 16,
                  borderRadius: '50%',
                  backgroundColor:
                    customerListCustomer &&
                    customerListCustomer.calls &&
                    customerListCustomer.calls.length >= attempts
                      ? 'errorColor'
                      : 'alternateTextColor',
                  color:
                    customerListCustomer &&
                    customerListCustomer.calls &&
                    customerListCustomer.calls.length >= attempts
                      ? 'alternateTextColor'
                      : 'primary',
                }}
              />
            }
            sx={{
              p: 0,
              position: 'absolute',
              top: '8px',
              left: '26px',
            }}
          >
            {this.renderAvatar()}
          </Badge>
        );
      }
    }

    return avatar;
  };

  getAbbr = () => {
    let abbr = null;
    const { customer } = this.props;

    if (!customer) {
      return '';
    }

    switch (customer.gender) {
      case 'company':
        abbr = customer.completeName.charAt(0);
        break;
      case 'female':
      case 'male':
      default:
        abbr =
          (customer.firstname ? customer.firstname.charAt(0) : '') +
          '' +
          (customer.name ? customer.name.charAt(0) : '');
        break;
    }

    return abbr;
  };

  renderAvatar = (style = {}) => {
    const { size } = this.props;

    return (
      <Avatar sx={style} size={size}>
        {this.getAbbr()}
      </Avatar>
    );
  };

  render() {
    const { noWrap } = this.props;
    let avatar = this.renderAvatar({
      p: 0,
      position: 'absolute',
      top: '8px',
      left: '26px',
    });

    if (!noWrap) {
      if (this.hasRequiredRole('ROLE_CALL')) {
        avatar = this.wrapCallStatus();
      } else {
        avatar = this.wrapCompletedStatus();
      }
    }

    return avatar;
  }
}

function mapStateToProps(state, props) {
  const customerListCustomerId = props.customerListCustomerId;
  const {
    entities: { complaint, customerListCustomer, customer, call, calling },
  } = state;

  let currentComplaint = props.complaintId
    ? complaint[props.complaintId]
    : call && call.complaint
      ? complaint[call.complaint]
      : null;
  let callingId = props?.match?.params?.callingId
    ? parseInt(props?.match?.params?.callingId)
    : currentComplaint
      ? currentComplaint.calling
      : null;
  let attempts = calling && callingId ? calling[callingId].attempts : 0;

  return {
    customerListCustomerId: customerListCustomerId,
    customerListCustomer: customerListCustomerId
      ? customerListCustomer[customerListCustomerId]
      : null,
    customer: customerListCustomerId
      ? customer[customerListCustomer[customerListCustomerId].customer]
      : null,
    calls: call,
    complaints: complaint,
    attempts: attempts,
  };
}

const enhance = compose(withRouter, connect(mapStateToProps, {}));

export default enhance(CustomerListCustomerAvatar);

// eslint-disable-next-line no-unused-vars
import React, { Component } from 'react';
import { intersection } from 'lodash';

class RoleAwareComponent extends Component {
  isPermitted = (requiredRoles, userRoles) => {
    return (
      requiredRoles.length &&
      intersection(requiredRoles, userRoles).length === requiredRoles.length
    );
  };

  hasRequiredRole = (requiredRoles = []) => {
    requiredRoles =
      typeof requiredRoles === 'string' ? [requiredRoles] : requiredRoles;

    const user = JSON.parse(localStorage.getItem('user'));

    return this.isPermitted(requiredRoles, user.roles);
  };
}

export default RoleAwareComponent;

import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';
import axios from 'axios';
import { normalize, schema } from 'normalizr';
import { Schemas } from '../../schemas';
import type { RootState } from '../../reducers';
import { MenuItem } from './MenuItemType';

const menuAdapter = createEntityAdapter<MenuItem>({});

export const loadMenu = createAsyncThunk('menu/loadMenu', async () => {
  const response = await axios.get(Routing.generate('menu_v3', null, true), {
    withCredentials: true,
  });
  const normalized = normalize<
    schema.Array<MenuItem>,
    {
      menuitem: Record<string, MenuItem>;
    },
    string[]
  >(response.data, Schemas.MENU);
  return { entities: normalized.entities };
});

const menuSlice = createSlice({
  name: 'menu',
  initialState: menuAdapter.getInitialState({
    loading: false,
  }),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadMenu.fulfilled, (state, action) => {
      const { menuitem } = action.payload.entities;

      if (menuitem) {
        menuAdapter.upsertMany(state, menuitem);
      }
    });
  },
});

export const {
  selectAll: selectAllMenuItems,
  selectById: selectMenuItemById,
  selectIds,
} = menuAdapter.getSelectors<RootState>((state) => state.menu);

export default menuSlice.reducer;

import selectors from '../../selectors';
import { filter } from 'lodash';
import { makeStaffById } from '../Staff/selectors';

const getEntityName = (state, props) => props.logEntityName;
const getEntityId = (state, props) => props.logEntityId;
// eslint-disable-next-line no-unused-vars
const getLogs = (state, props) => state.entities.log;
const getStaff = makeStaffById();

export const makeLogByStaff = () => {
  return selectors([getStaff, getLogs], (staff, logs) => {
    return logs && staff ? filter(logs, { staff: staff.id }) : [];
  });
};

export const makeLogByEntity = () => {
  return selectors(
    [getEntityName, getEntityId, getLogs],
    (entityName, entityId, logs) => {
      return logs && entityName && entityId
        ? filter(logs, { entityName: entityName, entityId: entityId })
        : [];
    },
  );
};

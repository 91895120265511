import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardHeader,
  CardContent,
  IconButton,
  Collapse,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { connect } from 'react-redux';
import { isEqual, size, map } from 'lodash';
import Translate from '../../../components/service/Translate';
import { loadAffiliateChildren } from '../actions';
import { makeAffiliatesByParent } from '../selectors';
import { loadAffiliateSettings } from '../../AffiliateSetting/actions';
import Show from './Show';

class Children extends Component {
  static propTypes = {
    affiliateId: PropTypes.number.isRequired,
    loadAffiliateChildren: PropTypes.func.isRequired,
    children: PropTypes.object,
    loadAffiliateSetting: PropTypes.func,
  };

  state = {
    sortColumns: ['id'],
    sortOrders: ['asc'],
    expanded: false,
  };

  componentDidMount = () => {
    if (this.props.affiliateId !== null) {
      this.props.loadAffiliateChildren(this.props.affiliateId);
    }
  };

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState) {
    if (!isEqual(this.props.affiliateId, prevProps.affiliateId)) {
      this.props.loadAffiliateChildren(this.props.affiliateId);
      this.props.loadAffiliateSetting(
        this.state.sortColumns,
        this.state.sortOrders,
        this.props.affiliateId,
      );
    }
  }

  shouldComponentUpdate = (nextProps, nextState) => {
    return (
      !isEqual(nextProps.affiliateId, this.props.affiliateId) ||
      !isEqual(nextProps.children, this.props.children) ||
      !isEqual(nextState.expanded, this.state.expanded)
    );
  };

  handleExpandClick = (index) => {
    const { expanded } = this.state;
    if (expanded === index) {
      this.setState({ expanded: false });
    } else {
      this.setState({ expanded: index });
    }
  };

  renderChild = (child, index) => {
    return (
      <Card key={`affiliate-child-${child.id}`}>
        <CardHeader
          title={`Standort ${child.name}`}
          subheader={<Translate>{child.tag}</Translate>}
          action={
            <IconButton
              onClick={() => this.handleExpandClick(index)}
              aria-expanded={this.state.expanded}
              aria-label="Show more"
              sx={this.state.expanded ? { transform: 'rotate(180deg)' } : {}}
              size="large"
            >
              <ExpandMoreIcon />
            </IconButton>
          }
        />
        <Collapse
          in={index === this.state.expanded}
          timeout="auto"
          unmountOnExit
        >
          <CardContent>
            <Show affiliateId={child.id} />
          </CardContent>
        </Collapse>
      </Card>
    );
  };

  render() {
    const { children } = this.props;

    if (!children && !size(children)) {
      return null;
    }

    return <div>{map(children, this.renderChild)}</div>;
  }
}

const makeMapStateToProps = () => {
  const getAffiliatesByParent = makeAffiliatesByParent();

  return (state, props) => {
    return {
      children: getAffiliatesByParent(state, props),
    };
  };
};

export default connect(makeMapStateToProps, {
  loadAffiliateChildren,
  loadAffiliateSetting: loadAffiliateSettings,
})(Children);

import { CALL_API } from '../../middleware';
import { Schemas } from '../../schemas';

export const LOCATION_SETTINGS_REQUEST = 'LOCATION_SETTINGS_REQUEST';
export const LOCATION_SETTINGS_SUCCESS = 'LOCATION_SETTINGS_SUCCESS';
export const LOCATION_SETTINGS_FAILURE = 'LOCATION_SETTINGS_FAILURE';

function fetchLocationSettings(
  sortColumns,
  sortOrders,
  offset = null,
  limit = null,
  join,
  filter,
  locationSettingId,
) {
  return {
    [CALL_API]: {
      types: [
        LOCATION_SETTINGS_REQUEST,
        LOCATION_SETTINGS_SUCCESS,
        LOCATION_SETTINGS_FAILURE,
      ],
      endpoint: Routing.generate(
        'location_setting_index',
        {
          sortColumns: sortColumns,
          sortOrders: sortOrders,
          offset: offset,
          limit: limit,
          join: join,
          filter: filter,
          locationSettingId: locationSettingId,
        },
        true,
      ),
      schema: Schemas.LOCATIONSETTINGS,
    },
  };
}

export function loadLocationSettings(
  sortColumns = [],
  sortOrders = [],
  join = [],
  filter = [],
  locationSettingId = null,
) {
  return (dispatch) => {
    let offset = null;
    let limit = null;

    return dispatch(
      fetchLocationSettings(
        sortColumns,
        sortOrders,
        offset,
        limit,
        join,
        filter,
        locationSettingId,
      ),
    );
  };
}

export const LOCATIONSETTING_PAGED_REQUEST = 'LOCATIONSETTING_PAGED_REQUEST';
export const LOCATIONSETTING_PAGED_SUCCESS = 'LOCATIONSETTING_PAGED_SUCCESS';
export const LOCATIONSETTING_PAGED_FAILURE = 'LOCATIONSETTING_PAGED_FAILURE';

function fetchLocationSettingsPaged(
  sortColumns,
  sortOrders,
  offset = null,
  limit = null,
  join,
  filter,
) {
  return {
    [CALL_API]: {
      types: [
        LOCATIONSETTING_PAGED_REQUEST,
        LOCATIONSETTING_PAGED_SUCCESS,
        LOCATIONSETTING_PAGED_FAILURE,
      ],
      endpoint: Routing.generate(
        'location_setting_index',
        {
          sortColumns: sortColumns,
          sortOrders: sortOrders,
          offset: offset,
          limit: limit,
          join: join,
          filter: filter,
        },
        true,
      ),
      schema: Schemas.LOCATIONSETTINGS,
    },
  };
}

export function loadNext(
  sortColumns = [],
  sortOrders = [],
  offset = null,
  limit = null,
  join = [],
  filter = [],
) {
  return (dispatch, getState) => {
    const locationsetting = getState().entities.locationsetting;
    const pagination =
      locationsetting && getState().pagination.locationsetting
        ? getState().pagination.locationsetting
        : false;

    if (!locationsetting || !pagination) {
      return null;
    }

    return dispatch(
      fetchLocationSettingsPaged(
        sortColumns,
        sortOrders,
        offset,
        limit,
        join,
        filter,
      ),
    );
  };
}

export const SETTING_TYPE_REQUEST = 'SETTING_TYPE_REQUEST';
export const SETTING_TYPE_SUCCESS = 'SETTING_TYPE_SUCCESS';
export const SETTING_TYPE_FAILURE = 'SETTING_TYPE_FAILURE';

function fetchSettings() {
  return {
    [CALL_API]: {
      types: [SETTING_TYPE_REQUEST, SETTING_TYPE_SUCCESS, SETTING_TYPE_FAILURE],
      endpoint: Routing.generate('setting_index', {}, true),
      schema: Schemas.SETTINGS,
    },
  };
}

export function loadSettings() {
  return (dispatch) => {
    return dispatch(fetchSettings());
  };
}

export const LOCATIONSETTING_UPDATE_REQUEST = 'LOCATIONSETTING_UPDATE_REQUEST';
export const LOCATIONSETTING_UPDATE_SUCCESS = 'LOCATIONSETTING_UPDATE_SUCCESS';
export const LOCATIONSETTING_UPDATE_FAILURE = 'LOCATIONSETTING_UPDATE_FAILURE';

function writeLocationSetting(form, settingId) {
  return {
    [CALL_API]: {
      types: [
        LOCATIONSETTING_UPDATE_REQUEST,
        LOCATIONSETTING_UPDATE_SUCCESS,
        LOCATIONSETTING_UPDATE_FAILURE,
      ],
      endpoint: Routing.generate(
        'location_setting_update',
        { id: settingId },
        true,
      ),
      method: 'POST',
      data: form,
      schema: Schemas.LOCATIONSETTING,
    },
  };
}

export function updateLocationSetting(form, settingId = null) {
  return (dispatch) => {
    return dispatch(writeLocationSetting(form, settingId));
  };
}

export const LOCATIONSETTING_REMOVE_REQUEST = 'LOCATIONSETTING_REMOVE_REQUEST';
export const LOCATIONSETTING_REMOVE_SUCCESS = 'LOCATIONSETTING_REMOVE_SUCCESS';
export const LOCATIONSETTING_REMOVE_FAILURE = 'LOCATIONSETTING_REMOVE_FAILURE';

function deleteLocationSetting(id, deleteToken) {
  return {
    [CALL_API]: {
      types: [
        LOCATIONSETTING_REMOVE_REQUEST,
        LOCATIONSETTING_REMOVE_SUCCESS,
        LOCATIONSETTING_REMOVE_FAILURE,
      ],
      endpoint: Routing.generate(
        'location_setting_delete',
        { id: id, deleteToken: deleteToken },
        true,
      ),
      method: 'DELETE',
    },
  };
}

export function removeLocationSetting(settingId, deleteToken) {
  return (dispatch) => {
    return dispatch(deleteLocationSetting(settingId, deleteToken));
  };
}

export const LOCATIONSETTING_FORM_REQUEST = 'LOCATIONSETTING_FORM_REQUEST';
export const LOCATIONSETTING_FORM_SUCCESS = 'LOCATIONSETTING_FORM_SUCCESS';
export const LOCATIONSETTING_FORM_FAILURE = 'LOCATIONSETTING_FORM_FAILURE';

function fetchForm() {
  return {
    [CALL_API]: {
      types: [
        LOCATIONSETTING_FORM_REQUEST,
        LOCATIONSETTING_FORM_SUCCESS,
        LOCATIONSETTING_FORM_FAILURE,
      ],
      endpoint: Routing.generate('location_setting_token', {}, true),
      schema: Schemas.FORM,
    },
  };
}

export function initForm() {
  return (dispatch, getState) => {
    const updateToken = getState().forms.updateToken;

    if (updateToken) {
      return null;
    }

    return dispatch(fetchForm());
  };
}

import selectors from '../../selectors';
import { pickBy, isEmpty, isNumber } from 'lodash';

// eslint-disable-next-line no-unused-vars
const getMailingModules = (state, props) => state.entities.mailingModule;
const getSeasonFilter = (state, props) => parseInt(props.seasonId);

export const makeTextModules = () => {
  return selectors([getMailingModules], (modules) => {
    return !isEmpty(modules)
      ? pickBy(modules, { mode: 'text', template: null })
      : {};
  });
};

const getTextModules = makeTextModules();
export const makeTextModulesBySeasonId = () => {
  return selectors([getSeasonFilter, getTextModules], (seasonId, modules) => {
    return !isEmpty(modules)
      ? pickBy(modules, (value) => {
          return !value.season || value.season === seasonId;
        })
      : {};
  });
};

const getTextModulesBySeasonId = makeTextModulesBySeasonId();
export const makeTextModulesBySeasonIdForMergeTags = () => {
  return selectors([getTextModulesBySeasonId], (unfilteredModules) => {
    let usedKeys = [];
    let filteredModules = {};

    Object.values(unfilteredModules).forEach((value) => {
      if (usedKeys.includes(value.id)) {
        return;
      }
      let pickedModules = pickBy(unfilteredModules, {
        position: value.position,
      });
      if (Object.keys(pickedModules).length > 1) {
        let moduleWithoutAffiliate = pickBy(pickedModules, { affiliate: null });
        let moduleWithAffiliate = pickBy(pickedModules, (module) => {
          return isNumber(module.affiliate);
        });

        const checkObjects = (object) => {
          Object.values(object).forEach((value) => {
            if (usedKeys.includes(value.id)) {
              return;
            }
            let sameValues = pickBy(object, { tag: value.tag });
            if (Object.keys(sameValues).length > 1) {
              let module = sameValues[Object.keys(sameValues)[0]];
              filteredModules[module.id] = module;
              Object.values(sameValues).forEach((modul) => {
                usedKeys.push(modul.id);
              });
            } else {
              Object.values(sameValues).forEach((value) => {
                filteredModules[value.id] = value;
                usedKeys.push(value.id);
              });
            }
          });
        };

        checkObjects(moduleWithoutAffiliate);
        checkObjects(moduleWithAffiliate);
      } else if (Object.keys(pickedModules).length === 1) {
        let module = pickedModules[Object.keys(pickedModules)[0]];
        filteredModules[module.id] = module;
        usedKeys.push(module.id);
      }
    });

    return filteredModules;
  });
};

import React, { Component } from 'react';

class Scene extends Component {
  static propTypes = {};

  static defaultProps = {};

  render() {
    let content = React.createElement('div', {}, this.props.children);

    return <>{content}</>;
  }
}

export default Scene;

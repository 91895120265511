import React, { Component } from 'react';
import { Paper } from '@mui/material';
import { connect, ConnectedProps } from 'react-redux';
import { isEqual } from 'lodash';
import { GridRowId, GridSortDirection } from '@mui/x-data-grid';
import Translate from '../../components/service/Translate';
import {
  deleteEmailAccount,
  fetchAllEmailAccounts,
  fetchToken,
  writeEmailAccount,
} from './EmailAccountSlice';
import EmailAccountList from './components/List';
import Edit from './components/Edit';
import DeleteDialog from '../../components/DeleteDialog';
import ErrorBoundary from '../../components/ErrorBoundary';
import { withRouter, WithRouterProps } from '../../withRouter';
import { RootState } from '../../reducers';
import { FORM_MODE, REQUEST_STATUS } from '../../utils/Constants';
import { GridParams } from '../../components/DataGrid/DataGridTypes';

type EmailAccountProps = {
  mode: string;
};

type ParamsFromRouter = { mode: string };
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = EmailAccountProps &
  PropsFromRedux &
  WithRouterProps<ParamsFromRouter>;

type EmailAccountState = {
  current: GridRowId | null;
  adding: boolean;
  editing: boolean;
  deleting: boolean;
  valid: boolean;
  dataLoading: boolean;
  mode: string;
  sortColumns: string[];
  sortOrders: GridSortDirection[];
};

class EmailAccount extends Component<Props, EmailAccountState> {
  static defaultProps = {
    mode: 'show',
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      current: null,
      adding: false,
      editing: false,
      deleting: false,
      valid: false,
      mode: 'show',
      sortColumns: ['host', 'id'],
      sortOrders: ['asc', 'asc'],
      dataLoading: true,
    };
  }

  componentDidMount() {
    const { fetchToken, fetchAllEmailAccounts, mode } = this.props;

    fetchToken();
    this.setState({ dataLoading: true });
    const gridParams: GridParams = {
      paginationModel: {
        page: 0,
        pageSize: 100,
      },
    };
    Promise.all([fetchAllEmailAccounts({ gridParams })]).then(
      () => {
        this.setState({ dataLoading: false });
      },
      () => {
        this.setState({ dataLoading: false });
      },
    );

    switch (mode) {
      case 'new':
        this.setState({
          adding: true,
          editing: false,
          valid: false,
          mode: FORM_MODE.ADD,
        });
        break;
      case 'edit':
        this.setState({
          adding: false,
          editing: true,
          valid: false,
          mode: FORM_MODE.EDIT,
        });
        break;
      default:
        this.setState({
          adding: false,
          editing: false,
          valid: false,
          mode: FORM_MODE.SHOW,
        });
        break;
    }
  }

  shouldComponentUpdate(nextProps: Props, nextState: EmailAccountState) {
    const { mode, accounts, defaultIndex } = this.props;

    return (
      !isEqual(nextProps.mode, mode) ||
      !isEqual(nextProps.accounts, accounts) ||
      !isEqual(nextState, this.state) ||
      !isEqual(nextProps.defaultIndex, defaultIndex)
    );
  }

  componentDidUpdate(prevProps: Props) {
    const { defaultIndex, mode } = this.props;

    if (defaultIndex && prevProps.defaultIndex !== defaultIndex) {
      this.setState({ current: defaultIndex });
    }

    if (prevProps.mode !== mode) {
      switch (mode) {
        case 'new':
          this.setState({
            adding: true,
            editing: false,
            valid: false,
            mode: FORM_MODE.ADD,
          });
          break;
        case 'edit':
          this.setState({
            adding: false,
            editing: true,
            valid: false,
            mode: FORM_MODE.EDIT,
          });
          break;
        default:
          this.setState({
            adding: false,
            editing: false,
            valid: false,
            mode: FORM_MODE.SHOW,
          });
          break;
      }
    }
  }

  handleSubmit = async (form: FormData) => {
    const promises = [];
    const {
      writeEmailAccount,
      fetchAllEmailAccounts,
      sortModel,
      paginationModel,
    } = this.props;
    const { current, editing } = this.state;

    this.setState({ valid: false });
    promises.push(
      writeEmailAccount({
        data: form,
        id: editing ? current : null,
      }),
    );

    return Promise.all(promises).then((values) => {
      if (values[0]?.meta?.requestStatus === REQUEST_STATUS.FULFILLED) {
        fetchAllEmailAccounts({ gridParams: { sortModel, paginationModel } });

        this.setState({ editing: false, mode: 'show' });
      }
    });
  };

  handleDelete = (accountId: number) => {
    const {
      accounts,
      deleteEmailAccount,
      fetchAllEmailAccounts,
      navigate,
      sortModel,
      paginationModel,
    } = this.props;

    this.setState({ deleting: false, mode: 'show', current: null });

    Promise.all([
      deleteEmailAccount({
        current: accountId,
        deleteToken: accounts[accountId]!.deleteToken,
      }),
    ]).then(
      () => {
        fetchAllEmailAccounts({ gridParams: { sortModel, paginationModel } });
        navigate(Routing.generate('emailaccount_index'));
      },
      () => {
        fetchAllEmailAccounts({ gridParams: { sortModel, paginationModel } });
      },
    );
  };

  onTouchEdit = (current: GridRowId) => {
    this.setState({
      adding: false,
      editing: true,
      valid: false,
      mode: 'editing',
      current,
    });
  };

  onTouchAdd = () => {
    this.setState({
      adding: true,
      editing: false,
      valid: false,
      mode: 'adding',
    });
  };

  onTouchDelete = (current: GridRowId) => {
    this.setState({ current, deleting: true });
  };

  onTouchCancel = () => {
    this.setState({
      adding: false,
      editing: false,
      valid: false,
      mode: 'show',
      deleting: false,
    });
  };

  renderDeleteDialog = () => {
    const { accounts } = this.props;
    const { deleting, current } = this.state;

    if (deleting && current) {
      return (
        <DeleteDialog
          current={accounts[+current]}
          onCancel={this.onTouchCancel}
          onSubmit={this.handleDelete}
        />
      );
    }

    return null;
  };

  renderContent() {
    const { mode, current, dataLoading } = this.state;

    let child = null;

    if (dataLoading) {
      child = <Translate>Data loading...</Translate>;
    } else {
      switch (mode) {
        case 'adding':
          child = (
            <ErrorBoundary>
              <Edit
                onSubmit={this.handleSubmit}
                accountId={null}
                onCancel={this.onTouchCancel}
              />
            </ErrorBoundary>
          );
          break;
        case 'editing':
          child = (
            <ErrorBoundary>
              <Edit
                onSubmit={this.handleSubmit}
                accountId={current}
                onCancel={this.onTouchCancel}
              />
            </ErrorBoundary>
          );
          break;
        case 'show':
        default:
          return (
            <ErrorBoundary>
              <EmailAccountList
                onAdd={this.onTouchAdd}
                onEdit={this.onTouchEdit}
                onDelete={this.onTouchDelete}
              />
            </ErrorBoundary>
          );
      }
    }

    return <Paper>{child}</Paper>;
  }

  render() {
    return (
      <>
        {this.renderContent()}
        {this.renderDeleteDialog()}
      </>
    );
  }
}

function mapStateToProps(state: RootState) {
  const {
    emailAccount: {
      resultset,
      pagination,
      entities,
      paginationModel,
      sortModel,
    },
  } = state;

  const accounts = entities;

  return {
    accounts,
    defaultIndex: accounts && resultset ? resultset[0] : null,
    pagination,
    sortModel,
    paginationModel,
  };
}

const connector = connect(mapStateToProps, {
  fetchToken,
  deleteEmailAccount,
  fetchAllEmailAccounts,
  writeEmailAccount,
});

export default withRouter(connector(EmailAccount));

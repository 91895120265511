import { CALL_API } from '../../middleware';
import { Schemas } from '../../schemas';
import { pick, size, sample } from 'lodash';

export const AFFILIATES_REQUEST = 'AFFILIATES_REQUEST';
export const AFFILIATES_SUCCESS = 'AFFILIATES_SUCCESS';
export const AFFILIATES_FAILURE = 'AFFILIATES_FAILURE';

function fetchAffiliates(sortColumns, sortOrders, join) {
  return {
    [CALL_API]: {
      types: [AFFILIATES_REQUEST, AFFILIATES_SUCCESS, AFFILIATES_FAILURE],
      endpoint: Routing.generate(
        'affiliate_index',
        { sortColumns: sortColumns, sortOrders: sortOrders, join: join },
        true,
      ),
      schema: Schemas.AFFILIATES,
    },
  };
}

export function loadAffiliates(
  sortColumns = [],
  sortOrders = [],
  join = [],
  requiredFields = [],
) {
  return (dispatch, getState) => {
    const affiliate = getState().entities.affiliate;
    const resultset = getState().resultsets.affiliate;
    const outdated = getState().outdated.affiliate;

    if (
      !outdated &&
      resultset &&
      resultset.length &&
      affiliate &&
      requiredFields.every((key) => sample(affiliate).hasOwnProperty(key))
    ) {
      return null;
    }

    return dispatch(fetchAffiliates(sortColumns, sortOrders, join));
  };
}

export const AFFILIATE_REQUEST = 'AFFILIATE_REQUEST';
export const AFFILIATE_SUCCESS = 'AFFILIATE_SUCCESS';
export const AFFILIATE_FAILURE = 'AFFILIATE_FAILURE';

function fetchAffiliate(id) {
  return {
    [CALL_API]: {
      types: [AFFILIATE_REQUEST, AFFILIATE_SUCCESS, AFFILIATE_FAILURE],
      endpoint: Routing.generate('affiliate_show', { id: id }, true),
      schema: Schemas.AFFILIATE,
    },
  };
}

export function loadAffiliate(id, requiredFields = []) {
  return (dispatch, getState) => {
    const affiliates = getState().entities.affiliate;

    if (
      affiliates &&
      affiliates[id] &&
      requiredFields.every((key) => affiliates[id].hasOwnProperty(key))
    ) {
      return null;
    }

    return dispatch(fetchAffiliate(id));
  };
}

export const AFFILIATE_RELOAD_REQUEST = 'AFFILIATE_RELOAD_REQUEST';
export const AFFILIATE_RELOAD_SUCCESS = 'AFFILIATE_RELOAD_SUCCESS';
export const AFFILIATE_RELOAD_FAILURE = 'AFFILIATE_RELOAD_FAILURE';

function fetchAffiliateChildren(affiliateId) {
  return {
    [CALL_API]: {
      types: [
        AFFILIATE_RELOAD_REQUEST,
        AFFILIATE_RELOAD_SUCCESS,
        AFFILIATE_RELOAD_FAILURE,
      ],
      endpoint: Routing.generate(
        'affiliate_children',
        { id: affiliateId },
        true,
      ),
      schema: Schemas.AFFILIATES,
    },
  };
}

export function loadAffiliateChildren(affiliateId, requiredFields = []) {
  return (dispatch, getState) => {
    const affiliates = getState().entities.affiliate;
    const children = pick(affiliates, { parent: affiliateId });

    if (
      affiliates &&
      affiliates[affiliateId] &&
      size(children) &&
      requiredFields.every((key) => sample(children).hasOwnProperty(key))
    ) {
      return null;
    }

    return dispatch(fetchAffiliateChildren(affiliateId));
  };
}

export const AFFILIATE_UPDATE_REQUEST = 'AFFILIATE_UPDATE_REQUEST';
export const AFFILIATE_UPDATE_SUCCESS = 'AFFILIATE_UPDATE_SUCCESS';
export const AFFILIATE_UPDATE_FAILURE = 'AFFILIATE_UPDATE_FAILURE';

function writeAffiliate(form, affiliateId) {
  return {
    [CALL_API]: {
      types: [
        AFFILIATE_UPDATE_REQUEST,
        AFFILIATE_UPDATE_SUCCESS,
        AFFILIATE_UPDATE_FAILURE,
      ],
      endpoint: Routing.generate('affiliate_update', { id: affiliateId }, true),
      method: 'POST',
      data: form,
      schema: Schemas.AFFILIATE,
    },
  };
}

export function updateAffiliate(form, affiliateId = null) {
  return (dispatch) => {
    return dispatch(writeAffiliate(form, affiliateId));
  };
}

export const AFFILIATE_DELETE_REQUEST = 'AFFILIATE_DELETE_REQUEST';
export const AFFILIATE_DELETE_SUCCESS = 'AFFILIATE_DELETE_SUCCESS';
export const AFFILIATE_DELETE_FAILURE = 'AFFILIATE_DELETE_FAILURE';

function deleteAffiliate(form, affiliateId, deleteToken) {
  return {
    [CALL_API]: {
      types: [
        AFFILIATE_DELETE_REQUEST,
        AFFILIATE_DELETE_SUCCESS,
        AFFILIATE_DELETE_FAILURE,
      ],
      endpoint: Routing.generate(
        'affiliate_delete',
        { id: affiliateId, deleteToken: deleteToken },
        true,
      ),
      method: 'DELETE',
      data: form,
    },
  };
}

export function removeAffiliate(form, affiliateId, deleteToken) {
  return (dispatch) => {
    return dispatch(deleteAffiliate(form, affiliateId, deleteToken));
  };
}

export const UPLOAD_UPDATE_REQUEST = 'UPLOAD_UPDATE_REQUEST';
export const UPLOAD_UPDATE_SUCCESS = 'UPLOAD_UPDATE_SUCCESS';
export const UPLOAD_UPDATE_FAILURE = 'UPLOAD_UPDATE_FAILURE';

function writeUpload(form) {
  return {
    [CALL_API]: {
      types: [
        UPLOAD_UPDATE_REQUEST,
        UPLOAD_UPDATE_SUCCESS,
        UPLOAD_UPDATE_FAILURE,
      ],
      endpoint: Routing.generate('upload_update', {}, true),
      method: 'POST',
      data: form,
      schema: Schemas.FORM,
    },
  };
}

export function updateUpload(form) {
  return (dispatch) => {
    return dispatch(writeUpload(form));
  };
}

export const INTERFACEIMPORT_FORM_REQUEST = 'INTERFACEIMPORT_FORM_REQUEST';
export const INTERFACEIMPORT_FORM_SUCCESS = 'INTERFACEIMPORT_FORM_SUCCESS';
export const INTERFACEIMPORT_FORM_FAILURE = 'INTERFACEIMPORT_FORM_FAILURE';

function startImport() {
  return {
    [CALL_API]: {
      types: [
        INTERFACEIMPORT_FORM_REQUEST,
        INTERFACEIMPORT_FORM_SUCCESS,
        INTERFACEIMPORT_FORM_FAILURE,
      ],
      endpoint: Routing.generate('interface_import', null, true),
      schema: Schemas.FORM,
    },
  };
}

export function interfaceImport() {
  return (dispatch) => {
    return dispatch(startImport());
  };
}

export const TAGS_REQUEST = 'TAGS_REQUEST';
export const TAGS_SUCCESS = 'TAGS_SUCCESS';
export const TAGS_FAILURE = 'TAGS_FAILURE';

function fetchTags() {
  return {
    [CALL_API]: {
      types: [TAGS_REQUEST, TAGS_SUCCESS, TAGS_FAILURE],
      endpoint: Routing.generate('affiliate_setting_tags', null, true),
      schema: Schemas.TAGS,
    },
  };
}

export function loadTags(requiredFields = []) {
  return (dispatch, getState) => {
    const tags = getState().entities.tag;

    if (
      tags &&
      requiredFields.every((key) => sample(tags).hasOwnProperty(key))
    ) {
      return null;
    }

    return dispatch(fetchTags());
  };
}

export const REPRESENTATION_REQUEST = 'REPRESENTATION_REQUEST';
export const REPRESENTATION_SUCCESS = 'REPRESENTATION_SUCCESS';
export const REPRESENTATION_FAILURE = 'REPRESENTATION_FAILURE';

function fetchRepresentation() {
  return {
    [CALL_API]: {
      types: [
        REPRESENTATION_REQUEST,
        REPRESENTATION_SUCCESS,
        REPRESENTATION_FAILURE,
      ],
      endpoint: Routing.generate('affiliate_representation', null, true),
      schema: Schemas.REPRESENTATION,
    },
  };
}

export function loadRepresentation(requiredFields = []) {
  return (dispatch, getState) => {
    const representation = getState().entities.representation;

    if (
      representation &&
      requiredFields.every((key) => sample(representation).hasOwnProperty(key))
    ) {
      return null;
    }

    return dispatch(fetchRepresentation());
  };
}

export const AFFILIATE_FORM_REQUEST = 'AFFILIATE_FORM_REQUEST';
export const AFFILIATE_FORM_SUCCESS = 'AFFILIATE_FORM_SUCCESS';
export const AFFILIATE_FORM_FAILURE = 'AFFILIATE_FORM_FAILURE';

function fetchForm() {
  return {
    [CALL_API]: {
      types: [
        AFFILIATE_FORM_REQUEST,
        AFFILIATE_FORM_SUCCESS,
        AFFILIATE_FORM_FAILURE,
      ],
      endpoint: Routing.generate('affiliate_token', {}, true),
      schema: Schemas.FORM,
    },
  };
}

export function initForm() {
  return (dispatch, getState) => {
    const updateToken = getState().forms.updateToken;

    if (updateToken) {
      return null;
    }

    return dispatch(fetchForm());
  };
}

import { parse } from 'date-fns';

const ValidationRules = {
  cmykRegex: /^({cmyk ([0-9.]+\s?){4}}|#[a-z0-9]{6})$/,
  phoneRegex: /^[0-9()\s+--.]+$/,
  configKeyRegex: /^[A-z_0-9]+$/,
  mailerUrlRegex: /^(?!http).+/,
  passwordRegexCategories: [
    /[A-Z]+/, // These rules are also used in PasswordValidationValidator.php and login.js of password reset
    /[0-9]+/,
    /[a-z]+/,
    /[-#@!$%^&*()_+|~={}[\]:;<>?,./]+/,
  ],
  urlRegex:
    /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/,
  parseDateString: (_value: Date, originalValue: string): Date =>
    parse(originalValue as string, 'dd.MM.yyyy', new Date()),
};

export default ValidationRules;

import PropTypes from 'prop-types';
import React, { Component } from 'react';

class PhoneNumber extends Component {
  static propTypes = {
    format: PropTypes.string,
    restrictCountryCode: PropTypes.string,
    defaultCountryCode: PropTypes.string,
    linkFormat: PropTypes.string,
  };

  static defaultProps = {
    format: 'International',
    linkFormat: 'International_plaintext',
    restrictCountryCode: 'DE',
    defaultCountryCode: 'DE',
  };

  render() {
    if (this.props.children) {
      // const phoneNumber = this.props.children.substr(0, 2) == '49' ? '+' + this.props.children : this.props.children;
      // const parsedPhoneNumber = parse(phoneNumber, this.props.defaultCountryCode);
      // const formated = format(parsedPhoneNumber, this.props.format);
      // const link = format(parsedPhoneNumber, this.props.linkFormat);

      return <span>{this.props.children}</span>;
      // return (<a href={`tel:${link}`}>{formated}</a>);
    }

    return null;
  }
}

export default PhoneNumber;

import { schema } from 'normalizr';

export const Customer = new schema.Entity(
  'customer',
  {},
  { idAttribute: (customer) => customer.id },
);
export const Brand = new schema.Entity(
  'brand',
  {},
  { idAttribute: (brand) => brand.id },
);

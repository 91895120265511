import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isEqual, values } from 'lodash';
import Translate from '../../../components/service/Translate';
import { loadTags } from '../actions';
import { MenuItem, TextField } from '@mui/material';
import { EmptyMenuItem } from '../../../components/StyledElements/StyledElements';

class Tags extends Component {
  static propTypes = {
    values: PropTypes.object.isRequired,
    name: PropTypes.string,
    loadTags: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    tags: PropTypes.array,
  };

  componentDidMount = () => {
    this.props.loadTags();
  };

  // eslint-disable-next-line no-unused-vars
  shouldComponentUpdate = (nextProps, nextState) => {
    return (
      !isEqual(nextProps.values, this.props.values) ||
      !isEqual(nextProps.tags, this.props.tags) ||
      !isEqual(this.props.errors, nextProps.errors) ||
      !isEqual(this.props.touched, nextProps.touched)
    );
  };

  renderSelect = (item) => {
    return (
      <MenuItem key={Math.random()} value={item.id}>
        <span>
          <Translate>{item.primaryText}</Translate> ({item.primaryText})
        </span>
      </MenuItem>
    );
  };

  render() {
    const { tags, values, name, handleChange, errors, touched, handleBlur } =
      this.props;

    if (tags && tags.length) {
      return (
        <TextField
          select
          name={name ? `${name}[tag]` : 'tag'}
          id={name ? `${name}[tag]` : 'tag'}
          label={<Translate>tag</Translate>}
          onChange={handleChange}
          onBlur={handleBlur}
          helperText={touched.tag ? errors.tag : ''}
          error={touched.tag && Boolean(errors.tag)}
          fullWidth
          value={values.tag ? values.tag : ''}
        >
          <EmptyMenuItem key={`${name}NullTag`} value={''} />

          {tags.map(this.renderSelect)}
        </TextField>
      );
    }

    return null;
  }
}

// eslint-disable-next-line no-unused-vars
function mapStateToProps(state, ownProps) {
  const {
    entities: { tag },
  } = state;

  return {
    tags: values(tag),
  };
}

export default connect(mapStateToProps, { loadTags })(Tags);

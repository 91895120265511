import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Box, Paper } from '@mui/material';
import Translate from '../../components/service/Translate';
import { filter, forEach, isEqual, merge, map, slice, toArray } from 'lodash';
import {
  Table,
  H2,
  Section,
} from '../../components/StyledElements/StyledElements';
import Scene from '../../components/Scene';

class Placeholders extends Component {
  static propTypes = {
    items: PropTypes.array,
  };

  static defaultProps = {
    items: [
      {
        group: 'customer.',
        wildcard: 'salutationLetter',
        parameter: '',
        format: '',
        description: <Translate>Briefanrede</Translate>,
      },
      {
        group: 'customer.',
        wildcard: 'customerNumber',
        parameter: '',
        format: '',
        description: '',
      },
      {
        group: 'customer.',
        wildcard: 'company',
        parameter: '',
        format: '',
        description: '',
      },
      {
        group: 'customer.',
        wildcard: 'firstname',
        parameter: '',
        format: '',
        description: '',
      },
      {
        group: 'customer.',
        wildcard: 'name',
        parameter: '',
        format: '',
        description: '',
      },
      {
        group: 'customer.',
        wildcard: 'email',
        parameter: '',
        format: '',
        description: '',
      },
      {
        group: 'customer.',
        wildcard: 'address',
        parameter: '',
        format: '',
        description: '',
      },
      {
        group: 'customer.',
        wildcard: 'pobox',
        parameter: '',
        format: '',
        description: '',
      },
      {
        group: 'customer.',
        wildcard: 'zip',
        parameter: '',
        format: '',
        description: '',
      },
      {
        group: 'customer.',
        wildcard: 'city',
        parameter: '',
        format: '',
        description: '',
      },
      {
        group: 'customer.',
        wildcard: 'phone',
        parameter: '',
        format: '',
        description: '',
      },
      {
        group: 'customer.',
        wildcard: 'fax',
        parameter: '',
        format: '',
        description: '',
      },
      {
        group: 'customer.',
        wildcard: 'birthday',
        parameter: '',
        format:
          'lcDate siehe <a href="http://twig.sensiolabs.org/doc/filters/date.html">http://twig.sensiolabs.org/doc/filters/date.html</a>',
        description: '',
      },
      {
        group: 'customer.',
        wildcard: 'name2',
        parameter: '',
        format: '',
        description: '',
      },
      {
        group: 'customer.',
        wildcard: 'title',
        parameter: '',
        format: '',
        description: '',
      },
      {
        group: 'customer.',
        wildcard: 'replySalutation',
        parameter: '',
        format: '',
        description: <Translate>Firmenabhängige Anrede (übersetzt)</Translate>,
      },
      {
        group: 'customer.',
        wildcard: 'contact',
        parameter: '',
        format: '',
        description: '',
      },
      {
        group: 'customer.',
        wildcard: 'note',
        parameter: '',
        format: '',
        description: '',
      },

      {
        group: 'car.',
        wildcard: 'brand.company.title',
        parameter: '',
        format: '',
        description: '',
      },
      {
        group: 'car.',
        wildcard: 'brand.company.short',
        parameter: '',
        format: '',
        description: '',
      },
      {
        group: 'car.',
        wildcard: 'licenseNumber',
        parameter: '',
        format: '',
        description: '',
      },
      {
        group: 'car.',
        wildcard: 'warrantyEnd',
        parameter: '',
        format:
          'lcDate siehe <a href="http://twig.sensiolabs.org/doc/filters/date.html">http://twig.sensiolabs.org/doc/filters/date.html</a>',
        description: '',
      },
      {
        group: 'car.',
        wildcard: 'hu',
        parameter: '',
        format:
          'lcDate siehe <a href="http://twig.sensiolabs.org/doc/filters/date.html">http://twig.sensiolabs.org/doc/filters/date.html</a>',
        description: '',
      },
      {
        group: 'car.',
        wildcard: 'sp',
        parameter: '',
        format:
          'lcDate siehe <a href="http://twig.sensiolabs.org/doc/filters/date.html">http://twig.sensiolabs.org/doc/filters/date.html</a>',
        description: '',
      },
      {
        group: 'car.',
        wildcard: 'tp',
        parameter: '',
        format:
          'lcDate siehe <a href="http://twig.sensiolabs.org/doc/filters/date.html">http://twig.sensiolabs.org/doc/filters/date.html</a>',
        description: '',
      },
      {
        group: 'car.',
        wildcard: 'apr',
        parameter: '',
        format:
          'lcDate siehe <a href="http://twig.sensiolabs.org/doc/filters/date.html">http://twig.sensiolabs.org/doc/filters/date.html</a>',
        description: '',
      },
      {
        group: 'car.',
        wildcard: 'model',
        parameter: '',
        format: '',
        description: '',
      },
      {
        group: 'car.',
        wildcard: 'ident',
        parameter: '',
        format: '',
        description: '',
      },
      {
        group: 'car.',
        wildcard: 'type',
        parameter: '',
        format: '',
        description: '',
      },
      {
        group: 'car.',
        wildcard: 'series',
        parameter: '',
        format: '',
        description: '',
      },
      {
        group: 'car.',
        wildcard: 'classification',
        parameter: '',
        format: '',
        description: '',
      },
      {
        group: 'car.',
        wildcard: 'registrationDate ',
        parameter: '',
        format:
          'lcDate siehe <a href="http://twig.sensiolabs.org/doc/filters/date.html">http://twig.sensiolabs.org/doc/filters/date.html</a>',
        description: '',
      },
      {
        group: 'car.',
        wildcard: 'mileage',
        parameter: '',
        format: '',
        description: '',
      },
      {
        group: 'car.',
        wildcard: 'au',
        parameter: '',
        format: '',
        description: '',
      },
      {
        group: 'car.',
        wildcard: 'engineType',
        parameter: '',
        format: '',
        description: '',
      },
      {
        group: 'car.',
        wildcard: 'color',
        parameter: '',
        format: '',
        description: '',
      },
      {
        group: 'car.',
        wildcard: 'kw',
        parameter: '',
        format: '',
        description: '',
      },
      {
        group: 'car.',
        wildcard: 'ccm',
        parameter: '',
        format: '',
        description: '',
      },
      {
        group: 'car.',
        wildcard: 'warrantyEnd',
        parameter: '',
        format:
          'lcDate siehe <a href="http://twig.sensiolabs.org/doc/filters/date.html">http://twig.sensiolabs.org/doc/filters/date.html</a>',
        description: '',
      },
      {
        group: 'car.',
        wildcard: 'financingEnd',
        parameter: '',
        format:
          'lcDate siehe <a href="http://twig.sensiolabs.org/doc/filters/date.html">http://twig.sensiolabs.org/doc/filters/date.html</a>',
        description: '',
      },
      {
        group: 'car.',
        wildcard: 'registrationType ',
        parameter: '',
        format: '',
        description: '',
      },
      {
        group: 'car.',
        wildcard: 'lastMaintenance',
        parameter: '',
        format:
          'lcDate siehe <a href="http://twig.sensiolabs.org/doc/filters/date.html">http://twig.sensiolabs.org/doc/filters/date.html</a>',
        description: '',
      },
      {
        group: 'car.',
        wildcard: 'nextService',
        parameter: '',
        format:
          'lcDate siehe <a href="http://twig.sensiolabs.org/doc/filters/date.html">http://twig.sensiolabs.org/doc/filters/date.html</a>',
        description: '',
      },
      {
        group: 'car.',
        wildcard: 'engineNumber',
        parameter: '',
        format: '',
        description: '',
      },
      {
        group: 'car.',
        wildcard: 'warrantyNumber',
        parameter: '',
        format: '',
        description: '',
      },
      {
        group: 'car.',
        wildcard: 'warrantyTerm',
        parameter: '',
        format: '',
        description: '',
      },
      {
        group: 'car.',
        wildcard: 'producer',
        parameter: '',
        format: '',
        description: '',
      },
      {
        group: 'car.',
        wildcard: 'isSmart',
        parameter: '',
        format: '',
        description: 'wahr wenn smart',
      },
      {
        group: 'car.',
        wildcard: 'isMercedes',
        parameter: 'includeSmart:bool',
        format: '',
        description: (
          <Translate>
            includeSmart:true - wahr wenn Mercedes oder smart
            <br />
            includeSmart:false - wahr wenn Mercedes
          </Translate>
        ),
      },
      {
        group: 'car.',
        wildcard: 'isFirstMaintenance',
        parameter: 'reference:date',
        format: '',
        description: (
          <Translate>erste Wartung - date:job.normalizedPrinted</Translate>
        ),
      },

      {
        group: 'job.',
        wildcard: 'normalizedPrinted',
        parameter: '',
        format: '',
        description: <Translate>1. Tag des Mailing Monats</Translate>,
      },
      {
        group: 'owner.',
        wildcard: 'headquarter.city',
        parameter: '',
        format: '',
        description: <Translate>Daten der Hauptniederlassung</Translate>,
      },
      {
        group: 'owner.',
        wildcard: 'headquarter.phone',
        parameter: '',
        format: '',
        description: '',
      },
      {
        group: 'owner.',
        wildcard: 'headquarter.signature',
        parameter: '',
        format: '',
        description: <Translate>Schlussformel</Translate>,
      },
      {
        group: 'vouchers.',
        wildcard: '',
        parameter: '',
        format: '',
        description: '',
      },
      {
        group: 'voucherCars.',
        wildcard: '',
        parameter: '',
        format: '',
        description: '',
      },
      {
        group: 'seasonVouchers.',
        wildcard: '',
        parameter: '',
        format: '',
        description: '',
      },
    ],
  };

  // eslint-disable-next-line no-unused-vars
  shouldComponentUpdate = (nextProps, nextState) => {
    return !isEqual(nextProps.items, this.props.items);
  };

  renderRows = (items) => {
    let rows = [];

    map(items, (row, key) => {
      rows.push(<tr key={'row_' + key}>{this.renderColumns(row, key)}</tr>);
    });

    return rows;
  };

  renderColumns = (item, rowNumber) => {
    let row = [];
    let values = toArray(item);
    let style = { padding: '5px 10px', textAlign: 'left' };
    const group = slice(values, 0, 1);

    forEach(slice(values, 1), (cell, key) => {
      if (0 === key) {
        cell = group[0] + cell;
        style = merge(style, { pl: 0 });
      }

      if ('object' !== typeof cell) {
        cell = <span dangerouslySetInnerHTML={{ __html: cell }} />;
      }

      row.push(
        <Box component="td" sx={style} key={key + '_' + rowNumber}>
          {cell}
        </Box>,
      );
    });

    return row;
  };

  getTable = (key) => {
    let items = filter(this.props.items, { group: key + '.' });

    return (
      <Table width="100%">
        <thead>
          <tr>
            <Box
              component="th"
              sx={{
                padding: '5px 10px',
                textAlign: 'left',
                pl: 0,
                width: '20%',
              }}
            >
              Platzhalter
            </Box>
            <Box
              component="th"
              sx={{ padding: '5px 10px', textAlign: 'left', width: '20%' }}
            >
              Parameter
            </Box>
            <Box
              component="th"
              sx={{ padding: '5px 10px', textAlign: 'left', width: '40%' }}
            >
              Formatierung
            </Box>
            <Box
              component="th"
              sx={{ padding: '5px 10px', textAlign: 'left', width: '20%' }}
            >
              Beschreibung / Beispiel
            </Box>
          </tr>
        </thead>
        <tbody>{this.renderRows(items, key)}</tbody>
      </Table>
    );
  };

  render() {
    return (
      <Scene>
        <main>
          <Paper>
            <header>
              <H2>Platzhalter (Variablen)</H2>
            </header>

            <Section>
              <header>
                <h3>Kundendaten (customer.)</h3>
              </header>
              {this.getTable('customer')}
            </Section>

            <Section>
              <header>
                <h3>Auto (car.)</h3>
              </header>
              {this.getTable('car')}
            </Section>

            <Section>
              <header>
                <h3>Auftrag (job.)</h3>
              </header>
              {this.getTable('job')}
            </Section>

            <Section>
              <header>
                <h3>Niederlassung (owner.)</h3>
              </header>
              {this.getTable('owner')}
            </Section>
          </Paper>
        </main>
      </Scene>
    );
  }
}

export default Placeholders;

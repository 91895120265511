import React, { Component } from 'react';
import { isEqual } from 'lodash';
import { MenuItem, TextField, IconButton } from '@mui/material';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Collapse,
  Button,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ContentRemove from '@mui/icons-material/Remove';
import Translate from '../../../components/service/Translate';
import ValidationErrors from '../../../ValidationErrors';
import { EmptyMenuItem } from '../../../components/StyledElements/StyledElements';
import { FormCheckbox } from '../../../components/StyledElements/StyledFormElements';
import Grid from '@mui/material/Unstable_Grid2';

class CampaignConfigFile extends Component {
  static propTypes = {};

  state = {
    expanded: false,
  };

  componentDidMount = () => {
    this.props.formikProps.setFieldValue(
      `campaignConfigFiles[${this.props.index}]`,
      this.props.values,
    );
  };

  // eslint-disable-next-line no-unused-vars
  shouldComponentUpdate(nextProps, nextState) {
    return (
      !isEqual(nextProps.values, this.props.values) ||
      !isEqual(nextProps.submitCount, this.props.submitCount) ||
      !isEqual(nextProps.formikProps, this.props.formikProps) ||
      !isEqual(nextProps.newFile, this.props.newFile) ||
      !isEqual(nextState.expanded, this.state.expanded)
    );
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState) {
    if (
      !isEqual(this.props.submitCount, prevProps.submitCount) &&
      this.props.formikProps.errors &&
      this.props.formikProps.errors.campaignConfigFiles &&
      this.props.formikProps.errors.campaignConfigFiles[this.props.index]
    ) {
      this.setState({ expanded: true });
    }
  }

  handleExpandClick = () => {
    const { expanded } = this.state;
    this.setState({ expanded: !expanded });
  };

  handleRemove = () => {
    this.props.onRemove(this.props.index);
    let configFiles = this.props.formikProps.values.campaignConfigFiles;
    configFiles.splice(this.props.index, 1);
    this.props.formikProps.setFieldValue('campaignConfigFiles', configFiles);
  };

  renderFieldset = () => {
    const { values, index } = this.props;
    const { touched, errors, handleChange, handleBlur, setFieldValue } =
      this.props.formikProps;
    const errorCheck =
      touched.campaignConfigFiles &&
      errors.campaignConfigFiles &&
      touched.campaignConfigFiles[index] &&
      errors.campaignConfigFiles[index];

    let campaignConfigError =
      touched.campaignConfigFiles &&
      errors.campaignConfigFiles &&
      typeof errors.campaignConfigFiles === 'string' &&
      errors.campaignConfigFiles.includes(values.configKey);

    return (
      <Grid container>
        <Grid xs={12} sm={6}>
          <TextField
            label={<Translate>label</Translate>}
            defaultValue={values.label ? values.label : ''}
            name={'campaignConfigFiles[' + index + '][label]'}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={
              errorCheck && errors.campaignConfigFiles[index].label
                ? errors.campaignConfigFiles[index].label
                : ''
            }
            error={
              errorCheck &&
              errors.campaignConfigFiles[index].label &&
              Boolean(errors.campaignConfigFiles[index].label)
            }
          />
        </Grid>
        <Grid xs={12} sm={6}>
          <TextField
            select
            name={'campaignConfigFiles[' + index + '][type]'}
            label={<Translate>Type</Translate>}
            value={values.type || ''}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={
              errorCheck && errors.campaignConfigFiles[index].type
                ? errors.campaignConfigFiles[index].type
                : ''
            }
            error={
              errorCheck &&
              errors.campaignConfigFiles[index].type &&
              Boolean(errors.campaignConfigFiles[index].type)
            }
          >
            <EmptyMenuItem />
            <MenuItem value={'pdf'}>
              <Translate>PDF</Translate>
            </MenuItem>
            <MenuItem value={'image'}>
              <Translate>Image</Translate>
            </MenuItem>
            <MenuItem value={'excel'}>
              <Translate>Excel</Translate>
            </MenuItem>
          </TextField>
        </Grid>
        <Grid xs={12} sm={6}>
          <TextField
            label={<Translate>Crm Key</Translate>}
            defaultValue={values.crmKey ? values.crmKey : ''}
            name={'campaignConfigFiles[' + index + '][crmKey]'}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={
              errorCheck && errors.campaignConfigFiles[index].crmKey
                ? errors.campaignConfigFiles[index].crmKey
                : ''
            }
            error={
              errorCheck &&
              errors.campaignConfigFiles[index].crmKey &&
              Boolean(errors.campaignConfigFiles[index].crmKey)
            }
          />
        </Grid>
        <Grid xs={12} sm={6}>
          <TextField
            label={<Translate>Config Key</Translate>}
            defaultValue={values.configKey ? values.configKey : ''}
            name={'campaignConfigFiles[' + index + '][configKey]'}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={
              campaignConfigError
                ? ValidationErrors.noDuplicates
                : errorCheck && errors.campaignConfigFiles[index].configKey
                  ? errors.campaignConfigFiles[index].configKey
                  : ''
            }
            error={
              (errorCheck &&
                errors.campaignConfigFiles[index].configKey &&
                Boolean(errors.campaignConfigFiles[index].configKey)) ||
              campaignConfigError
            }
          />
        </Grid>
        <Grid xs={12} sm={6}>
          <FormCheckbox
            name={'campaignConfigFiles[' + index + '][required]'}
            label={<Translate>required</Translate>}
            checked={values?.campaignConfigFiles?.[index]?.required ?? false}
            setFieldValue={setFieldValue}
            error={
              touched?.campaignConfigFiles?.[index]?.required &&
              errors?.campaignConfigFiles?.[index]?.required
            }
          />
        </Grid>
        <Grid xs={12} sm={6}>
          <TextField
            label={<Translate>description</Translate>}
            defaultValue={values.description ? values.description : ''}
            name={'campaignConfigFiles[' + index + '][description]'}
            multiline
            rows={3}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={
              errorCheck && errors.campaignConfigFiles[index].description
                ? errors.campaignConfigFiles[index].description
                : ''
            }
            error={
              errorCheck &&
              errors.campaignConfigFiles[index].description &&
              Boolean(errors.campaignConfigFiles[index].description)
            }
            fullWidth
          />
        </Grid>
      </Grid>
    );
  };

  render() {
    const { values, newFile } = this.props;
    const { expanded } = this.state;

    return (
      <Card>
        <CardHeader
          title={!newFile ? values.label : <Translate>New File</Translate>}
          action={
            <IconButton
              onClick={this.handleExpandClick}
              aria-expanded={this.state.expanded}
              aria-label="Show more"
              sx={
                expanded
                  ? {
                      transform: 'rotate(180deg)',
                    }
                  : {}
              }
              size="large"
            >
              <ExpandMoreIcon />
            </IconButton>
          }
        />
        {expanded && (
          <CardContent>
            <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
              {this.renderFieldset()}
            </Collapse>
          </CardContent>
        )}
        <CardActions>
          <Button variant="contained" color="error" onClick={this.handleRemove}>
            <ContentRemove />
            <Translate>remove</Translate>
          </Button>
        </CardActions>
      </Card>
    );
  }
}

export default CampaignConfigFile;

import { CALL_API } from '../../middleware';
import { Schemas } from '../../schemas';
import { sample } from 'lodash';

export const CURRENTUSER_REQUEST = 'CURRENTUSER_REQUEST';
export const CURRENTUSER_SUCCESS = 'CURRENTUSER_SUCCESS';
export const CURRENTUSER_FAILURE = 'CURRENTUSER_FAILURE';

function fetchProfile() {
  return {
    [CALL_API]: {
      types: [CURRENTUSER_REQUEST, CURRENTUSER_SUCCESS, CURRENTUSER_FAILURE],
      endpoint: Routing.generate('staff_current', { react: true }, true),
      schema: Schemas.CURRENTUSER,
    },
  };
}

export function loadProfile(requiredFields = []) {
  return (dispatch, getState) => {
    const currentUser = getState().entities.currentuser;

    if (
      currentUser &&
      requiredFields.every((key) => sample(currentUser).hasOwnProperty(key))
    ) {
      return null;
    }

    return dispatch(fetchProfile());
  };
}

export const STAFFLOG_REQUEST = 'STAFFLOG_REQUEST';
export const STAFFLOG_SUCCESS = 'STAFFLOG_SUCCESS';
export const STAFFLOG_FAILURE = 'STAFFLOG_FAILURE';

function fetchLog(staffId) {
  return {
    [CALL_API]: {
      types: [STAFFLOG_REQUEST, STAFFLOG_SUCCESS, STAFFLOG_FAILURE],
      endpoint: Routing.generate(
        'log_staff_list',
        { id: staffId, limit: 20 },
        true,
      ),
      schema: Schemas.LOGS,
    },
  };
}

export function loadLog(staffId, requiredFields = []) {
  return (dispatch, getState) => {
    const staff = getState().entities.staff;

    if (
      staff &&
      staff[staffId] &&
      requiredFields.every((key) => staff[staffId].hasOwnProperty(key))
    ) {
      return null;
    }

    return dispatch(fetchLog(staffId));
  };
}

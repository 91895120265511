import selectors from '../../selectors';
import { filter, map, property, find } from 'lodash';

const getMailings = (state) => state.entities.mailing;

const getPrintableFilter = (state, props) =>
  props?.match?.params?.printable ?? true;

export const makeMailingsByPrintable = () => {
  return selectors([getPrintableFilter, getMailings], (printable, mailings) => {
    return mailings ? filter(mailings, { printable: printable }) : [];
  });
};

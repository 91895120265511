import React, { Component } from 'react';
import { Paper } from '@mui/material';
import Translate from '../../components/service/Translate';
import { H2 } from '../../components/StyledElements/StyledElements';
import { styled } from '@mui/system';
import Scene from '../../components/Scene';

const IFrame = styled('iframe')(({ theme }) => ({
  height: '95%',
  marginLeft: -1 * theme.spacingConstants.desktopGutter,
}));

class Help extends Component {
  render() {
    return (
      // todo childStyle
      <Scene>
        <Paper
          sx={{
            height: 'calc(100vh - (2*2*3*8px) - (2*3*8px) - 16px)',
          }}
        >
          <header>
            <H2>
              <Translate>header.help</Translate>
            </H2>
          </header>
          <IFrame
            src="https://seeds-static.eeloy.com/support/"
            frameBorder="0"
            width="100%"
            height="100%"
          />
        </Paper>
      </Scene>
    );
  }
}

export default Help;

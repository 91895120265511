import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Box, Paper, Switch } from '@mui/material';
import Translate from '../../components/service/Translate';
import Edit from './components/Edit';
import { isEqual, keys, intersection, toNumber, cloneDeep } from 'lodash';
import {
  updateLocation,
  loadLocations,
  removeLocation,
  loadTags,
  loadNext,
  updateLocationHeadquarter,
} from './actions';
import { resetEntity, outdateEntity } from '../../actions';
import { makeLocationsByAffiliate } from './selectors_deprecated';
import DeleteDialog from '../../components/DeleteDialog';
import ErrorBoundary from '../../components/ErrorBoundary';
import DataGridTable from '../../components/DataGrid/DataGrid';
import RenderCellExpand from '../../components/DataGrid/RenderCellExpand';
import LocationSetting from '../LocationSetting/LocationSetting';

class LocationList extends Component {
  static propTypes = {
    updateLocation: PropTypes.func.isRequired,
    updateLocationHeadquarter: PropTypes.func.isRequired,
    resetEntity: PropTypes.func.isRequired,
    outdateEntity: PropTypes.func.isRequired,
    loadNext: PropTypes.func.isRequired,
    loadLocations: PropTypes.func.isRequired,
    removeLocation: PropTypes.func.isRequired,
    onRowSelect: PropTypes.func,
    affiliateId: PropTypes.number,
    resultset: PropTypes.array,
    locations: PropTypes.object,
    fitted: PropTypes.bool,
  };

  static defaultProps = {
    fitted: false,
  };

  state = {
    current: null,
    currentIndex: null,
    adding: false,
    editing: false,
    deleting: false,
    disableDelete: true,
    deleteTooltipText: null,
    valid: false,
    sortColumns: ['id'],
    sortOrders: ['asc'],
    loading: false,
    loadingHeadquarter: false,
    columns: [
      {
        field: 'id',
        headerName: 'ID',
        relation: null,
        type: 'number',
        width: 30,
      },
      {
        field: 'name',
        headerName: 'Name',
        relation: null,
        type: 'string',
        minWidth: 150,
        flex: 1,
        renderCell: RenderCellExpand,
      },
      {
        field: 'address',
        headerName: 'Adresse',
        relation: null,
        type: 'string',
        minWidth: 150,
        flex: 1,
        renderCell: RenderCellExpand,
      },
      {
        field: 'zip',
        headerName: 'PLZ',
        relation: null,
        type: 'string',
        minWidth: 150,
        flex: 1,
        renderCell: RenderCellExpand,
      },
      {
        field: 'city',
        headerName: 'Ort',
        relation: null,
        type: 'string',
        minWidth: 150,
        flex: 1,
        renderCell: RenderCellExpand,
      },
    ],
  };

  componentDidMount = () => {
    const { sortColumns, sortOrders, columns } = this.state;
    const { inMbvdMode, outdateEntity } = this.props;

    outdateEntity('location');
    this.setState({ loading: true });
    Promise.all([this.props.loadLocations(sortColumns, sortOrders)]).then(
      () => {
        this.setState({ loading: false });
        this.props.outdateEntity('location'); // something else could have loaded a partial resultset
        this.props.loadTags();
      },
      () => {
        this.setState({ loading: false });
      },
    );

    if (inMbvdMode) {
      this.setState({
        columns: columns.concat(
          {
            field: 'tag',
            headerName: 'Ausprägung',
            relation: null,
            type: 'string',
            modifier: 'translate',
            minWidth: 90,
            flex: 1,
            renderCell: RenderCellExpand,
          },
          {
            field: 'organisationId',
            headerName: 'VFNR',
            relation: null,
            type: 'string',
            minWidth: 150,
            flex: 1,
            renderCell: RenderCellExpand,
          },
          {
            field: 'representation',
            headerName: 'Vertreten durch',
            relation: null,
            modifier: 'translate',
            type: 'string',
            minWidth: 150,
            flex: 1,
            renderCell: RenderCellExpand,
          },
          {
            field: 'affiliateHead',
            headerName: 'Hauptsitz-Niederlassung',
            relation: null,
            modifier: 'translate',
            type: 'singleSelect',
            minWidth: 150,
            flex: 1,
            valueOptions: [
              { label: 'ja', value: 1 },
              { label: 'nein', value: 0 },
            ],
            renderCell: RenderCellExpand,
          },
        ),
      });
    } else {
      this.setState({
        columns: columns.concat({
          field: 'headquarter',
          headerName: 'Hauptsitz',
          relation: null,
          modifier: 'translate',
          type: 'singleSelect',
          minWidth: 150,
          flex: 1,
          renderCell: (params) => {
            return (
              <Switch
                checked={params.row.headquarter === 'ja'}
                onChange={(e) => {
                  this.handleChange(
                    params.id,
                    e.target.checked,
                    params.row.updateToken,
                  );
                }}
                disabled={this.state.loadingHeadquarter}
              />
            );
          },
        }),
      });
    }
  };

  handleChange = (id, isHq, updateToken) => {
    const { updateLocationHeadquarter, loadLocations, outdateEntity } =
      this.props;
    const { sortColumns, sortOrders } = this.state;

    let fd = new FormData();
    fd.append('id', id);
    fd.append('headquarter', isHq);
    fd.append('updateToken', updateToken);

    this.setState({ loadingHeadquarter: true });
    Promise.all([updateLocationHeadquarter(fd, id)]).then((values) => {
      outdateEntity('location');
      if (!(values && values[0] && values[0].error)) {
        Promise.all([loadLocations(sortColumns, sortOrders)]).then(() => {
          this.setState({ loadingHeadquarter: false });
        });
      }
    });
  };

  shouldComponentUpdate = (nextProps, nextState) => {
    return (
      !isEqual(nextProps.locations, this.props.locations) ||
      !isEqual(nextProps.resultset, this.props.resultset) ||
      !isEqual(nextProps.affiliateId, this.props.affiliateId) ||
      !isEqual(nextProps.tags, this.props.tags) ||
      !isEqual(nextState.current, this.state.current) ||
      !isEqual(nextState.adding, this.state.adding) ||
      !isEqual(nextState.editing, this.state.editing) ||
      !isEqual(nextState.valid, this.state.valid) ||
      !isEqual(nextState.loading, this.state.loading) ||
      !isEqual(nextState.columns, this.state.columns) ||
      !isEqual(nextState.deleting, this.state.deleting) ||
      !isEqual(nextState.disableDelete, this.state.disableDelete) ||
      !isEqual(nextState.deleteTooltipText, this.state.deleteTooltipText)
    );
  };

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.tags, this.props.tags)) {
      let columnsCopy = cloneDeep(this.state.columns);

      let tagIndex = columnsCopy.findIndex((column) => column.field === 'tag');

      if (tagIndex !== -1) {
        columnsCopy[tagIndex].valueOptions = Object.values(this.props.tags);
        columnsCopy[tagIndex].type = 'singleSelect';
        this.setState({ columns: columnsCopy });
      }
    }
  }

  loadNext = (
    columns,
    sort,
    join = [],
    offset = null,
    limit = null,
    filter = [],
  ) => {
    return Promise.all([
      this.props.loadNext(columns, sort, offset, limit, join, filter),
    ]);
  };

  handleRowSelection = (selectedRow, selectedIndex) => {
    this.setState({ current: selectedRow, currentIndex: selectedIndex }); // single select
  };

  handleDoubleClick = (current) => {
    this.setState({
      current: current,
      currentIndex: current ? current.id : 0,
      adding: false,
      editing: true,
      valid: false,
      mode: 'editing',
    });
  };

  handleOrderChange = (columns, sort, join, limit, offset, filter) => {
    this.setState({ sortColumns: columns, sortOrders: sort });
    this.props.outdateEntity('location');

    return this.loadNext(columns, sort, join, offset, limit, filter);
  };

  submit = () => {
    this.form.submit();
  };

  // eslint-disable-next-line no-unused-vars
  handleDelete = (form, locationId) => {
    const { locations, resultset, loadLocations } = this.props;
    const { currentIndex, sortColumns, sortOrders, current } = this.state;

    const promises = [];
    const nextIndex =
      currentIndex >= 0 && currentIndex < resultset.length - 1
        ? resultset[currentIndex + 1]
        : currentIndex > 0
          ? resultset[currentIndex - 1]
          : 0;
    const next = locations[nextIndex];

    promises.push(this.props.resetEntity('location', current));
    promises.push(
      this.props.removeLocation(current, locations[current].deleteToken),
    );

    this.setState({
      deleting: false,
      mode: 'show',
      currentIndex: nextIndex,
      current: next,
    });

    return Promise.all(promises).then(() => {
      Promise.all([loadLocations(sortColumns, sortOrders)]).then(
        () => {
          this.props.outdateEntity('location'); // something else could have loaded a partial resultset
          this.props.loadTags();
        },
        () => {},
      );
    });
  };

  handleFormMount = (form) => {
    this.form = form;
  };

  handleSubmit = (form) => {
    const { loadLocations, outdateEntity, resetEntity, updateLocation } =
      this.props;
    const { editing, current, sortColumns, sortOrders } = this.state;

    if (!editing) {
      outdateEntity('location');
    }

    return Promise.all([updateLocation(form, editing ? current : null)]).then(
      (values) => {
        if (!(values && values[0] && values[0].error)) {
          this.setState({ adding: false, deleting: false, editing: false });
          resetEntity('location', current);
          outdateEntity('location');
          loadLocations(sortColumns, sortOrders);
        }
        return values;
      },
      (reason) => {
        return reason;
      },
    );
  };

  handleValidityChange = (valid) => {
    this.setState({ valid: valid });
  };

  onRowSelect = (current) => {
    let location = this.props.locations[current];
    if (location.headquarter) {
      this.setState({
        disableDelete: true,
        deleteTooltipText:
          'Der Hauptsitz kann nicht gelöscht werden, bitte markieren Sie zuerst einen anderen Standort als Hauptsitz.',
      });
    } else {
      this.setState({ disableDelete: false, deleteTooltipText: null });
    }
  };

  onTouchEdit = (current) => {
    this.setState({
      adding: false,
      editing: true,
      valid: false,
      current: current,
    });
  };

  onTouchAdd = () => {
    this.setState({ adding: true, editing: false, valid: false });
  };

  onTouchDelete = (current) => {
    this.setState({ deleting: true, current: current });
  };

  onTouchCancel = () => {
    this.setState({
      adding: false,
      editing: false,
      deleting: false,
      valid: false,
    });
  };

  renderDeleteDialog = () => {
    const { deleting, current } = this.state;
    const { locations } = this.props;
    if (deleting && current) {
      return (
        <DeleteDialog
          current={locations[current]}
          onCancel={this.onTouchCancel}
          onSubmit={this.handleDelete}
        />
      );
    }

    return null;
  };

  renderContent = () => {
    const {
      locations,
      fitted,
      affiliateId,
      resultset,
      showLocationSetting,
      embedded,
      pagination,
    } = this.props;
    const {
      columns,
      adding,
      editing,
      current,
      loading,
      disableDelete,
      deleteTooltipText,
    } = this.state;
    let child = null;

    if (loading) {
      child = <Translate>Data loading...</Translate>;
    } else {
      if (adding) {
        child = (
          <ErrorBoundary>
            <Edit
              sx={{
                p: (theme) => theme.spacingConstants.desktopGutter + 'px',
              }}
              onMount={this.handleFormMount}
              onSubmit={this.handleSubmit}
              onValidityChange={this.handleValidityChange}
              locationId={null}
              affiliateId={affiliateId}
              onCancel={this.onTouchCancel}
              submit={this.submit}
            />
          </ErrorBoundary>
        );
      }

      if (editing && !adding) {
        child = (
          <ErrorBoundary>
            <Edit
              sx={{
                p: (theme) => theme.spacingConstants.desktopGutter + 'px',
              }}
              onMount={this.handleFormMount}
              onSubmit={this.handleSubmit}
              onValidityChange={this.handleValidityChange}
              locationId={current}
              onCancel={this.onTouchCancel}
              submit={this.submit}
            />
          </ErrorBoundary>
        );
      }

      if (!adding && !editing) {
        return (
          <>
            <ErrorBoundary>
              <DataGridTable
                noPaper={fitted}
                title="Standorte"
                rows={locations}
                iteratableRows={resultset}
                columns={columns}
                rowHeight={52}
                pagination={pagination}
                embedded={embedded}
                onEdit={this.onTouchEdit}
                onAdd={this.onTouchAdd}
                onDelete={this.onTouchDelete}
                onCancel={this.onTouchCancel}
                onOrderChange={this.handleOrderChange}
                onLoadNext={this.loadNext}
                onRowSelect={this.onRowSelect}
                disableDelete={disableDelete}
                deleteTooltipText={deleteTooltipText}
              />
            </ErrorBoundary>
            {showLocationSetting ? (
              <Box sx={{ pt: 3 }}>
                <LocationSetting />
              </Box>
            ) : null}
          </>
        );
      }
    }

    if (!fitted) {
      return <Paper>{child}</Paper>;
    }

    return child;
  };

  render() {
    return (
      <>
        {this.renderContent()}
        {this.renderDeleteDialog()}
      </>
    );
  }
}

const makeMapStateToProps = () => {
  const getLocationsByAffiliate = makeLocationsByAffiliate();

  return (state, props) => {
    const {
      entities: {
        location,
        config: { config: config },
        owner,
        tag,
      },
      resultsets: { location: resultset },
      // eslint-disable-next-line no-unused-vars
      forms: { location: forms },
      pagination: { location: pagination },
    } = state;

    if (props.affiliateId) {
      const locations = getLocationsByAffiliate(state, props);
      return {
        inMbvdMode: owner[config.owner].seedsMode === 'mbvd',
        locations: locations,
        resultset: intersection(resultset, keys(locations).map(toNumber)),
        pagination: pagination,
      };
    }

    return {
      inMbvdMode: owner[config.owner].seedsMode === 'mbvd',
      locations: location,
      resultset: resultset,
      tags: tag,
      pagination: pagination,
    };
  };
};

export default connect(makeMapStateToProps, {
  resetEntity,
  outdateEntity,
  updateLocation,
  updateLocationHeadquarter,
  loadLocations,
  removeLocation,
  loadTags,
  loadNext,
})(LocationList);

import React, { ComponentType } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

export type WithRouterProps<T = ReturnType<typeof useParams>> = {
  history: {
    back: () => void;
    goBack: () => void;
    location: ReturnType<typeof useLocation>;
    push: (url: string, state?: never) => void;
  };
  location: ReturnType<typeof useLocation>;
  match: {
    params: T;
  };
  navigate: ReturnType<typeof useNavigate>;
};

export const withRouter = <P extends object>(Component: ComponentType<P>) => {
  function withRouterOut(props: Omit<P, keyof WithRouterProps>) {
    const location = useLocation();
    const match = { params: useParams() };
    const navigate = useNavigate();

    const history = {
      back: () => navigate(-1),
      goBack: () => navigate(-1),
      location,
      push: (url: string, state?: never) => navigate(url, { state }),
      replace: (url: string, state?: never) =>
        navigate(url, {
          replace: true,
          state,
        }),
    };

    return (
      <Component
        history={history}
        location={location}
        match={match}
        navigate={navigate}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...(props as P)}
      />
    );
  }
  return withRouterOut;
};

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { intersection } from 'lodash';
import { withRouter } from '../withRouter';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Navigate } from 'react-router-dom';

class ACL extends Component {
  static propTypes = {
    authorized: PropTypes.array,
    fallback: PropTypes.object,
    silent: PropTypes.bool,
    callback: PropTypes.func,
  };

  static defaultProps = {
    fallback: null,
    authorized: null,
    callback: null,
    silent: false,
  };

  state = {
    permitted: false,
    navigateLocation: null,
  };

  componentDidMount = () => {
    this.validateRequest();
  };

  isPermitted = (authorized, userRoles) => {
    return (
      authorized.length &&
      intersection(authorized, userRoles).length === authorized.length
    );
  };

  validateRequest = () => {
    const { authorized, silent, config, roles } = this.props;

    if (authorized) {
      if (!config) {
        this.setState({ navigateLocation: '/login' });
        //this.props.navigate('/login');
      }

      // compare routes with user data
      const permitted = this.isPermitted(authorized, roles);
      this.setState({ permitted: permitted });

      if (permitted) {
        if (this.props.callback) {
          this.props.callback();
        }
      } else if (!silent) {
        this.setState({ navigateLocation: '/not-authorized' });
        //this.props.navigate('/not-authorized');
      }
    }
  };

  render() {
    // eslint-disable-next-line no-unused-vars
    const {
      children,
      fallback,
      silent,
      authorized,
      callback,
      dispatch,
      navigate,
      ...other
    } = this.props;
    const { permitted, navigateLocation } = this.state;

    if (navigateLocation) {
      return <Navigate to={navigateLocation} />;
    }

    if (!permitted && !fallback) {
      return null;
    } else if (!permitted) {
      return <span>{fallback}</span>;
    }

    const childrenWithProps = React.Children.map(children, (child) => {
      if (React.isValidElement(child)) {
        return React.cloneElement(child, { ...other });
      }
      return child;
    });

    return <>{childrenWithProps}</>;
  }
}

// eslint-disable-next-line no-unused-vars
const mapStateToProps = (state, props) => {
  const {
    entities: {
      config: { config: config },
    },
  } = state;

  return {
    config: config,
    roles: config.roles,
  };
};

const enhance = compose(withRouter, connect(mapStateToProps, null));

export default enhance(ACL);

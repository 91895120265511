import React from 'react';
import {
  Paper,
  Typography,
  Box,
  Avatar,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material';
import AssessmentIcon from '@mui/icons-material/Assessment';
import { ReportTable as ReportTableType } from '../../StatisticCampaignTypes';
import ReportTableCell from './ReportTableCell';
import ReportTableRow from './ReportTableRow';
import { seedsGrey125 } from '../../../../theme/colors';

type ReportTableProps = {
  title: string;
  table: ReportTableType;
};

export default function ReportTable({ title, table }: ReportTableProps) {
  return (
    <Paper sx={{ p: 2 }}>
      <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
        <Avatar sx={{ mr: 2, ml: 2 }}>
          <AssessmentIcon />
        </Avatar>
        <Box>
          <Typography variant="body1" color={seedsGrey125} gutterBottom>
            Auswertung
          </Typography>
          <Typography variant="h5" gutterBottom>
            {title}
          </Typography>
        </Box>
      </Box>
      <TableContainer component={Box}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Sparte / Kundengruppe</TableCell>
              <TableCell align="right">Versandmenge</TableCell>
              <TableCell align="right">Zustellmenge</TableCell>
              <TableCell align="right">Öffnungen E-Mail</TableCell>
              <TableCell align="right">Öffnungen Web</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {table.rows.map((row) => (
              <ReportTableRow key={row.label} row={row} />
            ))}
            <TableRow sx={{ 'td, th': { border: 0 } }}>
              <TableCell />
              <TableCell component="th" scope="row">
                Gesamt
              </TableCell>
              <TableCell align="right">
                {table.sent.toLocaleString('de-DE')}
              </TableCell>
              <ReportTableCell count={table.delivered} total={table.sent} />
              <ReportTableCell
                count={table.openedEmail}
                total={table.delivered}
              />
              <ReportTableCell
                count={table.openedWeb}
                total={table.openedEmail}
              />
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

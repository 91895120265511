const carConditions = [
  {
    type: 'General',
    label: 'Abfrage smart',
    description: 'Prüft ob, es sich um einen smart handelt.',
    before: '{% if car.isSmart %}',
    after: '{% endif %}',
  },
  {
    type: 'General',
    label: 'Abfrage smart Elektro ',
    description:
      'Fragt ab ob, es sich um einen smart und ein Elektrofahrzeug handelt.',
    before: '{% if car.isSmart and car.isElectric  %}',
    after: '{% endif %}',
  },
  {
    type: 'General',
    label: 'Abfrage Mercedes',
    description: 'Fragt ab ob, es sich um einen Mercedes handelt.',
    before: '{% if car.isMercedes(false) %}',
    after: '{% endif %}',
  },
  {
    type: 'General',
    label: 'Abfrage Mercedes Elektro',
    description:
      'Fragt ab ob, es sich um  einen Mercedes und ein Elektrofahrzeug handelt.',
    before: '{% if car.isMercedes(false) and car.isElectric  %}',
    after: '{% endif %}',
  },
  {
    type: 'General',
    label: 'Abfrage Sparte - Pkw',
    description: 'Prüft ob, es sich um einen Pkw handelt.',
    before: '{% if car.isPassengercar %}',
    after: '{% endif %}',
  },
  {
    type: 'General',
    label: 'Abfrage Sparte - Pkw ohne smart',
    description: 'Prüft ob, es sich um einen Pkw handelt, schließt smart aus.',
    before: '{% if car.isPassengercar and not car.isSmart %}',
    after: '{% endif %}',
  },
  {
    type: 'General',
    label: 'Abfrage Sparte - Transporter',
    description: 'Prüft ob, es sich um einen Transporter handelt.',
    before: '{% if car.isTransporter %}',
    after: '{% endif %}',
  },
  {
    type: 'General',
    label: 'Abfrage Elektro',
    description: 'Fragt ab ob, es sich um ein Elektrofahrzeug handelt.',
    before: '{% if car.isElectric %}',
    after: '{% endif %}',
  },
  {
    type: 'General',
    label: 'Abfrage Firma',
    description:
      'Fragt ab ob, es sich beim Kunden um einen Firmenkunden handelt.',
    before: '{% if customer.isCompany %}',
    after: '{% endif %}',
  },
  {
    type: 'General',
    label: 'Mehrere Fahrzeuge',
    description: 'Fragt ab, ob es sich um mehr als ein Fahrzeug handelt.',
    before: '{% if cars %}',
    after: '{% endif %}',
  },
  {
    type: 'General',
    label: 'Ein Fahrzeug',
    description: 'Fragt ab, ob es sich nur um ein Fahrzeug handelt.',
    before: '{% if not cars %}',
    after: '{% endif %}',
  },
  {
    type: 'General',
    label: 'Standortbestimmung',
    description: 'Prüft welchem Standort ein Fahrzeug zugeordnet ist.',
    before: '{% if car.location and car.location.configKey %}',
    after: '{% endif %}',
  },
  {
    type: 'Other',
    label: 'Service Vorteil',
    description:
      'Fragt ab, ob ein Fahrzeug für das Service Vorteilsprogramm zugelassen ist.',
    before:
      "{% if ((cars and cars|matchCriteria('hasNeedle',['Service VorteilsProgramm'])) or (not cars and car.hasNeedle('Service VorteilsProgramm'))) %}",
    after: '{% endif %}',
  },
  {
    type: 'Other',
    label: 'Garantie',
    description:
      'Prüft ob, ein Fahrzeug (Mercedes und smart) für eine Garantieverlängerung geeignet ist. (Hinweis: Ausgehend vom Produktionsdatum; tag muss vorab definiert werden)',
    before:
      "{% if not cars and hasTextmodule('warranty', tag) and car.isMercedes and car.mileage < 100000 and car.registrationDate|date('Y-m-d') < job.normalizedPrinted|date_modify('-2 years')|date('Y-m-d') and car.registrationDate|date('Y-m-d') > job.normalizedPrinted|date_modify('-5 years')|date('Y-m-d') %}",
    after: '{% endif %}',
  },
];

export default carConditions;

export const seedsGrey50 = '#303030';
export const seedsGrey100 = '#404853';
export const seedsGrey125 = '#7e7e7e';
export const seedsGrey140 = '#E9E9EF';
export const seedsGrey150 = '#f7f8fa';
export const seedsGrey150Alpha09 = 'rgba(247,248,250, 0.9)';

export const seedsBlue50 = '#4282b0';
export const seedsBlue100 = '#2580c3';
export const seedsBlue150 = '#619dd8';

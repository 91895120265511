import React, { Component } from 'react';
import { Paper } from '@mui/material';
import { isEqual } from 'lodash';
import { GridColDef, GridRowId } from '@mui/x-data-grid';
import { connect, ConnectedProps } from 'react-redux';
import Edit from './Edit';
import ErrorBoundary from '../../../components/ErrorBoundary';
import RenderCellExpand from '../../../components/DataGrid/RenderCellExpand';
import { FORM_MODE } from '../../../utils/Constants';
import { GridDataLoader } from '../../../components/DataGrid/DataGridTypes';
import { Tag } from '../../Tag/TagType';
import EeloyDataGrid from '../../../components/DataGrid/EeloyDataGrid';
import { RootState } from '../../../reducers';
import { fetchAllMailingModules } from '../MailingModuleSlice';
import { makeMailingModulesWithAffiliatesAndSeasonByResultset } from '../selectors';

type ListProps = {
  onAdd: () => void;
  onDelete: (current: GridRowId) => void;
  onEdit: (current: GridRowId) => void;
  onCancel: () => void;
  onSubmit: (formData: FormData) => Promise<void>;
  fitted: boolean;
  mode: string;
  current?: GridRowId;
};

type ListState = {
  columns: GridColDef[];
};

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & ListProps;

class MailingModuleList extends Component<Props, ListState> {
  static defaultProps = {
    fitted: false,
    mode: FORM_MODE.SHOW,
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      columns: [
        {
          field: 'id',
          headerName: 'ID',
          type: 'number',
          width: 30,
        },
        {
          field: 'mode',
          headerName: 'Modus',
          type: 'singleSelect',
          minWidth: 90,
          flex: 1,
          valueOptions: [
            { label: 'text', value: 'text' },
            { label: 'qr_code', value: 'qr_code' },
            { label: 'object', value: 'object' },
          ],
          renderCell: RenderCellExpand,
        },
        {
          field: 'title',
          headerName: 'Name',
          type: 'string',
          minWidth: 200,
          flex: 1,
          renderCell: RenderCellExpand,
        },
        {
          field: 'campaign.title',
          headerName: 'Season',
          type: 'string',
          valueGetter: (_value, row) => row.season?.primaryText ?? '',
          minWidth: 150,
          flex: 1,
          renderCell: RenderCellExpand,
        },
        {
          field: 'affiliate.name',
          headerName: 'NDL',
          type: 'string',
          valueGetter: (_value, row) => row.affiliate?.name ?? '',
          minWidth: 150,
          flex: 1,
          renderCell: RenderCellExpand,
        },
        {
          field: 'tag',
          headerName: 'Ausprägung',
          type: 'singleSelect',
          minWidth: 90,
          flex: 1,
          valueOptions: [{ label: '', value: '' }],
          renderCell: (params) =>
            RenderCellExpand({ ...params, modifier: 'translate' }),
        },
        {
          field: 'position',
          headerName: 'Position',
          type: 'string',
          minWidth: 150,
          flex: 1,
          renderCell: RenderCellExpand,
        },
        {
          field: 'embedded',
          headerName: 'Im Fließtext',
          type: 'singleSelect',
          minWidth: 90,
          flex: 1,
          valueOptions: [
            { label: 'ja', value: 1 },
            { label: 'nein', value: 0 },
          ],
          renderCell: (params) =>
            RenderCellExpand({ ...params, modifier: 'translate' }),
        },
        {
          field: 'updated',
          headerName: 'Letzte Änderung',
          minWidth: 150,
          flex: 1,
          renderCell: (params) =>
            RenderCellExpand({ ...params, modifier: 'datetime' }),
        },
      ],
    };
  }

  // eslint-disable-next-line no-unused-vars
  shouldComponentUpdate(nextProps: Props, nextState: ListState) {
    const { modules, resultset, tags, mode, current } = this.props;
    const { columns } = this.state;

    return (
      !isEqual(modules, nextProps.modules) ||
      !isEqual(resultset, nextProps.resultset) ||
      !isEqual(tags, nextProps.tags) ||
      !isEqual(mode, nextProps.mode) ||
      !isEqual(nextState.columns, columns) ||
      !isEqual(current, nextProps.current)
    );
  }

  componentDidUpdate(prevProps: Props) {
    const { tags } = this.props;
    const { columns } = this.state;

    if (!isEqual(prevProps.tags, tags)) {
      const columnsCopy = [...columns];

      const tagIndex = columnsCopy.findIndex(
        (column) => column.field === 'tag',
      );

      if (tagIndex !== -1) {
        columnsCopy[tagIndex] = {
          field: 'tag',
          headerName: 'Ausprägung',
          type: 'singleSelect',
          minWidth: 90,
          flex: 1,
          valueOptions: Object.values(tags).map((tag) => ({
            value: tag.value,
            label: tag.label,
          })),
          renderCell: (params) =>
            RenderCellExpand({ ...params, modifier: 'translate' }),
        };
      }
    }
  }

  loadNext: GridDataLoader = (gridParams) => {
    const { fetchAllMailingModules } = this.props;

    return Promise.all([fetchAllMailingModules({ gridParams })]);
  };

  renderContent() {
    const {
      modules,
      onSubmit,
      onDelete,
      onAdd,
      onEdit,
      onCancel,
      current,
      mode,
      fitted,
      sortModel,
      paginationModel,
      pagination,
    } = this.props;
    const { columns } = this.state;
    let child = null;

    switch (mode) {
      case FORM_MODE.ADD:
        child = (
          <ErrorBoundary>
            <Edit onSubmit={onSubmit} moduleId={null} onCancel={onCancel} />
          </ErrorBoundary>
        );
        break;

      case FORM_MODE.EDIT:
        child = (
          <ErrorBoundary>
            <Edit
              onSubmit={onSubmit}
              moduleId={current ? +current : null}
              onCancel={onCancel}
            />
          </ErrorBoundary>
        );
        break;

      case FORM_MODE.SHOW:
      default:
        return (
          <div>
            <ErrorBoundary>
              <Paper>
                <EeloyDataGrid
                  title="Bausteine"
                  columnsDefintion={columns}
                  rows={modules}
                  onEdit={onEdit}
                  onAdd={onAdd}
                  onDelete={onDelete}
                  rowCount={pagination?.total ?? 0}
                  loadNext={this.loadNext}
                  initialState={{
                    sorting: { sortModel },
                    pagination: { paginationModel },
                  }}
                  customJoinModel={{
                    campaign: {
                      season: 'mailingmodule.season',
                      campaign: 'season.campaign',
                    },
                    affiliate: {
                      affiliate: 'mailingmodule.affiliate',
                    },
                  }}
                />
              </Paper>
            </ErrorBoundary>
          </div>
        );
    }

    if (!fitted) {
      return <Paper>{child}</Paper>;
    }

    return null;
  }

  render() {
    return <div>{this.renderContent()}</div>;
  }
}

function mapStateToProps() {
  const selectMailingModulesWithAffiliatesAndSeasonByResultset =
    makeMailingModulesWithAffiliatesAndSeasonByResultset();
  return (state: RootState) => {
    const {
      entities: { tag },
      mailingModule: { resultset, pagination, sortModel, paginationModel },
    } = state;

    return {
      tags: tag as Tag[],
      resultset,
      modules: selectMailingModulesWithAffiliatesAndSeasonByResultset(state),
      pagination,
      sortModel,
      paginationModel,
    };
  };
}

const connector = connect(mapStateToProps, {
  fetchAllMailingModules,
});

export default connector(MailingModuleList);

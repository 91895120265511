function parseDate(input: string) {
  const parts = input.match(/(\d+)/g);
  if (!parts) {
    return null;
  }
  // note parts[1]-1
  const year = parseInt(parts[2], 10);
  const month = parseInt(parts[1], 10) - 1;
  const day = parseInt(parts[0], 10);

  return new Date(year, month, day);
}

export { parseDate };

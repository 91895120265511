import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../reducers';
import { selectAllMailingModules } from './MailingModuleSlice';

const selectMailingModuleResultset = (state: RootState) =>
  state.mailingModule.resultset;
const selectSeasons = (state: RootState) => state.season.entities;
const selectAffiliates = (state: RootState) => state.affiliate.entities;

export const makeMailingModulesByResultset = () =>
  createSelector(
    [selectAllMailingModules, selectMailingModuleResultset],
    (mailingModules, resultset) => {
      if (resultset) {
        return resultset
          .map((id) => mailingModules.find((module) => module.id === id))
          .filter((module) => module !== undefined);
      }
      return [];
    },
  );

const selectMailingModulesByResultset = makeMailingModulesByResultset();

export const makeMailingModulesWithAffiliatesAndSeasonByResultset = () =>
  createSelector(
    [selectMailingModulesByResultset, selectSeasons, selectAffiliates],
    (mailingModules, seasons, affiliates) => {
      if (mailingModules) {
        return mailingModules.map((mailingModule) => ({
          ...mailingModule,
          season: seasons[+mailingModule!.season],
          affiliate: affiliates[+mailingModule!.affiliate],
        }));
      }
      return [];
    },
  );

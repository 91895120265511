import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';
import axios from 'axios';
import { normalize, schema } from 'normalizr';
import { camelizeKeys } from 'humps';
import {
  GridFilterModel,
  GridPaginationModel,
  GridRowId,
  GridSortModel,
} from '@mui/x-data-grid';
import { Schemas } from '../../schemas';
import { EmailAccount } from './EmailAccountType';
import type { RootState } from '../../reducers';
import { GridParams } from '../../components/DataGrid/DataGridTypes';
import { convertGridParamsToRequestParams } from '../../components/DataGrid/EeloyDataGridUtils';

const emailAccountAdapter = createEntityAdapter<EmailAccount>({
  sortComparer: (a, b) => a.id - b.id,
});

export const fetchAllEmailAccounts = createAsyncThunk(
  'emailAccount/fetchAll',
  async (data: { gridParams?: GridParams } = {}) => {
    const { gridParams } = data;
    const response = await axios.get(
      Routing.generate('emailaccount_index', null, true),
      {
        withCredentials: true,
        params: { ...convertGridParamsToRequestParams(gridParams) },
      },
    );

    const normalized = normalize<
      schema.Entity<EmailAccount>,
      {
        emailAccount: Record<string, EmailAccount>;
      }
    >(camelizeKeys(response.data.data), Schemas.EMAILACCOUNTS);

    return {
      entities: normalized.entities,
      pagination: response.data.pagination,
      paginationModel: gridParams?.paginationModel,
      resultset: normalized.result,
      sortModel: gridParams?.sortModel,
      filterModel: gridParams?.filterModel,
    };
  },
);

export const writeEmailAccount = createAsyncThunk(
  'emailAccount/write',
  async ({ data, id }: { data: FormData; id: GridRowId | null }) => {
    const response = await axios.post(
      Routing.generate('emailaccount_update', { id }, true),
      data,
      { withCredentials: true },
    );

    const normalized = normalize<
      schema.Entity<EmailAccount>,
      {
        emailAccount: Record<string, EmailAccount>;
      }
    >(camelizeKeys(response.data.data), Schemas.ACCOUNT);

    return { entities: normalized.entities };
  },
);

export const fetchToken = createAsyncThunk('emailAccount/token', async () => {
  const response = await axios.get(
    Routing.generate('emailaccount_token', null, true),
    { withCredentials: true },
  );

  return response.data;
});

export const deleteEmailAccount = createAsyncThunk(
  'emailAccount/delete',
  async ({
    current,
    deleteToken,
  }: {
    current: number;
    deleteToken: string;
  }) => {
    await axios.delete(
      Routing.generate(
        'emailaccount_delete',
        { id: current, deleteToken },
        true,
      ),
      { withCredentials: true },
    );

    return current;
  },
);

interface SliceState {
  updateToken?: string | null;
  resultset?: number[];
  paginationModel?: GridPaginationModel;
  sortModel?: GridSortModel;
  filterModel?: GridFilterModel;
  pagination?: {
    total: number;
    limit: number;
    offset: number;
  };
}

const initialState: SliceState = {
  updateToken: null,
};

const emailAccountSlice = createSlice({
  name: 'emailAccount',
  initialState: emailAccountAdapter.getInitialState(initialState),
  reducers: {
    removeUpdateToken(state) {
      state.updateToken = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllEmailAccounts.fulfilled, (state, action) => {
      const { emailAccount } = action.payload.entities;

      if (emailAccount) {
        emailAccountAdapter.upsertMany(
          state,
          action.payload.entities.emailAccount,
        );
      }

      state.pagination = action.payload.pagination;
      state.resultset = action.payload.resultset;
    });
    builder.addCase(writeEmailAccount.fulfilled, (state, action) => {
      const { emailAccount } = action.payload.entities;

      if (emailAccount) {
        emailAccountAdapter.upsertMany(
          state,
          action.payload.entities.emailAccount,
        );
      }

      state.updateToken = null;
    });
    builder.addCase(fetchToken.fulfilled, (state, action) => {
      state.updateToken = action.payload.results.updateToken;
    });
    builder.addCase(fetchToken.rejected, (state) => {
      state.updateToken = null;
    });
    builder.addCase(deleteEmailAccount.fulfilled, (state, action) => {
      emailAccountAdapter.removeOne(state, action.payload);

      if (state.resultset) {
        const index: number = state.resultset.findIndex(
          (item) => item === action.payload,
        );

        if (index > -1) {
          state.resultset.splice(index, 1);
        }
      }
    });
  },
});

export const {
  selectAll: selectAllEmailAccounts,
  selectById: selectEmailAccountById,
  selectIds: selectEmailAccountsByIds,
} = emailAccountAdapter.getSelectors<RootState>((state) => state.emailAccount);

export const { removeUpdateToken } = emailAccountSlice.actions;
export default emailAccountSlice.reducer;

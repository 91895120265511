import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { isEqual, pick, uniq, difference, map } from 'lodash';
import { Typography, IconButton, Tooltip, Box } from '@mui/material';
import HardwareKeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import HardwareKeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import CommunicationCall from '@mui/icons-material/Call';
import CommunicationCallEnd from '@mui/icons-material/CallEnd';
import EditorModeEdit from '@mui/icons-material/Edit';
import ContentSave from '@mui/icons-material/Save';
import NavigationCancel from '@mui/icons-material/Cancel';
import { connect } from 'react-redux';
import CustomerListCustomerCustomer from './CustomerListCustomerCustomer';
import CustomerListCustomerAvatar from './CustomerListCustomerAvatar';
import CustomerListCustomerInvoice from './CustomerListCustomerInvoice';
import FullWidthTabs from './FullWidthTabs';
import ErrorBoundary from './ErrorBoundary';
import Translate from './service/Translate';
import { updateCustomerMemo } from '../forms';
import {
  Actions,
  Spacer,
  Title,
  ToolbarRoot,
} from './StyledElements/StyledElements';
import { styled } from '@mui/system';

const Inline = styled('div')({
  display: 'inline-block',
  height: 44,
  verticalAlign: 'bottom',
});

class CustomerListCustomer extends Component {
  static propTypes = {
    customerListCustomerId: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
    updateCustomerMemo: PropTypes.func.isRequired,
    pageable: PropTypes.bool,
    callable: PropTypes.bool,
    editable: PropTypes.bool,
    onCall: PropTypes.func,
    customerListCustomer: PropTypes.object,
    cars: PropTypes.array,
    invoices: PropTypes.array,
    customer: PropTypes.object,
    customerCalls: PropTypes.array,
    calls: PropTypes.object,
    onForward: PropTypes.func,
    onBack: PropTypes.func,
    status: PropTypes.string,
    form: PropTypes.object,
    completedCallId: PropTypes.number,
    initialSelectedIndex: PropTypes.number,
    complaintId: PropTypes.string,
    renderToolbar: PropTypes.func,
    additionalTabs: PropTypes.array,
    dataLoading: PropTypes.bool,
  };

  static defaultProps = {
    customer: {},
    pageable: true,
    callable: false,
    editable: false,
    customerCalls: [],
    calls: {},
    completedCallId: null,
    complaintId: null,
    initialSelectedIndex: 0,
    renderToolbar: () => {},
  };

  state = {
    edit: false,
    valid: false,
    loading: false,
    startDisabled: false,
    endDisabled: false,
  };

  componentDidMount = () => {
    const { customerListCustomer } = this.props;
    if (customerListCustomer) {
      if (customerListCustomer.status === 'ongoing') {
        this.setState({ startDisabled: true, endDisabled: false });
      } else if (customerListCustomer.status === 'completed') {
        this.setState({ startDisabled: true, endDisabled: true });
      } else {
        this.setState({ startDisabled: false, endDisabled: true });
      }
    }
  };

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate = (nextProps, nextState) => {
    if (
      !isEqual(
        nextProps.customerListCustomerId,
        this.props.customerListCustomerId,
      ) ||
      (nextProps.callable &&
        !isEqual(
          nextProps.customerListCustomer.status,
          this.props.customerListCustomer.status,
        ))
    ) {
      const { customerListCustomer } = this.props;
      if (customerListCustomer) {
        if (customerListCustomer.status === 'ongoing') {
          this.setState({ startDisabled: true, endDisabled: false });
        } else if (customerListCustomer.status === 'completed') {
          this.setState({ startDisabled: true, endDisabled: true });
        } else {
          this.setState({ startDisabled: false, endDisabled: true });
        }
      }
    }
  };

  shouldComponentUpdate = (nextProps, nextState) => {
    return (
      !isEqual(
        this.props.customerListCustomerId,
        nextProps.customerListCustomerId,
      ) ||
      !isEqual(
        this.props.customerListCustomer,
        nextProps.customerListCustomer,
      ) ||
      !isEqual(this.props.customer, nextProps.customer) ||
      !isEqual(this.props.form, nextProps.form) ||
      !isEqual(this.props.dataLoading, nextProps.dataLoading) ||
      !isEqual(this.state.startDisabled, nextState.startDisabled) ||
      !isEqual(this.state.endDisabled, nextState.endDisabled) ||
      !isEqual(this.state.edit, nextState.edit) ||
      !isEqual(this.state.valid, nextState.valid) ||
      !isEqual(this.state.loading, nextState.loading) ||
      !isEqual(
        nextProps.initialSelectedIndex,
        this.props.initialSelectedIndex,
      ) ||
      !isEqual(nextProps.status, this.props.status)
    );
  };

  handleCallStart = () => {
    this.setState({ startDisabled: true, endDisabled: false });
    this.props.onCall(this.props.customerListCustomer.id, 1);
  };

  handleCallEnd = () => {
    this.setState({ endDisabled: true, startDisabled: false });
    this.props.onCall(this.props.customerListCustomer.id, 0);
  };

  submit = () => {
    this.form.submit();
  };

  handleFormMount = (form) => {
    this.form = form;
  };

  handleFormSubmit = (form) => {
    const { customer } = this.props;
    const promises = [];

    this.setState({ loading: true, valid: false });
    promises.push(this.props.updateCustomerMemo(customer.id, form));

    return Promise.all(promises).then(
      () => {
        this.setState({ edit: false, loading: false });
      },
      () => {
        this.setState({ valid: false, loading: false });
      },
    );
  };

  submitMyForm = null;

  handleSubmitMyForm = (e) => {
    if (this.submitMyForm) {
      this.submitMyForm(e);
    }
  };
  bindSubmitForm = (submitForm) => {
    this.submitMyForm = submitForm;
  };

  handleFormValidation = (valid) => {
    this.setState({ valid: valid });
  };

  enableEditMode = () => {
    this.setState({ edit: true, valid: false });
  };

  disableEditMode = () => {
    this.setState({ edit: false, valid: false });
  };

  renderToolbar = () => {
    const { pageable, callable, editable } = this.props;
    const { edit, loading, startDisabled, endDisabled } = this.state;

    let pager, callbar, editor;

    if (pageable) {
      pager = (
        <Inline>
          <Tooltip title="Back">
            <IconButton
              aria-label="Back"
              onClick={this.props.onBack}
              size="large"
            >
              <HardwareKeyboardArrowLeft />
            </IconButton>
          </Tooltip>
          <Tooltip title="Forward">
            <IconButton
              aria-label="Forward"
              onClick={this.props.onForward}
              size="large"
            >
              <HardwareKeyboardArrowRight />
            </IconButton>
          </Tooltip>
        </Inline>
      );
    }

    if (editable) {
      editor = (
        <Inline>
          <Tooltip title="Edit">
            <IconButton
              aria-label="Edit"
              onClick={this.enableEditMode}
              size="large"
            >
              <EditorModeEdit />
            </IconButton>
          </Tooltip>
        </Inline>
      );

      if (edit) {
        editor = (
          <Inline>
            <Tooltip title="Abbrechen">
              <IconButton
                aria-label="Abbrechen"
                onClick={this.disableEditMode}
                size="large"
              >
                <NavigationCancel />
              </IconButton>
            </Tooltip>
            <Tooltip title="Speichern">
              <IconButton
                disabled={loading}
                type="submit"
                color="primary"
                aria-label="Speichern"
                onClick={this.handleSubmitMyForm}
                size="large"
              >
                <ContentSave />
              </IconButton>
            </Tooltip>
          </Inline>
        );
      }
    }

    if (callable) {
      callbar = (
        <Inline>
          <Tooltip title="Anruf beginnen">
            <span>
              <IconButton
                aria-label="Anruf beginnen"
                disabled={startDisabled}
                onClick={this.handleCallStart}
                size="large"
              >
                <CommunicationCall />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title="Anruf beenden">
            <span>
              <IconButton
                aria-label="Speichern"
                disabled={endDisabled}
                onClick={this.handleCallEnd}
                size="large"
              >
                <CommunicationCallEnd />
              </IconButton>
            </span>
          </Tooltip>
        </Inline>
      );
    }

    return (
      <div>
        {pager}
        {editor}
        {callbar}
      </div>
    );
  };

  renderInvoice = (invoice, index) => {
    return (
      <ErrorBoundary key={index}>
        <CustomerListCustomerInvoice
          initallyExpanded={index === 0}
          key={`invoice-${invoice}`}
          invoiceId={invoice}
          initiallyExpanded={index === 0}
        />
      </ErrorBoundary>
    );
  };

  renderInvoices = () => {
    const { invoices } = this.props;

    if (!invoices || !invoices.length) {
      return null;
    }

    return <div>{invoices.map(this.renderInvoice)}</div>;
  };

  render() {
    const {
      customer,
      dataLoading,
      callable,
      customerListCustomer,
      completedCallId,
      complaintId,
      cars,
      initialSelectedIndex,
      invoices,
    } = this.props;
    const { edit } = this.state;

    if (dataLoading) {
      return <Translate>Data loading...</Translate>;
    }

    if (!customer && !customerListCustomer) {
      return <Translate>No entries found.</Translate>;
    } else if (!customer.id && customerListCustomer.customer === null) {
      customer.primaryText = 'deleted.CustomerName, deleted.CustomerFirstname';
      customer.customerNumber = '';
    }
    const tabs = [
      {
        title: 'Kontaktdaten',
        content: (
          <ErrorBoundary>
            <CustomerListCustomerCustomer
              customerId={customer.id}
              cars={cars}
              callable={callable}
              editorMode={edit}
              bindSubmitForm={this.bindSubmitForm}
              onFormSubmit={this.handleFormSubmit}
              onFormValidityChange={this.handleFormValidation}
              onFormMount={this.handleFormMount}
            />
          </ErrorBoundary>
        ),
      },
    ];

    if (invoices && invoices.length) {
      tabs.push({ title: 'Rechnung', content: this.renderInvoices() });
    }

    const { additionalTabs } = this.props;

    const allTabs = additionalTabs ? tabs.concat(additionalTabs) : tabs;

    return (
      <>
        <ToolbarRoot>
          <Title>
            <Typography variant="h5" id="tableTitle">
              <ErrorBoundary>
                <CustomerListCustomerAvatar
                  size={10}
                  noWrap={false}
                  complaintId={complaintId}
                  completedCallId={
                    completedCallId
                      ? completedCallId
                      : customerListCustomer.completedCall
                  }
                  customerListCustomerId={customerListCustomer.id}
                />
              </ErrorBoundary>
              <Box sx={{ ml: 6, lineHeight: '40px' }}>
                {' '}
                {customer.customerNumber
                  ? `#${customer.customerNumber}`
                  : ''}{' '}
                <Translate>{customer.primaryText}</Translate>
              </Box>
            </Typography>
          </Title>
          <Spacer />
          <Actions>{this.renderToolbar()}</Actions>
        </ToolbarRoot>
        <ErrorBoundary>
          <FullWidthTabs
            tabs={allTabs}
            initialSelectedIndex={initialSelectedIndex}
          />
        </ErrorBoundary>
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const customerListCustomerId = ownProps.customerListCustomerId;
  const {
    entities: {
      customer,
      invoice,
      call,
      customerForm,
      customerListCustomer: customerListCustomers,
    },
  } = state;

  const customerListCustomer = customerListCustomers
    ? customerListCustomers[customerListCustomerId]
    : null;
  let invoices = [];
  let cars = [];

  if (customerListCustomer) {
    if (invoice && customerListCustomer.invoice) {
      invoices.push(invoice[customerListCustomer.invoice].id);
      cars.push(invoice[customerListCustomer.invoice].car);
    } else if (customerListCustomer.car) {
      cars.push(customerListCustomer.car);
    }

    if (customerListCustomer.children && customerListCustomer.children.length) {
      let carChildren = [];
      let invoiceChildren = map(
        pick(
          state.entities.customerListCustomer,
          customerListCustomer.children,
        ),
        'invoice',
      );

      invoices = invoices.concat(invoiceChildren);
      invoices = uniq(difference(invoices, [null]));

      if (invoices.length) {
        carChildren = map(pick(invoice, invoiceChildren), 'car');
      } else {
        carChildren = map(
          pick(
            state.entities.customerListCustomer,
            customerListCustomer.children,
          ),
          'car',
        );
      }

      cars = cars.concat(carChildren);
      cars = uniq(difference(cars, [null]));
    }
  }

  return {
    customerListCustomer: customerListCustomer,
    invoices: invoices,
    cars: cars,
    customer: customerListCustomer
      ? customer[customerListCustomer.customer]
      : null,
    customerCalls:
      customerListCustomer && customerListCustomer.calls
        ? customerListCustomer.calls
        : [],
    calls: customerListCustomer && customerListCustomer.calls ? call : {},
    form: customerForm ? customerForm[customerListCustomer.customer] : null,
  };
}

export default connect(mapStateToProps, { updateCustomerMemo })(
  CustomerListCustomer,
);

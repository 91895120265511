import React from 'react';
import { Outlet } from 'react-router-dom';
import ACL from '../../components/ACL';

type StatisticCampaignProps = {
  requiredRoles?: string[];
};

function StatisticCampaign({
  requiredRoles = ['ROLE_STAFF'],
}: StatisticCampaignProps) {
  return (
    <ACL authorized={requiredRoles}>
      <Outlet />
    </ACL>
  );
}

export default StatisticCampaign;

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { isEmpty, keys } from 'lodash';
import de from '../../translations/de';
import en from '../../translations/en';
import { connect } from 'react-redux';

const dictionary = {
  de,
  en,
};

export const translateValue = (key, language = 'de', context = 'app') => {
  return dictionary[language][context][key];
};

class Translate extends Component {
  static propTypes = {
    context: PropTypes.string,
    wildcards: PropTypes.object,
    wildcardTranslate: PropTypes.bool,
    raw: PropTypes.bool,
  };

  static defaultProps = {
    context: 'app',
    wildcards: null,
    wildcardTranslate: false,
    raw: false,
  };

  state = {
    language: 'de',
  };

  componentDidMount = () => {
    const user = this.props.user;

    // use 'de' if user language is set to empty string
    if (user && user.language && user.language.length) {
      this.setState({ language: user.language });
    } else {
      this.setState({ language: 'de' });
    }
  };

  replace = (text) => {
    const { wildcards, wildcardTranslate } = this.props;

    if (!wildcards) {
      return text;
    }

    const search = new RegExp(keys(wildcards).join('|'), 'g');
    let translate = this.translate;

    return text.replace(search, function (matched) {
      if (wildcardTranslate) {
        return translate(wildcards[matched]);
      }

      return wildcards[matched];
    });
  };

  translate = (key) => {
    const { context } = this.props;
    const { language } = this.state;

    let text = key;

    if (context === 'date') {
      // only months need to be translated
      let dateParts = key.split(' ');
      for (let i = 0; i < dateParts.length; i++) {
        if (dictionary[language][context][dateParts[i]]) {
          dateParts[i] = dictionary[language][context][dateParts[i]];
        }
      }

      text = dateParts.join(' ');
    } else if (dictionary[language][context][key]) {
      text = translateValue(key, language, context);
    }

    return text;
  };

  translateText = () => {
    const { children: key } = this.props;

    let text = this.translate(key);
    return this.replace(text);
  };

  render() {
    if (this.props.raw) {
      return <div dangerouslySetInnerHTML={{ __html: this.translateText() }} />;
    }

    return <span>{this.translateText()}</span>;
  }
}

// eslint-disable-next-line no-unused-vars
const mapStateToProps = (state, props) => {
  const {
    entities: { config: staff },
  } = state;

  return {
    user: !isEmpty(staff) ? staff[staff.config.user] : {},
  };
};

export default connect(mapStateToProps, null)(Translate);

import { CALL_API } from '../../middleware';
import { Schemas } from '../../schemas';
import { sample } from 'lodash';
import has from 'has';

export const STAFFS_REQUEST = 'STAFFS_REQUEST';
export const STAFFS_SUCCESS = 'STAFFS_SUCCESS';
export const STAFFS_FAILURE = 'STAFFS_FAILURE';

function fetchStaffs(
  sortColumns,
  sortOrders,
  offset = null,
  limit = null,
  join,
  filter,
  affiliateId,
) {
  return {
    [CALL_API]: {
      types: [STAFFS_REQUEST, STAFFS_SUCCESS, STAFFS_FAILURE],
      endpoint: Routing.generate(
        'staff_index',
        {
          sortColumns: sortColumns,
          sortOrders: sortOrders,
          offset: offset,
          limit: limit,
          join: join,
          filter: filter,
          affiliateId: affiliateId,
        },
        true,
      ),
      schema: Schemas.STAFFS,
    },
  };
}

export function loadStaffs(
  sortColumns = [],
  sortOrders = [],
  join = [],
  filter = [],
  affiliateId = null,
) {
  return (dispatch) => {
    return dispatch(
      fetchStaffs(
        sortColumns,
        sortOrders,
        null,
        null,
        join,
        filter,
        affiliateId,
      ),
    );
  };
}

export const STAFF_REQUEST = 'STAFF_REQUEST';
export const STAFF_SUCCESS = 'STAFF_SUCCESS';
export const STAFF_FAILURE = 'STAFF_FAILURE';

function fetchStaff(id) {
  return {
    [CALL_API]: {
      types: [STAFF_REQUEST, STAFF_SUCCESS, STAFF_FAILURE],
      endpoint: Routing.generate('staff_show', { id: id }, true),
      schema: Schemas.STAFF,
    },
  };
}

export function loadStaff(id, requiredFields = []) {
  return (dispatch, getState) => {
    const staffs = getState().entities.staff;
    const outdated = getState().outdated.staff;

    if (
      !outdated &&
      staffs &&
      staffs[id] &&
      requiredFields.every((key) => has(staffs[id], key))
    ) {
      return null;
    }

    return dispatch(fetchStaff(id));
  };
}

export const STAFF_PAGED_REQUEST = 'STAFF_PAGED_REQUEST';
export const STAFF_PAGED_SUCCESS = 'STAFF_PAGED_SUCCESS';
export const STAFF_PAGED_FAILURE = 'STAFF_PAGED_FAILURE';

function fetchStaffPaged(
  sortColumns,
  sortOrders,
  offset = null,
  limit = null,
  join = [],
  filter = [],
) {
  return {
    [CALL_API]: {
      types: [STAFF_PAGED_REQUEST, STAFF_PAGED_SUCCESS, STAFF_PAGED_FAILURE],
      endpoint: Routing.generate(
        'staff_index',
        {
          sortColumns: sortColumns,
          sortOrders: sortOrders,
          offset: offset,
          limit: limit,
          join: join,
          filter: filter,
        },
        true,
      ),
      schema: Schemas.STAFFS,
    },
  };
}

export function loadNext(
  sortColumns = [],
  sortOrders = [],
  offset = null,
  limit = null,
  join = [],
  filter = [],
) {
  return (dispatch, getState) => {
    const staff = getState().entities.staff;
    const pagination =
      staff && getState().pagination.staff
        ? getState().pagination.staff
        : false;

    if (!staff || !pagination) {
      return null;
    }

    return dispatch(
      fetchStaffPaged(sortColumns, sortOrders, offset, limit, join, filter),
    );
  };
}

export const STAFF_EXTRACT_REQUEST = 'STAFF_EXTRACT_REQUEST';
export const STAFF_EXTRACT_SUCCESS = 'STAFF_EXTRACT_SUCCESS';
export const STAFF_EXTRACT_FAILURE = 'STAFF_EXTRACT_FAILURE';

function fetchExtract(id) {
  return {
    [CALL_API]: {
      types: [
        STAFF_EXTRACT_REQUEST,
        STAFF_EXTRACT_SUCCESS,
        STAFF_EXTRACT_FAILURE,
      ],
      endpoint: Routing.generate('staff_extract', { id: id }, true),
      schema: Schemas.STAFF,
    },
  };
}

export function loadExtract(id, requiredFields = []) {
  return (dispatch, getState) => {
    const staffs = getState().entities.staff;

    if (
      staffs &&
      staffs[id] &&
      requiredFields.every((key) => has(staffs[id], key))
    ) {
      return null;
    }

    return dispatch(fetchExtract(id));
  };
}

export const STAFF_UPDATE_REQUEST = 'STAFF_UPDATE_REQUEST';
export const STAFF_UPDATE_SUCCESS = 'STAFF_UPDATE_SUCCESS';
export const STAFF_UPDATE_FAILURE = 'STAFF_UPDATE_FAILURE';

function writeStaff(form, staffId) {
  return {
    [CALL_API]: {
      types: [STAFF_UPDATE_REQUEST, STAFF_UPDATE_SUCCESS, STAFF_UPDATE_FAILURE],
      endpoint: Routing.generate('staff_update', { id: staffId }, true),
      method: 'POST',
      data: form,
      schema: Schemas.STAFF,
    },
  };
}

export function updateStaff(form, staffId = null) {
  return (dispatch) => {
    return dispatch(writeStaff(form, staffId));
  };
}

export const STAFF_DELETE_REQUEST = 'STAFF_DELETE_REQUEST';
export const STAFF_DELETE_SUCCESS = 'STAFF_DELETE_SUCCESS';
export const STAFF_DELETE_FAILURE = 'STAFF_DELETE_FAILURE';

function deleteStaff(form, staffId) {
  return {
    [CALL_API]: {
      types: [STAFF_DELETE_REQUEST, STAFF_DELETE_SUCCESS, STAFF_DELETE_FAILURE],
      endpoint: Routing.generate('staff_delete', { id: staffId }, true),
      method: 'POST',
      data: form,
    },
  };
}

export function removeStaff(form, staffId) {
  return (dispatch) => {
    return dispatch(deleteStaff(form, staffId));
  };
}

export const STAFF_ROLES_REQUEST = 'STAFF_ROLES_REQUEST';
export const STAFF_ROLES_SUCCESS = 'STAFF_ROLES_SUCCESS';
export const STAFF_ROLES_FAILURE = 'STAFF_ROLES_FAILURE';

function fetchRoles() {
  return {
    [CALL_API]: {
      types: [STAFF_ROLES_REQUEST, STAFF_ROLES_SUCCESS, STAFF_ROLES_FAILURE],
      endpoint: Routing.generate('staff_roles', null, true),
      schema: Schemas.ROLE,
    },
  };
}

export function loadRoles(requiredFields = []) {
  return (dispatch, getState) => {
    const roles = getState().entities.role;

    if (roles && requiredFields.every((key) => has(sample(roles), key))) {
      return null;
    }

    return dispatch(fetchRoles());
  };
}

export const SEASON_PRINTABLECOPYGROUPS_REQUEST =
  'SEASON_PRINTABLECOPYGROUPS_REQUEST';
export const SEASON_PRINTABLECOPYGROUPS_SUCCESS =
  'SEASON_PRINTABLECOPYGROUPS_SUCCESS';
export const SEASON_PRINTABLECOPYGROUPS_FAILURE =
  'SEASON_PRINTABLECOPYGROUPS_FAILURE';

function fetchPrintableCopyGroups() {
  return {
    [CALL_API]: {
      types: [
        SEASON_PRINTABLECOPYGROUPS_REQUEST,
        SEASON_PRINTABLECOPYGROUPS_SUCCESS,
        SEASON_PRINTABLECOPYGROUPS_FAILURE,
      ],
      endpoint: Routing.generate('staff_reference_selection', null, true),
      schema: Schemas.PRINTABLECOPYGROUPS,
    },
  };
}

export function loadPrintableCopyGroups() {
  return (dispatch) => {
    return dispatch(fetchPrintableCopyGroups());
  };
}

export const STAFF_CONFIG_REQUEST = 'STAFF_CONFIG_REQUEST';
export const STAFF_CONFIG_SUCCESS = 'STAFF_CONFIG_SUCCESS';
export const STAFF_CONFIG_FAILURE = 'STAFF_CONFIG_FAILURE';

function fetchStaffConfig() {
  return {
    [CALL_API]: {
      types: [STAFF_CONFIG_REQUEST, STAFF_CONFIG_SUCCESS, STAFF_CONFIG_FAILURE],
      endpoint: Routing.generate('staff_config', null, true),
      schema: Schemas.CONFIG,
    },
  };
}

export function loadStaffConfig() {
  return (dispatch, getState) => {
    const config = getState().entities.config;

    if (config) {
      return null;
    }

    return dispatch(fetchStaffConfig());
  };
}

export const SESSION_REQUEST = 'SESSION_REQUEST';
export const SESSION_SUCCESS = 'SESSION_SUCCESS';
export const SESSION_FAILURE = 'SESSION_FAILURE';

function fetchSession() {
  return {
    [CALL_API]: {
      types: [SESSION_REQUEST, SESSION_SUCCESS, SESSION_FAILURE],
      endpoint: Routing.generate('security_authentication', null, true),
      schema: Schemas.SESSION,
    },
  };
}

export function loadSession() {
  return (dispatch) => {
    return dispatch(fetchSession());
  };
}

export const STAFF_FORM_REQUEST = 'STAFF_FORM_REQUEST';
export const STAFF_FORM_SUCCESS = 'STAFF_FORM_SUCCESS';
export const STAFF_FORM_FAILURE = 'STAFF_FORM_FAILURE';

function fetchForm() {
  return {
    [CALL_API]: {
      types: [STAFF_FORM_REQUEST, STAFF_FORM_SUCCESS, STAFF_FORM_FAILURE],
      endpoint: Routing.generate('staff_token', null, true),
      schema: Schemas.FORM,
    },
  };
}

export function initForm() {
  return (dispatch, getState) => {
    const form = getState().forms.staff;

    if (form) {
      return null;
    }

    return dispatch(fetchForm());
  };
}

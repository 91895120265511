import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ErrorBoundary from '../../../components/ErrorBoundary';
import { isEqual, isEmpty, intersection, keys, toNumber } from 'lodash';
import { withRouter } from '../../../withRouter';
import { connect } from 'react-redux';
import { Box, Paper, Tooltip } from '@mui/material';
import Translate from '../../../components/service/Translate';
import DeleteDialog from '../../../components/DeleteDialog';
import DataGridTable from '../../../components/DataGrid/DataGrid';
import EditorModeEdit from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import { compose } from 'redux';
import Duplication from './Duplication';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import EeloyDataGrid from '../../../components/DataGrid/EeloyDataGrid';
import {
  deleteCampaignConfigParam,
  fetchAllCampaignConfigParams,
  fetchCampaignConfigParamById,
  updateCampaignConfigParam,
} from '../CampaignConfigParamSlice';
import { makeCampaignConfigParamsWithCampaignsByResultset } from '../selectors';

const defColumns = [
  { headerName: 'ID', field: 'id', id: 'id', type: 'number', width: 80 },
  {
    headerName: 'Schüssel',
    field: 'config',
    id: 'config',
    type: 'string',
    minWidth: 150,
    flex: 1,
    editable: true,
  },
  {
    headerName: 'Wert',
    field: 'param',
    id: 'param',
    type: 'string',
    minWidth: 50,
    flex: 1,
    editable: true,
  },
];

class List extends Component {
  static propTypes = {
    campaignConfigParams: PropTypes.object.isRequired,
  };

  state = {
    columns: defColumns,
    dataLoading: true,
    disableResetButton: true,
    current: null,
    cellEdit: false,
    selectedIds: [],
  };

  componentDidMount = () => {
    let columns = defColumns;

    if (!this.props.campaignId) {
      columns = columns.concat({
        headerName: 'Kampagne',
        field: 'campaign.crmId',
        valueGetter: (value, row) => {
          return row.campaign?.crmId;
        },
        id: 'crmId',
        type: 'string',
        minWidth: 150,
        flex: 1,
      });
    }

    columns = columns.concat(
      { headerName: 'Lokal', field: 'local', id: 'local', type: 'boolean' },
      {
        field: 'action',
        headerName: 'Aktionen',
        sortable: false,
        minWidth: 150,
        renderCell: (params) => {
          return (
            <>
              <Tooltip title="Bearbeiten" disableInteractive>
                <IconButton
                  aria-label="Edit"
                  disabled={!isEmpty(this.state.selectedIds)}
                  onClick={(e) => {
                    e.stopPropagation();
                    this.onTouchEdit(params.id);
                  }}
                  size="large"
                >
                  <EditorModeEdit />
                </IconButton>
              </Tooltip>
              <Tooltip title="Duplizieren" disableInteractive>
                <IconButton
                  aria-label="Duplicate"
                  disabled={!isEmpty(this.state.selectedIds)}
                  onClick={(e) => {
                    e.stopPropagation();
                    this.handleSingleDuplication(params.id);
                  }}
                  size="large"
                >
                  <FileCopyIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Löschen" disableInteractive>
                <IconButton
                  aria-label="Delete"
                  disabled={!isEmpty(this.state.selectedIds)}
                  onClick={(e) => {
                    e.stopPropagation();
                    this.onTouchDelete(params.id);
                  }}
                  size="large"
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </>
          );
        },
      },
    );

    this.setState({ dataLoading: true, columns: columns });

    const gridParams = {
      sortModel: [{ field: 'local', sort: 'desc' }],
    };

    Promise.all([
      this.props.fetchAllCampaignConfigParams({
        campaignId: this.props.campaignId,
        gridParams: gridParams,
      }),
    ]).then(
      () => {
        this.setState({ dataLoading: false });
      },
      () => {
        this.setState({ dataLoading: false });
      },
    );
  };

  // eslint-disable-next-line no-unused-vars
  shouldComponentUpdate = (nextProps, nextState) => {
    return (
      !isEqual(
        nextProps.campaignConfigParamsByResultset,
        this.props.campaignConfigParamsByResultset,
      ) ||
      !isEqual(nextProps.mode, this.props.mode) ||
      !isEqual(
        nextProps.campaignConfigParamId,
        this.props.campaignConfigParamId,
      ) ||
      !isEqual(nextProps.pagination, this.props.pagination) ||
      !isEqual(nextState.selectedIds, this.state.selectedIds) ||
      !isEqual(this.state, nextState)
    );
  };

  onTouchEdit = (current) => {
    this.props.navigate('/campaignconfigparam/edit/' + current);
  };

  onTouchAdd = () => {
    this.props.navigate('/campaignconfigparam/new');
  };

  onTouchDelete = (current) => {
    this.setState({ current: current, deleting: true });
  };

  onTouchCancel = () => {
    this.setState({ deleting: false });
    this.props.navigate('/campaignconfigparam/');
  };

  onRowSelect = (current) => {
    if (!this.state.cellEdit) {
      const { campaignConfigParams } = this.props;
      this.setState({ current: current });

      if (
        current &&
        campaignConfigParams[current] &&
        campaignConfigParams[current].original &&
        campaignConfigParams[current].modified
      ) {
        this.setState({ disableResetButton: false });
      } else {
        this.setState({ disableResetButton: true });
      }
    }
  };

  renderDeleteDialog = () => {
    const { deleting, current } = this.state;
    const { campaignConfigParams } = this.props;

    if (deleting) {
      return (
        <DeleteDialog
          current={campaignConfigParams[current]}
          onCancel={this.onTouchCancel}
          onSubmit={this.handleDelete}
        />
      );
    }

    return null;
  };

  loadNext = (gridParams) => {
    const { fetchAllCampaignConfigParams, campaignId } = this.props;

    return Promise.all([
      fetchAllCampaignConfigParams({
        gridParams,
        campaignId,
      }),
    ]);
  };

  handleDelete = (current) => {
    const { deleteCampaignConfigParam, campaignConfigParams } = this.props;
    this.setState({ deleting: true, current: current });

    Promise.all([
      deleteCampaignConfigParam({
        id: current,
        deleteToken: campaignConfigParams[current].deleteToken,
      }),
    ]);
  };

  handleSingleDuplication = (id) => {
    this.props.navigate('/campaignConfigParam/duplicate/' + id);
  };

  handleDuplicationClose = () => {
    this.setState({ selectedIds: null });
  };

  handleDuplicate = (checkboxSelectionModel) => {
    this.setState({ valid: false });
    this.setState({ selectedIds: checkboxSelectionModel }, () => {
      this.props.navigate('/campaignConfigParam/duplicate/multiple');
    });
  };

  /*  handleCheckboxChange = (selectedCheckboxIds) => {
    this.setState({ selectedIds: selectedCheckboxIds });
  };*/

  processRowUpdate = (newRow, oldRow) => {
    const { campaignConfigParams, updateCampaignConfigParam } = this.props;

    if (!isEqual(oldRow, newRow)) {
      let fd = new FormData();
      let campaignConfigParamId = newRow.id;
      let data = campaignConfigParams[campaignConfigParamId];

      Object.keys(data).map((rowKey) => {
        if (rowKey in newRow && !isEqual(newRow[rowKey], oldRow[rowKey])) {
          fd.append(rowKey, newRow[rowKey]);
        } else {
          fd.append(rowKey, data[rowKey]);
        }
      });

      return Promise.all([
        updateCampaignConfigParam({
          data: fd,
          id: campaignConfigParamId,
        }),
      ]).then((value) => {
        if (!value[0].error) {
          return newRow;
        }
      });
    }
  };

  renderContent = () => {
    const {
      campaignConfigParamsByResultset,
      pagination,
      campaign,
      campaignId,
      mode,
      campaignConfigParamId,
      paginationModel,
      sortModel,
    } = this.props;
    const { columns, dataLoading, selectedIds } = this.state;

    if (dataLoading) {
      return (
        <Paper>
          <Translate>Data loading...</Translate>
        </Paper>
      );
    }

    if (mode === 'duplicate' && campaignConfigParamId === 'multiple') {
      return (
        <ErrorBoundary>
          <Duplication
            selectedIds={selectedIds}
            onClose={this.handleDuplicationClose}
          />
        </ErrorBoundary>
      );
    }

    let title =
      campaignId && !isEmpty(campaign) && !isEmpty(campaign.primaryText)
        ? campaign.primaryText
        : 'Parameter zur Kampagnenkonfiguration';

    const initialState = {
      sorting: { sortModel: sortModel },
      pagination: { paginationModel: paginationModel },
    };

    const customJoinModel = {
      campaign: { campaign: 'campaignConfigParam.campaign' },
    };

    return (
      <ErrorBoundary>
        <Paper>
          <EeloyDataGrid
            title={title}
            columnsDefintion={columns}
            rows={campaignConfigParamsByResultset}
            onAdd={this.onTouchAdd}
            onDuplicate={this.handleDuplicate}
            rowCount={pagination?.total ?? 0}
            loadNext={this.loadNext}
            checkboxSelection
            //onCheckboxSelection={this.handleCheckboxChange}
            processRowUpdate={this.processRowUpdate}
            onRowSelection={this.onRowSelect}
            initialState={initialState}
            customJoinModel={customJoinModel}
            useCheckboxSelectionForDuplicate
          />
        </Paper>
      </ErrorBoundary>
    );
  };

  render() {
    return (
      <>
        {this.renderContent()}
        {this.renderDeleteDialog()}
      </>
    );
  }
}

// eslint-disable-next-line no-unused-vars
function makeMapStateToProps(state, props) {
  const selectCampaignConfigParamsByResultset =
    makeCampaignConfigParamsWithCampaignsByResultset();

  return (state, props) => {
    const {
      campaignConfigParam: {
        entities: campaignConfigParam,
        pagination: pagination,
        paginationModel: paginationModel,
        sortModel: sortModel,
      },
      entities: { campaign },
    } = state;

    const {
      params: { campaignId, mode, campaignConfigParamId },
    } = props.match;

    return {
      campaignConfigParamsByResultset:
        selectCampaignConfigParamsByResultset(state),
      campaignConfigParams: !isEmpty(campaignConfigParam)
        ? campaignConfigParam
        : {},
      sortModel: sortModel,
      campaignId: campaignId ? parseInt(campaignId) : null,
      campaign: campaignId && !isEmpty(campaign) ? campaign[campaignId] : {},
      pagination: pagination,
      paginationModel: paginationModel,
      mode: mode,
      campaignConfigParamId: campaignConfigParamId,
    };
  };
}

const enhance = compose(
  withRouter,
  connect(makeMapStateToProps, {
    fetchAllCampaignConfigParams,
    fetchCampaignConfigParamById,
    updateCampaignConfigParam,
    deleteCampaignConfigParam,
  }),
);

export default enhance(List);

import React, { JSX } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

function EeloyClaim({ style }: SvgIconProps): JSX.Element {
  return (
    <SvgIcon viewBox="0 0 563 142.98" style={style}>
      <path d="M98.79,5.61v26.8H31.68V60.18H92V85.83H31.68v28h69.23V140.4H1.6V5.61Z" />
      <path d="M208.51,5.61v26.8H141.4V60.18h60.36V85.83H141.4v28h69.23V140.4H111.32V5.61Z" />
      <path d="M221,140.4V5.61h30.08v108h57.46v26.8Z" />
      <path d="M427.75,121.79q-20.44,20-50.33,20t-50.33-20q-20.44-20-20.44-49.65T327.1,22.48q20.44-20,50.33-20t50.33,20q20.44,20,20.44,49.65T427.75,121.79ZM417.54,72.23q0-18-11.57-30.76T377.52,28.75q-16.88,0-28.44,12.73T337.51,72.23q0,18,11.57,30.66a38.34,38.34,0,0,0,56.89,0Q417.53,90.27,417.54,72.23Z" />
      <path d="M514.33,140.4H484.25V87.18L437.59,5.61h32.59l29.12,50.14L528.41,5.61H561L514.33,87.18Z" />
    </SvgIcon>
  );
}

export default EeloyClaim;

const MAILER_URL = 'https://mailer.eeloy.com/build/external/icons/';

const Config = {
  base: {
    locale: 'de-DE',
    customJS: [
      `${window.location.protocol}//${window.location.host}/utils/UnlayerCustom.js`,
    ],
    projectId: 7359,
    features: {
      textEditor: { tables: true },
      imageEditor: true,
      userUploads: false,
      stockImages: false,
    },
    tools: {
      image: {
        enabled: true,
      },
      html: {
        enabled: true,
      },
      menu: {
        enabled: false,
      },
      timer: {
        enabled: false,
      },
      social: {
        enabled: true,
        properties: {
          icons: {
            value: {
              editor: {
                data: {
                  customIcons: [
                    {
                      name: 'Facebook_0',
                      url: 'https://example.com/',
                      icons: {
                        circle: MAILER_URL + 'Facebook_0.svg',
                        'circle-black': MAILER_URL + 'Facebook_0.svg',
                        'circle-white': MAILER_URL + 'Facebook_0.svg',
                        rounded: MAILER_URL + 'Facebook_0.svg',
                        'rounded-black': MAILER_URL + 'Facebook_0.svg',
                        squared: MAILER_URL + 'Facebook_0.svg',
                        'squared-black': MAILER_URL + 'Facebook_0.svg',
                      },
                    },
                    {
                      name: 'Facebook_1',
                      url: 'https://example.com/',
                      icons: {
                        circle: MAILER_URL + 'Facebook_1.svg',
                        'circle-black': MAILER_URL + 'Facebook_1.svg',
                        'circle-white': MAILER_URL + 'Facebook_1.svg',
                        rounded: MAILER_URL + 'Facebook_1.svg',
                        'rounded-black': MAILER_URL + 'Facebook_1.svg',
                        squared: MAILER_URL + 'Facebook_1.svg',
                        'squared-black': MAILER_URL + 'Facebook_1.svg',
                      },
                    },
                    {
                      name: 'Facebook_2',
                      url: 'https://example.com/',
                      icons: {
                        circle: MAILER_URL + 'Facebook_2.svg',
                        'circle-black': MAILER_URL + 'Facebook_2.svg',
                        'circle-white': MAILER_URL + 'Facebook_2.svg',
                        rounded: MAILER_URL + 'Facebook_2.svg',
                        'rounded-black': MAILER_URL + 'Facebook_2.svg',
                        squared: MAILER_URL + 'Facebook_2.svg',
                        'squared-black': MAILER_URL + 'Facebook_2.svg',
                      },
                    },
                    {
                      name: 'Facebook_3',
                      url: 'https://example.com/',
                      icons: {
                        circle: MAILER_URL + 'Facebook_3.svg',
                        'circle-black': MAILER_URL + 'Facebook_3.svg',
                        'circle-white': MAILER_URL + 'Facebook_3.svg',
                        rounded: MAILER_URL + 'Facebook_3.svg',
                        'rounded-black': MAILER_URL + 'Facebook_3.svg',
                        squared: MAILER_URL + 'Facebook_3.svg',
                        'squared-black': MAILER_URL + 'Facebook_3.svg',
                      },
                    },
                    {
                      name: 'Facebook_4',
                      url: 'https://example.com/',
                      icons: {
                        circle: MAILER_URL + 'Facebook_4.svg',
                        'circle-black': MAILER_URL + 'Facebook_4.svg',
                        'circle-white': MAILER_URL + 'Facebook_4.svg',
                        rounded: MAILER_URL + 'Facebook_4.svg',
                        'rounded-black': MAILER_URL + 'Facebook_4.svg',
                        squared: MAILER_URL + 'Facebook_4.svg',
                        'squared-black': MAILER_URL + 'Facebook_4.svg',
                      },
                    },
                    {
                      name: 'Facebook_5',
                      url: 'https://example.com/',
                      icons: {
                        circle: MAILER_URL + 'Facebook_5.svg',
                        'circle-black': MAILER_URL + 'Facebook_5.svg',
                        'circle-white': MAILER_URL + 'Facebook_5.svg',
                        rounded: MAILER_URL + 'Facebook_5.svg',
                        'rounded-black': MAILER_URL + 'Facebook_5.svg',
                        squared: MAILER_URL + 'Facebook_5.svg',
                        'squared-black': MAILER_URL + 'Facebook_5.svg',
                      },
                    },
                    {
                      name: 'Instagram_0',
                      url: 'https://example.com/',
                      icons: {
                        circle: MAILER_URL + 'Instagram_0.svg',
                        'circle-black': MAILER_URL + 'Instagram_0.svg',
                        'circle-white': MAILER_URL + 'Instagram_0.svg',
                        rounded: MAILER_URL + 'Instagram_0.svg',
                        'rounded-black': MAILER_URL + 'Instagram_0.svg',
                        squared: MAILER_URL + 'Instagram_0.svg',
                        'squared-black': MAILER_URL + 'Instagram_0.svg',
                      },
                    },
                    {
                      name: 'Instagram_1',
                      url: 'https://example.com/',
                      icons: {
                        circle: MAILER_URL + 'Instagram_1.svg',
                        'circle-black': MAILER_URL + 'Instagram_1.svg',
                        'circle-white': MAILER_URL + 'Instagram_1.svg',
                        rounded: MAILER_URL + 'Instagram_1.svg',
                        'rounded-black': MAILER_URL + 'Instagram_1.svg',
                        squared: MAILER_URL + 'Instagram_1.svg',
                        'squared-black': MAILER_URL + 'Instagram_1.svg',
                      },
                    },
                    {
                      name: 'Instagram_2',
                      url: 'https://example.com/',
                      icons: {
                        circle: MAILER_URL + 'Instagram_2.svg',
                        'circle-black': MAILER_URL + 'Instagram_2.svg',
                        'circle-white': MAILER_URL + 'Instagram_2.svg',
                        rounded: MAILER_URL + 'Instagram_2.svg',
                        'rounded-black': MAILER_URL + 'Instagram_2.svg',
                        squared: MAILER_URL + 'Instagram_2.svg',
                        'squared-black': MAILER_URL + 'Instagram_2.svg',
                      },
                    },
                    {
                      name: 'Instagram_3',
                      url: 'https://example.com/',
                      icons: {
                        circle: MAILER_URL + 'Instagram_3.svg',
                        'circle-black': MAILER_URL + 'Instagram_3.svg',
                        'circle-white': MAILER_URL + 'Instagram_3.svg',
                        rounded: MAILER_URL + 'Instagram_3.svg',
                        'rounded-black': MAILER_URL + 'Instagram_3.svg',
                        squared: MAILER_URL + 'Instagram_3.svg',
                        'squared-black': MAILER_URL + 'Instagram_3.svg',
                      },
                    },
                    {
                      name: 'Instagram_4',
                      url: 'https://example.com/',
                      icons: {
                        circle: MAILER_URL + 'Instagram_4.svg',
                        'circle-black': MAILER_URL + 'Instagram_4.svg',
                        'circle-white': MAILER_URL + 'Instagram_4.svg',
                        rounded: MAILER_URL + 'Instagram_4.svg',
                        'rounded-black': MAILER_URL + 'Instagram_4.svg',
                        squared: MAILER_URL + 'Instagram_4.svg',
                        'squared-black': MAILER_URL + 'Instagram_4.svg',
                      },
                    },
                    {
                      name: 'Instagram_5',
                      url: 'https://example.com/',
                      icons: {
                        circle: MAILER_URL + 'Instagram_5.svg',
                        'circle-black': MAILER_URL + 'Instagram_5.svg',
                        'circle-white': MAILER_URL + 'Instagram_5.svg',
                        rounded: MAILER_URL + 'Instagram_5.svg',
                        'rounded-black': MAILER_URL + 'Instagram_5.svg',
                        squared: MAILER_URL + 'Instagram_5.svg',
                        'squared-black': MAILER_URL + 'Instagram_5.svg',
                      },
                    },
                    {
                      name: 'Linkedin_0',
                      url: 'https://example.com/',
                      icons: {
                        circle: MAILER_URL + 'Linkedin_0.svg',
                        'circle-black': MAILER_URL + 'Linkedin_0.svg',
                        'circle-white': MAILER_URL + 'Linkedin_0.svg',
                        rounded: MAILER_URL + 'Linkedin_0.svg',
                        'rounded-black': MAILER_URL + 'Linkedin_0.svg',
                        squared: MAILER_URL + 'Linkedin_0.svg',
                        'squared-black': MAILER_URL + 'Linkedin_0.svg',
                      },
                    },
                    {
                      name: 'Linkedin_1',
                      url: 'https://example.com/',
                      icons: {
                        circle: MAILER_URL + 'Linkedin_1.svg',
                        'circle-black': MAILER_URL + 'Linkedin_1.svg',
                        'circle-white': MAILER_URL + 'Linkedin_1.svg',
                        rounded: MAILER_URL + 'Linkedin_1.svg',
                        'rounded-black': MAILER_URL + 'Linkedin_1.svg',
                        squared: MAILER_URL + 'Linkedin_1.svg',
                        'squared-black': MAILER_URL + 'Linkedin_1.svg',
                      },
                    },
                    {
                      name: 'Linkedin_2',
                      url: 'https://example.com/',
                      icons: {
                        circle: MAILER_URL + 'Linkedin_2.svg',
                        'circle-black': MAILER_URL + 'Linkedin_2.svg',
                        'circle-white': MAILER_URL + 'Linkedin_2.svg',
                        rounded: MAILER_URL + 'Linkedin_2.svg',
                        'rounded-black': MAILER_URL + 'Linkedin_2.svg',
                        squared: MAILER_URL + 'Linkedin_2.svg',
                        'squared-black': MAILER_URL + 'Linkedin_2.svg',
                      },
                    },
                    {
                      name: 'Linkedin_3',
                      url: 'https://example.com/',
                      icons: {
                        circle: MAILER_URL + 'Linkedin_3.svg',
                        'circle-black': MAILER_URL + 'Linkedin_3.svg',
                        'circle-white': MAILER_URL + 'Linkedin_3.svg',
                        rounded: MAILER_URL + 'Linkedin_3.svg',
                        'rounded-black': MAILER_URL + 'Linkedin_3.svg',
                        squared: MAILER_URL + 'Linkedin_3.svg',
                        'squared-black': MAILER_URL + 'Linkedin_3.svg',
                      },
                    },
                    {
                      name: 'Linkedin_4',
                      url: 'https://example.com/',
                      icons: {
                        circle: MAILER_URL + 'Linkedin_4.svg',
                        'circle-black': MAILER_URL + 'Linkedin_4.svg',
                        'circle-white': MAILER_URL + 'Linkedin_4.svg',
                        rounded: MAILER_URL + 'Linkedin_4.svg',
                        'rounded-black': MAILER_URL + 'Linkedin_4.svg',
                        squared: MAILER_URL + 'Linkedin_4.svg',
                        'squared-black': MAILER_URL + 'Linkedin_4.svg',
                      },
                    },
                    {
                      name: 'Linkedin_5',
                      url: 'https://example.com/',
                      icons: {
                        circle: MAILER_URL + 'Linkedin_5.svg',
                        'circle-black': MAILER_URL + 'Linkedin_5.svg',
                        'circle-white': MAILER_URL + 'Linkedin_5.svg',
                        rounded: MAILER_URL + 'Linkedin_5.svg',
                        'rounded-black': MAILER_URL + 'Linkedin_5.svg',
                        squared: MAILER_URL + 'Linkedin_5.svg',
                        'squared-black': MAILER_URL + 'Linkedin_5.svg',
                      },
                    },
                    {
                      name: 'x_0',
                      url: 'https://example.com/',
                      icons: {
                        circle: MAILER_URL + 'x_0.svg',
                        'circle-black': MAILER_URL + 'x_0.svg',
                        'circle-white': MAILER_URL + 'x_0.svg',
                        rounded: MAILER_URL + 'x_0.svg',
                        'rounded-black': MAILER_URL + 'x_0.svg',
                        squared: MAILER_URL + 'x_0.svg',
                        'squared-black': MAILER_URL + 'x_0.svg',
                      },
                    },
                    {
                      name: 'x_1',
                      url: 'https://example.com/',
                      icons: {
                        circle: MAILER_URL + 'x_1.svg',
                        'circle-black': MAILER_URL + 'x_1.svg',
                        'circle-white': MAILER_URL + 'x_1.svg',
                        rounded: MAILER_URL + 'x_1.svg',
                        'rounded-black': MAILER_URL + 'x_1.svg',
                        squared: MAILER_URL + 'x_1.svg',
                        'squared-black': MAILER_URL + 'x_1.svg',
                      },
                    },
                    {
                      name: 'x_2',
                      url: 'https://example.com/',
                      icons: {
                        circle: MAILER_URL + 'x_2.svg',
                        'circle-black': MAILER_URL + 'x_2.svg',
                        'circle-white': MAILER_URL + 'x_2.svg',
                        rounded: MAILER_URL + 'x_2.svg',
                        'rounded-black': MAILER_URL + 'x_2.svg',
                        squared: MAILER_URL + 'x_2.svg',
                        'squared-black': MAILER_URL + 'x_2.svg',
                      },
                    },
                    {
                      name: 'x_3',
                      url: 'https://example.com/',
                      icons: {
                        circle: MAILER_URL + 'x_3.svg',
                        'circle-black': MAILER_URL + 'x_3.svg',
                        'circle-white': MAILER_URL + 'x_3.svg',
                        rounded: MAILER_URL + 'x_3.svg',
                        'rounded-black': MAILER_URL + 'x_3.svg',
                        squared: MAILER_URL + 'x_3.svg',
                        'squared-black': MAILER_URL + 'x_3.svg',
                      },
                    },
                    {
                      name: 'x_4',
                      url: 'https://example.com/',
                      icons: {
                        circle: MAILER_URL + 'x_4.svg',
                        'circle-black': MAILER_URL + 'x_4.svg',
                        'circle-white': MAILER_URL + 'x_4.svg',
                        rounded: MAILER_URL + 'x_4.svg',
                        'rounded-black': MAILER_URL + 'x_4.svg',
                        squared: MAILER_URL + 'x_4.svg',
                        'squared-black': MAILER_URL + 'x_4.svg',
                      },
                    },
                    {
                      name: 'x_5',
                      url: 'https://example.com/',
                      icons: {
                        circle: MAILER_URL + 'x_5.svg',
                        'circle-black': MAILER_URL + 'x_5.svg',
                        'circle-white': MAILER_URL + 'x_5.svg',
                        rounded: MAILER_URL + 'x_5.svg',
                        'rounded-black': MAILER_URL + 'x_5.svg',
                        squared: MAILER_URL + 'x_5.svg',
                        'squared-black': MAILER_URL + 'x_5.svg',
                      },
                    },
                    {
                      name: 'Youtube_0',
                      url: 'https://example.com/',
                      icons: {
                        circle: MAILER_URL + 'Youtube_0.svg',
                        'circle-black': MAILER_URL + 'Youtube_0.svg',
                        'circle-white': MAILER_URL + 'Youtube_0.svg',
                        rounded: MAILER_URL + 'Youtube_0.svg',
                        'rounded-black': MAILER_URL + 'Youtube_0.svg',
                        squared: MAILER_URL + 'Youtube_0.svg',
                        'squared-black': MAILER_URL + 'Youtube_0.svg',
                      },
                    },
                    {
                      name: 'Youtube_1',
                      url: 'https://example.com/',
                      icons: {
                        circle: MAILER_URL + 'Youtube_1.svg',
                        'circle-black': MAILER_URL + 'Youtube_1.svg',
                        'circle-white': MAILER_URL + 'Youtube_1.svg',
                        rounded: MAILER_URL + 'Youtube_1.svg',
                        'rounded-black': MAILER_URL + 'Youtube_1.svg',
                        squared: MAILER_URL + 'Youtube_1.svg',
                        'squared-black': MAILER_URL + 'Youtube_1.svg',
                      },
                    },
                    {
                      name: 'Youtube_2',
                      url: 'https://example.com/',
                      icons: {
                        circle: MAILER_URL + 'Youtube_2.svg',
                        'circle-black': MAILER_URL + 'Youtube_2.svg',
                        'circle-white': MAILER_URL + 'Youtube_2.svg',
                        rounded: MAILER_URL + 'Youtube_2.svg',
                        'rounded-black': MAILER_URL + 'Youtube_2.svg',
                        squared: MAILER_URL + 'Youtube_2.svg',
                        'squared-black': MAILER_URL + 'Youtube_2.svg',
                      },
                    },
                    {
                      name: 'Youtube_3',
                      url: 'https://example.com/',
                      icons: {
                        circle: MAILER_URL + 'Youtube_3.svg',
                        'circle-black': MAILER_URL + 'Youtube_3.svg',
                        'circle-white': MAILER_URL + 'Youtube_3.svg',
                        rounded: MAILER_URL + 'Youtube_3.svg',
                        'rounded-black': MAILER_URL + 'Youtube_3.svg',
                        squared: MAILER_URL + 'Youtube_3.svg',
                        'squared-black': MAILER_URL + 'Youtube_3.svg',
                      },
                    },
                    {
                      name: 'Youtube_4',
                      url: 'https://example.com/',
                      icons: {
                        circle: MAILER_URL + 'Youtube_4.svg',
                        'circle-black': MAILER_URL + 'Youtube_4.svg',
                        'circle-white': MAILER_URL + 'Youtube_4.svg',
                        rounded: MAILER_URL + 'Youtube_4.svg',
                        'rounded-black': MAILER_URL + 'Youtube_4.svg',
                        squared: MAILER_URL + 'Youtube_4.svg',
                        'squared-black': MAILER_URL + 'Youtube_4.svg',
                      },
                    },
                    {
                      name: 'Youtube_5',
                      url: 'https://example.com/',
                      icons: {
                        circle: MAILER_URL + 'Youtube_5.svg',
                        'circle-black': MAILER_URL + 'Youtube_5.svg',
                        'circle-white': MAILER_URL + 'Youtube_5.svg',
                        rounded: MAILER_URL + 'Youtube_5.svg',
                        'rounded-black': MAILER_URL + 'Youtube_5.svg',
                        squared: MAILER_URL + 'Youtube_5.svg',
                        'squared-black': MAILER_URL + 'Youtube_5.svg',
                      },
                    },
                  ],
                },
              },
            },
          },
        },
      },
      video: {
        enabled: true,
      },
    },
    translations: {
      'de-DE': {
        'labels.merge_tags': 'Variablen einfügen',
      },
    },
  },
};

export default Config;

import React, { Component } from 'react';
import { Paper } from '@mui/material';
import {
  H2,
  Section,
  SubList,
} from '../../components/StyledElements/StyledElements';
import Scene from '../../components/Scene';

class Promotionmailings extends Component {
  render() {
    return (
      <Scene>
        <main>
          <Paper>
            <header>
              <H2>Aktionsmailing</H2>
            </header>
            <Section>
              <header>
                <h3>Mailing einrichten</h3>
              </header>
              <ul>
                <li>Mailing wie gewohnt einrichten</li>
                <li>
                  <b>Wichtig</b>
                </li>
                <SubList>
                  <ol>
                    <li>
                      Druckdatum muss ausgefüllt sein (anhand dessen wird die
                      Laufzeit des Gutscheins berechnet)
                    </li>
                    <li>
                      Unter Gutscheinset muss der/die gewünschte
                      Gutscheinvorlage/n ausgewählt sein. Laufzeit, Erinnerung &
                      Preis können angepasst werden.
                    </li>
                  </ol>
                </SubList>
              </ul>
            </Section>
            <Section>
              <header>
                <h3>Mailingkonfigurator</h3>
              </header>
              <ol>
                <li>Gutscheine freigeben</li>
                <li>Empfängerliste bearbeiten und freigeben</li>
                <li>
                  <b>Gutscheine erzeugen</b>
                </li>
                <SubList>
                  Die Gutscheine werden im Hintergrund erzeugt, nach Abschluss
                  erscheint ein Hinweis zum Export
                </SubList>
              </ol>
            </Section>
            <Section>
              <header>
                <h3>Export</h3>
              </header>
              <ul>
                <li>
                  Sobald die Gutscheine erzeugt wurden erscheinen die
                  zugehörigen Nummern in der Liste und können dort über{' '}
                  <b>Export (XLSX)</b> exportiert werden
                </li>
                <li>
                  Beim <b>entfernen/widerherstellen</b> eines Kunden/Fahrzeugs
                  werden die zugehörigen Gutscheine ebenfalls
                  deaktiviert/aktiviert.
                </li>
                <li>
                  Sollten Kunden/Fahrzeuge neu zur Selektion dazu kommen einfach
                  erneut <b>Gutscheine erzeugen</b> aufrufen, für die neuen
                  Empfänger werden die entsprechenden Gutscheine erzeugt.
                </li>
              </ul>
            </Section>
          </Paper>
        </main>
      </Scene>
    );
  }
}

export default Promotionmailings;

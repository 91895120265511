import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import SmsIcon from '@mui/icons-material/Sms';
import {
  Paper,
  Icon,
  Typography,
  Avatar,
  List,
  ListItem,
  ListItemText,
  Button,
  ListItemButton,
  ListItemAvatar,
  Box,
} from '@mui/material';
import ContentAdd from '@mui/icons-material/Add';
import CommunicationMailOutline from '@mui/icons-material/MailOutline';
import CommunicationPhone from '@mui/icons-material/Phone';
import AlternateEmail from '../../theme/icons/AlternateEmail';
import ACL from '../../components/ACL';
import { fetchCampaign } from './actions';
import { getCampaignsSelector } from './selectors_deprecated';
import Translate from '../../components/service/Translate';
import ErrorBoundary from '../../components/ErrorBoundary';
import { withRouter } from '../../withRouter';
import {
  Actions,
  Spacer,
  Title,
  ToolbarRoot,
} from '../../components/StyledElements/StyledElements';
import { compose } from 'redux';

class CampaignIndex extends Component {
  static propTypes = {
    requiredRoles: PropTypes.array,
    campaigns: PropTypes.array,
  };

  static defaultProps = {
    requiredRoles: ['ROLE_PRO', 'ROLE_SUPERADMIN'],
    campaigns: [],
  };

  state = {
    showForm: false,
    current: null,
    currentIndex: 0,
    adding: false,
    editing: false,
    valid: false,
    view: 'list',
    mode: 'show',
    sortColumns: ['primaryText', 'id'],
    sortOrders: ['asc', 'asc'],
  };

  componentDidMount = () => {
    this.props.fetchCampaign();
  };

  createNew = () => {
    this.props.navigate('/campaign/new');
  };

  goToSingle = (campaignId) => {
    this.props.navigate(Routing.generate('campaign_show', { id: campaignId }));
  };

  renderSeasonIcons = (campaign) => {
    const { season } = this.props;

    if (campaign.seasons && campaign.seasons.length > 0) {
      return (
        <Box sx={{ display: 'flex' }}>
          {campaign.seasons.map((key, index) => {
            if (season[key].type.toLowerCase() === 'calling') {
              return (
                <CommunicationPhone key={season[key].id + 'calling' + index} />
              );
            } else if (season[key].type.toLowerCase() === 'print') {
              return (
                <CommunicationMailOutline
                  key={season[key].id + 'print' + index}
                />
              );
            } else if (season[key].type.toLowerCase() === 'email') {
              return <AlternateEmail key={season[key].id + 'email' + index} />;
            } else if (season[key].type.toLowerCase() === 'sms') {
              return <SmsIcon key={season[key].id + 'sms' + index} />;
            }
          })}
        </Box>
      );
    }
    return null;
  };

  renderCampaignItem = (campaign, divider = false, index) => {
    let avatar;
    if (campaign.teaser !== null) {
      avatar = (
        <Avatar
          alt="Campaign Avatar"
          src={Routing.generate('campaign_teaser', { id: campaign.id }, true)}
        />
      );
    } else {
      avatar = (
        <Avatar>
          {' '}
          <Icon className="muidocs-icon-communication-voicemail" />
          &#125;
        </Avatar>
      );
    }
    let headline =
      campaign.title + (campaign.crmId ? ' - ' + campaign.crmId : '');
    return (
      <div key={index}>
        <ListItem divider={divider} disablePadding alignItems="flex-start">
          <ListItemButton onClick={() => this.goToSingle(campaign.id)}>
            <ListItemAvatar>{avatar}</ListItemAvatar>
            <ListItemText primary={headline} secondary={campaign.description} />
            {this.renderSeasonIcons(campaign)}
          </ListItemButton>
        </ListItem>
      </div>
    );
  };

  renderCampaignGroup = (campaignGroup, index) => {
    return (
      <Paper sx={{ mr: 0, ml: 0 }} key={index}>
        <ErrorBoundary>
          <List>
            {campaignGroup.map((campaign, index) =>
              this.renderCampaignItem(
                campaign,
                index < campaignGroup.length - 1,
                index,
              ),
            )}
          </List>
        </ErrorBoundary>
      </Paper>
    );
  };

  renderCampaignList = () => {
    const { campaignList } = this.props;
    const groups = [];
    let lastCampaign = null,
      currentGroup = [];
    for (let currentCampaign of campaignList) {
      if (currentGroup.length > 0) {
        lastCampaign = currentGroup[currentGroup.length - 1];
        if (lastCampaign.rootCampaignId === currentCampaign.rootCampaignId) {
          currentGroup.push(currentCampaign);
        } else {
          groups.push(currentGroup);
          currentGroup = [];
          currentGroup.push(currentCampaign);
        }
      } else {
        currentGroup.push(currentCampaign);
      }
    }
    groups.push(currentGroup);
    return groups.map((group, index) => this.renderCampaignGroup(group, index));
  };

  renderHeader = () => {
    return (
      <>
        <ToolbarRoot>
          <Title>
            <Typography variant="h5" id="tableTitle">
              Kampagnen
            </Typography>
          </Title>
          <Spacer />
          <Actions>
            <Button
              variant="contained"
              color="primary"
              onClick={this.createNew}
            >
              <ContentAdd />
              <Translate>neue Kampagne</Translate>
            </Button>
          </Actions>
        </ToolbarRoot>
      </>
    );
  };

  render() {
    return (
      <ACL authorized={this.props.requiredRoles}>
        {this.renderHeader()}
        {this.renderCampaignList()}
      </ACL>
    );
  }
}

const getCampaigns = getCampaignsSelector();

const mapStateToProps = (state, props) => {
  return {
    campaignList: getCampaigns(state, props),
    submittingForm: state.loading.includes('CAMPAIGN_SUBMIT'),
    season: state.entities.season,
  };
};

const enhance = compose(
  withRouter,
  connect(mapStateToProps, { fetchCampaign }),
);

export default enhance(CampaignIndex);

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from '../../withRouter';
import Translate from '../../components/service/Translate';
import ErrorBoundary from '../../components/ErrorBoundary';
import ProductTab from './components/ProductTab';
import ProductList from './components/List';
import { Paper } from '@mui/material';
import { loadProducts, loadNext, removeProduct, resetProduct } from './actions';
import { intersection, isEqual, keys, toNumber, isEmpty } from 'lodash';
import { resetEntity, outdateEntity } from '../../actions';
import { compose } from 'redux';

class Product extends Component {
  static propTypes = {
    loadNext: PropTypes.func.isRequired,
    requiredRoles: PropTypes.array,
    productId: PropTypes.number,
    campaignId: PropTypes.number,
    mode: PropTypes.string,
    products: PropTypes.object,
  };

  static defaultProps = {
    requiredRoles: ['ROLE_SUPERADMIN'],
    mode: 'show',
  };

  state = {
    mode: 'show',
    sortColumns: ['id'],
    sortOrders: ['asc'],
    dataLoading: false,
    initialMode: '',
  };

  componentDidMount = () => {
    if (this.props.mode === 'show') {
      this.loadAllProducts();
    }
    this.setState({ mode: this.props.mode, initialMode: this.props.mode });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.mode !== this.props.mode) {
      this.setState({ mode: this.props.mode, deleting: false });
    }
    if (
      this.props.outdated ||
      ((this.state.initialMode === 'edit' ||
        this.state.initialMode === 'add') &&
        this.props.mode === 'show')
    ) {
      this.setState({ initialMode: null });
      this.loadAllProducts(true);
    }
  }

  shouldComponentUpdate = (nextProps, nextState) => {
    return (
      !isEqual(nextProps.mode, this.props.mode) ||
      !isEqual(nextProps.productId, this.props.productId) ||
      !isEqual(nextProps.products, this.props.products) ||
      !isEqual(nextState.dataLoading, this.state.dataLoading) ||
      !isEqual(nextState.mode, this.state.mode) ||
      !isEqual(nextState.initialMode, this.state.initialMode) ||
      !isEqual(nextState.current, this.state.current) ||
      !isEqual(nextState.deleting, this.state.deleting)
    );
  };

  loadAllProducts = (showLoading = true) => {
    const { sortColumns, sortOrders } = this.state;
    showLoading ? this.setState({ dataLoading: true }) : null;
    Promise.all([
      this.props.loadProducts(sortColumns, sortOrders, this.props.campaignId),
    ]).then(
      () => {
        this.setState({ dataLoading: false });
      },
      () => {
        this.setState({ dataLoading: false });
      },
    );
  };

  onTouchCancel = () => {
    this.setState({ deleting: false });
    this.props.navigate('/product/');
  };

  handleOrderChange = (columns, sort, join, limit, offset, filter) => {
    this.setState({ sortColumns: columns, sortOrders: sort });
    this.props.outdateEntity('product');

    return this.loadNext(columns, sort, join, offset, limit, filter);
  };

  loadNext = (
    columns,
    sort,
    join = [],
    offset = null,
    limit = null,
    filter = [],
  ) => {
    return Promise.all([
      this.props.loadNext(
        columns,
        sort,
        offset,
        limit,
        join,
        filter,
        this.props.campaignId,
      ),
    ]);
  };

  handleReset = (productId) => {
    const { resetEntity, resetProduct } = this.props;

    Promise.all([resetProduct(productId)]).then((values) => {
      if (!(values && values[0] && values[0].error)) {
        resetEntity('product', productId);
        this.loadAllProducts(false);
        this.props.navigate('/product');
      }
    });
  };

  handleDelete = (productId, deleteToken) => {
    const { resetEntity, removeProduct } = this.props;

    this.setState({ deleting: true });

    Promise.all([removeProduct(productId, deleteToken)]).then(
      () => {
        this.setState({ deleting: false });
        resetEntity('product', productId);
        this.loadAllProducts(false);
        this.props.navigate('/product');
      },
      () => {
        this.setState({ deleting: false });
      },
    );
  };

  renderContent() {
    const { mode, sortOrders, sortColumns, dataLoading } = this.state;

    let child = null;

    if (dataLoading) {
      child = <Translate>Data loading...</Translate>;
    } else {
      switch (mode) {
        case 'add':
          child = (
            <ErrorBoundary>
              <ProductTab
                onCancel={this.onTouchCancel}
                onDelete={this.onTouchDelete}
              />
            </ErrorBoundary>
          );
          break;
        case 'edit':
          child = (
            <ErrorBoundary>
              <ProductTab
                onCancel={this.onTouchCancel}
                onDelete={this.onTouchDelete}
              />
            </ErrorBoundary>
          );
          break;
        case 'show':
        default:
          return (
            <ErrorBoundary>
              <ProductList
                defaultSortColumns={sortColumns}
                defaultSortOrders={sortOrders}
                onOrderChange={this.handleOrderChange}
                onLoadNext={this.loadNext}
                onReset={this.handleReset}
                onDelete={this.handleDelete}
              />
            </ErrorBoundary>
          );
      }
    }

    return <Paper>{child}</Paper>;
  }

  render() {
    return <>{this.renderContent()}</>;
  }
}

function mapStateToProps(state, props) {
  const {
    entities: { product },
    outdated: { product: outdated },
    resultsets: { product: resultset },
  } = state;

  return {
    mode: props?.match?.params?.mode ?? 'show',
    productId: props?.match?.params?.productId
      ? parseInt(props.match.params.productId)
      : null,
    campaignId: props?.match?.params?.campaignId
      ? parseInt(props.match.params.campaignId)
      : null,
    products: !isEmpty(product) ? product : {},
    resultset: intersection(resultset, keys(product).map(toNumber)),
    outdated: outdated,
  };
}

const enhance = compose(
  withRouter,
  connect(mapStateToProps, {
    loadProducts,
    loadNext,
    removeProduct,
    resetProduct,
    resetEntity,
    outdateEntity,
  }),
);

export default enhance(Product);

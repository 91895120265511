import React from 'react';
import { SvgIcon } from '@mui/material';

let AutoSelect = (props) => (
  <SvgIcon viewBox="0 0 24 24" {...props}>
    <path
      d="M15.5,14h-0.8l-0.3-0.3c1-1.1,1.6-2.6,1.6-4.2C16,5.9,13.1,3,9.5,3S3,5.9,3,9.5C3,13.1,5.9,16,9.5,16
	c1.6,0,3.1-0.6,4.2-1.6l0.3,0.3v0.8l5,5l1.5-1.5L15.5,14z M10.7,10.3H8.2l1.2-3.5L10.7,10.3z M8.8,5.1L6,12.3c-0.6-0.8-1-1.7-1-2.8
	C5,7.3,6.6,5.4,8.8,5.1z M7.2,13.3l0.6-1.8h3.3l0.7,1.9c-0.7,0.4-1.4,0.6-2.3,0.6C8.6,14,7.9,13.7,7.2,13.3z M12.9,12.4l-2.8-7.3
	C12.3,5.4,14,7.2,14,9.5C14,10.6,13.6,11.6,12.9,12.4z"
    />
  </SvgIcon>
);
AutoSelect.displayName = 'AutoSelect';
AutoSelect.muiName = 'SvgIcon';

export default AutoSelect;

import React, { Component } from 'react';
import EeloyIcon from '../../../theme/icons/EeloyIcon';

type WhiteLabelIconProps = {
  width?: number;
  height?: number;
};

class WhiteLabelIcon extends Component<WhiteLabelIconProps> {
  static defaultProps = {
    width: 30,
    height: 30,
  };

  shouldComponentUpdate() {
    return false;
  }

  render() {
    const { width, height } = this.props;

    return <EeloyIcon style={{ width, height }} />;
  }
}

export default WhiteLabelIcon;

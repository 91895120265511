import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Grid,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import Select from '@mui/material/Select';
import { useSelector } from 'react-redux';
import InputLabel from '@mui/material/InputLabel';
import Translate from '../../../components/service/Translate';
import { isEmpty } from 'lodash';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Form from '../../../components/Form';
import * as Yup from 'yup';
import ValidationErrors from '../../../ValidationErrors';
import { useNavigate } from 'react-router-dom';
import { duplicateCampaignConfigParams } from '../actions';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import InfoIcon from '@mui/icons-material/Info';
import { useAppDispatch } from '../../../hooks';
import { fetchAllCampaigns } from '../../Campaign/CampaignSlice';
import { fetchToken } from '../CampaignConfigParamSlice';
import { makeCampaignsBySingularSeason } from '../../Campaign/selectors';

const validationSchema = Yup.object().shape({
  campaignConfigParamIds: Yup.array().of(Yup.number()).required(),
  campaign: Yup.number().required(ValidationErrors.required),
});

function Duplication(props) {
  const { selectedIds, onClose } = props;
  const selectCampaignsBySingularSeason = useMemo(
    makeCampaignsBySingularSeason,
    [],
  );
  const campaignsBySingularSeason = useSelector((state) =>
    selectCampaignsBySingularSeason(state, props),
  );
  const configParams = useSelector(
    (state) => state.campaignConfigParam.entities,
  );
  const campaigns = useSelector((state) => state.campaign.entities);
  const token = useSelector((state) => state.campaignConfigParam?.updateToken);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState();
  const [response, setResponse] = useState();
  const [savedOnce, setSavedOnce] = useState(false);

  useEffect(() => {
    if (isEmpty(selectedIds)) {
      navigate('/campaignconfigparam/');
      return;
    }
    dispatch(fetchToken());
    setLoading(true);
    dispatch(fetchAllCampaigns()).then(() => setLoading(false));
  }, []);

  const handleSubmit = (form) => {
    setSubmitting(true);
    return dispatch(duplicateCampaignConfigParams(form)).then((val) => {
      const response = val.response.data;
      setResponse(response);
      const falsyObjects = Object.values(response).filter(
        (entry) => !entry.success,
      );
      if (falsyObjects.length === 0) {
        setTimeout(() => {
          handleClose();
        }, 1000);
      }
      setSubmitting(false);
      setSavedOnce(true);
    });
  };

  const handleClose = () => {
    onClose();
    navigate('/campaignconfigparam/');
  };

  function MyTable() {
    return (
      <Table aria-label="chosen params">
        <TableHead>
          <TableRow>
            <TableCell>Schlüssel</TableCell>
            <TableCell sx={{ width: '33%' }}>Wert</TableCell>
            <TableCell>Kampagne</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {selectedIds?.map((id, index) => (
            <TableRow key={index}>
              <TableCell>{configParams[id].config}</TableCell>
              <TableCell sx={{ width: '33%' }}>
                {configParams[id].param}
              </TableCell>
              <TableCell>
                {campaigns[configParams[id].campaign].title +
                  (campaigns[configParams[id].campaign].crmId
                    ? ' - ' + campaigns[configParams[id].campaign].crmId
                    : '')}
              </TableCell>
              {!response && <TableCell></TableCell>}
              {response && response[id] && response[id].success && (
                <TableCell sx={{ color: 'okColor' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <CheckCircleOutlineIcon />
                    <Box component="span" sx={{ pr: 1, pl: 1 }}>
                      Parameter erfolgreich dupliziert
                    </Box>
                  </Box>
                </TableCell>
              )}
              {response && response[id] && !response[id].success && (
                <TableCell sx={{ color: 'errorColor' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <HighlightOffIcon />{' '}
                    <Box component="span" sx={{ pr: 1, pl: 1 }}>
                      Parameter existiert bereits
                    </Box>
                    <Tooltip title={response[id].message}>
                      <InfoIcon sx={{ color: 'textColor' }} />
                    </Tooltip>
                  </Box>
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  }

  function renderFieldset(props) {
    const { touched, errors, values, handleChange } = props;

    return (
      <Grid container sx={{ pb: 4 }}>
        <Grid item xs={12}>
          <Typography component="h2">
            Folgende Parameter werden dupliziert:
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <MyTable />
        </Grid>
        <Grid item xs={12}>
          <Typography component="h2" sx={{ pt: 4 }}>
            Wählen Sie die neue Kampagne aus:
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormControl
            fullWidth
            error={touched.campaign && Boolean(errors.campaign)}
          >
            <InputLabel>
              <Translate>Campaign</Translate>
            </InputLabel>
            <Select
              labelId="campaign-select"
              id="campaign-select"
              value={values?.campaign ?? ''}
              label="Age"
              name="campaign"
              onChange={handleChange}
            >
              {campaignsBySingularSeason.map((campaign, index) => {
                return (
                  <MenuItem key={index} value={campaign.id}>
                    {campaign.title +
                      (campaign.crmId ? ' - ' + campaign.crmId : '')}
                  </MenuItem>
                );
              })}
            </Select>
            <FormHelperText>
              {touched.campaign ? errors.campaign : ''}
            </FormHelperText>
          </FormControl>
        </Grid>
      </Grid>
    );
  }

  if (loading) {
    return (
      <Paper>
        <Translate>Data loading...</Translate>{' '}
      </Paper>
    );
  }

  return (
    <Paper>
      <Form
        headline={'Parameter duplizieren'}
        renderFieldset={renderFieldset}
        name="campaign_config_param_multiple"
        onCancel={handleClose}
        onSubmit={handleSubmit}
        initialValues={{
          campaignConfigParamIds: selectedIds,
          updateToken: token,
        }}
        validationSchema={validationSchema}
        disableSubmitButton={savedOnce || submitting}
      />
    </Paper>
  );
}

export default Duplication;

import { schema } from 'normalizr';

export const Location = new schema.Entity(
  'location',
  {},
  { idAttribute: (location) => location.id },
);
export const Role = new schema.Entity(
  'role',
  {},
  { idAttribute: (role) => role.id },
);
export const Representation = new schema.Entity(
  'representation',
  {},
  { idAttribute: (representation) => representation.id },
);
export const Owner = new schema.Entity(
  'owner',
  {},
  { idAttribute: (owner) => owner.id },
);
export const OwnerInterface = new schema.Entity(
  'ownerinterface',
  {},
  { idAttribute: (ownerinterface) => ownerinterface.id },
);
export const Affiliate = new schema.Entity(
  'affiliate',
  {},
  { idAttribute: (affiliate) => affiliate.id },
);
export const Staff = new schema.Entity(
  'staff',
  {},
  { idAttribute: (staff) => staff.id },
);
export const Signee = new schema.Entity(
  'signee',
  {},
  { idAttribute: (signee) => signee.id },
);
export const CurrentUser = new schema.Entity(
  'currentuser',
  {},
  { idAttribute: (currentuser) => currentuser.id },
);
export const AffliliateSetting = new schema.Entity(
  'affiliatesetting',
  {},
  { idAttribute: (affiliatesetting) => affiliatesetting.id },
);
export const Setting = new schema.Entity(
  'setting',
  {},
  { idAttribute: (setting) => setting.id },
);
export const Config = new schema.Entity(
  'config',
  {},
  { idAttribute: (config) => config.id },
);
export const Session = new schema.Entity(
  'session',
  {},
  { idAttribute: (session) => session.id },
);
export const LocationSetting = new schema.Entity(
  'locationsetting',
  {},
  { idAttribute: (locationsetting) => locationsetting.id },
);

export const calcRate = (count: number, total: number): number =>
  count !== 0 && total !== 0 ? Math.round((count / total) * 1000) / 10 : 0.0;

export const calcRateToString = (count: number, total: number): string => {
  const rate = calcRate(count, total).toLocaleString('de-DE', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  return `${rate} %`;
};

export const toPercentageString = (percentage: number | null): string =>
  percentage !== null
    ? `${percentage.toLocaleString('de-DE', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })} %`
    : '';

export default { calcRate, calcRateToString, toPercentageString };

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Paper } from '@mui/material';
import PropertiesTable from '../../../components/PropertiesTable';
import Translate from '../../../components/service/Translate';
import { isEqual } from 'lodash';
import { withTheme } from '../../../withTheme';

class Summary extends Component {
  static propTypes = {
    ownerInterface: PropTypes.object.isRequired,
  };

  // eslint-disable-next-line no-unused-vars
  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return !isEqual(nextProps.ownerInterface, this.props.ownerInterface);
  }

  render() {
    const { ownerInterface, theme } = this.props;

    let customerStatus = <Translate>{ownerInterface.customerStatus}</Translate>;
    let lastCustomerImport = ownerInterface.lastCustomerImport
      ? new Intl.DateTimeFormat(theme.locale.de, {
          month: '2-digit',
          day: '2-digit',
          year: 'numeric',
        }).format(new Date(ownerInterface.lastCustomerImport))
      : '';
    let carStatus = <Translate>{ownerInterface.carStatus}</Translate>;
    let lastCarImport = ownerInterface.lastCarImport
      ? new Intl.DateTimeFormat(theme.locale.de, {
          month: '2-digit',
          day: '2-digit',
          year: 'numeric',
        }).format(new Date(ownerInterface.lastCarImport))
      : '';
    let invoiceStatus = <Translate>{ownerInterface.invoiceStatus}</Translate>;
    let lastInvoiceImport = ownerInterface.lastInvoiceImport
      ? new Intl.DateTimeFormat(theme.locale.de, {
          month: '2-digit',
          day: '2-digit',
          year: 'numeric',
        }).format(new Date(ownerInterface.lastInvoiceImport))
      : '';

    const tableData = [
      {
        row: [
          { label: 'Typ', data: ownerInterface.type },
          { label: ' ', data: ' ', empty: true },
        ],
      },
      {
        row: [
          { label: 'Status Kunden', data: customerStatus },
          { label: 'Letzter Import Kunden', data: lastCustomerImport },
        ],
      },
      {
        row: [
          { label: 'Status Fahrzeuge', data: carStatus },
          { label: 'Letzter Import Fahrzeug', data: lastCarImport },
        ],
      },
      {
        row: [
          { label: 'Status Rechnungen', data: invoiceStatus },
          { label: 'Letzter Import Rechnungen', data: lastInvoiceImport },
        ],
      },
    ];

    return (
      <Paper>
        <PropertiesTable tableData={tableData} styles={{}} />
      </Paper>
    );
  }
}

export default withTheme(Summary);

import React, { Component } from 'react';
import {
  TextField,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Slide,
  Input,
} from '@mui/material';
import { connect } from 'react-redux';
import Translate from '../../../components/service/Translate';
import PropTypes from 'prop-types';
import { loadProfile } from '../../Profile/actions';
import { makeCustomerListCustomerById } from '../selectors';
import { dispatchEmail } from '../actions';
import Form from '../../../components/Form';
import { NotSelected } from '../../../components/StyledElements/StyledElements';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class SendEmailDialog extends Component {
  static propTypes = {
    setVisibleState: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    visible: PropTypes.bool.isRequired,
    dispatchEmail: PropTypes.func,
    values: PropTypes.object,
    currentUser: PropTypes.object,
  };

  static defaultProps = {};

  state = {
    focus: false,
  };

  handleMountDialog = (form) => {
    this.form = form;
  };

  handleSubmitDialog = (form) => {
    return Promise.all([this.props.dispatchEmail(form)]).then(() => {
      this.props.setVisibleState(false);
    });
  };

  handleSendDialog = () => {
    this.form.submit();
  };

  getCurrentUserEmail = () => {
    return this.props.currentUser ? this.props.currentUser['email'] : null;
  };

  onFocus = () => {
    this.setState({ focus: true });
  };

  closeDialog = () => {
    this.setState({ focus: false });
    this.props.onCancel();
  };

  renderFieldset = (props) => {
    const { visible, customerListCustomer } = this.props;
    const { values, handleChange, handleBlur, handleSubmit, isSubmitting } =
      props;

    return (
      <>
        <Dialog
          open={visible}
          TransitionComponent={Transition}
          keepMounted
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          {customerListCustomer === 1 || !customerListCustomer ? (
            <DialogContent>
              {' '}
              <NotSelected>Customer is not selected yet. </NotSelected>
            </DialogContent>
          ) : (
            <div>
              <form onSubmit={handleSubmit}>
                <DialogContent>
                  <TextField
                    id="emailAddress"
                    label={<Translate>E-Mail Adresse</Translate>}
                    name="emailAddress"
                    defaultValue={values.emailAddress}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onClick={this.onFocus}
                    /*helperText={touched.name ? errors.name : ''}
                error={touched.name && Boolean(errors.name)}*/
                  />

                  <Input
                    type="hidden"
                    id="emailQueue"
                    name="emailQueue"
                    value={values.emailQueue}
                  />
                </DialogContent>
                <DialogActions>
                  <Button type="submit" color="primary" disabled={isSubmitting}>
                    Vorschau abschicken
                  </Button>
                  <Button onClick={this.closeDialog} color="primary">
                    schließen
                  </Button>
                </DialogActions>
              </form>
            </div>
          )}
        </Dialog>
      </>
    );
  };

  render() {
    const { values, OnMount, customerListCustomer } = this.props;
    const currentEmail = this.getCurrentUserEmail();

    if (customerListCustomer != null) {
      return (
        <Form
          onMount={OnMount}
          disableToolbar={true}
          onSubmit={this.handleSubmitDialog}
          initialValues={{
            emailAddress: currentEmail,
            emailQueue: customerListCustomer
              ? customerListCustomer.emailQueue
              : null,
          }}
          name="email_send"
          renderFieldset={this.renderFieldset}
          values={values}
        />
      );
    } else {
      return null;
    }
  }
}

function mapStateToProps(state, props) {
  const getCustomerListCustomerById = makeCustomerListCustomerById();
  const {
    entities: { currentuser, staff },
    resultsets: { result: resultset },
  } = state;

  if (!currentuser || !staff) {
    return {};
  }

  const current = staff[currentuser.current.staff];
  const customerListCustomer = getCustomerListCustomerById(state, props);

  return {
    values: {},
    currentUser: current,
    resultset: resultset,
    customerListCustomer: customerListCustomer,
  };
}

export default connect(mapStateToProps, { dispatchEmail, loadProfile })(
  SendEmailDialog,
);

import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  EntityId,
} from '@reduxjs/toolkit';
import axios from 'axios';
import { normalize, schema } from 'normalizr';
import { camelizeKeys } from 'humps';
import {
  GridFilterModel,
  GridPaginationModel,
  GridSortModel,
} from '@mui/x-data-grid';
import { Schemas } from '../../schemas';
import { CampaignConfigParam } from './CampaignConfigParamType';
import type { RootState } from '../../reducers';
import { Season } from '../Season/SeasonType';
import { GridParams } from '../../components/DataGrid/DataGridTypes';
import { convertGridParamsToRequestParams } from '../../components/DataGrid/EeloyDataGridUtils';
import { Campaign } from '../Campaign/CampaignType';

const campaignConfigParamAdapter = createEntityAdapter<CampaignConfigParam>({
  sortComparer: (a, b) => a.id - b.id,
});

export const fetchAllCampaignConfigParams = createAsyncThunk(
  'campaignConfigParam/fetchAll',
  async (data: { gridParams?: GridParams; campaignId?: EntityId } = {}) => {
    const { gridParams, campaignId } = data;

    const response = await axios.get(
      Routing.generate(
        campaignId
          ? 'campaignconfigparam_campaign'
          : 'campaignconfigparam_index',
        campaignId ? { id: campaignId } : null,
        true,
      ),
      {
        withCredentials: true,
        params: { ...convertGridParamsToRequestParams(gridParams) },
      },
    );
    const normalized = normalize<
      schema.Array<CampaignConfigParam>,
      {
        campaignConfigParam: Record<string, CampaignConfigParam>;
        season: Record<string, Season>;
        campaign: Record<string, Campaign>;
      }
    >(camelizeKeys(response.data.data), Schemas.CAMPAIGNCONFIGPARAMS);

    return {
      entities: normalized.entities,
      pagination: response.data.pagination,
      paginationModel: gridParams?.paginationModel,
      resultset: normalized.result,
      sortModel: gridParams?.sortModel,
      filterModel: gridParams?.filterModel,
    };
  },
);

export const fetchCampaignConfigParamById = createAsyncThunk(
  'campaignConfigParam/fetchById',
  async (data: { id: EntityId }) => {
    const { id } = data;

    const response = await axios.get(
      Routing.generate('campaignconfigparam_show', { id }, true),
      { withCredentials: true },
    );
    const normalized = normalize<
      schema.Entity<CampaignConfigParam>,
      {
        campaignConfigParam: Record<string, CampaignConfigParam>;
        season: Record<string, Season>;
      }
    >(camelizeKeys(response.data.data), Schemas.CAMPAIGNCONFIGPARAM);

    return { entities: normalized.entities };
  },
);

export const fetchToken = createAsyncThunk(
  'campaignConfigParam/token',
  async () => {
    const response = await axios.get(
      Routing.generate('campaignconfigparam_token', null, true),
      { withCredentials: true },
    );

    return response.data;
  },
);

export const createCampaignConfigParam = createAsyncThunk(
  'campaignConfigParam/create',
  async ({ data }: { data: FormData }) => {
    const response = await axios.post(
      Routing.generate('campaignconfigparam_create', null, true),
      data,
      { withCredentials: true },
    );

    return {
      statusCode: response.status,
      createdId: response.data.id,
      location: response.headers.location,
    };
  },
);

export const updateCampaignConfigParam = createAsyncThunk(
  'campaignConfigParam/write',
  async ({ data, id }: { data: FormData; id: number }) => {
    const response = await axios.post(
      Routing.generate('campaignconfigparam_update', { id }, true),
      data,
      { withCredentials: true },
    );

    return { statusCode: response.status };
  },
);

export const deleteCampaignConfigParam = createAsyncThunk(
  'campaignConfigParam/delete',
  async ({ id, deleteToken }: { id: number; deleteToken: string }) => {
    await axios.delete(
      Routing.generate('campaignconfigparam_delete', { id, deleteToken }, true),
      { withCredentials: true },
    );

    return id;
  },
);

interface SliceState {
  updateToken?: string | null;
  resultset?: number[];
  paginationModel?: GridPaginationModel;
  sortModel?: GridSortModel;
  filterModel?: GridFilterModel;
  pagination?: {
    total: number;
    limit: number;
    offset: number;
  };
}

const initialState: SliceState = {
  updateToken: null,
};

const campaignConfigParamSlice = createSlice({
  name: 'campaignConfigParam',
  initialState: campaignConfigParamAdapter.getInitialState(initialState),
  reducers: {
    removeUpdateToken(state) {
      state.updateToken = null;
    },
    resetDataGridOptions: (state) => {
      if (state.filterModel || state.sortModel) {
        delete state.filterModel;
        delete state.sortModel;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllCampaignConfigParams.fulfilled, (state, action) => {
      const { campaignConfigParam } = action.payload.entities;

      if (campaignConfigParam) {
        campaignConfigParamAdapter.upsertMany(state, campaignConfigParam);
      }

      state.paginationModel = action.payload.paginationModel;
      state.filterModel = action.payload.filterModel;
      state.sortModel = action.payload.sortModel;
      state.resultset = action.payload.resultset;
      state.pagination = action.payload.pagination;
    });
    builder.addCase(fetchCampaignConfigParamById.fulfilled, (state, action) => {
      const { campaignConfigParam } = action.payload.entities;

      if (campaignConfigParam) {
        campaignConfigParamAdapter.upsertMany(state, campaignConfigParam);
      }
    });
    builder.addCase(fetchToken.fulfilled, (state, action) => {
      state.updateToken = action.payload.results.updateToken;
    });
    builder.addCase(fetchToken.rejected, (state) => {
      state.updateToken = null;
    });
    builder.addCase(updateCampaignConfigParam.fulfilled, (state) => {
      state.updateToken = null;
    });
    builder.addCase(createCampaignConfigParam.fulfilled, (state) => {
      state.updateToken = null;
    });
    builder.addCase(deleteCampaignConfigParam.fulfilled, (state, action) => {
      campaignConfigParamAdapter.removeOne(state, action.payload);

      if (state.resultset) {
        const index: number = state.resultset.findIndex(
          (item) => item === action.payload,
        );

        if (index > -1) {
          state.resultset.splice(index, 1);
        }
      }
    });
  },
});

export const {
  selectAll: selectAllCampaignConfigParams,
  selectById: selectCampaignConfigParamById,
  selectIds: selectCampaignConfigParamIds,
} = campaignConfigParamAdapter.getSelectors<RootState>(
  (state) => state.campaignConfigParam,
);

export const { removeUpdateToken, resetDataGridOptions } =
  campaignConfigParamSlice.actions;

export default campaignConfigParamSlice.reducer;

import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';
import axios from 'axios';
import { normalize, schema } from 'normalizr';
import { camelizeKeys } from 'humps';
import { Season } from './SeasonType';
import { Schemas } from '../../schemas';
import {
  fetchAllMailingModules,
  fetchMailingModuleById,
} from '../MailingModule/MailingModuleSlice';
import type { RootState } from '../../reducers';
import { fetchAllEmails } from '../Email/EmailSlice';

const seasonAdapter = createEntityAdapter<Season>({
  sortComparer: (a, b) => a.id - b.id,
});

export const fetchAllSeasons = createAsyncThunk('season/fetchAll', async () => {
  const response = await axios.get(
    Routing.generate('season_index', null, true),
    { withCredentials: true },
  );
  const normalized = normalize<
    schema.Entity<Season>,
    {
      season: Record<string, Season>;
    }
  >(camelizeKeys(response.data), Schemas.SEASONS);

  return { entities: normalized.entities };
});

const seasonSlice = createSlice({
  name: 'season',
  initialState: seasonAdapter.getInitialState({}),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAllSeasons.fulfilled, (state, action) => {
      const { season } = action.payload.entities;

      if (season) {
        seasonAdapter.upsertMany(state, season);
      }
    });
    builder.addCase(fetchAllMailingModules.fulfilled, (state, action) => {
      const { season } = action.payload.entities;

      if (season) {
        seasonAdapter.upsertMany(state, season);
      }
    });
    builder.addCase(fetchMailingModuleById.fulfilled, (state, action) => {
      const { season } = action.payload.entities;

      if (season) {
        seasonAdapter.upsertMany(state, season);
      }
    });
    builder.addCase(fetchAllEmails.fulfilled, (state, action) => {
      const { season } = action.payload.entities;

      if (season) {
        seasonAdapter.upsertMany(state, season);
      }
    });
  },
});

export const {
  selectAll: selectAllSeasons,
  selectById: selectSeasonById,
  selectIds: selectSeasonsByIds,
} = seasonAdapter.getSelectors<RootState>((state) => state.season);

export default seasonSlice.reducer;

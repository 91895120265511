import selectors from '../../selectors';
import { pickBy, merge, filter, uniqBy } from 'lodash';

const getSeasons = (state) => state.entities.season;
const getUploads = (state) => state.entities.upload;
const getTags = (state) => state.entities.tag;
const getSeasonFilter = (state, props) =>
  props.seasonId ? parseInt(props.seasonId) : null;

export const makeSeasonById = () => {
  return selectors([getSeasonFilter, getSeasons], (seasonId, seasons) => {
    return seasons && seasons[seasonId] ? seasons[seasonId] : null;
  });
};

export const makeChildableBySeason = () => {
  return selectors([getSeasonFilter, getSeasons], (seasonId, seasons) => {
    // seasons without parent
    const without = pickBy(seasons, { parent: null });
    // seasons with current season parent
    const children = pickBy(seasons, { parent: seasonId });

    const childable = merge({}, children, without);
    delete childable[seasonId];

    return childable;
  });
};

export const makeUploadsBySeason = () => {
  return selectors([getSeasonFilter, getUploads], (seasonId, uploads) => {
    return pickBy(uploads, { season: seasonId });
  });
};

const getUploadsBySeason = makeUploadsBySeason();
export const makeTagsBySeason = () => {
  return selectors([getUploadsBySeason, getTags], (uploads, tags) => {
    return tags
      ? filter(tags, function (t) {
          return uploads[t.upload] !== undefined;
        })
      : [];
  });
};

const getTagsBySeason = makeTagsBySeason();
export const makeUniqueCampaignDateSelection = () => {
  return selectors([getTagsBySeason], (tags) => {
    return tags ? uniqBy(tags, 'campaignDate') : [];
  });
};

const getSeasonTypeFilter = (state, props) =>
  props.seasonType ? props.seasonType : null;

export const makeSeasonsByType = () => {
  return selectors(
    [getSeasonTypeFilter, getSeasons],
    (seasonTypeFilter, seasons) => {
      return seasons && seasonTypeFilter
        ? pickBy(seasons, { type: seasonTypeFilter })
        : [];
    },
  );
};

const getPrintableFilter = (state, props) =>
  props?.match?.params?.printable ?? true;
/** @deprecated Use `makeSeasonsByPrintable` from `src/components/Season/selectors.ts` instead */
export const makeSeasonsByPrintable = () => {
  return selectors([getPrintableFilter, getSeasons], (printable, seasons) => {
    return seasons ? filter(seasons, { printable: printable }) : [];
  });
};

export const makeProductSeasonByType = () => {
  return selectors([getSeasons], (seasons) => {
    const emails = pickBy(seasons, { type: 'email' });
    const mailings = pickBy(seasons, { type: 'print' });

    return merge({}, emails, mailings);
  });
};

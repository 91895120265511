import React from 'react';
import PropTypes from 'prop-types';
import { TextField, Input } from '@mui/material';
import Translate from '../../../components/service/Translate';
import Form from '../../../components/Form';

const CallingForm = (props) => {
  const { onSubmit } = this.props;
  const { values, handleBlur, handleSubmit, errors, touched } = props;

  const renderFieldset = (values) => {
    return (
      <>
        <form onSubmit={handleSubmit}>
          <h3>Basisdaten</h3>
          <TextField
            id="config_key"
            label={<Translate>Konfigschlüssel</Translate>}
            name="config_key"
            onBlur={handleBlur}
            helperText={touched.name ? errors.name : ''}
            error={touched.name && Boolean(errors.name)}
            fullWidth
          />
          <TextField
            id="crm_id"
            label={<Translate>CRM ID</Translate>}
            name="crm_id"
            onBlur={handleBlur}
            helperText={touched.name ? errors.name : ''}
            error={touched.name && Boolean(errors.name)}
            fullWidth
          />
          <TextField
            id="fee_call"
            label={<Translate>Telefonkosten</Translate>}
            name="fee_call"
            onBlur={handleBlur}
            helperText={touched.name ? errors.name : ''}
            error={touched.name && Boolean(errors.name)}
            fullWidth
          />
          <TextField
            id="attempts"
            label={<Translate>Versuche</Translate>}
            name="attempts"
            onBlur={handleBlur}
            helperText={touched.name ? errors.name : ''}
            error={touched.name && Boolean(errors.name)}
            fullWidth
          />
          <Input
            type="hidden"
            id="campaign_id"
            name="campaign_id"
            value={values.season.campaignId}
          />
        </form>
      </>
    );
  };
  return (
    <Form
      initialValues={{}}
      values={values}
      onSubmit={onSubmit}
      name="calling"
      renderFieldset={renderFieldset}
    />
  );
};

CallingForm.propTypes = {
  values: PropTypes.object,
  onSubmit: PropTypes.func,
};

export default CallingForm;

import { schema } from 'normalizr';

export const Selection = new schema.Entity(
  'selection',
  {},
  { idAttribute: (selection) => selection.id },
);
export const SelectionCriterion = new schema.Entity(
  'selectionCriterion',
  {},
  { idAttribute: (selectionCriterion) => selectionCriterion.id },
);

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { pickBy, isEmpty } from 'lodash';
import {
  Paper,
  Toolbar,
  Divider,
  MenuItem,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Menu,
  Box,
} from '@mui/material';
import ContentAdd from '@mui/icons-material/Add';
import ACL from '../../components/ACL';
import BreadcrumbsNav from '../../components/BreadcrumbNavigation';
import CallingProperties from '../Season/components/CallingProperties';
import SeasonList from '../Season/components/SeasonList';
import { fetchCampaign } from './actions';
import { outdateEntity } from '../../actions';
import { disableSeason, enableSeason } from '../Season/actions';
import {
  getCampaignByIdSelector,
  makeCallingSeasonsByCampaign,
} from './selectors_deprecated';
import {
  getAnswerOptionsSelector,
  getQuestionsSelector,
} from '../Question/selectors';
import ErrorBoundary from '../../components/ErrorBoundary';
import { withRouter } from '../../withRouter';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import {
  ColorActions,
  Spacer,
  Title,
  AccentColorButton,
} from '../../components/StyledElements/StyledElements';
import { compose } from 'redux';

class CampaignShow extends Component {
  static propTypes = {
    fetchCampaign: PropTypes.func.isRequired,
    enableSeason: PropTypes.func.isRequired,
    disableSeason: PropTypes.func.isRequired,
    outdateEntity: PropTypes.func.isRequired,
    requiredRoles: PropTypes.array,
    campaignId: PropTypes.number,
    campaign: PropTypes.object,
  };

  static defaultProps = {
    requiredRoles: ['ROLE_PRO', 'ROLE_SUPERADMIN'],
  };

  state = {
    anchorEl: null,
  };

  componentDidMount = () => {
    const {
      match: {
        params: { campaignId },
      },
      fetchCampaign,
    } = this.props;
    fetchCampaign(campaignId, [
      { seasons: ['mailing', 'email', 'calling', 'sms'] },
    ]);
  };

  handleMenuClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
  };

  handleSeasonToggle = (seasonId, isInputChecked) => {
    const {
      disableSeason,
      enableSeason,
      fetchCampaign,
      campaignId,
      outdateEntity,
    } = this.props;
    const promises = [];

    let form = new FormData();
    form.append('id', seasonId);

    promises.push(isInputChecked ? enableSeason(form) : disableSeason(form));

    Promise.all(promises).then(() => {
      outdateEntity('season');
      fetchCampaign(campaignId, [
        { seasons: ['mailing', 'email', 'calling', 'sms'] },
      ]);
    });
  };

  createNewSeason = (mode) => {
    this.props.navigate(
      '/campaign/' + this.props.campaignId + '/' + mode + '/new',
    );
  };

  editSeason = (mode) => {
    this.props.navigate(
      '/campaign/' + this.props.campaignId + '/' + mode + '/edit',
    );
  };

  handleEditClick = () => {
    this.props.navigate('/campaign/' + this.props.campaignId + '/edit');
  };

  renderHeader = (campaign) => {
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);

    const callback = (event, value) => {
      this.props.navigate(
        '/campaign/' + this.props.campaignId + '/' + value + '/new',
      );
    };

    const EmailMenuItem = (
      <MenuItem value="email" onClick={(e) => callback(e, 'email')}>
        <ListItemIcon>
          <ContentAdd color="inherit" />
        </ListItemIcon>
        <ListItemText primary="neue E-Mail" />
      </MenuItem>
    );

    const SMSMenuItem = (
      <MenuItem value="sms" onClick={(e) => callback(e, 'sms')}>
        <ListItemIcon>
          <ContentAdd color="inherit" />
        </ListItemIcon>
        <ListItemText primary="neue SMS" />
      </MenuItem>
    );

    return (
      <>
        <Toolbar>
          <Title>
            <BreadcrumbsNav
              items={[
                { link: '/#/campaign', text: 'Kampagnen' },
                campaign.title,
              ]}
            />
          </Title>
          <Spacer />
          <ColorActions>
            <AccentColorButton
              sx={{ mr: 3 }}
              variant="contained"
              color="primary"
              aria-owns={open ? 'render-props-menu' : null}
              aria-haspopup="true"
              onClick={this.handleEditClick}
            >
              <EditIcon />
            </AccentColorButton>
            <AccentColorButton
              variant="contained"
              color="primary"
              aria-owns={open ? 'render-props-menu' : null}
              aria-haspopup="true"
              onClick={this.handleMenuClick}
            >
              <AddIcon />
            </AccentColorButton>
            <Menu
              id="long-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={this.handleMenuClose}
            >
              {EmailMenuItem}
              {SMSMenuItem}
            </Menu>
          </ColorActions>
        </Toolbar>
      </>
    );
  };

  renderCallingSeasons = (seasons) => {
    if (!seasons.length === 0) {
      return <div>Keine Calling vorhanden</div>;
    }

    return <div>Das Modul Telefonie wird nicht mehr unterstützt.</div>;
  };

  renderQuestionnaire = (questionnaire) => {
    if (questionnaire.length === 0) {
      return null;
    }

    const { questionList } = this.props;

    return [
      // eslint-disable-next-line react/jsx-key
      <Box
        component="h3"
        sx={{ pt: (theme) => theme.spacingConstants.desktopGutter }}
      >
        Fragebogen
      </Box>,

      // eslint-disable-next-line react/jsx-key
      <ErrorBoundary>
        <List
          component="nav"
          sx={{
            mt: (theme) => theme.spacingConstants.desktopGutter,
            borderStyle: 'solid',
            borderWidth: '1px',
          }}
        >
          {questionnaire.map((questionId, index) => {
            const prefix = 'Frage ' + (index + 1) + ': ';
            const question = pickBy(questionList, { id: questionId });
            if (!isEmpty(question)) {
              const answers = question.answers;
              const listitem = (
                <ListItem
                  button
                  key={index + 1}
                  initiallyOpen={false}
                  nestedItems={answers.map((answer, index) => {
                    const answerOption = answer.answerOption;
                    const prefix = 'Antwort ' + (index + 1) + ': ';
                    return (
                      <ListItem key={index + 1}>
                        <ListItemText
                          inset
                          primary={prefix + answerOption.title}
                        />
                      </ListItem>
                    );
                  })}
                >
                  <ListItemText inset primary={prefix + question.title} />
                </ListItem>
              );
              // eslint-disable-next-line no-undef,react/jsx-key
              return questions.length === index + 1
                ? listitem
                : [listitem, <Divider key={questionId} />];
            }
          })}
        </List>
      </ErrorBoundary>,
    ];
  };

  renderMailingSeasons = () => {
    return (
      <ErrorBoundary>
        <SeasonList seasonType="print" onToggle={this.handleSeasonToggle} />
      </ErrorBoundary>
    );
  };

  renderEmailSeasons = () => {
    return (
      <ErrorBoundary>
        <SeasonList seasonType="email" onToggle={this.handleSeasonToggle} />
      </ErrorBoundary>
    );
  };

  renderSmsSeasons = () => {
    return (
      <ErrorBoundary>
        <SeasonList seasonType="sms" onToggle={this.handleSeasonToggle} />
      </ErrorBoundary>
    );
  };

  render() {
    const { campaign, campaignId, callingSeasons } = this.props;
    if (!campaign) {
      return null;
    }
    let campaignById = campaign[campaignId];
    return (
      <ACL authorized={this.props.requiredRoles}>
        {this.renderHeader(campaignById)}
        {!isEmpty(callingSeasons) && this.renderCallingSeasons(callingSeasons)}
        {this.renderMailingSeasons()}
        {this.renderEmailSeasons()}
        {this.renderSmsSeasons()}
      </ACL>
    );
  }
}

const makeMapStateToProps = () => {
  const getCampaignById = getCampaignByIdSelector();
  const getQuestions = getQuestionsSelector();
  const getAnswerOptions = getAnswerOptionsSelector();
  const getCallingSeasonsByCampaign = makeCallingSeasonsByCampaign();

  return (state, props) => {
    return {
      campaignId: parseInt(props?.match?.params?.campaignId),
      campaign: getCampaignById(state, props),
      questionList: getQuestions(state, props),
      answerOptionList: getAnswerOptions(state, props),
      season: state.entities.season,
      callingSeasons: getCallingSeasonsByCampaign(state, props),
    };
  };
};

const enhance = compose(
  withRouter,
  connect(makeMapStateToProps, {
    fetchCampaign,
    disableSeason,
    enableSeason,
    outdateEntity,
  }),
);

export default enhance(CampaignShow);

import selectors from '../../selectors';
import { intersectionWith, pickBy } from 'lodash';

const getCustomerListCustomer = (state) => state.entities.customerListCustomer;
const getSmsJobs = (state) => state.entities.smsJob;
const getEmailJobFilter = (state, props) =>
  props.jobId
    ? parseInt(props.jobId)
    : props?.match?.params?.seasonJobId
      ? parseInt(props.match.params.seasonJobId)
      : props.seasonJobId
        ? parseInt(props.seasonJobId)
        : null;
const getCustomerListCustomerFilter = (state, props) =>
  props.customerListCustomerId ? parseInt(props.customerListCustomerId) : null;

export const makeSmsJobById = () => {
  return selectors([getEmailJobFilter, getSmsJobs], (smsJobId, smsJobs) => {
    return smsJobs && smsJobs[smsJobId] ? smsJobs[smsJobId] : null;
  });
};

const getSmsJobById = makeSmsJobById();

export const makeCustomerListCustomerByJob = () => {
  return selectors(
    [getSmsJobById, getCustomerListCustomer],
    (smsJob, customerListCustomers) => {
      return customerListCustomers && smsJob
        ? pickBy(customerListCustomers, {
            customerList: smsJob.customerList,
          })
        : null;
    },
  );
};

export const makeCustomerListCustomerById = () => {
  return selectors(
    [getCustomerListCustomerFilter, getCustomerListCustomer],
    (customerListCustomerId, customerListCustomers) => {
      return customerListCustomers &&
        customerListCustomers[customerListCustomerId]
        ? customerListCustomers[customerListCustomerId]
        : null;
    },
  );
};

const getCustomerLists = (state) => state.entities.customerList;

export const makeCustomerListByJob = () => {
  return selectors([getSmsJobById, getCustomerLists], (job, customerLists) => {
    return customerLists && job && job.customerList
      ? customerLists[job.customerList]
      : {};
  });
};

const getCustomerListByJob = makeCustomerListByJob();

export const makeCustomerListCustomerByCl = () => {
  return selectors(
    [getCustomerListByJob, getCustomerListCustomer],
    (customerList, customerListCustomer) => {
      if (customerList && customerListCustomer) {
        return intersectionWith(
          customerList.customerListCustomers,
          Object.keys(customerListCustomer),
          (a, b) => {
            return a == b;
          },
        ).sort();
      }
    },
  );
};

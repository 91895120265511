import React, { Component } from 'react';
import { TextField } from '@mui/material';

import Translate from '../../../components/service/Translate';
import Form from '../../../components/Form';
import * as Yup from 'yup';
import ValidationErrors from '../../../ValidationErrors';
import Grid from '@mui/material/Unstable_Grid2';

const validationSchema = Yup.object().shape({
  type: Yup.string(),
  label: Yup.string().required(ValidationErrors.required),
  description: Yup.string().required(ValidationErrors.required),
  before: Yup.string().required(ValidationErrors.required),
  after: Yup.string().required(ValidationErrors.required),
});

class DisplayConditionForm extends Component {
  static defaultProps = {
    values: {
      type: '',
      after: '{% endif %}',
    },
  };

  state = {};

  renderFieldset = (props) => {
    const { values, handleChange, handleBlur, touched, errors } = props;

    return (
      <Grid container>
        <Grid xs={12} sm={6}>
          <TextField
            type="hidden"
            name="after"
            value={values.after ? values.after : '{% endif %}'}
          />
        </Grid>
        <Grid xs={12} sm={6}>
          <TextField
            type="hidden"
            name="type"
            value={values.type ? values.type : ''}
          />
        </Grid>
        <Grid xs={12}>
          <TextField
            label={<Translate>Name</Translate>}
            name="label"
            defaultValue={values.label ? values.label : ''}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={touched.label ? errors.label : ''}
            error={touched.label && Boolean(errors.label)}
          />
        </Grid>
        <Grid xs={12}>
          <TextField
            label={<Translate>Description</Translate>}
            name="description"
            defaultValue={values.description ? values.description : ''}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={touched.description ? errors.description : ''}
            error={touched.description && Boolean(errors.description)}
            multiline
          />
        </Grid>
        <Grid xs={12}>
          <TextField
            label={<Translate>Condition</Translate>}
            name="before"
            defaultValue={values.before ? values.before : ''}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={touched.before ? errors.before : ''}
            error={touched.before && Boolean(errors.before)}
            multiline
          />
        </Grid>
      </Grid>
    );
  };

  render() {
    const { onSubmit, onCancel } = this.props;
    const { ...defaultValues } = this.props.values;

    return (
      <Form
        validationSchema={validationSchema}
        values={defaultValues}
        initialValues={defaultValues}
        onSubmit={onSubmit}
        onCancel={onCancel}
        name="display_conditions"
        renderFieldset={this.renderFieldset}
      />
    );
  }
}

export default DisplayConditionForm;

import selectors from '../../selectors';
import { intersectionWith, pickBy, pick, merge } from 'lodash';

const getAffiliates = (state) => state.entities.affiliate;
const getAffiliateSettings = (state) => state.entities.affiliatesetting;

const getParentFilter = (state, props) =>
  props.affiliateId ? parseInt(props.affiliateId) : null;
const getAffiliateFilter = (state, props) =>
  props.affiliateId ? parseInt(props.affiliateId) : null;

export const makeAffiliatesByParent = () => {
  return selectors([getParentFilter, getAffiliates], (parentId, affiliates) => {
    return pickBy(affiliates, { parent: parentId });
  });
};

export const makeAffiliateById = () => {
  return selectors(
    [getAffiliateFilter, getAffiliates],
    (affiliateId, affiliates) => {
      return affiliates[affiliateId];
    },
  );
};

export const makeSettingsByAffiliate = () => {
  return selectors(
    [getAffiliateFilter, getAffiliateSettings, getAffiliates],
    (affiliateId, settings, affiliate) => {
      if (affiliateId && settings && affiliate) {
        return intersectionWith(
          affiliate[affiliateId].setting,
          Object.keys(settings),
          (a, b) => {
            return a == parseInt(b);
          },
        );
      }
    },
  );
};

const getAffiliateById = makeAffiliateById();
export const makeAffiliateWithChildren = () => {
  return selectors(
    [getAffiliateById, getAffiliates],
    (affiliate, affiliates) => {
      if (affiliate && affiliate.children) {
        let affiliateWithChildren = merge({}, affiliate);
        affiliateWithChildren.children = Object.values(
          pick(affiliates, affiliate.children),
        );

        return affiliateWithChildren;
      } else {
        return affiliate;
      }
    },
  );
};

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import List from './components/List';
import Edit from './components/Edit';
import { isEqual } from 'lodash';
import ErrorBoundary from '../../components/ErrorBoundary';
import { withRouter } from '../../withRouter';
import { compose } from 'redux';

class CampaignConfigParam extends Component {
  static propTypes = {
    mode: PropTypes.string,
  };

  // eslint-disable-next-line no-unused-vars
  shouldComponentUpdate = (nextProps, nextState) => {
    return (
      !isEqual(nextProps.mode, this.props.mode) ||
      !isEqual(
        nextProps.campaignConfigParamId,
        this.props.campaignConfigParamId,
      )
    );
  };

  render() {
    const { mode, campaignConfigParamId } = this.props;

    switch (mode) {
      case 'new':
      case 'edit':
      case 'duplicate':
        if (campaignConfigParamId === 'multiple') {
          return (
            <ErrorBoundary>
              <List />
            </ErrorBoundary>
          );
        }
        return (
          <ErrorBoundary>
            <Edit />
          </ErrorBoundary>
        );
      default:
        return (
          <ErrorBoundary>
            <List />
          </ErrorBoundary>
        );
    }
  }
}

// eslint-disable-next-line no-unused-vars
function makeMapStateToProps(state, ownProps) {
  // eslint-disable-next-line no-unused-vars
  return (state, props) => {
    const mode = props?.match?.params?.mode;
    const campaignConfigParamId = props.match.params.campaignConfigParamId;

    return {
      mode: mode,
      campaignConfigParamId: campaignConfigParamId,
    };
  };
}

const enhance = compose(withRouter, connect(makeMapStateToProps, {}));

export default enhance(CampaignConfigParam);

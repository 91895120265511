import logger from 'redux-logger';
import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './reducers';
import api from './middleware';

export function initStore() {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => {
      const middleware = getDefaultMiddleware({
        serializableCheck: false,
      }).prepend(api);

      if (process.env.NODE_ENV !== 'production') {
        middleware.push(logger);
      }

      return middleware;
    },
    devTools: process.env.NODE_ENV !== 'production',
  });
}

type Store = ReturnType<typeof initStore>;

export type AppDispatch = Store['dispatch'];

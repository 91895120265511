import { CALL_API } from '../../middleware';
import { Schemas } from '../../schemas';
import { sample } from 'lodash';

export const SELECTIONS_REQUEST = 'SELECTIONS_REQUEST';
export const SELECTIONS_SUCCESS = 'SELECTIONS_SUCCESS';
export const SELECTIONS_FAILURE = 'SELECTIONS_FAILURE';

function fetchSelections(sortColumns, sortOrders, seasonId = null) {
  return {
    [CALL_API]: {
      types: [SELECTIONS_REQUEST, SELECTIONS_SUCCESS, SELECTIONS_FAILURE],
      endpoint: Routing.generate(
        'selection_list',
        {
          sortColumns: sortColumns,
          sortOrders: sortOrders,
          seasonId: seasonId,
        },
        true,
      ),
      schema: Schemas.SELECTIONS,
    },
  };
}

export function loadSelections(
  sortColumns = [],
  sortOrders = [],
  requiredFields = [],
  seasonId = null,
) {
  return (dispatch, getState) => {
    const selection = getState().entities.selection;
    const resultset = getState().resultsets.selection;
    const outdated = getState().outdated.selection;

    if (
      !outdated &&
      resultset &&
      resultset.length &&
      selection &&
      requiredFields.every((key) => sample(selection).hasOwnProperty(key))
    ) {
      return null;
    }

    return dispatch(fetchSelections(sortColumns, sortOrders, seasonId));
  };
}

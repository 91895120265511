import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { concat, isEmpty, isEqual } from 'lodash';
import { Paper, Toolbar, IconButton, TextField, Input } from '@mui/material';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ContentAdd from '@mui/icons-material/Add';
import ACL from '../../components/ACL';
import BreadcrumbsNav from '../../components/BreadcrumbNavigation';
import { postForm, initForm, fetchCampaign, updateCampaign } from './actions';
import Translate from '../../components/service/Translate';
import ErrorBoundary from '../../components/ErrorBoundary';
import { createBrowserHistory } from 'history';
import Form from '../../components/Form';
import Upload from '../../components/form/Upload';
import * as Yup from 'yup';
import ValidationErrors from '../../ValidationErrors';
import { makeCampaignWithConfigFiles } from './selectors_deprecated';
import CampaignConfigFile from './components/CampaignConfigFile';
import { withRouter } from '../../withRouter';
import { DatePicker } from '@mui/x-date-pickers';
import {
  AccentColorButton,
  ColorActions,
  Spacer,
  Title,
} from '../../components/StyledElements/StyledElements';
import Grid from '@mui/material/Unstable_Grid2';
import { compose } from 'redux';
import { parseDate } from '../../utils/HelperFunctions';

const browserHistory = createBrowserHistory();

const validationSchema = Yup.object().shape({
  title: Yup.string().required(ValidationErrors.required),
  description: Yup.string().required(ValidationErrors.required),
  crmId: Yup.string()
    .nullable()
    .when(['isChild'], {
      is: (isChild) => isChild,
      then: () => Yup.string().required(ValidationErrors.required),
      otherwise: () => Yup.string().nullable(),
    }),
  referenceDate: Yup.string().nullable(),
  campaignKey: Yup.string()
    .nullable()
    .when(['isChild'], {
      is: (isChild) => isChild,
      then: () => Yup.string().nullable(),
      otherwise: () => Yup.string().required(ValidationErrors.required),
    }),
  campaignConfigFiles: Yup.array()
    .of(
      Yup.object().shape({
        type: Yup.string()
          .oneOf(['excel', 'pdf', 'image'], ValidationErrors.requiredSelect)
          .required(ValidationErrors.requiredSelect),
        crmKey: Yup.string().nullable(),
        label: Yup.string().required(ValidationErrors.required),
        configKey: Yup.string().required(ValidationErrors.required),
        required: Yup.bool(),
        description: Yup.string().nullable(),
      }),
    )
    .test('uniqueConfigKey', 'Message', function (value) {
      let configKeys = [];
      let duplicates = [];

      if (!value) {
        return true;
      }

      for (let i = 0; i < value.length; i++) {
        if (configKeys.includes(value[i].configKey)) {
          duplicates.push(value[i].configKey);
        }
        configKeys.push(value[i].configKey);
      }
      return isEmpty(duplicates)
        ? true
        : this.createError({
            message: `${duplicates.map((val) => ' ' + val + ' ')}`,
            path: 'campaignConfigFilesCheck',
          });
    }),
});

class Edit extends Component {
  static propTypes = {
    requiredRoles: PropTypes.array,
  };

  static defaultProps = {
    requiredRoles: ['ROLE_PRO', 'ROLE_SUPERADMIN'],
    values: {
      title: '',
      description: '',
    },
  };

  state = {
    campaignConfigFiles: [],
  };

  componentDidMount = () => {
    if (this.props.campaignId) {
      this.setState({ campaignLoading: true });
      Promise.all([this.props.fetchCampaign(this.props.campaignId)]).then(
        // eslint-disable-next-line no-unused-vars
        (value) => {
          this.setState({
            campaignLoading: false,
            campaignConfigFiles: Object.keys(this.props.campaignConfigFiles),
          });
        },
      );
    } else {
      this.props.initForm();
    }
  };

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState) {
    if (this.props.campaignId !== prevProps.campaignId) {
      this.props.fetchCampaign(this.props.campaignId);
    }

    if (
      !isEqual(this.props.campaignConfigFiles, prevProps.campaignConfigFiles)
    ) {
      this.setState({
        campaignConfigFiles: Object.keys(this.props.campaignConfigFiles),
      });
    }
  }

  handleSubmit = (form) => {
    const { campaignId } = this.props;

    if (campaignId) {
      return Promise.all([this.props.updateCampaign(form, campaignId)]).then(
        (values) => {
          if (
            !(
              values &&
              values[0] &&
              values[0].type &&
              values[0].type.match(/_FAILURE/)
            )
          ) {
            this.props.navigate('/campaign/');
          }
        },
      );
    } else {
      return Promise.all([this.props.postForm(form)]).then((values) => {
        if (
          !(
            values &&
            values[0] &&
            values[0].type &&
            values[0].type.match(/_FAILURE/)
          )
        ) {
          this.props.navigate('/campaign/');
        }
      });
    }
  };

  handleCancel = () => {
    browserHistory.back();
  };

  renderHeader = () => {
    const { campaignId, campaign } = this.props;

    const title = campaignId ? campaign.primaryText : 'Neue Kampagne';

    return (
      <>
        <Toolbar>
          <Title>
            <BreadcrumbsNav
              items={[{ link: '/#/campaign', text: 'Kampagnen' }, title]}
            />
          </Title>
          <Spacer />
          <ColorActions>
            <AccentColorButton
              variant="contained"
              color="primary"
              onClick={this.handleCancel}
            >
              <ChevronLeft />
              <Translate>Zurück</Translate>
            </AccentColorButton>
          </ColorActions>
        </Toolbar>
      </>
    );
  };

  renderCampaignConfigFiles = (props) => {
    const { campaignConfigFiles: campaignConfigFilesState, campaignLoading } =
      this.state;

    if (campaignLoading) {
      return (
        <span>
          <h4>
            <Translate>Configuration files</Translate>
          </h4>
          <Translate>Data loading...</Translate>
        </span>
      );
    }

    return (
      <Grid xs={12}>
        <h4>
          <Translate>Configuration files</Translate>
          <IconButton
            aria-label={<Translate>add</Translate>}
            onClick={this.handleConfigFileAdd}
          >
            <ContentAdd />
          </IconButton>
        </h4>
        {campaignConfigFilesState.map((key, index) => {
          return this.renderCampaignConfigFile(props, key, index);
        })}
      </Grid>
    );
  };

  renderCampaignConfigFile = (props, key, index) => {
    const { submitCount } = props;
    const values =
      typeof key === 'string' &&
      -1 !== key.indexOf('new_') &&
      (typeof props.values?.campaignConfigFiles?.[index] === 'undefined' ||
        isEmpty(props.values?.campaignConfigFiles?.[index]))
        ? {}
        : props.values.campaignConfigFiles[index];

    return (
      <CampaignConfigFile
        formikProps={props}
        onRemove={this.handleConfigFileRemove}
        onAdd={this.handleConfigFileAdd}
        values={values}
        index={index}
        key={`campaignConfigFile-${index}`}
        submitCount={submitCount}
        newFile={typeof key === 'string' && -1 !== key.indexOf('new_')}
      />
    );
  };

  handleConfigFileAdd = () => {
    this.setState({
      campaignConfigFiles: concat(this.state.campaignConfigFiles, [
        `new_${this.state.campaignConfigFiles.length + 1}`,
      ]),
    });
  };

  handleConfigFileRemove = (childId) => {
    let newConfigFiles = this.state.campaignConfigFiles;
    newConfigFiles.splice(childId, 1);
    this.setState({ campaignConfigFiles: newConfigFiles });
  };

  renderFieldset = (props) => {
    const { values, handleChange, handleBlur, touched, errors, setFieldValue } =
      props;

    return (
      <>
        <Input
          type="hidden"
          name="updateToken"
          defaultValue={values.updateToken ? values.updateToken : ''}
        />
        <Grid container>
          <Grid xs={12} sm={6}>
            <TextField
              label={<Translate>Titel</Translate>}
              name="title"
              onBlur={handleBlur}
              onChange={handleChange}
              defaultValue={values.title ? values.title : ''}
              helperText={touched.title ? errors.title : ''}
              error={touched.title && Boolean(errors.title)}
              fullWidth
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <TextField
              label={<Translate>Beschreibung</Translate>}
              name="description"
              onBlur={handleBlur}
              onChange={handleChange}
              defaultValue={values.description ? values.description : ''}
              helperText={touched.description ? errors.description : ''}
              error={touched.description && Boolean(errors.description)}
              fullWidth
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <TextField
              label={<Translate>campaign key</Translate>}
              name="campaignKey"
              onBlur={handleBlur}
              onChange={handleChange}
              defaultValue={values.campaignKey ? values.campaignKey : ''}
              helperText={touched.campaignKey ? errors.campaignKey : ''}
              error={touched.campaignKey && Boolean(errors.campaignKey)}
              fullWidth
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <TextField
              label={<Translate>CRM ID</Translate>}
              defaultValue={values.crmId ? values.crmId : ''}
              name="crmId"
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={touched.crmId ? errors.crmId : ''}
              error={touched.crmId && Boolean(errors.crmId)}
              fullWidth
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <DatePicker
              label={<Translate>reference date</Translate>}
              format="dd.MM.yyyy"
              value={
                values.referenceDate ? parseDate(values.referenceDate) : null
              }
              onChange={(date) =>
                setFieldValue(
                  'referenceDate',
                  date.toLocaleString('de-DE', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                  }),
                )
              }
              slotProps={{
                textField: {
                  helperText: errors.referenceDate,
                },
              }}
            />
          </Grid>
          <Grid xs={12}>
            <Upload
              label={
                <Translate wildcards={{ '%type%': 'png | 300 x 300' }}>
                  Upload file %type%
                </Translate>
              }
              name="fileTeaser"
              setFieldValue={setFieldValue}
            />
          </Grid>
          {this.renderCampaignConfigFiles(props)}
        </Grid>
      </>
    );
  };

  render() {
    const { OnMount, form, token, campaign, campaignId } = this.props;
    let { ...defaultValues } = this.props.values;

    if (isEmpty(form) && !campaignId) {
      return null;
    }

    if (campaignId && isEmpty(campaign)) {
      return null;
    }

    if (!isEmpty(form)) {
      defaultValues.updateToken = token;
    }

    if (campaign && !isEmpty(campaign)) {
      defaultValues = campaign;
    }

    return (
      <ACL authorized={this.props.requiredRoles}>
        {this.renderHeader()}
        <Paper>
          <ErrorBoundary>
            <Form
              onMount={OnMount}
              onSubmit={this.handleSubmit}
              onCancel={this.handleCancel}
              validationSchema={validationSchema}
              initialValues={defaultValues}
              name="campaign"
              renderFieldset={this.renderFieldset}
              values={{}}
            />
          </ErrorBoundary>
        </Paper>
      </ACL>
    );
  }
}

const makeMapStateToProps = () => {
  const getCampaignWithConfigFiles = makeCampaignWithConfigFiles();

  return (state, props) => {
    const {
      forms: { campaign: forms },
    } = state;

    const campaignId = props?.match?.params?.campaignId
      ? parseInt(props.match.params.campaignId)
      : null;
    const campaignWithConfigFiles = getCampaignWithConfigFiles(state, props);

    return {
      campaignId: campaignId,
      campaign: campaignWithConfigFiles ? campaignWithConfigFiles : {},
      campaignConfigFiles:
        campaignWithConfigFiles &&
        !isEmpty(campaignWithConfigFiles.campaignConfigFiles)
          ? campaignWithConfigFiles.campaignConfigFiles
          : {},
      token: !isEmpty(forms) ? forms.updateToken : '',
      form: forms,
    };
  };
};

const enhance = compose(
  withRouter,
  connect(makeMapStateToProps, {
    postForm,
    initForm,
    fetchCampaign,
    updateCampaign,
  }),
);

export default enhance(Edit);

import React from 'react';
import {
  // Button,
  Typography,
} from '@mui/material';
// import DownloadIcon from '@mui/icons-material/Download';
import {
  // Actions,
  // Spacer,
  Title,
  ToolbarRoot,
} from '../../../../components/StyledElements/StyledElements';
// import Translate from '../../../../components/service/Translate';

type HeaderProps = {
  title: string;
};

export default function Header({ title }: HeaderProps) {
  return (
    <ToolbarRoot>
      <Title>
        <Typography variant="h5" id="tableTitle">
          {title}
        </Typography>
      </Title>
      {/*
          <Spacer />
          <Actions>
            <Button variant='contained' color='primary'>
              <DownloadIcon />
              <Translate>Download</Translate>
            </Button>
          </Actions>
        */}
    </ToolbarRoot>
  );
}

import selectors from '../../selectors';
import { isEmpty } from 'lodash';

// eslint-disable-next-line no-unused-vars
const getProducts = (state) => state.entities.product;
const getParentProduct = (state, props) =>
  props?.match?.params?.productId
    ? parseInt(props.match.params.productId)
    : null;

export const makeChildProduct = () => {
  return selectors([getProducts, getParentProduct], (product, parent) => {
    if (!isEmpty(product) && parent) {
      let childId = product[parent].master;
      return product[childId];
    }

    return {};
  });
};

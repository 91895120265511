import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEqual, values } from 'lodash';
import Translate from '../../../components/service/Translate';
import { loadBrandCompanies } from '../actions';
import ErrorBoundary from '../../../components/ErrorBoundary';
import { MenuItem, TextField } from '@mui/material';
import { EmptyMenuItem } from '../../../components/StyledElements/StyledElements';

class BrandCompany extends Component {
  static propTypes = {
    loadBrandCompanies: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    values: PropTypes.object,
    required: PropTypes.bool,
    enabledOnly: PropTypes.bool,
  };

  static defaultProps = {
    required: false,
    enabledOnly: false,
  };

  state = {};

  componentDidMount = () => {
    if (this.props.enabledOnly) {
      this.props.loadBrandCompanies(['id', 'primaryText']);
    }
  };

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState) {
    if (!isEqual(this.props.enabledOnly, prevProps.enabledOnly)) {
      this.props.loadBrandCompanies(['id', 'primaryText']);
    }
  }

  // eslint-disable-next-line no-unused-vars
  shouldComponentUpdate = (nextProps, nextState) => {
    return (
      !isEqual(nextProps.values, this.props.values) ||
      !isEqual(nextProps.brands, this.props.brands) ||
      !isEqual(this.props.errors, nextProps.errors) ||
      !isEqual(this.props.touched, nextProps.touched)
    );
  };

  renderSelect = (item) => {
    return (
      <MenuItem key={Math.random()} value={item.id}>
        {' '}
        <Translate>{item.primaryText}</Translate>
      </MenuItem>
    );
  };
  renderRequired = () => {
    if (this.props.required) {
      return null;
    }

    return <EmptyMenuItem key="brandCompanyNull" value={''} />;
  };

  render() {
    const { values, brands, handleChange } = this.props;

    if (brands) {
      return (
        <ErrorBoundary>
          <TextField
            select
            id="brandCompany"
            name={`${this.props.name}[brandCompany]`}
            label={<Translate>Manufactor</Translate>}
            value={values.brandCompany ? values.brandCompany : ''}
            onChange={handleChange}
            /*helperText={touched.brand ? errors.brand : ''}
       error={touched.brand && Boolean(errors.brand)}*/
            fullWidth
          >
            {this.renderRequired()}
            {brands.map(this.renderSelect)}
          </TextField>
        </ErrorBoundary>
      );
    }

    return null;
  }
}

// eslint-disable-next-line no-unused-vars
function mapStateToProps(state, ownProps) {
  const {
    entities: { brand },
  } = state;

  if (!brand) {
    return {};
  }

  return {
    brands: values(brand),
  };
}

export default connect(mapStateToProps, { loadBrandCompanies })(BrandCompany);

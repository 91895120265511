import { filter } from 'lodash';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../reducers';

const getSeasons = (state: RootState) => state.season.entities;

const makeSeasonsByPrintable = () =>
  createSelector([getSeasons], (seasons) =>
    seasons ? filter(seasons, { printable: true }) : [],
  );

export default makeSeasonsByPrintable;

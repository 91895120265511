import PropTypes from 'prop-types';
import React, { Component } from 'react';

class Email extends Component {
  static propTypes = {
    link: PropTypes.bool,
  };

  static defaultProps = {
    link: true,
  };

  render() {
    if (this.props.children) {
      return (
        <a href={`mailto:${this.props.children}`}>{this.props.children}</a>
      );
    }

    return null;
  }
}

export default Email;

import React from 'react';
import { Typography, Box } from '@mui/material';
import { calcRate, calcRateToString } from '../../utils';
import { BorderLinearProgress } from '../../../../components/StyledElements/StyledElements';
import Translate from '../../../../components/service/Translate';

type InteractionDetailProps = {
  total: number;
  count: number;
  label: string;
};

export default function InteractionDetail({
  total,
  count,
  label,
}: InteractionDetailProps) {
  return (
    <>
      <Typography variant="h6" gutterBottom>
        <Translate context="customerGroups">{label}</Translate>
      </Typography>
      <Box display="flex" alignItems="center">
        <Box width={4 / 5} marginRight={1}>
          <BorderLinearProgress
            variant="determinate"
            value={calcRate(count, total)}
          />
        </Box>
        <Box
          minWidth={1 / 5}
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
        >
          <Typography variant="body2" color="text.secondary">
            {calcRateToString(count, total)}
          </Typography>
        </Box>
      </Box>
    </>
  );
}

import { CALL_API } from '../../middleware';
import { Schemas } from '../../schemas';
import { sample } from 'lodash';

export const EMAILMODULES_REQUEST = 'EMAILMODULES_REQUEST';
export const EMAILMODULES_SUCCESS = 'EMAILMODULES_SUCCESS';
export const EMAILMODULES_FAILURE = 'EMAILMODULES_FAILURE';

function fetchModules(
  sortColumns,
  sortOrders,
  offset = null,
  limit = null,
  join,
  filter,
) {
  return {
    [CALL_API]: {
      types: [EMAILMODULES_REQUEST, EMAILMODULES_SUCCESS, EMAILMODULES_FAILURE],
      endpoint: Routing.generate(
        'emailmodule_index',
        {
          sortColumns: sortColumns,
          sortOrders: sortOrders,
          offset: offset,
          limit: limit,
          join: join,
          filter: filter,
        },
        true,
      ),
      schema: Schemas.EMAILMODULES,
    },
  };
}

export function loadModules(
  sortColumns = [],
  sortOrders = [],
  requiredFields = [],
  join = [],
  filter = [],
) {
  return (dispatch, getState) => {
    const module = getState().entities.module;
    const resultset = getState().resultsets.module;
    const outdated = getState().outdated.module;

    let offset = null;
    let limit = null;

    if (
      !outdated &&
      resultset &&
      resultset.length &&
      module &&
      requiredFields.every((key) => sample(module).hasOwnProperty(key))
    ) {
      return null;
    }

    return dispatch(
      fetchModules(sortColumns, sortOrders, offset, limit, join, filter),
    );
  };
}

export const EMAILMODULES_LIST_REQUEST = 'EMAILMODULES_LIST_REQUEST';
export const EMAILMODULES_LIST_SUCCESS = 'EMAILMODULES_LIST_SUCCESS';
export const EMAILMODULES_LIST_FAILURE = 'EMAILMODULES_LIST_FAILURE';

function fetchList(sortColumns, sortOrders, join, filter) {
  return {
    [CALL_API]: {
      types: [
        EMAILMODULES_LIST_REQUEST,
        EMAILMODULES_LIST_SUCCESS,
        EMAILMODULES_LIST_FAILURE,
      ],
      endpoint: Routing.generate(
        'emailmodule_list',
        {
          sortColumns: sortColumns,
          sortOrders: sortOrders,
          join: join,
          filter: filter,
        },
        true,
      ),
      schema: Schemas.EMAILMODULES,
    },
  };
}

export function loadList(
  sortColumns = [],
  sortOrders = [],
  requiredFields = [],
  join = [],
  filter = [],
) {
  return (dispatch, getState) => {
    const module = getState().entities.module;
    const resultset = getState().resultsets.module;
    const outdated = getState().outdated.module;

    if (
      !outdated &&
      resultset &&
      resultset.length &&
      module &&
      requiredFields.every((key) => sample(module).hasOwnProperty(key))
    ) {
      return null;
    }

    return dispatch(fetchList(sortColumns, sortOrders, join, filter));
  };
}

export const EMAILMODULE_REQUEST = 'EMAILMODULE_REQUEST';
export const EMAILMODULE_SUCCESS = 'EMAILMODULE_SUCCESS';
export const EMAILMODULE_FAILURE = 'EMAILMODULE_FAILURE';

function fetchModule(id) {
  return {
    [CALL_API]: {
      types: [EMAILMODULE_REQUEST, EMAILMODULE_SUCCESS, EMAILMODULE_FAILURE],
      endpoint: Routing.generate('emailmodule_show', { id: id }, true),
      schema: Schemas.EMAILMODULE,
    },
  };
}

export function loadModule(id, requiredFields = []) {
  return (dispatch, getState) => {
    const modules = getState().entities.module;

    if (
      modules &&
      modules[id] &&
      requiredFields.every((key) => modules[id].hasOwnProperty(key))
    ) {
      return null;
    }

    return dispatch(fetchModule(id));
  };
}

export const EMAILMODULE_PAGED_REQUEST = 'EMAILMODULE_PAGED_REQUEST';
export const EMAILMODULE_PAGED_SUCCESS = 'EMAILMODULE_PAGED_SUCCESS';
export const EMAILMODULE_PAGED_FAILURE = 'EMAILMODULE_PAGED_FAILURE';

function fetchEmailModulePaged(
  sortColumns,
  sortOrders,
  offset = null,
  limit = null,
  join,
  filter,
) {
  return {
    [CALL_API]: {
      types: [
        EMAILMODULE_PAGED_REQUEST,
        EMAILMODULE_PAGED_SUCCESS,
        EMAILMODULE_PAGED_FAILURE,
      ],
      endpoint: Routing.generate(
        'emailmodule_index',
        {
          sortColumns: sortColumns,
          sortOrders: sortOrders,
          offset: offset,
          limit: limit,
          join: join,
          filter,
        },
        true,
      ),
      schema: Schemas.EMAILMODULES,
    },
  };
}

export function loadNext(
  sortColumns = [],
  sortOrders = [],
  offset = null,
  limit = null,
  join = [],
  filter = [],
) {
  return (dispatch, getState) => {
    const emailModule = getState().entities.emailModule;
    const pagination =
      emailModule && getState().pagination.emailModule
        ? getState().pagination.emailModule
        : false;

    if (!emailModule || !pagination) {
      return null;
    }

    return dispatch(
      fetchEmailModulePaged(
        sortColumns,
        sortOrders,
        offset,
        limit,
        join,
        filter,
      ),
    );
  };
}

export const EMAILMODULE_UPDATE_REQUEST = 'EMAILMODULE_UPDATE_REQUEST';
export const EMAILMODULE_UPDATE_SUCCESS = 'EMAILMODULE_UPDATE_SUCCESS';
export const EMAILMODULE_UPDATE_FAILURE = 'EMAILMODULE_UPDATE_FAILURE';

function writeModule(form, moduleId) {
  return {
    [CALL_API]: {
      types: [
        EMAILMODULE_UPDATE_REQUEST,
        EMAILMODULE_UPDATE_SUCCESS,
        EMAILMODULE_UPDATE_FAILURE,
      ],
      endpoint: Routing.generate('emailmodule_update', { id: moduleId }, true),
      method: 'POST',
      data: form,
      schema: Schemas.EMAILMODULE,
    },
  };
}

export function updateModule(form, moduleId = null) {
  return (dispatch) => {
    return dispatch(writeModule(form, moduleId));
  };
}

export const EMAILMODULE_DELETE_REQUEST = 'EMAILMODULE_DELETE_REQUEST';
export const EMAILMODULE_DELETE_SUCCESS = 'EMAILMODULE_DELETE_SUCCESS';
export const EMAILMODULE_DELETE_FAILURE = 'EMAILMODULE_DELETE_FAILURE';

function deleteModule(moduleId, deleteToken) {
  return {
    [CALL_API]: {
      types: [
        EMAILMODULE_DELETE_REQUEST,
        EMAILMODULE_DELETE_SUCCESS,
        EMAILMODULE_DELETE_FAILURE,
      ],
      endpoint: Routing.generate(
        'emailmodule_delete',
        { id: moduleId, deleteToken: deleteToken },
        true,
      ),
      method: 'DELETE',
    },
  };
}

export function removeModule(moduleId, deleteToken) {
  return (dispatch) => {
    return dispatch(deleteModule(moduleId, deleteToken));
  };
}

export const EMAILMODULE_FORM_REQUEST = 'EMAILMODULE_FORM_REQUEST';
export const EMAILMODULE_FORM_SUCCESS = 'EMAILMODULE_FORM_SUCCESS';
export const EMAILMODULE_FORM_FAILURE = 'EMAILMODULE_FORM_FAILURE';

function fetchForm() {
  return {
    [CALL_API]: {
      types: [
        EMAILMODULE_FORM_REQUEST,
        EMAILMODULE_FORM_SUCCESS,
        EMAILMODULE_FORM_FAILURE,
      ],
      endpoint: Routing.generate('emailmodule_token', {}, true),
      schema: Schemas.FORM,
    },
  };
}

export function initForm() {
  return (dispatch, getState) => {
    const updateToken = getState().forms.updateToken;

    if (updateToken) {
      return null;
    }

    return dispatch(fetchForm());
  };
}

export const MODULETAGS_REQUEST = 'MODULETAGS_REQUEST';
export const MODULETAGS_SUCCESS = 'MODULETAGS_SUCCESS';
export const MODULETAGS_FAILURE = 'MODULETAGS_FAILURE';

function fetchModuleTags() {
  return {
    [CALL_API]: {
      types: [MODULETAGS_REQUEST, MODULETAGS_SUCCESS, MODULETAGS_FAILURE],
      endpoint: Routing.generate('moduletag_index', {}, true),
      schema: Schemas.MODULETAGS,
    },
  };
}

export function loadModuleTags(requiredFields = []) {
  return (dispatch, getState) => {
    const moduleTags = getState().entities.moduleTag;
    const resultset = getState().resultsets.moduleTag;
    const outdated = getState().outdated.moduleTag;

    if (
      !outdated &&
      resultset &&
      resultset.length &&
      moduleTags &&
      requiredFields.every((key) => sample(moduleTags).hasOwnProperty(key))
    ) {
      return null;
    }

    return dispatch(fetchModuleTags());
  };
}

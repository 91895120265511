import { CALL_API } from '../../middleware';
import { Schemas } from '../../schemas';
import has from 'has';

export const SIGNEES_REQUEST = 'SIGNEES_REQUEST';
export const SIGNEES_SUCCESS = 'SIGNEES_SUCCESS';
export const SIGNEES_FAILURE = 'SIGNEES_FAILURE';

function fetchSignees(
  sortColumns,
  sortOrders,
  offset = null,
  limit = null,
  join,
  filter,
) {
  return {
    [CALL_API]: {
      types: [SIGNEES_REQUEST, SIGNEES_SUCCESS, SIGNEES_FAILURE],
      endpoint: Routing.generate(
        'signee_index',
        {
          sortColumns: sortColumns,
          sortOrders: sortOrders,
          offset: offset,
          limit: limit,
          join: join,
          filter: filter,
        },
        true,
      ),
      schema: Schemas.SIGNEES,
    },
  };
}

export function loadSignees(
  sortColumns = [],
  sortOrders = [],
  join = [],
  filter = [],
) {
  return (dispatch) => {
    let offset = null;
    let limit = null;

    return dispatch(
      fetchSignees(sortColumns, sortOrders, offset, limit, join, filter),
    );
  };
}

export const SIGNEE_REQUEST = 'SIGNEE_REQUEST';
export const SIGNEE_SUCCESS = 'SIGNEE_SUCCESS';
export const SIGNEE_FAILURE = 'SIGNEE_FAILURE';

function fetchSignee(id) {
  return {
    [CALL_API]: {
      types: [SIGNEE_REQUEST, SIGNEE_SUCCESS, SIGNEE_FAILURE],
      endpoint: Routing.generate('signee_show', { id: id }, true),
      schema: Schemas.SIGNEE,
    },
  };
}

export function loadSignee(id, requiredFields = []) {
  return (dispatch, getState) => {
    const signee = getState().entities.signee;
    const outdated = getState().outdated.signee;

    if (
      !outdated &&
      signee &&
      signee[id] &&
      requiredFields.every((key) => has(signee[id], key))
    ) {
      return null;
    }

    return dispatch(fetchSignee(id));
  };
}

export const SIGNEE_PAGED_REQUEST = 'SIGNEE_PAGED_REQUEST';
export const SIGNEE_PAGED_SUCCESS = 'SIGNEE_PAGED_SUCCESS';
export const SIGNEE_PAGED_FAILURE = 'SIGNEE_PAGED_FAILURE';

function fetchSigneePaged(
  sortColumns,
  sortOrders,
  offset = null,
  limit = null,
  join,
  filter,
) {
  return {
    [CALL_API]: {
      types: [SIGNEE_PAGED_REQUEST, SIGNEE_PAGED_SUCCESS, SIGNEE_PAGED_FAILURE],
      endpoint: Routing.generate(
        'signee_index',
        {
          sortColumns: sortColumns,
          sortOrders: sortOrders,
          offset: offset,
          limit: limit,
          join: join,
          filter: filter,
        },
        true,
      ),
      schema: Schemas.SIGNEES,
    },
  };
}

export function loadNext(
  sortColumns = [],
  sortOrders = [],
  offset = null,
  limit = null,
  join = [],
  filter = [],
) {
  return (dispatch, getState) => {
    const signee = getState().entities.signee;
    const pagination =
      signee && getState().pagination.signee
        ? getState().pagination.signee
        : false;

    if (!signee || !pagination) {
      return null;
    }

    return dispatch(
      fetchSigneePaged(
        sortColumns,
        sortOrders,
        offset === 0 ? offset : pagination.next,
        limit ? limit : pagination.limit,
        join,
        filter,
      ),
    );
  };
}

export const SIGNEE_UPDATE_REQUEST = 'SIGNEE_UPDATE_REQUEST';
export const SIGNEE_UPDATE_SUCCESS = 'SIGNEE_UPDATE_SUCCESS';
export const SIGNEE_UPDATE_FAILURE = 'SIGNEE_UPDATE_FAILURE';

function writeSignee(form, signeeId) {
  return {
    [CALL_API]: {
      types: [
        SIGNEE_UPDATE_REQUEST,
        SIGNEE_UPDATE_SUCCESS,
        SIGNEE_UPDATE_FAILURE,
      ],
      endpoint: Routing.generate('signee_update', { id: signeeId }, true),
      method: 'POST',
      data: form,
      schema: Schemas.SIGNEE,
    },
  };
}

export function updateSignee(form, signeeId = null) {
  return (dispatch) => {
    return dispatch(writeSignee(form, signeeId));
  };
}

export const SIGNEE_DELETE_REQUEST = 'SIGNEE_DELETE_REQUEST';
export const SIGNEE_DELETE_SUCCESS = 'SIGNEE_DELETE_SUCCESS';
export const SIGNEE_DELETE_FAILURE = 'SIGNEE_DELETE_FAILURE';

function deleteSignee(signeeId, deleteToken) {
  return {
    [CALL_API]: {
      types: [
        SIGNEE_DELETE_REQUEST,
        SIGNEE_DELETE_SUCCESS,
        SIGNEE_DELETE_FAILURE,
      ],
      endpoint: Routing.generate(
        'signee_delete',
        { id: signeeId, deleteToken: deleteToken },
        true,
      ),
      method: 'DELETE',
    },
  };
}

export function removeSignee(signeeId, deleteToken) {
  return (dispatch) => {
    return dispatch(deleteSignee(signeeId, deleteToken));
  };
}

export const SIGNEE_FORM_REQUEST = 'SIGNEE_FORM_REQUEST';
export const SIGNEE_FORM_SUCCESS = 'SIGNEE_FORM_SUCCESS';
export const SIGNEE_FORM_FAILURE = 'SIGNEE_FORM_FAILURE';

function fetchForm() {
  return {
    [CALL_API]: {
      types: [SIGNEE_FORM_REQUEST, SIGNEE_FORM_SUCCESS, SIGNEE_FORM_FAILURE],
      endpoint: Routing.generate('signee_token', null, true),
      schema: Schemas.FORM,
    },
  };
}

export function initForm() {
  return (dispatch, getState) => {
    const form = getState().forms.signee;

    if (form) {
      return null;
    }

    return dispatch(fetchForm());
  };
}

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  Collapse,
  IconButton,
} from '@mui/material';
import { isEqual } from 'lodash';
import { connect } from 'react-redux';
import Currency from './service/Currency';
import Translate from './service/Translate';
import Tag from '../scenes/Staff/Tag';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Table, TableData } from './StyledElements/StyledElements';
import { compose } from 'redux';
import { withTheme } from '../withTheme';

class CustomerListCustomerInvoice extends Component {
  static propTypes = {
    invoiceId: PropTypes.number.isRequired,
    invoice: PropTypes.object,
    staff: PropTypes.object,
    initiallyExpanded: PropTypes.bool,
  };

  static defaultProps = {
    initiallyExpanded: false,
  };

  state = {
    expanded: false,
  };

  componentDidMount() {
    if (this.props.initiallyExpanded) {
      this.setState({ expanded: true });
    }
  }

  shouldComponentUpdate = (nextProps, nextState) => {
    return (
      !isEqual(this.props.invoiceId, nextProps.invoiceId) ||
      !isEqual(this.props.invoice, nextProps.invoice) ||
      !isEqual(nextState.expanded, this.state.expanded)
    );
  };

  handleExpandClick = () => {
    const { expanded } = this.state;
    this.setState({ expanded: !expanded });
  };

  render() {
    const { invoice, staff, theme } = this.props;

    if (!invoice) {
      return null;
    }

    return (
      <Card
        sx={{
          mb: 2,
          width: '100%',
        }}
      >
        <CardHeader
          sx={{
            fontSize: (theme) => theme.textSize.carHeadline,
            pt: 1,
          }}
          title="Rechnung"
          subheader={
            <span>
              <Translate
                wildcards={{
                  '%number%': invoice.number,
                  '%printed%': new Intl.DateTimeFormat(theme.locale.de).format(
                    new Date(invoice.printed),
                  ),
                }}
              >
                Invoice no.: %number%, Date of invoice: %printed%, Sum:{' '}
              </Translate>
              <Currency>{invoice.sumTotal}</Currency>
            </span>
          }
          action={
            <IconButton
              onClick={this.handleExpandClick}
              aria-expanded={this.state.expanded}
              aria-label="Show more"
              // className={this.state.expanded ? classes.expandOpen : ''}
              sx={{
                transform: 'rotate(180deg)',
              }}
              size="large"
            >
              <ExpandMoreIcon color="inherit" />
            </IconButton>
          }
        />
        <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Table width="100%" tableLayout="fixed">
              <tbody>
                <tr>
                  <TableData
                    width="20%"
                    textColor="secondaryTextColor"
                    fontSize="14px"
                  >
                    <Translate>Order no.</Translate>
                  </TableData>
                  <TableData width="30%" fontSize="14px">
                    {invoice.orderNumber}
                  </TableData>
                  <TableData
                    width="20%"
                    textColor="secondaryTextColor"
                    fontSize="14px"
                  >
                    <Translate>Invoice no.</Translate>
                  </TableData>
                  <TableData width="30%" fontSize="14px">
                    {invoice.number}
                  </TableData>
                </tr>
                <tr>
                  <TableData
                    width="20%"
                    textColor="secondaryTextColor"
                    fontSize="14px"
                  >
                    <Translate>Date of order</Translate>
                  </TableData>
                  <TableData width="30%" fontSize="14px">
                    {new Intl.DateTimeFormat(theme.locale.de).format(
                      new Date(invoice.ordered),
                    )}
                  </TableData>
                  <TableData
                    width="20%"
                    textColor="secondaryTextColor"
                    fontSize="14px"
                  >
                    <Translate>Date of invoice</Translate>
                  </TableData>
                  <TableData width="30%" fontSize="14px">
                    {new Intl.DateTimeFormat(theme.locale.de).format(
                      new Date(invoice.printed),
                    )}
                  </TableData>
                </tr>
                <tr>
                  <TableData
                    width="20%"
                    textColor="secondaryTextColor"
                    fontSize="14px"
                  >
                    <Translate>Sum</Translate>
                  </TableData>
                  <TableData width="30%" fontSize="14px">
                    <Currency>{invoice.sumTotal}</Currency>
                  </TableData>
                  <TableData
                    width="20%"
                    textColor="secondaryTextColor"
                    fontSize="14px"
                  >
                    <Translate>Agent</Translate>
                  </TableData>
                  <TableData width="30%" fontSize="14px">
                    {staff ? <Tag id={staff.id} /> : invoice.agentCode}
                  </TableData>
                </tr>
              </tbody>
            </Table>
          </CardContent>
        </Collapse>
      </Card>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const invoiceId = ownProps.invoiceId;
  const {
    entities: { invoice, staff },
  } = state;

  return {
    invoiceId: invoiceId,
    invoice: invoice[invoiceId],
    staff:
      staff && staff[invoice[invoiceId].staff]
        ? staff[invoice[invoiceId].staff]
        : null,
  };
}

const enhance = compose(connect(mapStateToProps, {}), withTheme);

export default enhance(CustomerListCustomerInvoice);

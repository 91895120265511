import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isEqual, pick, isEmpty } from 'lodash';
import { loadAffiliates, updateAffiliate, removeAffiliate } from './actions';
import { resetEntity, outdateEntity } from '../../actions';
import Grid from './components/Grid';
import DeleteDialog from '../../components/DeleteDialog';
import ToolBarGroup from '../../components/ToolBarGroup';
import ErrorBoundary from '../../components/ErrorBoundary';

class Affiliate extends Component {
  static propTypes = {
    resetEntity: PropTypes.func.isRequired,
    outdateEntity: PropTypes.func.isRequired,
    loadAffiliates: PropTypes.func.isRequired,
    updateAffiliate: PropTypes.func.isRequired,
    removeAffiliate: PropTypes.func.isRequired,
    resultset: PropTypes.array,
    affiliates: PropTypes.object,
    defaultIndex: PropTypes.number,
    deletable: PropTypes.bool,
  };

  static defaultProps = {
    requiredRoles: ['ROLE_SUPERADMIN', 'ROLE_PRO'],
    resultset: [],
  };

  state = {
    current: null,
    currentIndex: 0,
    adding: false,
    editing: false,
    deleting: false,
    valid: false,
    mode: 'show',
    sortColumns: ['name', 'id'],
    sortOrders: ['asc', 'asc'],
    isSubmitting: false,
  };

  componentDidMount = () => {
    const { sortColumns, sortOrders } = this.state;

    this.props.loadAffiliates(sortColumns, sortOrders);
  };

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.defaultIndex !== null &&
      prevProps.defaultIndex != this.props.defaultIndex
    ) {
      this.setState({
        currentIndex: this.props.defaultIndex,
        current: this.props.affiliates[this.props.defaultIndex],
      });
    }
  }

  shouldComponentUpdate = (nextProps, nextState) => {
    return (
      !isEqual(nextProps.affiliates, this.props.affiliates) ||
      !isEqual(nextState, this.state) ||
      !isEqual(nextProps.defaultIndex, this.props.defaultIndex) ||
      !isEqual(nextProps.success, this.props.success) ||
      !isEqual(nextProps.loading, this.props.loading) ||
      !isEqual(nextProps.deletable, this.props.deletable)
    );
  };

  handleFormMount = (form) => {
    this.form = form;
  };

  handleSubmit = (form) => {
    const { updateAffiliate, outdateEntity, loadAffiliates } = this.props;
    const { currentIndex, editing, sortColumns, sortOrders } = this.state;

    this.setState({ valid: false, isSubmitting: true });

    return Promise.all([
      updateAffiliate(form, editing ? currentIndex : null),
    ]).then(
      (values) => {
        if (!(values && values[0] && values[0].error)) {
          outdateEntity('affiliate');
          loadAffiliates(sortColumns, sortOrders);
          this.setState({
            editing: false,
            mode: 'show',
            adding: false,
            isSubmitting: false,
          });
        }
        this.setState({ isSubmitting: false });
      },
      () => {
        this.setState({ isSubmitting: false });
      },
    );
  };

  submit = () => {
    this.form.submit();
  };

  handleValidityChange = (valid) => {
    this.setState({ valid: valid });
  };

  handleGridSelection = (event, affiliateId) => {
    this.setState({
      current: this.props.affiliates[affiliateId],
      currentIndex: affiliateId,
    });
  };

  handleListSelection = (current) => {
    this.setState({ current: current, currentIndex: current ? current.id : 0 });
  };

  handleOrderChange = (columns, sort, join) => {
    this.setState({ sortColumns: columns, sortOrders: sort });

    this.props.outdateEntity('affiliate');
    this.props.loadAffiliates(columns, sort, join);
  };

  handleDoubleClick = (current) => {
    this.setState({
      current: current,
      currentIndex: current ? current.id : 0,
      adding: false,
      editing: true,
      valid: false,
      mode: 'editing',
    });
  };

  handleDelete = (form) => {
    const { affiliates, resultset } = this.props;
    const { sortColumns, sortOrders, current } = this.state;

    const promises = [];
    const currentIndex = resultset.indexOf(this.state.currentIndex);
    const nextIndex =
      currentIndex >= 0 && currentIndex < resultset.length - 1
        ? resultset[currentIndex + 1]
        : currentIndex > 0
          ? resultset[currentIndex - 1]
          : 0;
    const next = affiliates[nextIndex];

    promises.push(this.props.resetEntity('affiliate', current.id));
    promises.push(
      this.props.removeAffiliate(form, current.id, current.deleteToken),
    );

    this.setState({
      deleting: false,
      mode: 'show',
      currentIndex: nextIndex,
      current: next,
    });

    Promise.all(promises).then(() => {
      this.props.loadAffiliates(sortColumns, sortOrders);
    });
  };

  onTouchEdit = () => {
    this.setState({
      adding: false,
      editing: true,
      valid: false,
      mode: 'editing',
    });
  };

  onTouchAdd = () => {
    this.setState({
      adding: true,
      editing: false,
      valid: false,
      mode: 'adding',
    });
  };

  onTouchDelete = () => {
    this.setState({ deleting: true });
  };

  onTouchCancel = () => {
    this.setState({
      adding: false,
      editing: false,
      valid: false,
      mode: 'show',
      deleting: false,
    });
  };

  renderDeleteDialog = () => {
    const { deleting, current } = this.state;

    if (deleting) {
      return (
        <DeleteDialog
          current={current}
          onCancel={this.onTouchCancel}
          onSubmit={this.handleDelete}
        />
      );
    }

    return null;
  };

  submitMyForm = null;

  handleSubmitMyForm = (e) => {
    if (this.submitMyForm) {
      this.submitMyForm(e);
    }
  };
  bindSubmitForm = (submitForm) => {
    this.submitMyForm = submitForm;
  };

  renderContent() {
    const { affiliates, resultset, defaultIndex, loading, success } =
      this.props;
    const { mode, current } = this.state;
    let defaultCurrent = affiliates[defaultIndex];

    return (
      <ErrorBoundary>
        <Grid
          resultset={resultset}
          affiliates={affiliates}
          current={current ? current : defaultCurrent}
          mode={mode}
          loading={loading}
          success={success}
          onSelection={this.handleGridSelection}
          onFormMount={this.handleFormMount}
          onSubmit={this.handleSubmit}
          onValidityChange={this.handleValidityChange}
          bindSubmitForm={this.bindSubmitForm}
        />
      </ErrorBoundary>
    );
  }

  render() {
    const { affiliates, defaultIndex } = this.props;
    const { current, adding, editing, isSubmitting } = this.state;

    let defaultCurrent = affiliates[defaultIndex];

    return (
      <>
        <ErrorBoundary>
          <ToolBarGroup
            title={'Filialen'}
            adding={adding}
            editing={editing}
            isSubmitting={isSubmitting}
            current={current ? current : defaultCurrent}
            deletable={!(adding || isEmpty(affiliates))}
            onEdit={this.onTouchEdit}
            onCancel={this.onTouchCancel}
            onAdd={this.onTouchAdd}
            onDelete={this.onTouchDelete}
            onSubmit={this.submit}
            onHandleSubmit={this.handleSubmitMyForm}
          />
        </ErrorBoundary>
        {this.renderContent()}
        {this.renderDeleteDialog()}
      </>
    );
  }
}

// eslint-disable-next-line no-unused-vars
function mapStateToProps(state, ownProps) {
  const {
    entities: { affiliate },
    resultsets: { affiliate: resultset },
    loading: loading,
    success: successActions,
  } = state;

  return {
    affiliates: pick(affiliate, resultset),
    defaultIndex: affiliate && resultset ? resultset[0] : null,
    resultset: resultset,
    loading: loading,
    success: successActions,
  };
}

export default connect(mapStateToProps, {
  loadAffiliates,
  updateAffiliate,
  resetEntity,
  removeAffiliate,
  outdateEntity,
})(Affiliate);

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ACL from '../../components/ACL';
import Owner from './Owner';

class OwnerIndex extends Component {
  static propTypes = {
    requiredRoles: PropTypes.array,
  };

  static defaultProps = {
    requiredRoles: ['ROLE_ADMIN'],
    resultset: [],
  };

  render() {
    return (
      <ACL authorized={this.props.requiredRoles}>
        <Owner />
      </ACL>
    );
  }
}

export default OwnerIndex;

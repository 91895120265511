import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Translate from '../../components/service/Translate';
import FileCloud from '@mui/icons-material/Cloud';
import ContentAdd from '@mui/icons-material/Add';
import { Tooltip, Typography, IconButton } from '@mui/material';
import CcsScanList from './components/List';
import CcsScanExport from './components/Export';
import { isEmpty, isEqual } from 'lodash';
import { createExport, loadScans, loadNext } from './actions';
import { connect } from 'react-redux';
import ErrorBoundary from '../../components/ErrorBoundary';
import { outdateEntity } from '../../actions';
import {
  ColorActions,
  Spacer,
  ToolbarRoot,
  Title,
} from '../../components/StyledElements/StyledElements';

class CcsScan extends Component {
  static propTypes = {
    outdateEntity: PropTypes.func.isRequired,
    loadNext: PropTypes.func.isRequired,
    createExport: PropTypes.func.isRequired,
    form: PropTypes.object,
  };

  static defaultProps = {};

  state = {
    mode: 'list',
    dataLoading: false,
  };

  componentDidMount = () => {
    this.loadCcsScan(true);
  };

  shouldComponentUpdate = (nextProps, nextState) => {
    return (
      !isEqual(nextProps.ccsScan, this.props.ccsScan) ||
      !isEqual(nextProps.resultset, this.props.resultset) ||
      !isEqual(nextState.dataLoading, this.state.dataLoading) ||
      !isEqual(nextState.mode, this.state.mode)
    );
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.mode !== this.state.mode) {
      this.loadCcsScan(true);
    }
  }

  loadCcsScan = (showLoading = true) => {
    showLoading ? this.setState({ dataLoading: true }) : null;
    Promise.all([this.props.loadScans(['id', 'primaryText', 'scaned'])]).then(
      () => {
        this.setState({ dataLoading: false });
      },
      () => {
        this.setState({ dataLoading: false });
      },
    );
  };

  handleSubmit = (form) => {
    return Promise.all([this.props.createExport(form)]).then((values) => {
      if (
        !(
          values &&
          values[0] &&
          values[0].type &&
          values[0].type.match(/_FAILURE/)
        )
      ) {
        this.props.outdateEntity('ccsScan');
        this.loadCcsScan(false);
        this.setState({ mode: 'list' });
      }
    });
  };

  loadNext = (
    columns,
    sort,
    join = [],
    offset = null,
    limit = null,
    filter = [],
  ) => {
    return Promise.all([
      this.props.loadNext(columns, sort, offset, limit, join, filter),
    ]);
  };

  handleOrderChange = (columns, sort, join, limit, offset, filter) => {
    this.setState({ sortColumns: columns, sortOrders: sort });
    this.props.outdateEntity('ccsScan');

    return this.loadNext(columns, sort, join, offset, limit, filter);
  };

  onTouchAdd = () => {
    this.setState({ mode: 'export' });
  };

  onTouchCancel = () => {
    this.setState({ mode: 'list' });
  };

  renderActionbar = () => {
    return (
      <ToolbarRoot>
        <Title>
          <Typography variant="h5" id="tableTitle">
            Übersicht CCS Abgleich
          </Typography>
        </Title>
        <Spacer />
        <ColorActions>
          <div>
            <Tooltip title={<Translate>Nextcloud</Translate>}>
              <IconButton
                aria-label={<Translate>Nextcloud</Translate>}
                target="_blank"
                href={Routing.generate('ccsscan_nextcloud', null, true)}
                size="large"
              >
                <FileCloud />
              </IconButton>
            </Tooltip>
            <Tooltip title={<Translate>CCS Abgleich erstellen</Translate>}>
              <IconButton
                aria-label={<Translate>CCS Abgleich erstellen</Translate>}
                onClick={() => this.onTouchAdd()}
                size="large"
              >
                <ContentAdd />
              </IconButton>
            </Tooltip>
          </div>
        </ColorActions>
      </ToolbarRoot>
    );
  };

  renderContent() {
    const { mode, dataLoading } = this.state;

    let child;

    if (dataLoading) {
      child = <Translate>Data loading...</Translate>;
    } else {
      child = (
        <div>
          <ErrorBoundary msg={'export'}>
            <CcsScanExport
              open={mode === 'export'}
              onSubmit={this.handleSubmit}
              onCancel={this.onTouchCancel}
            />
          </ErrorBoundary>
          <ErrorBoundary msg={'list'}>
            <CcsScanList
              onAdd={this.handleSubmit}
              onOrderChange={this.handleOrderChange}
            />
          </ErrorBoundary>
        </div>
      );
    }
    return child;
  }

  render() {
    return (
      <>
        {this.renderActionbar()}
        {this.renderContent()}
      </>
    );
  }
}

// eslint-disable-next-line no-unused-vars
function mapStateToProps(state, props) {
  const {
    entities: { ccsScan },
    resultsets: { ccsScan: resultset },
    pagination: { ccsScan: pagination },
  } = state;

  return {
    ccsScan: !isEmpty(ccsScan) ? ccsScan : {},
    resultset: resultset,
    pagination: pagination,
  };
}

export default connect(mapStateToProps, {
  createExport,
  loadScans,
  loadNext,
  outdateEntity,
})(CcsScan);

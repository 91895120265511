import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ACL from '../../components/ACL';
import CcsScan from './CcsScan';

class CcsScanIndex extends Component {
  static propTypes = {
    requiredRoles: PropTypes.array,
  };

  static defaultProps = {
    requiredRoles: ['ROLE_STAFF'],
    resultset: [],
  };

  render() {
    return (
      <ACL authorized={this.props.requiredRoles}>
        <CcsScan />
      </ACL>
    );
  }
}

export default CcsScanIndex;

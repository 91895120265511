import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DeleteIcon from '@mui/icons-material/Delete';
import ContentAdd from '@mui/icons-material/Add';
import EditorModeEdit from '@mui/icons-material/Edit';
import { Typography, Tooltip } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import Translate from '../service/Translate';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import {
  ColorActions,
  Spacer,
  ToolbarRoot,
  Title,
} from '../StyledElements/StyledElements';

class DataGridToolbar extends Component {
  static propTypes = {
    onReset: PropTypes.func,
    disableResetButton: PropTypes.bool,
  };

  static defaultProps = {
    onReset: null,
    disableResetButton: true,
  };

  handleEdit = (e, selected) => {
    this.props.onEdit(selected);
  };

  handleAdd = () => {
    this.props.onAdd();
  };

  handleDelete = (e, selected) => {
    this.props.onDelete(selected);
  };

  handleDuplicate = (e, selected) => {
    this.props.onDuplicate(selected);
  };

  handleReset = (e, selected) => {
    this.props.onReset(selected);
  };

  handleExport = () => {
    this.props.onExport();
  };

  render() {
    const {
      title,
      selected,
      onDuplicate,
      onEdit,
      onReset,
      onDelete,
      onExport,
      disableAdd,
      disableEdit,
      disableDuplicate,
      disableDelete,
      deleteTooltipText,
      disableResetButton,
      disableExportButton,
      embedded,
    } = this.props;
    return (
      <ToolbarRoot>
        <Title>
          <Typography variant="h5" id="tableTitle">
            {embedded ? '' : title}
          </Typography>
        </Title>
        <Spacer />
        <ColorActions>
          <div>
            <Tooltip title={<Translate>Add</Translate>}>
              <span>
                <IconButton
                  aria-label="Add"
                  onClick={this.handleAdd}
                  disabled={disableAdd}
                  size="large"
                >
                  <ContentAdd />
                </IconButton>
              </span>
            </Tooltip>
            {onEdit && (
              <Tooltip title={<Translate>Edit</Translate>}>
                <span>
                  <IconButton
                    aria-label="Edit"
                    onClick={(e) => this.handleEdit(e, selected)}
                    disabled={disableEdit}
                    size="large"
                  >
                    <EditorModeEdit />
                  </IconButton>
                </span>
              </Tooltip>
            )}
            {onDuplicate ? (
              <Tooltip title={<Translate>Duplicate</Translate>}>
                <span>
                  <IconButton
                    aria-label="Duplicate"
                    onClick={(e) => this.handleDuplicate(e, selected)}
                    disabled={disableDuplicate}
                    size="large"
                  >
                    <FileCopyIcon />
                  </IconButton>
                </span>
              </Tooltip>
            ) : null}
            {onReset ? (
              <Tooltip title={<Translate>Reset</Translate>}>
                <span>
                  <IconButton
                    aria-label="Reset"
                    onClick={(e) => this.handleReset(e, selected)}
                    disabled={disableResetButton}
                    size="large"
                  >
                    <SettingsBackupRestoreIcon />
                  </IconButton>
                </span>
              </Tooltip>
            ) : null}
            {onExport ? (
              <Tooltip title={<Translate>Export</Translate>}>
                <span>
                  <IconButton
                    aria-label="Export"
                    onClick={(e) => this.handleExport(e)}
                    disabled={disableExportButton}
                    size="large"
                  >
                    <FileDownloadIcon />
                  </IconButton>
                </span>
              </Tooltip>
            ) : null}
            {onDelete && (
              <Tooltip
                title={
                  disableDelete ? (
                    (deleteTooltipText ?? <Translate>Delete</Translate>)
                  ) : (
                    <Translate>Delete</Translate>
                  )
                }
              >
                <span>
                  <IconButton
                    aria-label="Delete"
                    onClick={(e) => this.handleDelete(e, selected)}
                    disabled={disableDelete}
                    size="large"
                  >
                    <DeleteIcon />
                  </IconButton>
                </span>
              </Tooltip>
            )}
          </div>
        </ColorActions>
      </ToolbarRoot>
    );
  }
}

DataGridToolbar.propTypes = {
  numSelected: PropTypes.number,
};

export default DataGridToolbar;

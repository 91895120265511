import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Paper } from '@mui/material';
import ACL from '../../components/ACL';
import BreadcrumbsNav from '../../components/BreadcrumbNavigation';
import MailingForm from './components/MailingForm';
import CallingForm from './components/CallingForm';
import EmailForm from './components/Edit';
import SmsForm from './components/EditSms';
import { fetchCampaign } from '../Campaign/actions';
import { updateEmailSeason, updateSmsSeason } from './actions';
import { SEASON_TYPE } from '../../utils/Constants';
import { getCampaignByIdSelector } from '../Campaign/selectors_deprecated';
import ErrorBoundary from '../../components/ErrorBoundary';
import { withRouter } from '../../withRouter';
import { createBrowserHistory } from 'history';
import { isEqual } from 'lodash';
import { outdateEntity, resetEntity } from '../../actions';
import { loadEmailBySeason } from '../Email/actions';
import {
  Spacer,
  Title,
  ToolbarRoot,
} from '../../components/StyledElements/StyledElements';
import { compose } from 'redux';

const browserHistory = createBrowserHistory();

class SeasonNew extends Component {
  static propTypes = {
    requiredRoles: PropTypes.array,
    updateEmailSeason: PropTypes.func.isRequired,
    updateSmsSeason: PropTypes.func.isRequired,
  };

  static defaultProps = {
    requiredRoles: ['ROLE_PRO', 'ROLE_SUPERADMIN'],
  };

  state = {};

  componentDidMount = () => {
    const { campaignId, campaign, fetchCampaign } = this.props;
    if (!campaign) {
      fetchCampaign(campaignId, []);
    }
  };

  // eslint-disable-next-line no-unused-vars
  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return (
      !isEqual(this.props.campaignId, nextProps.campaignId) ||
      !isEqual(this.props.campaign, nextProps.campaign)
    );
  }

  handleSubmit = (form) => {
    const promises = [];
    const {
      updateEmailSeason,
      updateSmsSeason,
      seasonType,
      outdateEntity,
      resetEntity,
    } = this.props;
    let updateAction =
      seasonType === SEASON_TYPE.EMAIL ? updateEmailSeason : updateSmsSeason;
    let selectionId = form.get('season[selection]');

    promises.push(updateAction(form, null));
    return Promise.all(promises).then(
      (values) => {
        if (!(values && values[0] && values[0].error)) {
          outdateEntity('season');
          outdateEntity('email');
          outdateEntity('campaign');
          outdateEntity('layout');
          outdateEntity('sms');
          resetEntity('selection', selectionId);

          this.props.navigate(
            Routing.generate('campaign_show', { id: this.props.campaignId }),
          );
        }
      },
      () => {},
    );
  };

  handleFormMount = (form) => {
    this.form = form;
  };

  submit = () => {
    this.form.submit();
  };

  handleFormClose = () => {
    browserHistory.back();
  };

  handleValidityChange = (valid) => {
    this.setState({ valid: valid });
  };

  handleSubmitSuccess = () => {
    browserHistory.back();
  };

  onTouchCancel = () => {
    this.props.navigate(
      Routing.generate('campaign_show', { id: this.props.campaignId }),
    );
  };

  renderHeader = (campaign) => {
    const { seasonType, campaignId } = this.props;
    const seasonTypeTitle =
      seasonType === SEASON_TYPE.MAILING
        ? 'Mailing'
        : seasonType === SEASON_TYPE.CALLING
          ? 'Calling'
          : seasonType === SEASON_TYPE.EMAIL
            ? 'E-Mail'
            : 'SMS';
    return (
      <ToolbarRoot>
        <Title>
          <BreadcrumbsNav
            items={[
              { link: '/#/campaign', text: 'Kampagnen' },
              {
                link: '/#/campaign/' + campaign[campaignId].id + '/show',
                text: campaign[campaignId].title,
              },
              'Neuer Kanal (' + seasonTypeTitle + ')',
            ]}
          />
        </Title>
        <Spacer />
      </ToolbarRoot>
    );
  };

  render() {
    const { seasonType, campaignId, campaign } = this.props;

    if (!campaign) {
      return null;
    }

    return (
      <ACL authorized={this.props.requiredRoles}>
        {this.renderHeader(campaign)}
        <Paper>
          {seasonType === SEASON_TYPE.MAILING && (
            <ErrorBoundary>
              <MailingForm
                onSubmit={this.handleSubmit}
                values={{ season: { campaignId: campaignId } }}
              />
            </ErrorBoundary>
          )}
          {seasonType === SEASON_TYPE.CALLING && (
            <ErrorBoundary>
              <CallingForm
                onSubmit={this.handleSubmit}
                values={{ season: { campaignId: campaignId } }}
              />
            </ErrorBoundary>
          )}
          {seasonType === SEASON_TYPE.EMAIL && (
            <ErrorBoundary>
              <EmailForm
                onMount={this.handleFormMount}
                onValidityChange={this.handleValidityChange}
                campaignId={campaignId}
                seasonType={seasonType}
                onSubmit={this.handleSubmit}
                submit={this.submit}
                onCancel={this.onTouchCancel}
                values={{}}
              />
            </ErrorBoundary>
          )}
          {seasonType === SEASON_TYPE.SMS && (
            <ErrorBoundary>
              <SmsForm
                onMount={this.handleFormMount}
                onValidityChange={this.handleValidityChange}
                campaignId={campaignId}
                seasonType={seasonType}
                onSubmit={this.handleSubmit}
                submit={this.submit}
                onCancel={this.onTouchCancel}
                values={{}}
              />
            </ErrorBoundary>
          )}
        </Paper>
      </ACL>
    );
  }
}

const getCampaignById = getCampaignByIdSelector();

// eslint-disable-next-line no-unused-vars
function mapStateToProps(state, props) {
  return (state, props) => {
    const { mailing, calling, email } = state.forms;
    let form = null;
    switch (props.seasonType) {
      case 'email':
        form = email ? email : null;
        break;
      case 'mailing':
        form = mailing ? mailing : null;
        break;
      case 'calling':
        // eslint-disable-next-line no-unused-vars
        form = calling ? calling : null;
        break;
    }
    return {
      campaignId: parseInt(props.match.params.campaignId),
      campaign: getCampaignById(state, props),
    };
  };
}

const enhance = compose(
  withRouter,
  connect(mapStateToProps, {
    resetEntity,
    outdateEntity,
    loadEmailBySeason,
    fetchCampaign,
    updateEmailSeason,
    updateSmsSeason,
  }),
);

export default enhance(SeasonNew);

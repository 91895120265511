import { CALL_API } from '../../middleware';
import { Schemas } from '../../schemas';
import { sample } from 'lodash';
import {
  STAFF_PAGED_FAILURE,
  STAFF_PAGED_REQUEST,
  STAFF_PAGED_SUCCESS,
} from '../Staff/actions';

export const UPLOADLIST_REQUEST = 'UPLOADLIST_REQUEST';
export const UPLOADLIST_SUCCESS = 'UPLOADLIST_SUCCESS';
export const UPLOADLIST_FAILURE = 'UPLOADLIST_FAILURE';

function fetchUploadList(
  mode,
  sortColumns = [],
  sortOrders = [],
  offset = null,
  limit = null,
  join = [],
  filter = [],
) {
  return {
    [CALL_API]: {
      types: [UPLOADLIST_REQUEST, UPLOADLIST_SUCCESS, UPLOADLIST_FAILURE],
      endpoint: Routing.generate(
        'upload_list',
        {
          mode: mode,
          sortColumns: sortColumns,
          sortOrders: sortOrders,
          offset: offset,
          limit: limit,
          join: join,
          filter: filter,
        },
        true,
      ),
      schema: Schemas.UPLOADS,
    },
  };
}

export function loadUploadList(
  requiredFields = [],
  mode = null,
  sortColumns = [],
  sortOrders = [],
  offset = null,
  limit = null,
  join = [],
  filter = [],
) {
  return (dispatch, getState) => {
    const upload = getState().entities.upload;
    const outdated = getState().outdated.upload;
    const pagination =
      upload && getState().pagination.upload
        ? getState().pagination.upload
        : false;

    if (
      !outdated &&
      upload &&
      requiredFields.every((key) => sample(upload).hasOwnProperty(key))
    ) {
      return null;
    }

    return dispatch(
      fetchUploadList(
        mode,
        sortColumns,
        sortOrders,
        offset === 0 ? offset : pagination.next,
        limit ? limit : pagination.limit,
        join,
        filter,
      ),
    );
  };
}

function fetchUploadsPaged(
  sortColumns,
  sortOrders,
  offset = null,
  limit = null,
  join = [],
  filter = [],
) {
  return {
    [CALL_API]: {
      types: [STAFF_PAGED_REQUEST, STAFF_PAGED_SUCCESS, STAFF_PAGED_FAILURE],
      endpoint: Routing.generate(
        'upload_list',
        {
          sortColumns: sortColumns,
          sortOrders: sortOrders,
          offset: offset,
          limit: limit,
          join: join,
          filter: filter,
        },
        true,
      ),
      schema: Schemas.UPLOADS,
    },
  };
}

export function loadNext(
  sortColumns = [],
  sortOrders = [],
  offset = null,
  limit = null,
  join = [],
  filter = [],
) {
  return (dispatch, getState) => {
    const upload = getState().entities.upload;
    const pagination =
      upload && getState().pagination.upload
        ? getState().pagination.upload
        : false;

    if (!upload || !pagination) {
      return null;
    }

    return dispatch(
      fetchUploadsPaged(sortColumns, sortOrders, offset, limit, join, filter),
    );
  };
}

import selectors from '../../selectors';
import { intersectionWith, pickBy } from 'lodash';

const getCustomerListCustomer = (state) => state.entities.customerListCustomer;
const getEmailJobs = (state) => state.entities.emailJob;
const getEmailJobFilter = (state, props) =>
  props.jobId
    ? parseInt(props.jobId)
    : props?.match?.params?.seasonJobId
      ? parseInt(props.match.params.seasonJobId)
      : props.seasonJobId
        ? parseInt(props.seasonJobId)
        : null;
const getCustomerListCustomerFilter = (state, props) =>
  props.customerListCustomerId ? parseInt(props.customerListCustomerId) : null;

export const makeEmailJobById = () => {
  return selectors(
    [getEmailJobFilter, getEmailJobs],
    (emailJobId, emailJobs) => {
      return emailJobs && emailJobs[emailJobId] ? emailJobs[emailJobId] : null;
    },
  );
};

const getEmailJobById = makeEmailJobById();

export const makeCustomerListCustomerByJob = () => {
  return selectors(
    [getEmailJobById, getCustomerListCustomer],
    (emailJob, customerListCustomers) => {
      return customerListCustomers && emailJob
        ? pickBy(customerListCustomers, {
            customerList: emailJob.customerList,
          })
        : null;
    },
  );
};

export const makeCustomerListCustomerById = () => {
  return selectors(
    [getCustomerListCustomerFilter, getCustomerListCustomer],
    (customerListCustomerId, customerListCustomers) => {
      return customerListCustomers &&
        customerListCustomers[customerListCustomerId]
        ? customerListCustomers[customerListCustomerId]
        : null;
    },
  );
};

const getCustomerLists = (state) => state.entities.customerList;

export const makeCustomerListByJob = () => {
  return selectors(
    [getEmailJobById, getCustomerLists],
    (job, customerLists) => {
      return customerLists && job && job.customerList
        ? customerLists[job.customerList]
        : {};
    },
  );
};

const getCustomerListByJob = makeCustomerListByJob();

export const makeCustomerListCustomerByCl = () => {
  return selectors(
    [getCustomerListByJob, getCustomerListCustomer],
    (customerList, customerListCustomer) => {
      if (customerList && customerListCustomer) {
        return intersectionWith(
          customerList.customerListCustomers,
          Object.keys(customerListCustomer),
          (a, b) => {
            return a == b;
          },
        ).sort();
      }
    },
  );
};

import { CALL_API } from '../../middleware';
import { Schemas } from '../../schemas';
import { sample } from 'lodash';

export const LETTERS_REQUEST = 'LETTERS_REQUEST';
export const LETTERS_SUCCESS = 'LETTERS_SUCCESS';
export const LETTERS_FAILURE = 'LETTERS_FAILURE';

function fetchLetters(sortColumns, sortOrders) {
  return {
    [CALL_API]: {
      types: [LETTERS_REQUEST, LETTERS_SUCCESS, LETTERS_FAILURE],
      endpoint: Routing.generate(
        'letter_index',
        { sortColumns: sortColumns, sortOrders: sortOrders },
        true,
      ),
      schema: Schemas.LETTERS,
    },
  };
}

export function loadLetters(
  sortColumns = [],
  sortOrders = [],
  requiredFields = [],
) {
  return (dispatch, getState) => {
    const letter = getState().entities.letter;
    const resultset = getState().resultsets.letter;
    const outdated = getState().outdated.letter;

    if (
      !outdated &&
      resultset &&
      resultset.length &&
      letter &&
      requiredFields.every((key) => sample(letter).hasOwnProperty(key))
    ) {
      return null;
    }

    return dispatch(fetchLetters(sortColumns, sortOrders));
  };
}

export const LETTER_REQUEST = 'LETTER_REQUEST';
export const LETTER_SUCCESS = 'LETTER_SUCCESS';
export const LETTER_FAILURE = 'LETTER_FAILURE';

function fetchLetter(id) {
  return {
    [CALL_API]: {
      types: [LETTER_REQUEST, LETTER_SUCCESS, LETTER_FAILURE],
      endpoint: Routing.generate('letter_show', { id: id }, true),
      schema: Schemas.LETTER,
    },
  };
}

export function loadLetter(id, requiredFields = []) {
  return (dispatch, getState) => {
    const letters = getState().entities.letter;

    if (
      letters &&
      letters[id] &&
      requiredFields.every((key) => letters[id].hasOwnProperty(key))
    ) {
      return null;
    }

    return dispatch(fetchLetter(id));
  };
}

export const LETTER_UPDATE_REQUEST = 'LETTER_UPDATE_REQUEST';
export const LETTER_UPDATE_SUCCESS = 'LETTER_UPDATE_SUCCESS';
export const LETTER_UPDATE_FAILURE = 'LETTER_UPDATE_FAILURE';

function writeLetter(form, letterId) {
  return {
    [CALL_API]: {
      types: [
        LETTER_UPDATE_REQUEST,
        LETTER_UPDATE_SUCCESS,
        LETTER_UPDATE_FAILURE,
      ],
      endpoint: Routing.generate('letter_update', { id: letterId }, true),
      method: 'POST',
      data: form,
      schema: Schemas.LETTER,
    },
  };
}

export function updateLetter(form, letterId = null) {
  return (dispatch) => {
    return dispatch(writeLetter(form, letterId));
  };
}

export const LETTER_DELETE_REQUEST = 'LETTER_DELETE_REQUEST';
export const LETTER_DELETE_SUCCESS = 'LETTER_DELETE_SUCCESS';
export const LETTER_DELETE_FAILURE = 'LETTER_DELETE_FAILURE';

function deleteLetter(letterId, deleteToken) {
  return {
    [CALL_API]: {
      types: [
        LETTER_DELETE_REQUEST,
        LETTER_DELETE_SUCCESS,
        LETTER_DELETE_FAILURE,
      ],
      endpoint: Routing.generate(
        'letter_delete',
        { id: letterId, deleteToken: deleteToken },
        true,
      ),
      method: 'DELETE',
    },
  };
}

export function removeLetter(letterId, deleteToken) {
  return (dispatch) => {
    return dispatch(deleteLetter(letterId, deleteToken));
  };
}

export const LETTER_MAILINGS_REQUEST = 'LETTER_MAILINGS_REQUEST';
export const LETTER_MAILINGS_SUCCESS = 'LETTER_MAILINGS_SUCCESS';
export const LETTER_MAILINGS_FAILURE = 'LETTER_MAILINGS_FAILURE';

function fetchMailings(
  sortColumns,
  sortOrders,
  offset,
  limit,
  join,
  filter,
  letterId,
) {
  return {
    [CALL_API]: {
      types: [
        LETTER_MAILINGS_REQUEST,
        LETTER_MAILINGS_SUCCESS,
        LETTER_MAILINGS_FAILURE,
      ],
      endpoint: Routing.generate(
        'letter_mailings',
        {
          sortColumns: sortColumns,
          sortOrders: sortOrders,
          offset: offset,
          limit: limit,
          join: join,
          filter: filter,
          id: letterId,
        },
        true,
      ),
      schema: Schemas.MAILINGS,
    },
  };
}

export function loadMailings(
  letterId,
  sortColumns = [],
  sortOrders = null,
  join = [],
  filter = [],
) {
  return (dispatch) => {
    return dispatch(
      fetchMailings(
        sortColumns,
        sortOrders,
        null,
        null,
        join,
        filter,
        letterId,
      ),
    );
  };
}

export const LETTERMAILINGS_PAGED_REQUEST = 'LETTERMAILINGS_PAGED_REQUEST';
export const LETTERMAILINGS_PAGED_SUCCESS = 'LETTERMAILINGS_PAGED_SUCCESS';
export const LETTERMAILINGS_PAGED_FAILURE = 'LETTERMAILINGS_PAGED_FAILURE';

function fetchMailingsPaged(
  letterId,
  sortColumns,
  sortOrders,
  offset = null,
  limit = null,
  join,
  filter,
) {
  return {
    [CALL_API]: {
      types: [
        LETTERMAILINGS_PAGED_REQUEST,
        LETTERMAILINGS_PAGED_SUCCESS,
        LETTERMAILINGS_PAGED_FAILURE,
      ],
      endpoint: Routing.generate(
        'letter_mailings',
        {
          id: letterId,
          sortColumns: sortColumns,
          sortOrders: sortOrders,
          offset: offset,
          limit: limit,
          join: join,
          filter: filter,
        },
        true,
      ),
      schema: Schemas.MAILINGS,
    },
  };
}

export function loadNext(
  letterId,
  sortColumns = [],
  sortOrders = [],
  offset = null,
  limit = null,
  join = [],
  filter = [],
) {
  return (dispatch, getState) => {
    const mailing = getState().entities.mailing;
    const pagination =
      mailing && getState().pagination.mailing
        ? getState().pagination.mailing
        : false;

    if (!mailing || !pagination) {
      return null;
    }

    return dispatch(
      fetchMailingsPaged(
        letterId,
        sortColumns,
        sortOrders,
        offset,
        limit,
        join,
        filter,
      ),
    );
  };
}

export const LETTER_FORM_REQUEST = 'LETTER_FORM_REQUEST';
export const LETTER_FORM_SUCCESS = 'LETTER_FORM_SUCCESS';
export const LETTER_FORM_FAILURE = 'LETTER_FORM_FAILURE';

function fetchFormTokens() {
  return {
    [CALL_API]: {
      types: [LETTER_FORM_REQUEST, LETTER_FORM_SUCCESS, LETTER_FORM_FAILURE],
      endpoint: Routing.generate('letter_token', {}, true),
      schema: Schemas.FORM,
    },
  };
}

export function initForm() {
  return (dispatch, getState) => {
    const updateToken = getState().forms.updateToken;

    if (updateToken) {
      return null;
    }

    return dispatch(fetchFormTokens());
  };
}

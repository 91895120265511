import React, { Component } from 'react';
import { withRouter } from '../../withRouter';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@mui/material';
import Translate from '../../components/service/Translate';
import Form from '../../components/Form';
import ErrorBoundary from '../../components/ErrorBoundary';
import ValidationErrors from '../../ValidationErrors';
import Button from '@mui/material/Button';
import { initPreviewForm, loadPreview } from './actions';
import { isEmpty } from 'lodash';
import Grid from '@mui/material/Unstable_Grid2';
import { compose } from 'redux';

const validationSchema = Yup.object().shape({
  previewTo: Yup.string()
    .email(ValidationErrors.email)
    .required(ValidationErrors.required),
});

class RequestPreview extends Component {
  constructor(props) {
    super(props);
    this.submitMyForm = null;
  }

  static propTypes = {
    initPreviewForm: PropTypes.func,
    loadPreview: PropTypes.func,
    previewToken: PropTypes.string,
    emailId: PropTypes.string,
  };

  static defaultProps = {};

  state = {
    submitting: false,
  };

  componentDidMount = () => {
    this.props.initPreviewForm(this.props.emailId);
  };

  handleSubmitMyForm = (e) => {
    if (this.submitMyForm) {
      this.submitMyForm(e);
    }
  };
  bindSubmitForm = (submitForm) => {
    this.submitMyForm = submitForm;
  };

  renderFieldset = (props) => {
    const { handleChange, handleBlur, touched, errors, values } = props;

    this.bindSubmitForm(props.submitForm);

    return (
      <Grid xs={12}>
        <TextField
          label={<Translate>email</Translate>}
          name="previewTo"
          defaultValue={values.previewTo ? values.previewTo : ''}
          onChange={handleChange}
          onBlur={handleBlur}
          helperText={touched.previewTo ? errors.previewTo : ''}
          error={touched.previewTo && Boolean(errors.previewTo)}
        />
      </Grid>
    );
  };

  handleSubmit = (form) => {
    const { loadPreview, emailId, onClose } = this.props;

    this.setState({ submitting: true });
    const promise = [loadPreview(form, emailId)];
    return Promise.all(promise).then(
      (values) => {
        this.setState({ submitting: false });
        if (!(values && values[0] && values[0].error)) {
          onClose();
        }
      },
      () => {
        this.setState({ submitting: false });
      },
    );
  };

  render() {
    const { open, onClose, emailId, previewToken, previewTo } = this.props;
    const { submitting } = this.state;

    return (
      <ErrorBoundary>
        <Dialog
          disableEscapeKeyDown
          open={open}
          onClose={onClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            <Translate>Request Preview</Translate>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Translate>
                To which address should the preview be sent?
              </Translate>
            </DialogContentText>
            <Form
              onSubmit={this.handleSubmit}
              initialValues={{
                previewTo: previewTo,
                id: emailId,
                previewToken: previewToken,
              }}
              disableToolbar={true}
              validationSchema={validationSchema}
              name="requestPreview"
              renderFieldset={this.renderFieldset}
              values={{
                previewTo: previewTo,
                id: emailId,
                previewToken: previewToken,
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button disabled={submitting} onClick={onClose} color="primary">
              <Translate>Cancel</Translate>
            </Button>
            <Button
              disabled={submitting || !previewToken}
              onClick={this.handleSubmitMyForm}
              color="primary"
            >
              <Translate>Send</Translate>
            </Button>
          </DialogActions>
        </Dialog>
      </ErrorBoundary>
    );
  }
}

const makeMapStateToProps = () => {
  return (state) => {
    const {
      forms: { email: emailForm },
    } = state;

    return {
      previewToken: !isEmpty(emailForm) ? emailForm.previewToken : null,
      previewTo: !isEmpty(emailForm) ? emailForm.previewTo : null,
    };
  };
};

const enhance = compose(
  withRouter,
  connect(makeMapStateToProps, { loadPreview, initPreviewForm }),
);

export default enhance(RequestPreview);

const LinkTypes = [
  {
    name: 'tracking_web',
    label: 'Webseite (inkl. Tracking)',
    attrs: {
      href: "{{'{{original_url}}'|visitUrl('{{name}}',{{interface_id}},queue)}}",
      target: '_blank',
    },
    fields: [
      {
        name: 'original_url',
        label: 'Original Url',
        defaultValue: '',
        inputType: 'text',
        placeholderText: null,
        options: [],
      },
      {
        name: 'name',
        label: 'Name',
        defaultValue: '',
        inputType: 'text',
        placeholderText: null,
        options: [],
      },
      {
        name: 'interface_id',
        label: 'Interface ID',
        defaultValue: '',
        inputType: 'text',
        placeholderText: null,
        options: [
          { value: 'customer.affiliate.interfaceId', label: 'Verbünde' },
          { value: 'owner.organisationId', label: 'Servicepartner' },
        ],
      },
    ],
  },
  {
    name: 'tracking_var',
    label: 'Variable (inkl. Tracking)',
    attrs: {
      href: "{{{{variable}}|visitUrl('{{name}}',{{interface_id}},queue)}}",
      target: '_blank',
    },
    fields: [
      {
        name: 'variable',
        label: 'Variable',
        defaultValue: '',
        inputType: 'text',
        placeholderText: null,
        options: [],
      },
      {
        name: 'name',
        label: 'Name',
        defaultValue: '',
        inputType: 'text',
        placeholderText: null,
        options: [],
      },
      {
        name: 'interface_id',
        label: 'Interface ID',
        defaultValue: '',
        inputType: 'text',
        placeholderText: null,
        options: [
          { value: 'customer.affiliate.interfaceId', label: 'Verbünde' },
          { value: 'owner.organisationId', label: 'Servicepartner' },
        ],
      },
    ],
  },
  {
    name: 'short_link_with_mailer_url',
    label: 'Kurz-URL mit Kunden-Domain',
    attrs: {
      href: "{{'{{target_link}}'|shorten(queue,true)}}",
      target: '_blank',
    },
    fields: [
      {
        name: 'target_link',
        label: 'Ziel-URL',
        defaultValue: '',
        inputType: 'text',
        placeholderText: null,
        options: [],
      },
    ],
  },
  {
    name: 'short_link',
    label: 'Kurz-URL',
    attrs: {
      href: "{{'{{target_link}}'|shorten(queue)}}",
      target: '_blank',
    },
    fields: [
      {
        name: 'target_link',
        label: 'Ziel-URL',
        defaultValue: '',
        inputType: 'text',
        placeholderText: null,
        options: [],
      },
    ],
  },
  {
    name: 'twig',
    label: 'Anweisung',
    attrs: {
      href: '{{target_link}}',
      target: '_blank',
    },
    fields: [
      {
        name: 'target_link',
        label: 'Anweisung',
        defaultValue: '',
        inputType: 'text',
        placeholderText: null,
        options: [],
      },
    ],
  },
  {
    name: 'twig',
    label: 'Landingpage',
    attrs: {
      href: "{{webCode(car,'e')}}",
      target: '_blank',
    },
  },
  {
    name: 'webcode_tracking',
    label: 'Landingpage (inkl. Tracking)',
    attrs: {
      href: "{{webCode(car,'e')|visitUrl(job.season.configKey,owner.organisationId,queue)}}",
      target: '_blank',
    },
  },
  {
    name: 'wechsel_telefon',
    label: 'Telefon Räderwechsel',
    attrs: {
      href: "{{config.phone(car,'Wechsel')|phoneLink}}",
      target: '_blank',
    },
  },
  {
    name: 'verkauf_telefon',
    label: 'Telefon Reifenverkauf',
    attrs: {
      href: "{{config.phone(car,'Verkauf')|phoneLink}}",
      target: '_blank',
    },
  },
];

export default LinkTypes;

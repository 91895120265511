import selectors from '../../selectors';
import { pickBy, merge } from 'lodash';

const getSelections = (state) => state.entities.selection;
const getSeasonFilter = (state, props) =>
  props.seasonId ? parseInt(props.seasonId) : null;

export const makeSelectableSelections = () => {
  return selectors([getSeasonFilter, getSelections], (seasonId, selections) => {
    if (!selections) {
      return null;
    }

    const selectable = pickBy(selections, { season: null });

    if (seasonId) {
      merge(selectable, pickBy(selections, { season: seasonId }));
    }
    return selectable;
  });
};

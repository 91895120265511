import {
  merge,
  concat,
  difference,
  set,
  omit,
  without,
  each,
  assignIn,
  keys,
  every,
  get,
  union,
} from 'lodash';
import { routerReducer } from 'react-router-redux';
import { AnyAction, combineReducers } from 'redux';
import { StateFromReducersMapObject } from '@reduxjs/toolkit';
import { CAMPAIGNCONFIGPARAM_DUPLICATE_SUCCESS } from './scenes/CampaignConfigParam/actions';
import {
  RESET_ERROR_MESSAGE,
  RESET_INFO_MESSAGE,
  RESET_ENTITY,
  OUTDATE_ENTITY,
  RESET_DOWNLOAD,
} from './actions';
import menuSlice from './scenes/Menu/MenuSlice';
import emailAccountSlice from './scenes/EmailAccount/EmailAccountSlice';
import emailSlice from './scenes/Email/EmailSlice';
import mailingModuleSlice from './scenes/MailingModule/MailingModuleSlice';
import seasonSlice from './scenes/Season/SeasonSlice';
import locationSlice from './scenes/Location/LocationSlice';
import affiliateSlice from './scenes/Affiliate/AffiliateSlice';
import campaignConfigParamSlice from './scenes/CampaignConfigParam/CampaignConfigParamSlice';
import campaignSlice from './scenes/Campaign/CampaignSlice';
import statisticCampaignSlice from './scenes/StatisticCampaign/StatisticCampaignSlice';

const getActionType = (type: string) => type.substr(type.indexOf('_') + 1);

const getActionState = (type: string) => {
  const actionType = getActionType(type);
  return actionType.substr(actionType.indexOf('_') + 1);
};

const getActionDomainType = (type: string) =>
  type.substr(0, type.lastIndexOf('_'));

// Updates an entity cache in response to any action with response.entities.
function entities(
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: Record<string, Record<string, never>> = {},
  action: AnyAction,
) {
  const status = getActionState(action.type);
  const type = getActionType(action.type);

  if ([CAMPAIGNCONFIGPARAM_DUPLICATE_SUCCESS].includes(action.type)) {
    return state;
  }

  if (action.type === RESET_ENTITY) {
    return set(
      merge({}, state),
      [action.entity],
      omit(state[action.entity], [action.id]),
    );
  }

  if (status === 'SUCCESS' && action.response.entities) {
    let merged: Record<string, Record<string, never>>;
    switch (type) {
      /* case 'DELETE_SUCCESS': // remove
                merged = mergeWith(state, action.response.entities, (objValue, srcValue) => {
                    return srcValue;
                }); */

      // TODO: Nach Regressionsfehlern Ausschau halten
      // case 'DELETE_SUCCESS': // remove
      // merged = mergeWith(state, action.response.entities);
      // return merge({}, merged);
      case 'UPDATE_SUCCESS': // replace (merge would not remove deleted collection items)
        merged = merge({}, state);
        each(action.response.entities, (value, key) => {
          if (every(value, (o) => keys(o).length > 1)) {
            assignIn(merged[key], value);
          }
        });

        return merged;
      case 'PAGED_SUCCESS':
      case 'INFINITESCROLL_SUCCESS':
        // @ts-ignore Remove when refactored
        set(
          action.response.entities,
          action.path,
          union(
            // @ts-ignore Remove when refactored
            get(state, action.path),
            get(action.response.entities, action.path),
          ),
        );

        return merge({}, state, action.response.entities);

      /*            return {
                ...state,
                [action.path]: merge({}, stateAtPath, responseEntitiesAtPath),
            }; */
      case 'SEARCH_SUCCESS':
      default:
        return merge({}, state, action.response.entities);
    }
  }

  return state;
}

// sorting order for lists
// eslint-disable-next-line @typescript-eslint/default-param-last
function resultsets(state: Record<string, number[]> = {}, action: AnyAction) {
  const status = getActionState(action.type);
  const type = getActionType(action.type);

  if (type === 'RELOAD_SUCCESS') {
    return state;
  }

  if (action.type === RESET_ENTITY) {
    return set(
      merge({}, state),
      [action.entity],
      without(state[action.entity], action.id),
    );
  }

  if (
    status === 'SUCCESS' &&
    action.response.result &&
    type === 'INFINITESCROLL_SUCCESS' &&
    action.response.pagination[action.response.key][action.response.result]
      .current !== 0
  ) {
    return set(merge({}, state), [action.response.key], action.response.result);
    // return set(merge({}, state), [action.response.key], [...state[action.response.key], ...action.response.result]);
  }

  if (
    status === 'SUCCESS' &&
    action.response.result &&
    type === 'PAGED_SUCCESS' &&
    action.response.pagination[action.response.key].current !== 0
  ) {
    return set(merge({}, state), [action.response.key], action.response.result);
  }

  if (
    status === 'SUCCESS' &&
    action.response.result &&
    type !== 'SEARCH_SUCCESS'
  ) {
    return set(merge({}, state), [action.response.key], action.response.result);
  }

  return state;
}

// eslint-disable-next-line @typescript-eslint/default-param-last
function pagination(state = {}, action: AnyAction) {
  const status = getActionState(action.type);

  if (status === 'SUCCESS' && action.response.pagination) {
    return merge({}, state, action.response.pagination);
  }

  return state;
}

// form tokens
// eslint-disable-next-line @typescript-eslint/default-param-last
function forms(state = {}, action: AnyAction) {
  const type = getActionType(action.type);

  if (type === 'FORM_SUCCESS') {
    return merge({}, state, action.response.entities.form);
  }

  return state;
}

// eslint-disable-next-line @typescript-eslint/default-param-last
function searchresults(state = {}, action: AnyAction) {
  const type = getActionType(action.type);

  if (type === 'SEARCH_SUCCESS' && action.response.result) {
    return set(merge({}, state), [action.response.key], action.response.result);
  }

  return state;
}

// loading true if found %_%_REQUEST
// eslint-disable-next-line @typescript-eslint/default-param-last
function loading(state: string[] = [], action: AnyAction) {
  const status = getActionState(action.type);
  const domainType = getActionDomainType(action.type);

  switch (status) {
    case 'REQUEST':
      return concat(state, domainType);
    case 'SUCCESS':
    case 'FAILURE':
      return difference(state, [domainType]);
    default:
      return state;
  }
}

// loading true if found %_%_REQUEST
// eslint-disable-next-line @typescript-eslint/default-param-last
function success(state: string[] = [], action: AnyAction) {
  const status = getActionState(action.type);
  const domainType = getActionDomainType(action.type);

  switch (status) {
    case 'SUCCESS':
      return concat(state, domainType);
    case 'REQUEST':
    case 'FAILURE':
      return difference(state, [domainType]);
    default:
      return state;
  }
}

// eslint-disable-next-line @typescript-eslint/default-param-last
function downloads(state = null, action: AnyAction) {
  const type = getActionType(action.type);

  if (action.type === RESET_DOWNLOAD || type === 'DOWNLOAD_REQUEST') {
    return null;
  }
  if (
    type === 'DOWNLOAD_SUCCESS' &&
    action.response.entities &&
    action.response.entities.download
  ) {
    return action.response.entities.download[
      keys(action.response.entities.download)[0]
    ];
  }

  return state;
}

function loggedIn(
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: { loggedIn: boolean } = { loggedIn: false },
  action: AnyAction,
) {
  const { type, response } = action;

  if (response && (response.status === 403 || response.status === 401)) {
    return { ...state, loggedIn: false };
  }
  if (type === 'SESSION_FAILURE' || type === 'LOGIN_FAILURE') {
    return { ...state, loggedIn: false };
  }
  if (type === 'SESSION_SUCCESS' || type === 'LOGIN_SUCCESS') {
    return { ...state, loggedIn: true };
  }

  return state;
}

// Updates error message to notify about the failed fetches.
// eslint-disable-next-line @typescript-eslint/default-param-last
function errorMessage(state = null, action: AnyAction) {
  const { type, error, response } = action;

  if (
    type.endsWith('/pending') ||
    type === RESET_ERROR_MESSAGE ||
    (type.includes('SEARCH_FAILURE') && error.includes('Abort Error'))
  ) {
    return null;
  }
  if (
    error &&
    !(
      type === 'SESSION_FAILURE' ||
      type === 'LOGIN_FAILURE' ||
      response?.status === 403 ||
      response?.status === 401
    )
  ) {
    if (type.endsWith('/rejected')) {
      return action.error.message;
    }
    return action.error;
  }

  return state;
}

// Updates info message to notify about the failed fetches.
// eslint-disable-next-line @typescript-eslint/default-param-last
function infoMessage(state = null, action: AnyAction) {
  const { type, response } = action;

  if (type === RESET_INFO_MESSAGE || type.endsWith('/pending')) {
    return null;
  }
  if (response && response.info) {
    return response.info;
  }

  return state;
}

// eslint-disable-next-line @typescript-eslint/default-param-last
function outdated(state = {}, action: AnyAction) {
  if (action.type === RESET_ENTITY || action.type === OUTDATE_ENTITY) {
    return merge({}, state, { [action.entity]: true });
  }

  const status = getActionState(action.type);
  if (status === 'SUCCESS' && action.response.entities) {
    return merge({}, state, { [action.response.key]: false });
  }

  return state;
}

const reducer = {
  loading,
  success,
  entities,
  resultsets,
  pagination,
  forms,
  searchresults,
  downloads,
  infoMessage,
  loggedIn,
  errorMessage,
  outdated,
  routing: routerReducer,
  menu: menuSlice,
  mailingModule: mailingModuleSlice,
  season: seasonSlice,
  location: locationSlice,
  affiliate: affiliateSlice,
  emailAccount: emailAccountSlice,
  email: emailSlice,
  campaignConfigParam: campaignConfigParamSlice,
  campaign: campaignSlice,
  statisticCampaign: statisticCampaignSlice,
};

export type RootState = StateFromReducersMapObject<typeof reducer>;

const rootReducer = combineReducers(reducer);

export default rootReducer;

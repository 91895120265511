import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isEqual } from 'lodash';
import { loadMailings, loadNext } from '../actions';
import { outdateEntity } from '../../../actions';
import { makeMailingsByLetter } from '../selectors';
import ErrorBoundary from '../../../components/ErrorBoundary';
import Translate from '../../../components/service/Translate';
import RenderCellExpand from '../../../components/DataGrid/RenderCellExpand';
import DataGridTable from '../../../components/DataGrid/DataGrid';

class Mailings extends Component {
  static propTypes = {
    letterId: PropTypes.number.isRequired,
    loadMailings: PropTypes.func.isRequired,
    loadNext: PropTypes.func.isRequired,
    outdateEntity: PropTypes.func.isRequired,
    resultset: PropTypes.array,
    mailings: PropTypes.object,
  };

  static defaultProps = {};

  state = {
    current: null,
    currentIndex: null,
    sortColumns: ['id'],
    sortOrders: ['asc'],
    columns: [
      {
        field: 'id',
        headerName: 'ID',
        relation: null,
        id: 'id',
        type: 'number',
        width: 30,
      },
      {
        field: 'campaign',
        headerName: 'Name',
        relation: 'season.campaign',
        id: 'primaryText',
        type: 'string',
        sort: 'campaign.title',
        joinBy: { campaign: 'season.campaign' },
        minWidth: 150,
        flex: 1,
        renderCell: RenderCellExpand,
      },
      {
        field: 'enabled',
        headerName: 'Aktiviert',
        relation: 'season',
        id: 'enabled',
        modifier: 'translate',
        type: 'singleSelect',
        sort: 'season.enabled',
        minWidth: 90,
        flex: 1,
        valueOptions: [
          { label: 'ja', value: 1 },
          { label: 'nein', value: 0 },
        ],
        renderCell: RenderCellExpand,
      },
      {
        field: 'subscribed',
        headerName: 'am',
        relation: 'season',
        id: 'subscribed',
        sort: 'season.subscribed',
        joinBy: { season: 'mailing.season' },
        minWidth: 150,
        flex: 1,
        renderCell: (params) =>
          RenderCellExpand({ ...params, modifier: 'datetime' }),
      },
      {
        field: 'status',
        headerName: 'Template Status',
        relation: 'template',
        id: 'status',
        modifier: 'translate',
        type: 'string',
        sort: 'template.status',
        joinBy: { template: 'mailing.template' },
        minWidth: 150,
        flex: 1,
        renderCell: RenderCellExpand,
      },
    ],
    loading: false,
  };

  componentDidMount = () => {
    if (this.props.letterId) {
      this.loadMailings();
    }
  };

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState) {
    if (!isEqual(this.props.letterId, prevProps.letterId)) {
      this.loadMailings();
    }
  }

  shouldComponentUpdate = (nextProps, nextState) => {
    return (
      !isEqual(nextProps.letterId, this.props.letterId) ||
      !isEqual(nextProps.mailings, this.props.mailings) ||
      !isEqual(nextProps.resultset, this.props.resultset) ||
      !isEqual(nextProps.pagination, this.props.pagination) ||
      !isEqual(nextState.loading, this.state.loading) ||
      !isEqual(nextProps.letter, this.props.letter)
    );
  };

  loadMailings = () => {
    this.setState({ loading: true });
    this.props.loadMailings(this.props.letterId).then(
      () => {
        this.setState({ loading: false });
      },
      () => {
        this.setState({ loading: false });
      },
    );
  };

  handleRowSelection = (selectedRow, selectedIndex) => {
    this.setState({ current: selectedRow, currentIndex: selectedIndex });
  };

  handleOrderChange = (columns, sort, join, limit, offset, filter) => {
    this.setState({ sortColumns: columns, sortOrders: sort });
    this.props.outdateEntity('mailing');

    return this.loadNext(columns, sort, join, offset, limit, filter);
  };

  loadNext = (
    columns,
    sort,
    join = [],
    offset = null,
    limit = null,
    filter = [],
  ) => {
    return Promise.all([
      this.props.loadNext(
        this.props.letterId,
        columns,
        sort,
        offset,
        limit,
        join,
        filter,
      ),
    ]);
  };

  render() {
    const { mailings, resultset, embedded, pagination, noPaper } = this.props;
    const { columns, loading } = this.state;

    if (loading) {
      return <Translate>Data loading...</Translate>;
    }
    return (
      <>
        <ErrorBoundary>
          <DataGridTable
            title="Mailings"
            rows={mailings}
            iteratableRows={resultset}
            columns={columns}
            rowHeight={52}
            pagination={pagination}
            disableToolbar
            embedded={embedded}
            onOrderChange={this.handleOrderChange}
            onLoadNext={this.loadNext}
            noPaper={noPaper}
          />
        </ErrorBoundary>
      </>
    );
  }
}

const makeMapStateToProps = () => {
  const getMailingsByLetter = makeMailingsByLetter();

  return (state, props) => {
    const letterId = props.letterId;
    const {
      entities: { letter },
      resultsets: { mailing: resultset },
      pagination: { mailing: pagination },
    } = state;

    return {
      letter: letter ? letter[letterId] : null,
      mailings: getMailingsByLetter(state, props),
      resultset: resultset,
      pagination: pagination,
    };
  };
};

export default connect(makeMapStateToProps, {
  outdateEntity,
  loadMailings,
  loadNext,
})(Mailings);

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isEqual } from 'lodash';
import { loadLog } from '../actions';
import { makeLogByStaff } from '../../Log/selectors';
import DateTime from '../../../components/service/DateTime';
import Translate from '../../../components/service/Translate';
import {
  Table,
  TableData,
} from '../../../components/StyledElements/StyledElements';
import { Box } from '@mui/material';

class Log extends Component {
  static propTypes = {
    staffId: PropTypes.number.isRequired,
    loadLog: PropTypes.func.isRequired,
    staff: PropTypes.object,
    logs: PropTypes.array,
  };

  componentDidMount() {
    this.props.loadLog(this.props.staffId, ['logs']);
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState) {
    if (this.props.staffId != prevProps.staffId) {
      this.props.loadLog(this.props.staffId, ['logs']);
    }
  }

  // eslint-disable-next-line no-unused-vars
  shouldComponentUpdate = (nextProps, nextState) => {
    return (
      nextProps.staffId != this.props.staffId ||
      !isEqual(this.props.staff, nextProps.staff) ||
      !isEqual(this.props.logs, nextProps.logs)
    );
  };

  renderLog = (log) => {
    return (
      <tr key={`stafflog_${log.id}`}>
        <TableData width="15%" textColor="secondaryTextColor">
          <DateTime format="dd.mm.yyyy HH:MM">{log.created}</DateTime>
        </TableData>
        <TableData width="15%" textColor="secondaryTextColor">
          <Translate
            wildcards={{
              '%entity%': `log${log.entityName}`,
              '%id%': log.entityId,
              '%mode%': `logAction_${log.mode}`,
            }}
            wildcardTranslate={true}
            raw={true}
          >
            Entity %entity% with ID %id% has been %mode%.
          </Translate>
        </TableData>
      </tr>
    );
  };

  render() {
    const { staff, logs } = this.props;

    if (!staff || !logs) {
      return null;
    }

    return (
      <Box sx={{ pt: 3 }}>
        <Table width="100%">
          <thead>
            <tr>
              <TableData width="15%" textColor="secondaryTextColor">
                Datum
              </TableData>
              <TableData width="15%" textColor="secondaryTextColor">
                Aktion
              </TableData>
            </tr>
          </thead>
          <tbody>{logs.map(this.renderLog)}</tbody>
        </Table>
      </Box>
    );
  }
}

const makeMapStateToProps = () => {
  const getLogByStaff = makeLogByStaff();

  return (state, props) => {
    const staffId = props.staffId;
    const {
      entities: { staff },
    } = state;

    if (!staff || !staff[staffId]) {
      return {};
    }

    return {
      staff: staff[staffId],
      logs: getLogByStaff(state, props),
    };
  };
};

export default connect(makeMapStateToProps, { loadLog })(Log);

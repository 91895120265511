import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ACL from '../../components/ACL';
import Letter from './Letter';

class LetterIndex extends Component {
  static propTypes = {
    requiredRoles: PropTypes.array,
  };

  static defaultProps = {
    requiredRoles: ['ROLE_SUPERADMIN'],
    resultset: [],
  };

  render() {
    return (
      <ACL authorized={this.props.requiredRoles}>
        <Letter />
      </ACL>
    );
  }
}

export default LetterIndex;

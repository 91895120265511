import { styled } from '@mui/system';
import {
  AppBar,
  Drawer,
  MenuItem,
  Paper,
  Toolbar,
  LinearProgress,
  linearProgressClasses,
} from '@mui/material';
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';
import { seedsBlue50, seedsGrey140 } from '../../theme/colors';

export const CheckboxField = styled('div')({
  marginTop: 8,
  display: 'inline-flex',
  flexDirection: 'column',
});

export const H3 = styled('h3')(({ theme }) => ({
  lineHeight: '40px',
  marginBottom: theme.spacingConstants.desktopGutter,
}));

export const MultiClassSwitch = styled(Switch)({
  '&.root': {
    paddingRight: 8,
    position: 'sticky',
    paddingLeft: 0,
  },
  '&.switchBase': {
    color: 'accent2Color',
    '&$checked': {
      color: 'accent2Color',
    },
    '&$checked + $track': {
      backgroundColor: 'accent2Color',
    },
  },
  '&.checked': {
    color: 'accent2Color',
  },
});

export const NotSelected = styled('h4')({
  color: '#f44336',
});

export const SubList = styled('li')({
  listStyle: 'none',
});

export const Section = styled('section')(({ theme }) => ({
  marginBottom: theme.spacingConstants.desktopGutter,
}));

export const H2 = styled('h2')(({ theme }) => ({
  marginBottom: theme.spacingConstants.desktopGutter,
}));

export const CustomerListDrawer = styled(Drawer)({
  width: 256,
  flexShrink: 0,
  top: 64,
  '& .MuiDrawer-paper': {
    width: 256,
    top: 64,
    padding: 0,
  },
});

export const MarginButton = styled(Button)({
  marginRight: 8,
  marginLeft: 8,
});

export const TableData = styled('td', {
  shouldForwardProp: (prop) =>
    ![
      'textColor',
      'whiteSpace',
      'width',
      'textAlign',
      'fontSize',
      'fontWeight',
      'verticalAlign',
    ].includes(prop),
})(
  ({
    textColor,
    whiteSpace,
    width,
    textAlign,
    fontSize,
    fontWeight,
    verticalAlign,
    theme,
  }) => ({
    color: theme.palette[textColor] ?? textColor,
    whiteSpace: whiteSpace,
    width: width,
    textAlign: textAlign,
    fontSize: fontSize,
    fontWeight: fontWeight,
    verticalAlign: verticalAlign,
  }),
);

export const Table = styled('table', {
  shouldForwardProp: (prop) =>
    !['tableWidth', 'tableLayout', 'verticalAlign'].includes(prop),
})(({ tableWidth, tableLayout, verticalAlign }) => ({
  width: tableWidth,
  tableLayout: tableLayout,
  verticalAlign: verticalAlign,
}));

export const Title = styled('div')({
  flex: '0 0 auto',
});

export const ToolbarRoot = styled(Toolbar)({
  paddingRight: 8,
  position: 'sticky',
});

export const Spacer = styled('div')({
  flex: '1',
});

export const ColorActions = styled('div')({
  color: 'shadowColor',
});

export const Actions = styled('div')({
  float: 'right',
});

export const EmptyMenuItem = styled(MenuItem)({
  width: '100%',
  height: 30,
});
EmptyMenuItem.defaultProps = {
  ...EmptyMenuItem.defaultProps,
  value: '',
};

export const AccentColorButton = styled(Button)({
  backgroundColor: 'accent3Color',
});

export const PaperWidthDockedList = styled(Paper)(({ theme }) => ({
  width: `calc(100% - ${theme.spacingConstants.dockedListWidth}px)`,
}));

export const CustomerListAppBar = styled(AppBar)(({ theme }) => ({
  width: 256,
  top: 64,
  backgroundColor: theme.palette.textColor,
}));

export const BorderLinearProgress = styled(LinearProgress)({
  height: 10,
  borderRadius: 5,
  width: '100%',
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: seedsGrey140,
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: seedsBlue50,
  },
});

import React, { Component } from 'react';
import { Paper } from '@mui/material';
import {
  H2,
  Section,
  SubList,
} from '../../components/StyledElements/StyledElements';
import { styled } from '@mui/system';
import Scene from '../../components/Scene';

const Blockquote = styled('blockquote')({
  backgroundColor: 'body',
  padding: 8,
  margin: 8,
});

class Mailingtemplate extends Component {
  render() {
    return (
      // todo childStyle
      <Scene>
        <main>
          <Paper>
            <header>
              <H2>Textblöcke</H2>
            </header>
            <Section>
              <header>
                <h3>Unterschrift einrichten</h3>
              </header>
              <ol>
                <li>
                  PDF/JPG/PNG unter Einstellungen &gt; Mitarbeiter hochladen
                </li>
                <li>
                  %signature(username, {'{legend}'},{' '}
                  {'{x, y, width, height, offsetX, offsetY}'})%
                </li>
                <SubList>
                  <ol>
                    {/* eslint-disable-next-line react/no-unescaped-entities */}
                    <li>
                      legend: Eigenschaften des Mitarbeiters (&quot;,&quot;
                      ergibt einen Umbruch)
                    </li>
                    <li>x: Verschiebung vom aktuellen Wert nach x</li>
                    <li>y: Verschiebung vom aktuellen Wert nach y</li>
                    <li>width: Breite der Unterschrift (abhängig von Höhe)</li>
                    <li>height: Höhe der Unterschrift (abhängig von Breite)</li>
                    <li>offsetX: Verschiebung der Legende Richtung x</li>
                    <li>offsetY: Verschiebung der Legende Richtung y</li>
                  </ol>
                </SubList>
              </ol>
            </Section>
            <Section>
              <header>
                <h3>Textblock einrichten</h3>
              </header>
              <Blockquote>
                %block(llx,lly,width,height,padding_x,padding_y) config(siehe:
                unten)% <br />
                content here <br />
                %endblock% <br />
              </Blockquote>
              <p>Config-Werte:</p>
              <ul>
                <li>
                  border: Rahmen abschalten oder einfärben (false oder
                  cmyk-Werte)
                </li>
                <li>font: Schriftart (light, regular, demi, bold)</li>
                <li>fontsize: Schriftgröße</li>
                <li>fillcolor: Schriftfarbe (cmyk-Werte)</li>
              </ul>
              <p>Beispiel:</p>
              <Blockquote>
                %block(305,712,255,25,5,4) config(border: 0.15 1 1 0, font:
                light, fontsize: 8, fillcolor: {'{cmyk 0.15 1 1 0}'}
                )%foobar%endblock%
              </Blockquote>
            </Section>
            <Section>
              <header>
                <h3>Platzhalter</h3>
              </header>
              <ul>
                <li>checkbox: Checkbox vor dem Text</li>
                <li>
                  lineto: Zieht eine Linie (x-Verschiebung kann mit offset
                  angegeben werden)
                </li>
              </ul>
              <p>Beispiel:</p>
              <Blockquote>
                %checkbox% Lorem ipsum <br />
                %lineto% <br />
                dolor sit:%lineto(offset: 43) <br />
              </Blockquote>
            </Section>
          </Paper>
        </main>
      </Scene>
    );
  }
}
export default Mailingtemplate;

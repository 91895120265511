import React, { JSX } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

function EeloyIcon({ style }: SvgIconProps): JSX.Element {
  return (
    <SvgIcon viewBox="0 0 38.27 35.74" style={style}>
      <path
        fill="#619DD8"
        d="M11.58,5.17c-0.22,0.01-0.42,0.02-0.61,0.04C8.2,5.55,6.5,6.87,5.62,9.36c-0.92,2.62-0.43,4.77,1.56,6.77
		c0.12,0.12,0.5,0.49,1.05,1.04c0.82,0.81,2.03,2,3.39,3.35c2.02-2.04,3.74-3.77,4.24-4.27l0.13-0.13c1.98-2,2.48-4.15,1.56-6.77
		c-0.88-2.49-2.58-3.81-5.35-4.15C12,5.19,11.8,5.18,11.58,5.17 M19.14,35.74l-7.53-7.88c-1.3,1.3-2.5,2.51-3.37,3.38
		c-1.01,1.01-2.65,1.01-3.66,0c-1.01-1.01-1.01-2.65,0-3.66c0.88-0.88,2.09-2.09,3.39-3.4c-1.35-1.34-2.55-2.52-3.38-3.34
		c-0.58-0.57-0.97-0.96-1.1-1.08c-3.4-3.42-4.35-7.62-2.76-12.13c1.54-4.37,4.86-6.98,9.61-7.56c0.38-0.04,0.76-0.07,1.18-0.08
		l0.05,0l0.05,0c0.42,0.01,0.8,0.03,1.18,0.08c4.74,0.58,8.07,3.19,9.61,7.56c1.59,4.51,0.63,8.7-2.76,12.13l-0.12,0.13
		c-0.5,0.5-2.24,2.25-4.27,4.31l3.89,4.07l5.2-5.44c2.38-2.39,4.66-4.64,5.88-5.85c0.45-0.45,0.76-0.75,0.86-0.85
		c1.98-2,2.48-4.15,1.56-6.77c-0.88-2.49-2.58-3.81-5.35-4.15c-0.19-0.02-0.39-0.04-0.61-0.04c-0.22,0.01-0.42,0.02-0.61,0.04
		c-1.42,0.17-2.7-0.84-2.88-2.25c-0.17-1.42,0.84-2.71,2.25-2.88c0.37-0.04,0.76-0.07,1.18-0.08l0.05,0l0.05,0
		c0.42,0.01,0.8,0.03,1.18,0.08c4.74,0.58,8.07,3.19,9.61,7.56c1.59,4.51,0.64,8.7-2.76,12.13c-0.11,0.11-0.43,0.43-0.9,0.89
		c-1.22,1.2-3.49,3.44-5.81,5.78L19.14,35.74z"
      />
    </SvgIcon>
  );
}

export default EeloyIcon;

import React, { useEffect } from 'react';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import StepConnector from '@mui/material/StepConnector';
import Translate from './service/Translate';
import { styled } from '@mui/system';

const StyledConnector = styled(StepConnector)(({ theme }) => ({
  top: 22,
  '& .MuiStepConnector-line': {
    height: 3,
    border: 0,
    backgroundColor: theme.palette.body,
    borderRadius: 1,
  },
}));

export const Root = styled('div')({
  width: '100%',
});

export default function FormStepper(props) {
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = props.formSteps();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  useEffect(() => {
    setActiveStep(props.activeStep);
  }, [props.activeStep]);

  return (
    <Root>
      <Stepper
        alternativeLabel
        nonLinear
        activeStep={activeStep}
        connector={<StyledConnector />}
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel StepIconComponent={props.formIcons}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        {props.renderStepContent(props.formikProps, activeStep)}
        <div>
          {activeStep === steps.length - 1 ? null : (
            <Button
              variant="contained"
              onClick={handleNext}
              sx={{
                mr: 1,
                float: 'right',
                backgroundColor: 'buttonColor',
                color: 'white',
                '&:hover': {
                  background: 'buttonColorHover',
                },
              }}
            >
              <Translate>Next</Translate>
            </Button>
          )}
          {activeStep === 0 ? null : (
            <Button
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{
                mr: 1,
                float: 'right',
                backgroundColor: 'buttonColor',
                color: 'white',
                '&:hover': {
                  background: 'buttonColorHover',
                },
              }}
            >
              <Translate>Back</Translate>
            </Button>
          )}
        </div>
      </div>
    </Root>
  );
}

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Translate from '../../../components/service/Translate';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import ListItemIcon from '@mui/material/ListItemIcon';
import Collapse from '@mui/material/Collapse';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import { PersonOutline, DirectionsCar } from '@mui/icons-material';
import SettingsIcon from '@mui/icons-material/Settings';
import EventIcon from '@mui/icons-material/Event';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import TollIcon from '@mui/icons-material/Toll';
import carConditions from '../../../utils/EmailEditor/DisplayConditions/Car';
import tyreConditions from '../../../utils/EmailEditor/DisplayConditions/Tyre';
import customerConditions from '../../../utils/EmailEditor/DisplayConditions/Customer';
import contactConditions from '../../../utils/EmailEditor/DisplayConditions/Contact';
import dueDateConditions from '../../../utils/EmailEditor/DisplayConditions/DueDate';
import otherConditions from '../../../utils/EmailEditor/DisplayConditions/Other';
import DisplayConditionForm from './DisplayConditionForm';

class DisplayConditionDialog extends Component {
  static propTypes = {
    onSubmit: PropTypes.func,
  };

  state = {
    open: true,
    nestedOpen: false,
    formOpen: false,
  };

  handleClose = () => {
    this.setState({ open: false });
    this.props.cancelDisplayCondition();
  };

  handleAddCondition = (form, data) => {
    this.props.addDisplayCondition(data);
    this.handleClose();
  };

  handleListClick = (e, item) => {
    let listState = this.state[item + 'Open'];
    this.setState({ [item + 'Open']: !listState });
  };

  handleFormClick = () => {
    this.setState({ formOpen: !this.state.formOpen });
  };

  renderConditions = (nodes) => {
    return nodes.map((node) => (
      // eslint-disable-next-line react/jsx-key
      <ListItem
        button
        sx={{
          pl: 4,
        }}
        onClick={() => this.handleAddCondition(null, node)}
      >
        <ListItemText
          key={node.label}
          primary={node.label}
          secondary={node.description}
        />
      </ListItem>
    ));
  };

  render() {
    return (
      <Dialog
        onClose={this.handleClose}
        aria-labelledby="display-conditions-title"
        open={this.state.open}
      >
        <DialogTitle id="display-conditions-title">
          <Translate>Display conditions</Translate>
        </DialogTitle>
        <List
          component="nav"
          aria-labelledby="nested-list-subheader"
          sx={{
            width: '100%',
            maxWidth: 500,
            minWidth: 500,
            backgroundColor: 'background.paper',
          }}
        >
          <ListItem
            button
            onClick={(e) => {
              this.handleListClick(e, 'customer');
            }}
          >
            <ListItemIcon>
              <PersonOutline />
            </ListItemIcon>
            <ListItemText primary={<Translate>Customer</Translate>} />
            {this.state['customerOpen'] ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse
            in={this.state['customerOpen']}
            timeout="auto"
            unmountOnExit
          >
            <List component="div" disablePadding>
              {this.renderConditions(customerConditions)}
            </List>
          </Collapse>

          <ListItem
            button
            onClick={(e) => {
              this.handleListClick(e, 'car');
            }}
          >
            <ListItemIcon>
              <DirectionsCar />
            </ListItemIcon>
            <ListItemText primary={<Translate>Car</Translate>} />
            {this.state['carOpen'] ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={this.state['carOpen']} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {this.renderConditions(carConditions)}
            </List>
          </Collapse>

          <ListItem
            button
            onClick={(e) => {
              this.handleListClick(e, 'dueDate');
            }}
          >
            <ListItemIcon>
              <EventIcon />
            </ListItemIcon>
            <ListItemText primary={<Translate>Due dates</Translate>} />
            {this.state['dueDateOpen'] ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={this.state['dueDateOpen']} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {this.renderConditions(dueDateConditions)}
            </List>
          </Collapse>

          <ListItem
            button
            onClick={(e) => {
              this.handleListClick(e, 'location');
            }}
          >
            <ListItemIcon>
              <ContactPhoneIcon />
            </ListItemIcon>
            <ListItemText
              primary={<Translate>Location and contact</Translate>}
            />
            {this.state['locationOpen'] ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse
            in={this.state['locationOpen']}
            timeout="auto"
            unmountOnExit
          >
            <List component="div" disablePadding>
              {this.renderConditions(contactConditions)}
            </List>
          </Collapse>

          <ListItem
            button
            onClick={(e) => {
              this.handleListClick(e, 'tyre');
            }}
          >
            <ListItemIcon>
              <TollIcon />
            </ListItemIcon>
            <ListItemText primary={<Translate>Tyres</Translate>} />
            {this.state['tyreOpen'] ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={this.state['tyreOpen']} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {this.renderConditions(tyreConditions)}
            </List>
          </Collapse>

          <ListItem
            button
            onClick={(e) => {
              this.handleListClick(e, 'other');
            }}
          >
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary={<Translate>Other</Translate>} />
            {this.state['otherOpen'] ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={this.state['otherOpen']} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {this.renderConditions(otherConditions)}
            </List>
          </Collapse>

          <ListItem autoFocus button onClick={() => this.handleFormClick()}>
            <ListItemIcon>
              <AddIcon />
            </ListItemIcon>
            <ListItemText primary={<Translate>New condition</Translate>} />
            {this.state.formOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={this.state.formOpen} timeout="auto" unmountOnExit>
            <DisplayConditionForm
              onSubmit={this.handleAddCondition}
              onCancel={this.handleClose}
            />
          </Collapse>
        </List>
      </Dialog>
    );
  }
}

export default DisplayConditionDialog;

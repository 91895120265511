import selectors from '../../selectors';
import { pickBy, merge, pick } from 'lodash';

// eslint-disable-next-line no-unused-vars
const getLocations = (state) => state.entities.location;
// eslint-disable-next-line no-unused-vars
const getAffiliates = (state) => state.entities.affiliate;

const getLocationFilter = (state, props) =>
  props.locationId ? parseInt(props.locationId) : null;
const getAffiliateFilter = (state, props) =>
  props.affiliateId ? parseInt(props.affiliateId) : null;

export const makeLocationById = () => {
  return selectors(
    [getLocationFilter, getLocations],
    (locationId, locations) => {
      return locations[locationId];
    },
  );
};

export const makeLocationsByAffiliate = () => {
  return selectors(
    [getAffiliateFilter, getLocations],
    (affiliateId, locations) => {
      return pickBy(locations, { affiliate: affiliateId });
    },
  );
};

/** @deprecated Use `makeHeadLocations` from `src/components/Location/selectors.ts` instead */
export const makeHeadLocations = () => {
  return selectors([getLocations], (locations) => {
    return merge({}, pickBy(locations, { isChild: false }));
  });
};

/** @deprecated Use `makeSelectableAffiliates` from `src/components/Location/selectors.ts` instead */
export const makeSelectableAffiliates = () => {
  return selectors([getAffiliates], (affiliates) => {
    return pickBy(affiliates, { parent: null });
  });
};

const getLocationById = makeLocationById();
export const makeLocationWithChildren = () => {
  return selectors([getLocationById, getLocations], (location, locations) => {
    if (location && location.children) {
      let locationWithChildren = merge({}, location);
      locationWithChildren.children = Object.values(
        pick(locations, location.children),
      );
      return locationWithChildren;
    } else {
      return location;
    }
  });
};

import { CALL_API } from '../../middleware';
import { Schemas } from '../../schemas';
import { sample } from 'lodash';

export const BRANDCOMPANY_REQUEST = 'BRANDCOMPANY_REQUEST';
export const BRANDCOMPANY_SUCCESS = 'BRANDCOMPANY_SUCCESS';
export const BRANDCOMPANY_FAILURE = 'BRANDCOMPANY_FAILURE';

function fetchBrandCompanies() {
  return {
    [CALL_API]: {
      types: [BRANDCOMPANY_REQUEST, BRANDCOMPANY_SUCCESS, BRANDCOMPANY_FAILURE],
      endpoint: Routing.generate('brand_enabled', null, true),
      schema: Schemas.BRANDS,
    },
  };
}

export function loadBrandCompanies(requiredFields = []) {
  return (dispatch, getState) => {
    const brand = getState().entities.brand;
    const outdated = getState().outdated.brand;

    if (
      !outdated &&
      brand &&
      requiredFields.every((key) => sample(brand).hasOwnProperty(key))
    ) {
      return null;
    }

    return dispatch(fetchBrandCompanies());
  };
}

import { CALL_API } from '../../middleware';
import { Schemas } from '../../schemas';

export const PASSWORD_RESET_REQUEST = 'PASSWORD_RESET_REQUEST';
export const PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS';
export const PASSWORD_RESET_FAILURE = 'PASSWORD_RESET_FAILURE';

function fetchPasswordReset(resetKey, password) {
  var form = new FormData();
  form.append('password', password);
  return {
    [CALL_API]: {
      types: [
        PASSWORD_RESET_REQUEST,
        PASSWORD_RESET_SUCCESS,
        PASSWORD_RESET_FAILURE,
      ],
      endpoint: Routing.generate(
        'password_reset_v3',
        { resetKey: resetKey },
        true,
      ),
      method: 'POST',
      data: form,
      schema: Schemas.LOGIN,
    },
  };
}

export function loadPasswordReset(resetKey, password) {
  return (dispatch) => {
    return dispatch(fetchPasswordReset(resetKey, password));
  };
}

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

function fetchLogin(username, password) {
  return {
    [CALL_API]: {
      types: [LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE],
      endpoint: Routing.generate('v3_login', {}, true),
      method: 'POST',
      data: JSON.stringify({ username: username, password: password }),
      headers: { 'Content-Type': 'application/json' },
      schema: Schemas.LOGIN,
    },
  };
}

export function loadLogin(username, password) {
  return (dispatch, getState) => {
    const userLoggedIn = getState().entities.userLoggedIn;

    if (userLoggedIn) {
      return null;
    }

    return dispatch(fetchLogin(username, password));
  };
}

import React, { Component } from 'react';

import PropTypes from 'prop-types';
import ErrorBoundary from '../../../components/ErrorBoundary';
import { intersection, isEqual, keys, toNumber, isEmpty } from 'lodash';
import { withRouter } from '../../../withRouter';
import { connect } from 'react-redux';
import { exportProducts } from '../actions';
import DeleteDialog from '../../../components/DeleteDialog';
import Translate from '../../../components/service/Translate';
import DataGridTable from '../../../components/DataGrid/DataGrid';
import FileFileDownload from '@mui/icons-material/GetApp';
import IconButton from '@mui/material/IconButton';
import { compose } from 'redux';

const defColumns = [
  { headerName: 'ID', field: 'id', id: 'id', type: 'number', width: 30 },
  {
    headerName: 'Bezeichnung',
    field: 'configLabel',
    id: 'configLabel',
    type: 'string',
    minWidth: 150,
    flex: 1,
  },
  {
    headerName: 'Sparte',
    field: 'classification',
    id: 'classification',
    modifier: 'translate',
    type: 'string',
    minWidth: 50,
    flex: 1,
  },
  {
    headerName: 'Schüssel',
    field: 'configKey',
    id: 'configKey',
    type: 'string',
    minWidth: 150,
    flex: 1,
  },
];

class List extends Component {
  static propTypes = {
    products: PropTypes.object.isRequired,
    onOrderChange: PropTypes.func.isRequired,
    onReset: PropTypes.func.isRequired,
    resultset: PropTypes.array.isRequired,
    defaultSortColumns: PropTypes.array.isRequired,
    defaultSortOrders: PropTypes.array.isRequired,
    campaignId: PropTypes.number,
    campaignProducts: PropTypes.object,
  };

  static defaultProps = {
    campaignId: null,
  };

  state = {
    columns: defColumns,
    exporting: false,
  };

  componentDidMount = () => {
    let columns = defColumns;

    if (this.props.campaignId) {
      columns = columns.concat(
        {
          headerName: 'Priorität',
          field: 'priority',
          valueGetter: (value, row) => this.getPriority(row),
          id: 'priority',
          type: 'number',
          headerAlign: 'left',
          width: 125,
        },
        {
          headerName: 'Individualisiert',
          field: 'modified',
          id: 'modified',
          type: 'boolean',
        },
        {
          headerName: 'Aktiviert',
          field: 'childrenCount',
          valueGetter: (value, row) => this.isEnabled(row),
          id: 'childrenCount',
          type: 'boolean',
        },
      );
    } else {
      columns = columns.concat(
        {
          headerName: 'Priorität',
          field: 'priority',
          valueGetter: (value, row) => this.getPriority(row),
          id: 'priority',
          type: 'number',
          headerAlign: 'left',
          width: 150,
        },
        {
          headerName: 'Individualisiert',
          field: 'modified',
          id: 'modified',
          type: 'boolean',
          width: 150,
        },
      );
    }

    columns = columns.concat({
      field: 'action',
      headerName: 'Aktionen',
      sortable: false,
      minWidth: 150,
      renderCell: (product) => {
        return (
          <IconButton
            href={Routing.generate(
              'product_download',
              { id: product.id },
              true,
            )}
            target="_self"
            aria-label="Download"
            download
            size="large"
          >
            <FileFileDownload />
          </IconButton>
        );
      },
    });

    this.setState({ columns: columns });
  };

  shouldComponentUpdate = (nextProps, nextState) => {
    return (
      !isEqual(nextProps.products, this.props.products) ||
      !isEqual(nextProps.resultset, this.props.resultset) ||
      !isEqual(nextState.current, this.state.current) ||
      !isEqual(nextState.columns, this.state.columns) ||
      !isEqual(nextState.exporting, this.state.exporting) ||
      !isEqual(nextState.deleting, this.state.deleting)
    );
  };

  getPriority = (row) => {
    const { campaignProducts } = this.props;

    if (row?.campaignProducts?.length) {
      let campaignProduct = campaignProducts[row.campaignProducts[0]];
      if (campaignProduct.priority) {
        return campaignProduct.priority;
      }
    }

    return row.priority;
  };

  isEnabled = (row) => {
    const { campaignProducts } = this.props;

    if (row.campaignProducts?.length) {
      let campaignProduct = campaignProducts[row.campaignProducts[0]];

      return <Translate>{campaignProduct.enabled}</Translate>;
    }

    return <Translate>false</Translate>;
  };

  onTouchEdit = (current) => {
    this.props.navigate('/product/edit/' + current);
  };

  onTouchAdd = () => {
    this.props.navigate('/product/add');
  };

  onTouchDelete = (current) => {
    this.setState({ current: current, deleting: true });
  };

  onTouchCancel = () => {
    this.setState({ deleting: false });
    this.props.navigate('/product/');
  };

  onTouchExport = () => {
    this.setState({ exporting: true });
    Promise.all([this.props.exportProducts(this.props.campaignId)]).then(
      // eslint-disable-next-line no-unused-vars
      (values) => {
        this.setState({ exporting: false });
      },
      // eslint-disable-next-line no-unused-vars
      (error) => {
        this.setState({ exporting: false });
      },
    );
  };

  onRowSelect = (current) => {
    const { products } = this.props;
    this.setState({ current: current });

    if (current && products[current] && products[current].modified) {
      this.setState({ disableResetButton: false });
    } else {
      this.setState({ disableResetButton: true });
    }
  };

  renderDeleteDialog = () => {
    const { deleting, current } = this.state;
    const { products, onDelete } = this.props;

    if (deleting && current && products[current] && products[current].local) {
      return (
        <DeleteDialog
          current={products[current]}
          onCancel={this.onTouchCancel}
          onSubmit={onDelete}
        />
      );
    }

    return null;
  };

  render() {
    const {
      products,
      onOrderChange,
      onReset,
      pagination,
      resultset,
      onLoadNext,
      campaignId,
    } = this.props;
    const { columns, disableResetButton, exporting, current } = this.state;

    return (
      <ErrorBoundary>
        <DataGridTable
          title="Produkte"
          rows={products}
          iteratableRows={resultset}
          columns={columns}
          rowHeight={52}
          pagination={pagination}
          onEdit={this.onTouchEdit}
          onAdd={this.onTouchAdd}
          onDelete={this.onTouchDelete}
          onOrderChange={onOrderChange}
          onLoadNext={onLoadNext}
          onRowSelect={this.onRowSelect}
          onExport={this.onTouchExport}
          disableExport={!campaignId || exporting}
          disableDelete={!products?.[current]?.local}
          onReset={onReset}
          disableResetButton={disableResetButton}
        />
        {this.renderDeleteDialog()}
      </ErrorBoundary>
    );
  }
}

function mapStateToProps(state, props) {
  const {
    entities: { product, campaignProduct },
    resultsets: { product: resultset },
    pagination: { product: pagination },
  } = state;

  return {
    campaignId: props?.match?.params?.campaignId
      ? parseInt(props.match.params.campaignId)
      : null,
    campaignProducts: campaignProduct,
    products: !isEmpty(product) ? product : {},
    pagination: pagination,
    resultset: intersection(resultset, keys(product).map(toNumber)),
  };
}

const enhance = compose(
  withRouter,
  connect(mapStateToProps, { exportProducts }),
);

export default enhance(List);

import selectors from '../../selectors';

// eslint-disable-next-line no-unused-vars
const getEmails = (state, props) =>
  state.entities.email ? state.entities.email : {};
// eslint-disable-next-line no-unused-vars
const getEmailTemplates = (state, props) => state.entities.emailTemplate;
const getEmailFilter = (state, props) =>
  props?.match?.params?.emailId ? parseInt(props.match.params.emailId) : null;

export const makeEmailById = () => {
  return selectors([getEmailFilter, getEmails], (emailId, emails) => {
    return emails && emails[emailId] ? emails[emailId] : null;
  });
};

const getEmailById = makeEmailById();
export const makeTemplateByEmail = () => {
  return selectors([getEmailById, getEmailTemplates], (email, templates) => {
    return email && email.template ? templates[email.template] : null;
  });
};

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ACL from '../../components/ACL';
import Season from './Season';
import { connect } from 'react-redux';
import { withRouter } from '../../withRouter';
import { compose } from 'redux';

class SeasonIndex extends Component {
  static propTypes = {
    requiredRoles: PropTypes.array,
    mode: PropTypes.string,
    seasonType: PropTypes.string,
    campaignId: PropTypes.number,
    seasonId: PropTypes.number,
  };

  static defaultProps = {
    requiredRoles: ['ROLE_SUPERADMIN'],
    resultset: [],
    mode: 'show',
    seasonType: null,
  };

  render() {
    const { requiredRoles, mode, seasonType, campaignId, seasonId } =
      this.props;
    return (
      <ACL authorized={requiredRoles}>
        <Season
          mode={mode}
          seasonType={seasonType}
          campaignId={campaignId}
          seasonId={seasonId}
        />
      </ACL>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    campaignId: props?.match?.params?.campaignId
      ? parseInt(props.match.params.campaignId)
      : null,
    mode: props?.match?.params?.mode ?? 'show',
    seasonType: props?.match?.params?.seasonType ?? null,
    seasonId: props?.match?.params?.seasonId
      ? parseInt(props.match.params.seasonId)
      : null,
  };
}

const enhance = compose(withRouter, connect(mapStateToProps, {}));

export default enhance(SeasonIndex);

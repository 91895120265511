import { CALL_API } from '../../middleware';
import { Schemas } from '../../schemas';

export const CAMPAIGN_REQUEST = 'CAMPAIGN_REQUEST';
export const CAMPAIGN_SUCCESS = 'CAMPAIGN_SUCCESS';
export const CAMPAIGN_FAILURE = 'CAMPAIGN_FAILURE';

export const CAMPAIGN_SUBMIT_REQUEST = 'CAMPAIGN_SUBMIT_REQUEST';
export const CAMPAIGN_SUBMIT_SUCCESS = 'CAMPAIGN_SUBMIT_SUCCESS';
export const CAMPAIGN_SUBMIT_FAILURE = 'CAMPAIGN_SUBMIT_FAILURE';

function createFetchCampaignActions(id = null) {
  if (id !== null) {
    return {
      [CALL_API]: {
        types: [CAMPAIGN_REQUEST, CAMPAIGN_SUCCESS, CAMPAIGN_FAILURE],
        endpoint: Routing.generate('campaign_show', { id: id }, true),
        schema: Schemas.CAMPAIGN,
      },
    };
  }
  return {
    [CALL_API]: {
      types: [CAMPAIGN_REQUEST, CAMPAIGN_SUCCESS, CAMPAIGN_FAILURE],
      endpoint: Routing.generate('campaign_index', {}, true),
      schema: Schemas.CAMPAIGNS,
    },
  };
}

export function fetchCampaign(id = null, requiredFields = []) {
  return (dispatch, getState) => {
    const { season, campaign } = getState().entities;
    const {
      campaign: outdateCampaign,
      season: outdateSeason,
      email: outdateEmail,
    } = getState().outdated;

    if (
      !outdateCampaign &&
      !outdateSeason &&
      !outdateEmail &&
      campaign &&
      campaign[id] &&
      requiredFields.every((input) =>
        Object.keys(input).every((key) =>
          campaign[id].hasOwnProperty(key)
            ? campaign[id][key].every((item) =>
                input[key].every((value) => season[item].hasOwnProperty(value)),
              )
            : false,
        ),
      )
    ) {
      return null;
    }
    return dispatch(createFetchCampaignActions(id));
  };
}

function postNew(form) {
  return {
    [CALL_API]: {
      types: [
        CAMPAIGN_SUBMIT_REQUEST,
        CAMPAIGN_SUBMIT_SUCCESS,
        CAMPAIGN_SUBMIT_FAILURE,
      ],
      method: 'POST',
      data: form,
      endpoint: Routing.generate('campaign_create', {}, true),
      schema: Schemas.CAMPAIGN,
    },
  };
}

export function postForm(form) {
  // eslint-disable-next-line no-unused-vars
  return (dispatch, getState) => {
    return dispatch(postNew(form));
  };
}

export const CAMPAIGN_UPDATE_REQUEST = 'CAMPAIGN_UPDATE_REQUEST';
export const CAMPAIGN_UPDATE_SUCCESS = 'CAMPAIGN_UPDATE_SUCCESS';
export const CAMPAIGN_UPDATE_FAILURE = 'CAMPAIGN_UPDATE_FAILURE';

function writeCampaign(form, campaignId = null) {
  return {
    [CALL_API]: {
      types: [
        CAMPAIGN_UPDATE_REQUEST,
        CAMPAIGN_UPDATE_SUCCESS,
        CAMPAIGN_UPDATE_FAILURE,
      ],
      method: 'POST',
      data: form,
      endpoint: Routing.generate('campaign_update', { id: campaignId }, true),
      schema: Schemas.CAMPAIGN,
    },
  };
}

export function updateCampaign(form, campaignId = null) {
  // eslint-disable-next-line no-unused-vars
  return (dispatch, getState) => {
    return dispatch(writeCampaign(form, campaignId));
  };
}

export const CAMPAIGN_FORM_REQUEST = 'CAMPAIGN_FORM_REQUEST';
export const CAMPAIGN_FORM_SUCCESS = 'CAMPAIGN_FORM_SUCCESS';
export const CAMPAIGN_FORM_FAILURE = 'CAMPAIGN_FORM_FAILURE';

function fetchForm() {
  return {
    [CALL_API]: {
      types: [
        CAMPAIGN_FORM_REQUEST,
        CAMPAIGN_FORM_SUCCESS,
        CAMPAIGN_FORM_FAILURE,
      ],
      endpoint: Routing.generate('campaign_token', {}, true),
      schema: Schemas.FORM,
    },
  };
}

export function initForm() {
  return (dispatch, getState) => {
    const updateToken = getState().forms.updateToken;

    if (updateToken) {
      return null;
    }

    return dispatch(fetchForm());
  };
}

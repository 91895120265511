import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from '../../../withRouter';
import { makeCustomerListCustomerById } from '../selectors';
import { loadSmsQueueSms } from '../actions';
import { isEqual } from 'lodash';
import { Avatar, Box } from '@mui/material';
import ActionAccountCircle from '@mui/icons-material/AccountCircle';
import { styled } from '@mui/system';
import { compose } from 'redux';

const ReceiveParagraph = styled('div')({
  maxWidth: '326px',
  wordWrap: 'break-word',
  mb: '12px',
  lineHeight: '24px',
  position: 'relative',
  padding: '10px 20px',
  borderRadius: '25px',

  background: '#e6e6e6',
  color: 'black',
  alignSelf: 'flex-start',
  fontSize: '14px',

  '&:before': {
    left: '-7px',
    width: '20px',
    backgroundColor: '#e6e6e6',
    borderBottomRightRadius: '16px 14px',
  },
  '&:after': {
    left: '-26px',
    width: '26px',
    backgroundColor: 'white',
    borderBottomRightRadius: '10px',
  },

  '&:before, &:after': {
    content: "''",
    position: 'absolute',
    bottom: '0',
    height: '25px',
  },
});

const IPhone = styled('div')({
  height: '700px',
  width: '360px',
  border: '1px solid #404853',
  padding: '70px 15px',
  borderRadius: '50px',
  backgroundColor: 'white',
});

const Screen = styled('div')({
  height: '556px',
  width: '326px',
  border: '1px solid #404853',
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'column',
  alignItems: 'flex-start',
  maxHeight: '596px',
  overflowY: 'auto',
  backgroundColor: 'white',
});

class SmsPreview extends Component {
  static propTypes = {
    // Fill in prop types
  };

  static defaultProps = {
    //Fill in default props values
  };

  state = {
    //Fill in initial state
  };

  componentDidMount = () => {
    //Called once the component did mount
    if (
      this.props.customerListCustomer &&
      this.props.customerListCustomer.smsQueue
    ) {
      this.props.loadSmsQueueSms(this.props.customerListCustomer.smsQueue);
    }
  };

  componentDidUpdate(prevProps) {
    if (
      !isEqual(
        this.props.customerListCustomer,
        prevProps.customerListCustomer,
      ) &&
      this.props.customerListCustomer
    ) {
      prevProps.loadSmsQueueSms(this.props.customerListCustomer.smsQueue);
    }
  }

  render() {
    const { smsQueue } = this.props;

    if (!smsQueue || !smsQueue.content) {
      return null;
    }

    let count = Math.ceil(smsQueue.content.length / 160);

    return (
      <Box
        sx={{
          display: 'flex',
          paddingTop: '24px',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <IPhone>
          <Screen>
            <Box
              sx={{
                height: '80px',
                width: '100%',
                display: 'flex',
                background: '#f5f5f5',
                borderBottom: '1px solid #c7c7c7',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <Avatar
                sx={{
                  mt: '10px',
                  mb: '5px',
                }}
              >
                <ActionAccountCircle />
              </Avatar>
              <span style={{ marginBottom: '5px' }}>{smsQueue.sender}</span>
            </Box>
            <Box
              sx={{
                /*justifyContent: 'flex-end', */ display: 'flex',
                width: '100%',
                padding: '15px',
                height: '472px',
                overflowY: 'scroll',
                flexDirection: 'column',
              }}
            >
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  height: 'auto',
                  marginBottom: '4px',
                }}
              >
                <Box sx={{ fontSize: '12px' }}>Ca. {count} SMS</Box>
              </Box>
              <ReceiveParagraph>{smsQueue.content}</ReceiveParagraph>
            </Box>
          </Screen>
        </IPhone>
      </Box>
    );
  }
}

function makeMapStateToProps() {
  const getCustomerListCustomerById = makeCustomerListCustomerById();
  return (state, props) => {
    const {
      entities: { smsQueue },
    } = state;

    const customerListCustomer = getCustomerListCustomerById(state, props);

    if (!smsQueue) {
      return {
        customerListCustomer: customerListCustomer,
      };
    }

    return {
      customerListCustomer: customerListCustomer,
      smsQueue:
        customerListCustomer !== null && smsQueue[customerListCustomer.smsQueue]
          ? smsQueue[customerListCustomer.smsQueue]
          : null,
    };
  };
}

const enhance = compose(
  withRouter,
  connect(makeMapStateToProps, { loadSmsQueueSms }),
);

export default enhance(SmsPreview);

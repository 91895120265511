import { alpha, createTheme, emphasize } from '@mui/material/styles';
import { common, red, green, orange, blue } from '@mui/material/colors';
import { deDE } from '@mui/x-data-grid/locales';
import type {} from '@mui/x-data-grid/themeAugmentation';
import { Localization } from '@mui/x-data-grid/utils/getGridLocalization';
import {
  seedsGrey100,
  seedsGrey125,
  seedsGrey140,
  seedsGrey150,
  seedsBlue50,
  seedsBlue100,
  seedsBlue150,
  seedsGrey150Alpha09,
} from './theme/colors';

const fullWhite = common.white;
const red900 = red['900'];
const red500 = red['500'];
const blue600 = blue['600'];
const orange600 = orange['600'];
const greenA700 = green.A700;

// check material-ui/styles/getMuiTheme
const palette = {
  background: {
    default: '#fafafa',
  },
  neutral: {
    main: '#000000DE',
  },
  body: seedsGrey140,
  white: fullWhite,
  primary1Color: seedsGrey150,
  primary2Color: seedsGrey150,
  primary3Color: seedsBlue50,
  accent1Color: seedsBlue150,
  accent2Color: seedsBlue50,
  accent3Color: seedsBlue150,
  textColor: seedsGrey100,
  alternateTextColor: fullWhite,
  secondaryTextColor: alpha(seedsGrey100, 0.7),
  canvasColor: fullWhite,
  borderColor: alpha(seedsGrey100, 0.3),
  borderOnWhiteColor: alpha(seedsGrey100, 0.3),
  disabledColor: alpha(seedsGrey100, 0.3),
  pickerHeaderColor: alpha(seedsBlue100, 0.12),
  clockCircleColor: alpha(seedsGrey100, 0.12),
  shadowColor: seedsGrey100,
  soften1Color: seedsGrey125,
  soften2Color: alpha(seedsBlue100, 0.3),
  menuItem: emphasize(fullWhite, 0.26),
  btnColor: alpha(seedsGrey100, 0.1),
  warningColor: orange600,
  infoColor: blue600,
  buttonColor: seedsBlue150,
  buttonColorHover: blue600,

  errorColor: red900,
  okColor: greenA700,
  muiErrorColor: red500,
};

const spacingConstants = {
  desktopGutter: 24,
  appBarHeight: 64,
  dockedListWidth: 256,
  dockedListClosedWidth: 50,
};

const textSize = {
  carHeadline: '18px',
  carText: '14px',
};

const locale = {
  de: 'de-DE',
  deString: 'dd.MM.yyyy',
  fr: 'fr-ca',
};

const components = {
  MuiCssBaseline: {
    defaultProps: {},
    styleOverrides: {
      body: {
        fontSize: '0.875rem',
      },
    },
  },
  MuiPaper: {
    defaultProps: {},
    styleOverrides: {
      root: {
        marginTop: spacingConstants.desktopGutter,
        marginBottom: spacingConstants.desktopGutter,
        // padding: spacingConstants.desktopGutter
      },
    },
  },
  MuiDrawer: {
    defaultProps: {},
    styleOverrides: {
      paper: {
        margin: 0,
        padding: 0,
        zIndex: 1200,
      },
    },
  },
  MuiDialog: {
    defaultProps: {},
    styleOverrides: {
      paper: {
        margin: 0,
        padding: 0,
        zIndex: 1200,
      },
    },
  },
  MuiPopover: {
    defaultProps: {},
    styleOverrides: {
      paper: {
        margin: 0,
        padding: 0,
      },
    },
  },
  MuiDataGrid: {
    defaultProps: {},
    styleOverrides: {
      paper: {
        margin: 0,
        padding: 0,
      },
      menu: {
        '& .MuiPaper-root': {
          padding: 0,
        },
      },
      filterFormDeleteIcon: {
        width: 'unset',
      },
    },
  },
  MuiAppBar: {
    defaultProps: {},
    styleOverrides: {
      root: {
        margin: 0,
        padding: 0,
        zIndex: 1201,
      },
    },
  },
  MuiGrid2: {
    defaultProps: {
      rowSpacing: 1,
      columnSpacing: 3,
      alignItems: 'center',
    },
  },
  MuiTextField: {
    defaultProps: {
      fullWidth: true,
      variant: 'standard' as const,
      // margin: 'dense'
    },
    styleOverrides: {
      root: {
        // marginTop: 8,
        // marginBottom: 4
      },
    },
  },
  MuiPickersPopper: {
    defaultProps: {},
    styleOverrides: {
      paper: {
        margin: 0,
        padding: 0,
      },
    },
  },
  /* MuiToolbar: {
        styleOverrides: {
            root: {
                paddingLeft: 0,
                paddingRight: 0
            },
            gutters: {
                '@media (min-width: 600px)': {
                    paddingLeft: 0,
                    paddingRight: 0,
                },
            },
        }
    }, */
  MuiFormHelperText: {
    defaultProps: {},
    styleOverrides: {
      root: {
        marginTop: 0,
      },
    },
  },
  MuiFormControl: {
    defaultProps: {
      fullWidth: true,
      variant: 'standard' as const,
    },
  },
  MuiAutocomplete: {
    defaultProps: {
      fullWidth: true,
    },
    styleOverrides: {
      popper: {
        '& .MuiPaper-root': {
          padding: 0,
        },
      },
    },
  },
};

declare module '@mui/material/styles' {
  interface Theme {
    spacingConstants: {
      desktopGutter: number;
      appBarHeight: number;
      dockedListWidth: number;
      dockedListClosedWidth: number;
    };
    textSize: {
      carHeadline: string;
      carText: string;
    };
    locale: {
      de: string;
      deString: string;
      fr: string;
    };
    fontFamily: string;
    deDE: Localization;
    login: {
      backgroundColor: string;
      buttonBackgroundColor: string;
      infoMessageColor: string;
      successMessageColor: string;
      headlineFontFamily: string;
    };
  }
  interface ThemeOptions {
    spacingConstants?: {
      desktopGutter?: number;
      appBarHeight?: number;
      dockedListWidth?: number;
      dockedListClosedWidth?: number;
    };
    textSize?: {
      carHeadline?: string;
      carText?: string;
    };
    locale?: {
      de?: string;
      deString?: string;
      fr?: string;
    };
    fontFamily?: string;
    deDE?: Localization;
    login?: {
      backgroundColor?: string;
      buttonBackgroundColor?: string;
      infoMessageColor?: string;
      successMessageColor?: string;
      headlineFontFamily?: string;
    };
  }
}

export default createTheme({
  spacing: 8,
  spacingConstants,
  textSize,
  locale,
  fontFamily: 'Roboto, sans-serif',
  palette,
  components,
  deDE,
  login: {
    backgroundColor: seedsGrey150Alpha09,
    buttonBackgroundColor: seedsBlue150,
    infoMessageColor: palette.infoColor,
    successMessageColor: palette.okColor,
    headlineFontFamily: "'acumin-pro','sans-serif'",
  },
});

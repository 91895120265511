import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import ContentAdd from '@mui/icons-material/Add';
import { isEqual, isEmpty, merge, concat } from 'lodash';
import Upload from '../../../components/form/Upload';
import Translate from '../../../components/service/Translate';
import Tags from './Tags';
import RepresentedBy from './RepresentedBy';
import BrandCompany from '../../Car/components/BrandCompany';
import Child from './Child';
import { loadAffiliate, initForm, loadAffiliateChildren } from '../actions';
import { IconButton, Input, TextField } from '@mui/material';
import Form from '../../../components/Form';
import ValidationErrors from '../../../ValidationErrors';
import ValidationRules from '../../../ValidationRules';
import * as Yup from 'yup';
import { makeAffiliateWithChildren } from '../selectors';
import Grid from '@mui/material/Unstable_Grid2';
import { FormCheckbox } from '../../../components/StyledElements/StyledFormElements';

let validationSchema = Yup.object().shape({
  name: Yup.string().required(ValidationErrors.required),
  city: Yup.string().required(ValidationErrors.required),
  replyTo: Yup.string().nullable(),
  interfaceId: Yup.string().required(ValidationErrors.required),
  poBox: Yup.string().nullable(),
  poZip: Yup.string().nullable(),
  poCity: Yup.string().nullable(),
  appointmentPhone: Yup.string()
    .matches(ValidationRules.phoneRegex, { message: ValidationErrors.phone })
    .nullable(),
  appointmentWww: Yup.string()
    .matches(ValidationRules.urlRegex, { message: ValidationErrors.url })
    .nullable(),
  appointmentEmail: Yup.string().nullable().email(ValidationErrors.email),
  showLocations: Yup.bool(),
  showingCity: Yup.bool(),
  tag: Yup.string().when('children', {
    is: (value) => value && value.length > 0,
    then: () => Yup.string().required(ValidationErrors.requiredSelect),
    otherwise: () => Yup.string().nullable(),
  }),
  brandCompany: Yup.string().nullable(),
  children: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required(ValidationErrors.required),
      city: Yup.string().required(ValidationErrors.required),
      replyTo: Yup.string().nullable(),
      interfaceId: Yup.string().nullable(),
      poBox: Yup.string().nullable(),
      poZip: Yup.string().nullable(),
      poCity: Yup.string().nullable(),
      appointmentPhone: Yup.string()
        .matches(ValidationRules.phoneRegex, {
          message: ValidationErrors.phone,
        })
        .nullable(),
      appointmentWww: Yup.string()
        .matches(ValidationRules.urlRegex, { message: ValidationErrors.url })
        .nullable(),
      appointmentEmail: Yup.string().nullable().email(ValidationErrors.email),
      showLocations: Yup.bool(),
      showingCity: Yup.bool(),
      tag: Yup.string().required(ValidationErrors.requiredSelect),
      brandCompany: Yup.string().nullable(),
    }),
  ),
});

class Edit extends Component {
  static propTypes = {
    affiliateId: PropTypes.number,
    loadAffiliate: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onMount: PropTypes.func.isRequired,
    onValidityChange: PropTypes.func.isRequired,
    values: PropTypes.object,
  };

  static defaultProps = {
    affiliateId: null,
    values: {
      showingLocations: false,
      showingCity: false,
    },
  };

  state = {
    children: [],
  };

  componentDidMount = () => {
    if (this.props.affiliateId) {
      Promise.all([
        this.props.loadAffiliate(this.props.affiliateId),
        this.props.loadAffiliateChildren(this.props.affiliateId),
      ]).then(() => {
        this.setState({ children: this.props.affiliate.children });
      });
    } else {
      this.props.initForm();
    }
  };

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState) {
    if (this.props.affiliateId !== prevProps.affiliateId) {
      Promise.all([this.props.loadAffiliate(this.props.affiliateId)]).then(
        () => {
          this.setState({ children: this.props.affiliate.children });
        },
      );
    }
  }

  shouldComponentUpdate = (nextProps, nextState) => {
    return (
      !isEqual(nextProps.affiliate, this.props.affiliate) ||
      !isEqual(nextProps.affiliateId, this.props.affiliateId) ||
      !isEqual(nextState.children, this.state.children) ||
      !isEqual(this.props.token, nextProps.token) ||
      !isEqual(this.props.affiliates, nextProps.affiliates) ||
      !isEqual(this.props.affiliate, nextProps.affiliate) ||
      !isEqual(this.props.form, nextProps.form)
    );
  };

  handleChildRemove = (childId) => {
    let children = this.state.children;
    children.splice(childId, 1);
    this.setState({ children: children });
  };

  handleChildAdd = () => {
    this.setState({
      children: concat(this.state.children, [
        `new_${this.state.children.length + 1}`,
      ]),
    });
  };

  renderChild = (item, index, props) => {
    const {
      handleChange,
      handleBlur,
      touched,
      errors,
      submitCount,
      setFieldTouched,
    } = props;
    let values = merge(
      {},
      props.values.children && props.values.children[index]
        ? props.values.children[index]
        : props.values,
      {
        children: null,
        id: props.values.children[index]
          ? props.values.children[index].id
          : null,
        isChild: true,
        interfaceId: null,
      },
    );

    return (
      <Child
        key={`affiliate-${this.props.affiliateId}-child-${index}`}
        values={values}
        onRemove={this.handleChildRemove}
        onAdd={this.handleChildAdd}
        index={index}
        handleChange={handleChange}
        handleBlur={handleBlur}
        setFieldTouched={setFieldTouched}
        formikProps={props}
        touched={touched}
        errors={errors}
        submitCount={submitCount}
        item={item}
      />
    );
  };

  renderChildren = (props) => {
    const { children } = this.state;
    return (
      <div>
        <h4>
          <Translate>Tags</Translate>
          <IconButton
            aria-label={<Translate>add</Translate>}
            onClick={this.handleChildAdd}
            sx={{
              width: 40,
              height: 40,
            }}
            size="large"
          >
            <ContentAdd sx={{ width: 20, height: 20 }} />
          </IconButton>
        </h4>
        {children.map((item, index) => this.renderChild(item, index, props))}
      </div>
    );
  };

  renderFieldset = (props) => {
    const { bindSubmitForm } = this.props;
    const { values, handleChange, handleBlur, setFieldValue, touched, errors } =
      props;

    bindSubmitForm(props.submitForm);
    return (
      <>
        <Input
          type="hidden"
          name="updateToken"
          defaultValue={values.updateToken ? values.updateToken : ''}
        />
        <Grid container>
          <Grid xs={12} sm={6}>
            <TextField
              label={<Translate>Name</Translate>}
              defaultValue={values.name ? values.name : ''}
              name="name"
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={touched.name ? errors.name : ''}
              error={touched.name && Boolean(errors.name)}
              required
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <TextField
              label={<Translate>City</Translate>}
              defaultValue={values.city ? values.city : ''}
              name="city"
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={touched.city ? errors.city : ''}
              error={touched.city && Boolean(errors.city)}
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <TextField
              label={<Translate>Interface ID</Translate>}
              defaultValue={values.interfaceId ? values.interfaceId : ''}
              name="interfaceId"
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={touched.interfaceId ? errors.interfaceId : ''}
              error={touched.interfaceId && Boolean(errors.interfaceId)}
              required
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <RepresentedBy
              name=""
              touched={touched}
              errors={errors}
              values={values}
              handleChange={handleChange}
            />
          </Grid>
          <Grid xs={12}>
            <h4>
              <Translate>Sender</Translate>
            </h4>
          </Grid>
          <Grid xs={12} sm={6}>
            <TextField
              label={<Translate>Reply to</Translate>}
              name="replyTo"
              defaultValue={values.replyTo ? values.replyTo : ''}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={touched.replyTo ? errors.replyTo : ''}
              error={touched.replyTo && Boolean(errors.replyTo)}
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <TextField
              label={<Translate>Postbox</Translate>}
              name="poBox"
              defaultValue={values.poBox ? values.poBox : ''}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={touched.poBox ? errors.poBox : ''}
              error={touched.poBox && Boolean(errors.poBox)}
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <TextField
              label={<Translate>Postbox ZIP</Translate>}
              name="poZip"
              defaultValue={values.poZip ? values.poZip : ''}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={touched.poZip ? errors.poZip : ''}
              error={touched.poZip && Boolean(errors.poZip)}
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <TextField
              label={<Translate>Postbox City</Translate>}
              name="poCity"
              defaultValue={values.poCity ? values.poCity : ''}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={touched.poCity ? errors.poCity : ''}
              error={touched.poCity && Boolean(errors.poCity)}
            />
          </Grid>
          <Grid xs={12}>
            <h4>
              <Translate>Appointment contact</Translate>
            </h4>
          </Grid>

          <Grid xs={12} sm={6}>
            <TextField
              label={<Translate>Phone</Translate>}
              name="appointmentPhone"
              defaultValue={
                values.appointmentPhone ? values.appointmentPhone : ''
              }
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={
                touched.appointmentPhone ? errors.appointmentPhone : ''
              }
              error={
                touched.appointmentPhone && Boolean(errors.appointmentPhone)
              }
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <TextField
              label={<Translate>Web</Translate>}
              name="appointmentWww"
              defaultValue={values.appointmentWww ? values.appointmentWww : ''}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={touched.appointmentWww ? errors.appointmentWww : ''}
              error={touched.appointmentWww && Boolean(errors.appointmentWww)}
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <TextField
              label={<Translate>Email</Translate>}
              name="appointmentEmail"
              defaultValue={
                values.appointmentEmail ? values.appointmentEmail : ''
              }
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={
                touched.appointmentEmail ? errors.appointmentEmail : ''
              }
              error={
                touched.appointmentEmail && Boolean(errors.appointmentEmail)
              }
            />
          </Grid>
          <Grid xs={12}>
            <h4>
              <Translate>Letter paper</Translate>
            </h4>
          </Grid>
          <Grid xs={12} sm={6}>
            <FormCheckbox
              name="showingLocations"
              label={<Translate>Showing locations below title?</Translate>}
              checked={values?.showingLocations ?? false}
              setFieldValue={setFieldValue}
              error={touched?.showingLocations && errors?.showingLocations}
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <FormCheckbox
              name="showingCity"
              label={<Translate>Showing city ahead of date?</Translate>}
              checked={values?.showingCity ?? false}
              setFieldValue={setFieldValue}
              error={touched?.showingCity && errors?.showingCity}
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <Tags
              name=""
              touched={touched}
              errors={errors}
              values={values}
              handleChange={handleChange}
            />
          </Grid>
          <Grid xs={12}>
            <BrandCompany
              name=""
              touched={touched}
              errors={errors}
              values={values}
              enabledOnly={true}
              handleChange={handleChange}
            />
            <Input
              type="hidden"
              name="premium"
              value={values.premium ? values.premium : ''}
            />
          </Grid>
          <Grid xs={12}>
            <h4>
              <Translate>Premium Upload</Translate>
            </h4>
          </Grid>
          <Grid xs={12}>
            <Upload
              label={
                <Translate wildcards={{ '%type%': 'Premium Label PDF-X3' }}>
                  Upload file %type%
                </Translate>
              }
              name="filePremium"
              setFieldValue={setFieldValue}
            />
            <Input
              type="hidden"
              name="premium"
              value={values.premium ? values.premium : ''}
            />
          </Grid>
          <Grid xs={12}>
            <h4>
              <Translate>Logo Upload</Translate>
            </h4>
          </Grid>
          <Grid xs={12}>
            <Upload
              label={
                <Translate
                  wildcards={{ '%type%': 'Vorlage png Max. Höhe: 80px' }}
                >
                  Upload file %type%
                </Translate>
              }
              name="fileLogo"
              setFieldValue={setFieldValue}
            />
            <Input
              type="hidden"
              name="logo"
              value={values.logo ? values.logo : ''}
            />
          </Grid>
        </Grid>
        {this.renderChildren(props)}
      </>
    );
  };

  render() {
    const {
      affiliateId,
      affiliateWithChildren,
      token,
      form,
      onSubmit,
      onCancel,
      onMount,
    } = this.props;
    const { ...defaultValues } = this.props.values;

    if (affiliateId && isEmpty(affiliateWithChildren)) {
      return null;
    }

    if (!affiliateId && isEmpty(form)) {
      return null;
    }

    if (!isEmpty(form)) {
      defaultValues.updateToken = token;
    }

    return (
      <Form
        name="affiliate"
        onMount={onMount}
        onSubmit={onSubmit}
        onCancel={onCancel}
        values={affiliateWithChildren}
        validationSchema={validationSchema}
        initialValues={
          !isEmpty(affiliateWithChildren)
            ? affiliateWithChildren
            : defaultValues
        }
        disableToolbar={true}
        renderFieldset={this.renderFieldset}
      />
    );
  }
}

function makeMapStateToProps(state, ownProps) {
  const affiliateId = ownProps.affiliateId;
  const getAffiliateWithChildren = makeAffiliateWithChildren();

  // eslint-disable-next-line no-unused-vars
  return (state, props) => {
    const {
      entities: { affiliate: affiliates },
      forms: { affiliate: form },
    } = state;

    if (affiliateId) {
      return {
        affiliates: affiliates ? affiliates : {},
        affiliate: affiliateId ? affiliates[affiliateId] : {},
        affiliateWithChildren: getAffiliateWithChildren(state, ownProps),
      };
    }
    return {
      affiliates: {},
      affiliate: {},
      token: !isEmpty(form) ? form.updateToken : '',
      form: form,
      affiliateWithChildren: {},
    };
  };
}

export default connect(makeMapStateToProps, {
  loadAffiliate,
  loadAffiliateChildren,
  initForm,
})(Edit);

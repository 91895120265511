import React, { Component } from 'react';
import { TextField, MenuItem, Input } from '@mui/material';
import { ConnectedProps, connect } from 'react-redux';
import { isEqual, isEmpty } from 'lodash';
import * as Yup from 'yup';
import Grid from '@mui/material/Unstable_Grid2';
import { ErrorMessage, FormikProps, FormikValues } from 'formik';
import { InferType } from 'yup';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import { GridRowId } from '@mui/x-data-grid';
import Translate from '../../../components/service/Translate';
import Form from '../../../components/Form';
import ValidationErrors from '../../../ValidationErrors';
import { withRouter, WithRouterProps } from '../../../withRouter';
import { RootState } from '../../../reducers';
import {
  fetchToken,
  removeUpdateToken,
  selectEmailAccountById,
} from '../EmailAccountSlice';
import { fetchAllEmails, selectAllEmails } from '../../Email/EmailSlice';
import { Email } from '../../Email/EmailType';
import { FormCheckbox } from '../../../components/StyledElements/StyledFormElements';

const validationSchema = Yup.object().shape({
  username: Yup.string().required(ValidationErrors.required),
  password: Yup.string().required(ValidationErrors.required),
  addresserEmail: Yup.string()
    .email(ValidationErrors.email)
    .required(ValidationErrors.required),
  replyTo: Yup.string()
    .email(ValidationErrors.email)
    .required(ValidationErrors.required),
  bcc: Yup.string().nullable().email(ValidationErrors.email),
  preview: Yup.string().nullable().email(ValidationErrors.email),
  host: Yup.string().required(ValidationErrors.required),
  inboxHost: Yup.string().nullable(),
  transmitter: Yup.string()
    .oneOf(['inxmail', 'smtp'], ValidationErrors.requiredSelect)
    .required(ValidationErrors.requiredSelect),
  useTls: Yup.bool(),
  port: Yup.number()
    .positive(ValidationErrors.positiveIntNumber)
    .integer(ValidationErrors.positiveIntNumber)
    .typeError(ValidationErrors.positiveIntNumber)
    .nullable(),
  emails: Yup.array().of(
    Yup.number()
      .nullable()
      .positive(ValidationErrors.positiveIntNumber)
      .integer(ValidationErrors.positiveIntNumber)
      .typeError(ValidationErrors.positiveIntNumber),
  ),
});

type EditProps = {
  accountId: GridRowId | null;
  onSubmit: (formData: FormData) => Promise<void>;
  onCancel: () => void;
};

type EditState = {
  dataLoading: boolean;
};

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & EditProps & WithRouterProps;

class EmailAccountEdit extends Component<Props, EditState> {
  static defaultProps = {
    accountId: null,
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      dataLoading: true,
    };
  }

  componentDidMount() {
    const promises = [];
    const { fetchAllEmails, fetchToken, accountId } = this.props;

    this.setState({ dataLoading: true });

    promises.push(fetchAllEmails());

    if (!accountId) {
      promises.push(fetchToken());
    }

    Promise.all(promises).then(() => {
      this.setState({ dataLoading: false });
    });
  }

  shouldComponentUpdate(nextProps: Props, nextState: EditState) {
    const { accountId, token, emails } = this.props;
    const { dataLoading } = this.state;

    return (
      !isEqual(nextProps.accountId, accountId) ||
      !isEqual(nextProps.token, token) ||
      !isEqual(nextProps.emails, emails) ||
      !isEqual(nextState.dataLoading, dataLoading)
    );
  }

  componentWillUnmount() {
    const { removeUpdateToken, token } = this.props;

    if (token) {
      removeUpdateToken();
    }
  }

  renderSelect = (email: Email) => (
    <MenuItem key={email.id} value={email.id}>
      <Translate>{email.primaryTextWithConfigKey}</Translate>
    </MenuItem>
  );

  renderEmails = (props: FormikProps<FormikValues>) => {
    const { emails } = this.props;
    const { handleChange, handleBlur, errors, touched, values } = props;

    if (!emails) {
      return null;
    }

    return (
      <>
        <Grid xs={12}>
          <h4>
            <Translate>verknüpfte Kampagnen</Translate>
          </h4>
        </Grid>

        <Grid xs={12} sm={6}>
          <FormControl error={touched.emails && Boolean(errors.emails)}>
            <InputLabel>
              <Translate>Emails</Translate>
            </InputLabel>
            <Select
              label={<Translate>Emails</Translate>}
              name="emails"
              value={values.emails ? values.emails : []}
              onChange={handleChange}
              onBlur={handleBlur}
              multiple
            >
              {emails.map((email) => this.renderSelect(email))}
            </Select>
            <FormHelperText>
              <ErrorMessage name="emails" />
            </FormHelperText>
          </FormControl>
        </Grid>
      </>
    );
  };

  renderFieldset = (props: FormikProps<FormikValues>) => {
    const { values, handleChange, handleBlur, touched, errors, setFieldValue } =
      props;

    return (
      <>
        <Input
          type="hidden"
          name="updateToken"
          defaultValue={values.updateToken ?? ''}
        />
        <Grid container>
          <Grid xs={12} sm={6}>
            <TextField
              label={<Translate>Username</Translate>}
              name="username"
              data-cy="emailaccount-edit-username"
              defaultValue={values.username ?? ''}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={<ErrorMessage name="username" />}
              error={touched.username && Boolean(errors.username)}
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <TextField
              label={<Translate>Password</Translate>}
              name="password"
              data-cy="emailaccount-edit-password"
              defaultValue={values.password ?? ''}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={<ErrorMessage name="password" />}
              error={touched.password && Boolean(errors.password)}
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <TextField
              label={<Translate>Sender E-Mail</Translate>}
              name="addresserEmail"
              data-cy="emailaccount-edit-addresseremail"
              defaultValue={values.addresserEmail ?? ''}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={<ErrorMessage name="addresserEmail" />}
              error={touched.addresserEmail && Boolean(errors.addresserEmail)}
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <TextField
              label={<Translate>E-Mail reply to</Translate>}
              name="replyTo"
              data-cy="emailaccount-edit-replyto"
              defaultValue={values.replyTo ?? ''}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={<ErrorMessage name="replyTo" />}
              error={touched.replyTo && Boolean(errors.replyTo)}
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <TextField
              label={<Translate>Bcc (used for backup)</Translate>}
              name="bcc"
              data-cy="emailaccount-edit-bcc"
              defaultValue={values.bcc ?? ''}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={<ErrorMessage name="bcc" />}
              error={touched.bcc && Boolean(errors.bcc)}
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <TextField
              label={<Translate>Receiver test run</Translate>}
              defaultValue={values.preview ?? ''}
              name="preview"
              data-cy="emailaccount-edit-preview"
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={<ErrorMessage name="preview" />}
              error={touched.preview && Boolean(errors.preview)}
              multiline={false}
            />
          </Grid>
          <Grid xs={12}>
            <h4>
              <Translate>Host</Translate>
            </h4>
          </Grid>
          <Grid xs={12} sm={6}>
            <TextField
              select
              label="Transmitter"
              name="transmitter"
              data-cy="emailaccount-edit-transmitter"
              value={values.transmitter ?? ''}
              onChange={handleChange('transmitter')}
              helperText={<ErrorMessage name="transmitter" />}
              error={touched.transmitter && Boolean(errors.transmitter)}
            >
              <MenuItem
                key="emailaccount-edit-transmitter-smtp"
                data-cy="emailaccount-edit-transmitter-smtp"
                value="smtp"
              >
                <Translate>SMTP</Translate>
              </MenuItem>
              <MenuItem
                key="emailaccount-edit-transmitter-inxmail"
                data-cy="emailaccount-edit-transmitter-inxmail"
                value="inxmail"
              >
                <Translate>Inxmail</Translate>
              </MenuItem>
            </TextField>
          </Grid>
          <Grid xs={6} sm={3}>
            <FormCheckbox
              name="useTls"
              label={<Translate>Use TLS</Translate>}
              data-cy="emailaccount-edit-usetls"
              checked={values?.useTls ?? false}
              setFieldValue={setFieldValue}
              error={touched?.useTls && errors?.useTls}
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <TextField
              label={<Translate>Host</Translate>}
              defaultValue={values.host ?? ''}
              name="host"
              data-cy="emailaccount-edit-host"
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={<ErrorMessage name="host" />}
              error={touched.host && Boolean(errors.host)}
              fullWidth
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <TextField
              label={<Translate>Inbox Host (deprecated)</Translate>}
              defaultValue={values.inboxHost ?? ''}
              name="inboxHost"
              data-cy="emailaccount-edit-inboxhost"
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={<ErrorMessage name="inboxHost" />}
              error={touched.inboxHost && Boolean(errors.inboxHost)}
              fullWidth
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <TextField
              label={<Translate>Port</Translate>}
              defaultValue={values.port ?? ''}
              name="port"
              data-cy="emailaccount-edit-port"
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={<ErrorMessage name="port" />}
              error={touched.port && Boolean(errors.port)}
            />
          </Grid>
          {this.renderEmails(props)}
        </Grid>
      </>
    );
  };

  render() {
    const { account, token, onSubmit, accountId, onCancel } = this.props;
    const { dataLoading } = this.state;
    const defaultValues: FormikValues = {};

    if (dataLoading) {
      return <Translate>Data loading...</Translate>;
    }

    if (accountId && isEmpty(account)) {
      // if accountId is set account should not be empty
      return null;
    }

    if (!accountId && !token) {
      // if accountId is missing form should not be empty
      return null;
    }

    if (!account && token) {
      // if form is not empty read token and write token into defaultValues
      defaultValues.updateToken = token;
    }

    const headline = account?.username ?? <Translate>New account</Translate>;

    return (
      <Form<InferType<typeof validationSchema>>
        headline={headline}
        validationSchema={validationSchema}
        onCancel={onCancel}
        onSubmit={onSubmit}
        initialValues={!isEmpty(account) ? account : defaultValues}
        renderFieldset={this.renderFieldset}
      />
    );
  }
}

function mapStateToProps(state: RootState, props: EditProps & WithRouterProps) {
  const {
    emailAccount: { updateToken: token },
  } = state;

  return {
    account: props.accountId
      ? selectEmailAccountById(state, +props.accountId)
      : null,
    token,
    emails: selectAllEmails(state),
  };
}

const connector = connect(mapStateToProps, {
  fetchAllEmails,
  fetchToken,
  removeUpdateToken,
});

export default withRouter(connector(EmailAccountEdit));

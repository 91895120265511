import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import { connect } from 'react-redux';
import { loadEmailQueueEmail } from '../actions';
import { makeCustomerListCustomerById } from '../selectors';
import { Box, Paper } from '@mui/material';
import ErrorBoundary from '../../../components/ErrorBoundary';

class EmailPreview extends Component {
  static propTypes = {
    loadEmailQueueEmail: PropTypes.func.isRequired,
    customerListCustomer: PropTypes.object,
    emailQueue: PropTypes.object,
    format: PropTypes.string,
    customerListLoading: PropTypes.bool,
  };

  static defaultProps = {};

  state = {
    dataLoading: false,
  };

  componentDidMount = () => {
    if (
      this.props.customerListCustomer &&
      this.props.customerListCustomer.emailQueue
    ) {
      this.setState({ dataLoading: true });
      Promise.all([
        this.props.loadEmailQueueEmail(
          this.props.customerListCustomer.emailQueue,
        ),
      ]).then(() => {
        this.setState({ dataLoading: false });
      });
    }
  };

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState) {
    if (
      !isEqual(
        this.props.customerListCustomer,
        prevProps.customerListCustomer,
      ) &&
      this.props.customerListCustomer
    ) {
      prevProps.loadEmailQueueEmail(this.props.customerListCustomer.emailQueue);
    }
  }

  shouldComponentUpdate = (nextProps, nextState) => {
    return (
      !isEqual(nextProps.emailQueue, this.props.emailQueue) ||
      !isEqual(nextProps.email, this.props.email) ||
      !isEqual(nextProps.token, this.props.token) ||
      !isEqual(nextState.dataLoading, this.state.dataLoading) ||
      !isEqual(nextProps.customerListLoading, this.props.customerListLoading) ||
      !isEqual(nextProps.format, this.props.format)
    );
  };

  renderHtml = () => {
    const { emailQueue } = this.props;

    if (this.props.format === 'html') {
      return (
        <div
          key={`emailQueue-${emailQueue.id}`}
          dangerouslySetInnerHTML={{ __html: emailQueue.htmlBody }}
        />
      );
    }
    return (
      <div
        key={`emailQueue-${emailQueue.id}`}
        dangerouslySetInnerHTML={{ __html: emailQueue.textBody }}
      />
    );
  };

  render() {
    const { emailQueue, customerListLoading } = this.props;
    const { dataLoading } = this.state;

    if (dataLoading || customerListLoading) {
      return null;
    }

    if (!emailQueue) {
      return null;
    }

    return (
      <ErrorBoundary>
        <Paper sx={{ pr: 0 }}>
          <Box component="h3" sx={{ color: 'black' }}>
            {emailQueue.subject}
          </Box>
          {this.renderHtml()}
        </Paper>
      </ErrorBoundary>
    );
  }
}

// eslint-disable-next-line no-unused-vars
function mapStateToProps(state, props) {
  const getCustomerListCustomerById = makeCustomerListCustomerById();
  return (state, props) => {
    const {
      entities: { emailQueue },
    } = state;

    const customerListCustomer = getCustomerListCustomerById(state, props);

    if (!emailQueue) {
      return {};
    }

    return {
      customerListCustomer: customerListCustomer,
      emailQueue:
        customerListCustomer !== null &&
        emailQueue[customerListCustomer.emailQueue]
          ? emailQueue[customerListCustomer.emailQueue]
          : null,
    };
  };
}

export default connect(mapStateToProps, { loadEmailQueueEmail })(EmailPreview);

import React from 'react';
import ACL from '../../components/ACL';
import Module from './Module';

type MailingModuleIndexProps = {
  requiredRoles?: string[];
};

function MailingModuleIndex({
  requiredRoles = ['ROLE_SUPERADMIN'],
}: MailingModuleIndexProps): JSX.Element {
  return (
    <ACL authorized={requiredRoles}>
      <Module />
    </ACL>
  );
}

export default MailingModuleIndex;

import { CALL_API } from '../../middleware';
import { Schemas } from '../../schemas';

export const TEMPLATE_REQUEST = 'TEMPLATE_REQUEST';
export const TEMPLATE_SUCCESS = 'TEMPLATE_SUCCESS';
export const TEMPLATE_FAILURE = 'TEMPLATE_FAILURE';

function fetchTemplate(id) {
  return {
    [CALL_API]: {
      types: [TEMPLATE_REQUEST, TEMPLATE_SUCCESS, TEMPLATE_FAILURE],
      endpoint: Routing.generate('emailtemplate_show', { id: id }, true),
      schema: Schemas.EMAILTEMPLATE,
    },
  };
}

export function loadTemplate(id) {
  return (dispatch) => {
    return dispatch(fetchTemplate(id));
  };
}

export const TEMPLATE_UPDATE_REQUEST = 'TEMPLATE_UPDATE_REQUEST';
export const TEMPLATE_UPDATE_SUCCESS = 'TEMPLATE_UPDATE_SUCCESS';
export const TEMPLATE_UPDATE_FAILURE = 'TEMPLATE_UPDATE_FAILURE';

function writeTemplate(form, templateId = null) {
  return {
    [CALL_API]: {
      types: [
        TEMPLATE_UPDATE_REQUEST,
        TEMPLATE_UPDATE_SUCCESS,
        TEMPLATE_UPDATE_FAILURE,
      ],
      endpoint: Routing.generate(
        'emailtemplate_update',
        { id: templateId },
        true,
      ),
      method: 'POST',
      data: form,
      schema: Schemas.EMAILTEMPLATE,
    },
  };
}

export function updateTemplate(form, templateId = null) {
  return (dispatch) => {
    return dispatch(writeTemplate(form, templateId));
  };
}

export const EMAIL_PREVIEW_REQUEST = 'EMAIL_PREVIEW_REQUEST';
export const EMAIL_PREVIEW_SUCCESS = 'EMAIL_PREVIEW_SUCCESS';
export const EMAIL_PREVIEW_FAILURE = 'EMAIL_PREVIEW_FAILURE';

function fetchPreview(form, emailId = null) {
  return {
    [CALL_API]: {
      types: [
        EMAIL_PREVIEW_REQUEST,
        EMAIL_PREVIEW_SUCCESS,
        EMAIL_PREVIEW_FAILURE,
      ],
      endpoint: Routing.generate('email_demo', { id: emailId }, true),
      method: 'POST',
      data: form,
      schema: Schemas.EMAIL,
    },
  };
}

export function loadPreview(form, emailId = null) {
  return (dispatch) => {
    return dispatch(fetchPreview(form, emailId));
  };
}

export const EMAILPREVIEW_FORM_REQUEST = 'EMAILPREVIEW_FORM_REQUEST';
export const EMAILPREVIEW_FORM_SUCCESS = 'EMAILPREVIEW_FORM_SUCCESS';
export const EMAILPREVIEW_FORM_FAILURE = 'EMAILPREVIEW_FORM_FAILURE';

function fetchPreviewForm(emailId) {
  return {
    [CALL_API]: {
      types: [
        EMAILPREVIEW_FORM_REQUEST,
        EMAILPREVIEW_FORM_SUCCESS,
        EMAILPREVIEW_FORM_FAILURE,
      ],
      endpoint: Routing.generate(
        'email_preview',
        { id: emailId, mode: 'v3' },
        true,
      ),
      schema: Schemas.FORM,
    },
  };
}

export function initPreviewForm(emailId) {
  return (dispatch, getState) => {
    const form = getState().forms.email;

    if (form) {
      return null;
    }

    return dispatch(fetchPreviewForm(emailId));
  };
}

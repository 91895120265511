import React, { Component } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Paper } from '@mui/material';
import { GridRowId } from '@mui/x-data-grid';
import MailingModuleList from './components/List';
import { withRouter, WithRouterProps } from '../../withRouter';
import {
  fetchAllMailingModules,
  updateMailingModule,
  fetchToken,
  deleteMailingModule,
  createMailingModule,
} from './MailingModuleSlice';
import { loadTags } from '../Location/actions';
import DeleteDialog from '../../components/DeleteDialog';
import ErrorBoundary from '../../components/ErrorBoundary';
import Translate from '../../components/service/Translate';
import { RootState } from '../../reducers';
import { FORM_MODE } from '../../utils/Constants';
import { GridParams } from '../../components/DataGrid/DataGridTypes';

type MailingModuleStates = {
  current?: GridRowId;
  editing: boolean;
  deleting: boolean;
  view: string;
  mode: string;
  dataLoading: boolean;
};

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & WithRouterProps;

class Module extends Component<Props, MailingModuleStates> {
  constructor(props: Props) {
    super(props);
    this.state = {
      editing: false,
      deleting: false,
      view: 'list',
      mode: FORM_MODE.SHOW,
      dataLoading: true,
    };
  }

  componentDidMount() {
    const { loadTags, paginationModel, sortModel } = this.props;

    this.loadMailingModules({ paginationModel, sortModel });
    loadTags();
  }

  loadMailingModules = (gridParams: GridParams): void => {
    const { fetchAllMailingModules } = this.props;

    this.setState({ dataLoading: true });

    Promise.all([fetchAllMailingModules({ gridParams })]).then(
      () => {
        this.setState({ dataLoading: false });
      },
      () => {
        this.setState({ dataLoading: false });
      },
    );
  };

  handleSubmit = async (form: FormData) => {
    const {
      updateMailingModule,
      createMailingModule,
      sortModel,
      paginationModel,
    } = this.props;
    const { current, editing } = this.state;

    let response;
    if (editing) {
      response = await updateMailingModule({
        data: form,
        id: current!,
      }).unwrap();
    } else {
      response = await createMailingModule({ data: form }).unwrap();
    }

    if ([200, 201].includes(response.statusCode)) {
      this.setState({ editing: false, mode: FORM_MODE.SHOW });

      this.loadMailingModules({ sortModel, paginationModel });
    }
  };

  handleDelete = async (current: number) => {
    const { modules, deleteMailingModule, sortModel, paginationModel } =
      this.props;

    this.setState({ deleting: true, current });

    const response = await deleteMailingModule({
      id: current,
      deleteToken: modules[current]!.deleteToken,
    }).unwrap();

    if (response.statusCode === 200) {
      this.setState({
        deleting: false,
        mode: FORM_MODE.SHOW,
        current: undefined,
      });

      this.loadMailingModules({ sortModel, paginationModel });
    }
  };

  onTouchEdit = (current: GridRowId) => {
    this.setState({ current, editing: true, mode: FORM_MODE.EDIT });
  };

  onTouchAdd = () => {
    this.setState({ editing: false, mode: FORM_MODE.ADD });
  };

  onTouchDelete = (current: GridRowId) => {
    this.setState({ current, deleting: true });
  };

  onTouchCancel = () => {
    this.setState({ editing: false, deleting: false, mode: FORM_MODE.SHOW });
  };

  renderDeleteDialog = () => {
    const { deleting, current } = this.state;
    const { modules } = this.props;
    const module = current ? modules[+current] : null;

    if (deleting && module) {
      return (
        <DeleteDialog
          current={module}
          onCancel={this.onTouchCancel}
          onSubmit={this.handleDelete}
        />
      );
    }

    return null;
  };

  renderContent() {
    const { mode, view, current, dataLoading } = this.state;

    if (dataLoading) {
      return (
        <Paper>
          <Translate>Data loading...</Translate>
        </Paper>
      );
    }

    if (view === 'list') {
      return (
        <ErrorBoundary>
          <MailingModuleList
            current={current}
            mode={mode}
            onSubmit={this.handleSubmit}
            onEdit={this.onTouchEdit}
            onCancel={this.onTouchCancel}
            onAdd={this.onTouchAdd}
            onDelete={this.onTouchDelete}
          />
        </ErrorBoundary>
      );
    }

    return null;
  }

  render() {
    return (
      <>
        {this.renderContent()}
        {this.renderDeleteDialog()}
      </>
    );
  }
}

function mapStateToProps() {
  return (state: RootState) => {
    const {
      entities: { tag },
      mailingModule: { resultset, pagination, paginationModel, sortModel },
    } = state;

    // TODO: insert seasons, affiliates, modules, tags in list not here
    const modules = state.mailingModule.entities;

    return {
      resultset,
      modules,
      pagination,
      paginationModel,
      sortModel,
      tags: tag,
    };
  };
}

const connector = connect(mapStateToProps, {
  fetchAllMailingModules,
  fetchToken,
  updateMailingModule,
  deleteMailingModule,
  createMailingModule,
  loadTags,
});

export default withRouter(connector(Module));

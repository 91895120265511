import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ACL from '../../components/ACL';
import { connect } from 'react-redux';
import { withRouter } from '../../withRouter';
import EmailModule from './Module';
import { compose } from 'redux';

class EmailModuleIndex extends Component {
  static propTypes = {
    requiredRoles: PropTypes.array,
    mode: PropTypes.string,
  };

  static defaultProps = {
    requiredRoles: ['ROLE_SUPERADMIN'],
    resultset: [],
    mode: 'show',
  };

  render() {
    return (
      <ACL authorized={this.props.requiredRoles}>
        <EmailModule mode={this.props.mode} />
      </ACL>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const params = ownProps.match.params;

  return {
    mode: params ? params.mode : 'show',
  };
}

const enhance = compose(withRouter, connect(mapStateToProps, {}));

export default enhance(EmailModuleIndex);

import React from 'react';
import { useParams } from 'react-router-dom';
import ACL from '../../components/ACL';
import EmailAccount from './Account';

type EmailAccountIndexProps = {
  requiredRoles?: string[];
};

function EmailAccountIndex({
  requiredRoles = ['ROLE_SUPERADMIN'],
}: EmailAccountIndexProps): JSX.Element {
  const { mode } = useParams();

  return (
    <ACL authorized={requiredRoles}>
      <EmailAccount mode={mode ?? 'show'} />
    </ACL>
  );
}

export default EmailAccountIndex;

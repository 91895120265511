import React, { useEffect, useState } from 'react';
import { Grid, Skeleton } from '@mui/material';
import Interaction from './Interaction';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { fetchInteractions } from '../../StatisticCampaignSlice';

type InteractionsProps = {
  campaignId: number;
};

export default function Interactions({ campaignId }: InteractionsProps) {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const { openedEmail, openedWeb, bounced } = useAppSelector(
    (state) => state.statisticCampaign.interactions,
  );

  useEffect(() => {
    setLoading(true);
    dispatch(fetchInteractions({ id: campaignId })).then(() =>
      setLoading(false),
    );
  }, []);

  return (
    <Grid container spacing={{ xs: 1, sm: 2, md: 4 }}>
      {loading ? (
        <>
          <Grid item xs={12} md={4}>
            <Skeleton
              variant="rounded"
              width="100%"
              height="600px"
              sx={{ mb: 3, mt: 3 }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Skeleton
              variant="rounded"
              width="100%"
              height="600px"
              sx={{ mb: 3, mt: 3 }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Skeleton
              variant="rounded"
              width="100%"
              height="600px"
              sx={{ mb: 3, mt: 3 }}
            />
          </Grid>
        </>
      ) : (
        <>
          {openedEmail && (
            <Grid item xs={12} md={4}>
              <Interaction
                title="Öffnungen"
                gaugeTitle="Öffnungsrate"
                dataset={openedEmail}
              />
            </Grid>
          )}
          {openedWeb && (
            <Grid item xs={12} md={4}>
              <Interaction
                title="Klicks"
                gaugeTitle="Klickrate"
                dataset={openedWeb}
              />
            </Grid>
          )}
          {bounced && (
            <Grid item xs={12} md={4}>
              <Interaction
                title="Bounces"
                gaugeTitle="Bounce-Rate"
                dataset={bounced}
              />
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
}

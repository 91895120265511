import { CALL_API } from '../../middleware';
import { Schemas } from '../../schemas';
import { sample } from 'lodash';

export const PRODUCT_REQUEST = 'PRODUCT_REQUEST';
export const PRODUCT_SUCCESS = 'PRODUCT_SUCCESS';
export const PRODUCT_FAILURE = 'PRODUCT_FAILURE';

function fetchProduct(id) {
  return {
    [CALL_API]: {
      types: [PRODUCT_REQUEST, PRODUCT_SUCCESS, PRODUCT_FAILURE],
      endpoint: Routing.generate('product_show', { id: id }, true),
      schema: Schemas.PRODUCT,
    },
  };
}

export function loadProduct(id, requiredFields = []) {
  return (dispatch, getState) => {
    const products = getState().entities.product;

    if (
      products &&
      products[id] &&
      requiredFields.every((key) => products[id].hasOwnProperty(key))
    ) {
      return null;
    }

    return dispatch(fetchProduct(id));
  };
}

export const PRODUCTS_REQUEST = 'PRODUCTS_REQUEST';
export const PRODUCTS_SUCCESS = 'PRODUCTS_SUCCESS';
export const PRODUCTS_FAILURE = 'PRODUCTS_FAILURE';

function fetchProducts(
  sortColumns,
  sortOrders,
  campaignId,
  offset = null,
  limit = null,
  join,
) {
  if (campaignId !== null) {
    return {
      [CALL_API]: {
        types: [PRODUCTS_REQUEST, PRODUCTS_SUCCESS, PRODUCTS_FAILURE],
        endpoint: Routing.generate(
          'product_campaign',
          {
            id: campaignId,
            sortColumns: sortColumns,
            sortOrders: sortOrders,
            offset: offset,
            limit: limit,
            join: join,
          },
          true,
        ),
        schema: Schemas.PRODUCTS,
      },
    };
  }
  return {
    [CALL_API]: {
      types: [PRODUCTS_REQUEST, PRODUCTS_SUCCESS, PRODUCTS_FAILURE],
      endpoint: Routing.generate(
        'product_index',
        {
          sortColumns: sortColumns,
          sortOrders: sortOrders,
          offset: offset,
          limit: limit,
          join: join,
        },
        true,
      ),
      schema: Schemas.PRODUCTS,
    },
  };
}

export function loadProducts(
  sortColumns = [],
  sortOrders = [],
  campaignId = null,
  requiredFields = [],
  join = [],
) {
  return (dispatch, getState) => {
    const product = getState().entities.product;
    const resultset = getState().resultsets.product;
    const outdated = getState().outdated.product;

    let offset = null;
    let limit = null;

    if (
      !outdated &&
      resultset &&
      resultset.length &&
      product &&
      requiredFields.every((key) => sample(product).hasOwnProperty(key))
    ) {
      return null;
    }

    return dispatch(
      fetchProducts(sortColumns, sortOrders, campaignId, offset, limit, join),
    );
  };
}

export const PRODUCTS_PAGED_REQUEST = 'PRODUCTS_PAGED_REQUEST';
export const PRODUCTS_PAGED_SUCCESS = 'PRODUCTS_PAGED_SUCCESS';
export const PRODUCTS_PAGED_FAILURE = 'PRODUCTS_PAGED_FAILURE';

function fetchProductsPaged(
  sortColumns,
  sortOrders,
  offset = null,
  limit = null,
  join = [],
  filter = [],
  campaignId = null,
) {
  if (campaignId !== null) {
    return {
      [CALL_API]: {
        types: [
          PRODUCTS_PAGED_REQUEST,
          PRODUCTS_PAGED_SUCCESS,
          PRODUCTS_PAGED_FAILURE,
        ],
        endpoint: Routing.generate(
          'product_campaign',
          {
            id: campaignId,
            sortColumns: sortColumns,
            sortOrders: sortOrders,
            offset: offset,
            limit: limit,
            join: join,
            filter: filter,
          },
          true,
        ),
        schema: Schemas.PRODUCTS,
      },
    };
  }
  return {
    [CALL_API]: {
      types: [
        PRODUCTS_PAGED_REQUEST,
        PRODUCTS_PAGED_SUCCESS,
        PRODUCTS_PAGED_FAILURE,
      ],
      endpoint: Routing.generate(
        'product_index',
        {
          sortColumns: sortColumns,
          sortOrders: sortOrders,
          offset: offset,
          limit: limit,
          join: join,
          filter: filter,
        },
        true,
      ),
      schema: Schemas.PRODUCTS,
    },
  };
}

export function loadNext(
  sortColumns = [],
  sortOrders = [],
  offset = null,
  limit = null,
  join = [],
  filter = [],
  campaignId = null,
) {
  return (dispatch, getState) => {
    const product = getState().entities.product;
    const pagination =
      product && getState().pagination.product
        ? getState().pagination.product
        : false;

    if (!product || !pagination) {
      return null;
    }

    return dispatch(
      fetchProductsPaged(
        sortColumns,
        sortOrders,
        offset,
        limit,
        join,
        filter,
        campaignId,
      ),
    );
  };
}

export const PRODUCT_FORM_REQUEST = 'PRODUCT_FORM_REQUEST';
export const PRODUCT_FORM_SUCCESS = 'PRODUCT_FORM_SUCCESS';
export const PRODUCT_FORM_FAILURE = 'PRODUCT_FORM_FAILURE';

function fetchFormTokens() {
  return {
    [CALL_API]: {
      types: [PRODUCT_FORM_REQUEST, PRODUCT_FORM_SUCCESS, PRODUCT_FORM_FAILURE],
      endpoint: Routing.generate('product_token', {}, true),
      schema: Schemas.FORM,
    },
  };
}

export function initForm() {
  return (dispatch, getState) => {
    const updateToken = getState().forms.updateToken;

    if (updateToken) {
      return null;
    }

    return dispatch(fetchFormTokens());
  };
}

export const PRODUCT_EXPORT_REQUEST = 'PRODUCT_EXPORT_REQUEST';
export const PRODUCT_EXPORT_SUCCESS = 'PRODUCT_EXPORT_SUCCESS';
export const PRODUCT_EXPORT_FAILURE = 'PRODUCT_EXPORT_FAILURE';

function exportProductAction(campaignId) {
  return {
    [CALL_API]: {
      types: [
        PRODUCT_EXPORT_REQUEST,
        PRODUCT_EXPORT_SUCCESS,
        PRODUCT_EXPORT_FAILURE,
      ],
      endpoint: Routing.generate(
        'product_campaignexport',
        { id: campaignId },
        true,
      ),
      method: 'POST',
      schema: Schemas.PRODUCT,
    },
  };
}

export function exportProducts(campaignId = null) {
  return (dispatch) => {
    return dispatch(exportProductAction(campaignId));
  };
}

export const PRODUCT_UPDATE_REQUEST = 'PRODUCT_UPDATE_REQUEST';
export const PRODUCT_UPDATE_SUCCESS = 'PRODUCT_UPDATE_SUCCESS';
export const PRODUCT_UPDATE_FAILURE = 'PRODUCT_UPDATE_FAILURE';

function writeProduct(form, productId) {
  return {
    [CALL_API]: {
      types: [
        PRODUCT_UPDATE_REQUEST,
        PRODUCT_UPDATE_SUCCESS,
        PRODUCT_UPDATE_FAILURE,
      ],
      endpoint: Routing.generate('product_update', { id: productId }, true),
      method: 'POST',
      data: form,
      schema: Schemas.PRODUCT,
    },
  };
}

export function updateProduct(form, productId = null) {
  return (dispatch) => {
    return dispatch(writeProduct(form, productId));
  };
}

export const PRODUCT_DELETE_REQUEST = 'PRODUCT_DELETE_REQUEST';
export const PRODUCT_DELETE_SUCCESS = 'PRODUCT_DELETE_SUCCESS';
export const PRODUCT_DELETE_FAILURE = 'PRODUCT_DELETE_FAILURE';

function deleteProduct(productId, deleteToken) {
  return {
    [CALL_API]: {
      types: [
        PRODUCT_DELETE_REQUEST,
        PRODUCT_DELETE_SUCCESS,
        PRODUCT_DELETE_FAILURE,
      ],
      endpoint: Routing.generate(
        'product_delete',
        { id: productId, deleteToken: deleteToken },
        true,
      ),
      method: 'DELETE',
    },
  };
}

export function removeProduct(productId, deleteToken) {
  return (dispatch) => {
    return dispatch(deleteProduct(productId, deleteToken));
  };
}

export const PRODUCT_RESET_REQUEST = 'PRODUCT_RESET_REQUEST';
export const PRODUCT_RESET_SUCCESS = 'PRODUCT_RESET_SUCCESS';
export const PRODUCT_RESET_FAILURE = 'PRODUCT_RESET_FAILURE';

function revertProduct(productId) {
  return {
    [CALL_API]: {
      types: [
        PRODUCT_RESET_REQUEST,
        PRODUCT_RESET_SUCCESS,
        PRODUCT_RESET_FAILURE,
      ],
      endpoint: Routing.generate('product_reset', { id: productId }, true),
      method: 'POST',
      schema: Schemas.PRODUCT,
    },
  };
}

export function resetProduct(productId) {
  return (dispatch) => {
    return dispatch(revertProduct(productId));
  };
}

import selectors from '../../selectors';
import { pickBy } from 'lodash';

// eslint-disable-next-line no-unused-vars
const getMailings = (state, props) => state.entities.mailing;
const getLetterFilter = (state, props) =>
  props.letterId ? parseInt(props.letterId) : null;

export const makeMailingsByLetter = () => {
  return selectors([getLetterFilter, getMailings], (letterId, mailings) => {
    return pickBy(mailings, { letter: letterId });
  });
};

import React, { Component } from 'react';

import PropTypes from 'prop-types';
import ErrorBoundary from '../../../components/ErrorBoundary';
import DataGridTable from '../../../components/DataGrid/DataGrid';
import RenderCellExpand from '../../../components/DataGrid/RenderCellExpand';
import { isEqual, isEmpty } from 'lodash';
import { withRouter } from '../../../withRouter';
import { connect } from 'react-redux';
import { compose } from 'redux';

class LocationSettingList extends Component {
  static propTypes = {
    locationsetting: PropTypes.object.isRequired,
    onOrderChange: PropTypes.func.isRequired,
    onAdd: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
    resultset: PropTypes.array,
  };

  static defaultProps = {};

  state = {
    columns: [
      {
        field: 'id',
        headerName: 'ID',
        relation: null,
        type: 'number',
        width: 30,
      },
      {
        field: 'location',
        headerName: 'Standort',
        relation: 'location',
        id: 'name',
        sort: 'location.name',
        joinBy: { location: 'locationsetting.location' },
        type: 'string',
        minWidth: 150,
        flex: 1,
        renderCell: RenderCellExpand,
      },
      {
        field: 'tag',
        headerName: 'Tag',
        relation: 'location.tag',
        type: 'string',
        sort: 'tag.name',
        joinBy: { location: 'locationsetting.location', tag: 'location.tag' },
        modifier: 'translate',
        minWidth: 150,
        flex: 1,
        renderCell: RenderCellExpand,
      },
      {
        field: 'setting',
        headerName: 'Schlüssel',
        relation: 'setting',
        id: 'primaryText',
        sort: 'setting.setting',
        joinBy: { setting: 'locationsetting.setting' },
        type: 'string',
        minWidth: 150,
        flex: 1,
        renderCell: RenderCellExpand,
      },
      {
        field: 'value',
        headerName: 'Einstellung',
        relation: null,
        type: 'string',
        minWidth: 150,
        flex: 1,
        renderCell: RenderCellExpand,
      },
    ],
  };

  componentDidMount = () => {};

  shouldComponentUpdate = (nextProps) => {
    return (
      !isEqual(nextProps.locationsetting, this.props.locationsetting) ||
      !isEqual(nextProps.resultset, this.props.resultset)
    );
  };

  render() {
    const {
      locationsetting,
      onOrderChange,
      resultset,
      pagination,
      onLoadNext,
      onDelete,
      onAdd,
      onEdit,
    } = this.props;
    const { columns } = this.state;

    return (
      <ErrorBoundary>
        <DataGridTable
          title="Einstellung"
          rows={locationsetting}
          iteratableRows={resultset}
          columns={columns}
          rowHeight={52}
          pagination={pagination}
          onEdit={onEdit}
          onAdd={onAdd}
          onDelete={onDelete}
          onOrderChange={onOrderChange}
          onLoadNext={onLoadNext}
        />
      </ErrorBoundary>
    );
  }
}

function mapStateToProps(state) {
  const {
    entities: { locationsetting },
    resultsets: { locationsetting: resultset },
    pagination: { locationsetting: pagination },
  } = state;

  return {
    locationsetting: !isEmpty(locationsetting) ? locationsetting : {},
    resultset: resultset,
    pagination: pagination,
  };
}

const enhance = compose(withRouter, connect(mapStateToProps, {}));

export default enhance(LocationSettingList);
